import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  selectAllNodes,
  selectAllPoints,
  selectFilteredTsps,
  selectInterconnects,
} from '../plotter/plotterSelectors';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON.js';
import { useSelector } from 'react-redux';
import { fromLonLat } from 'ol/proj';
import { WebGLLayer } from './webglLayer';
import { mapContext } from './EdgeMap';
import { InterconnectTooltip } from '../interconnects/InterconnectTooltip';
import { MapTooltip } from './MapTooltip';
import { useGetInterconnectsQuery } from '../services/edgeApi';

const getInterconnectStyle = () => [
  {
    filter: ['>', ['get', 'enabled'], 0],
    'stroke-width': 4,
    'stroke-color': '#00BFFF',
    'stroke-line-cap': 'round',
    'stroke-line-join': 'round',
  },
  {
    filter: ['<=', ['get', 'enabled'], 0],
    'stroke-width': 4,
    'stroke-color': '#00BFFF80',
    'stroke-line-cap': 'round',
    'stroke-line-join': 'round',
    'stroke-line-dash': [15, 10, 5, 10],
    'stroke-line-dash-offset': 0,
  },
];

export const MapInterconnects = ({ zIndex }) => {
  useGetInterconnectsQuery();
  const interconnects = useSelector(selectInterconnects);
  const { map, registerHoverListener, unregisterHoverListener } =
    useContext(mapContext);
  const nodes = useSelector(selectAllNodes);
  const allPoints = useSelector(selectAllPoints);
  const filteredTsps = useSelector(selectFilteredTsps);

  /** @type {[import('../services/edgeApi').Interconnect, React.Dispatch<React.SetStateAction<import('../services/edgeApi').Interconnect>>]} */
  const [tooltipInterconnect, setTooltipInterconnect] = useState(null);

  /** @type {import('./EdgeMap').MapEventListener} */
  const hoverListener = useCallback(
    (feature) => {
      if (
        feature?.getProperties()?.key ===
        `interconnect-${tooltipInterconnect?.id}`
      )
        return;
      if (
        !feature ||
        !feature?.getProperties()?.key?.startsWith('interconnect-')
      ) {
        setTooltipInterconnect(null);
        return;
      }
      const interconnectId = feature.getProperties()?.id;
      const interconnect = interconnects?.[interconnectId];
      setTooltipInterconnect(interconnect);
    },
    [interconnects],
  );

  const interconnectsLayer = useMemo(() => {
    const source = new VectorSource({
      features: new GeoJSON().readFeatures({
        type: 'FeatureCollection',
        features: Object.values(interconnects ?? {})
          .map((interconnect) => {
            const startPoint = allPoints[interconnect.start_point];
            const startNode = nodes[startPoint?.node];
            const endPoint = allPoints[interconnect.end_point];
            const endNode = nodes[endPoint?.node];
            const enabled = interconnect.enabled;
            const positions = [
              [startNode?.lat, startNode?.lng],
              [endNode?.lat, endNode?.lng],
            ];

            return {
              interconnect,
              startNode,
              startPoint,
              endNode,
              endPoint,
              enabled,
              positions,
            };
          })
          .filter(({ startNode, startPoint, endNode, endPoint }) => {
            return (
              startNode?.lat &&
              startNode?.lng &&
              endNode?.lat &&
              endNode?.lng &&
              (filteredTsps.length === 0 ||
                filteredTsps.includes(
                  startPoint?.tsp_short_name ?? startPoint.tsp_name,
                ) ||
                filteredTsps.includes(
                  endPoint?.tsp_short_name ?? endPoint.tsp_name,
                ))
            );
          })
          .map(({ startNode, endNode, interconnect }) => {
            return {
              type: 'Feature',
              geometry: {
                type: 'LineString',
                coordinates: [
                  fromLonLat([startNode.lng, startNode.lat]),
                  fromLonLat([endNode.lng, endNode.lat]),
                ],
              },
              properties: {
                id: interconnect.id,
                key: `interconnect-${interconnect.id}`,
                enabled: interconnect.enabled ? 1 : 0,
              },
            };
          }),
      }),
      format: new GeoJSON(),
    });

    return new WebGLLayer({
      source,
      style: getInterconnectStyle(),
      zIndex,
    });
  }, [interconnects, nodes, allPoints, filteredTsps]);

  useEffect(() => {
    if (!map || !interconnectsLayer) return;
    map.addLayer(interconnectsLayer);
    registerHoverListener(hoverListener);
    return () => {
      unregisterHoverListener(hoverListener);
      map.removeLayer(interconnectsLayer);
      interconnectsLayer.dispose();
    };
  }, [map, interconnectsLayer, hoverListener]);

  return (
    <MapTooltip visible={Boolean(tooltipInterconnect)} maxWidth={400}>
      <div className="p-2 bg-dark border-dark text-light rounded">
        <InterconnectTooltip interconnect={tooltipInterconnect} />
      </div>
    </MapTooltip>
  );
};
