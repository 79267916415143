import { DebouncedInput } from '../helpers/DebounceInput';
import { useEffect, useState } from 'react';
import { useScenarios } from '../helpers/hooks';
import { NavLink, useNavigate } from 'react-router-dom';
import { AddToGroup } from './AddToGroup';
import { InfiniteScrollObserver } from '../helpers/InfiniteScrollObserver';
import { LoadingIndicator } from '../helpers/LoadingIndicator';
import { DateSelector } from '../helpers/DateSelector';
import { useLazyGetScenarioQuery } from '../services/edgeApi';
import { CopyIcon, SearchIcon } from '@iconicicons/react';
import { useLocalStorage } from '../helpers/useLocalStorage';
import { MultiSelectButton } from '../helpers/MultiSelect';
import { UncontrolledTooltip } from 'reactstrap';

export const ScenarioList = () => {
  const [defaultFilters, setDefaultFilters] = useLocalStorage(
    'scenarioFilters',
    { onlyMine: true, hideSystem: true },
  );
  const navigate = useNavigate();

  const [search, setSearch] = useState(null);
  const [flowDate, setFlowDate] = useState(null);
  const [hideSystem, setHideSystem] = useState(
    Boolean(defaultFilters.hideSystem) ?? false,
  );
  const [onlyMine, setOnlyMine] = useState(
    Boolean(defaultFilters.onlyMine) ?? false,
  );

  const [scenarioTrigger, { isLoading }] = useLazyGetScenarioQuery();

  const { scenarios, isFetching, fetchNextPage } = useScenarios({
    search,
    showSystem: !hideSystem,
    all: !onlyMine,
    flowDate,
    pageSize: 30,
  });

  useEffect(() => {
    setDefaultFilters({ onlyMine, hideSystem });
  }, [onlyMine, hideSystem]);

  return (
    <>
      <div className="row m-3">
        <div className="col-xs-12 col-sm-8 col-md-6">
          <div className="input-group">
            <span className="input-group-text">
              Search <SearchIcon />
            </span>
            <DebouncedInput
              className="form-control"
              value={search ?? ''}
              onChange={(value) => {
                setSearch(value || null);
              }}
            />
          </div>
        </div>
        <div className="col-auto">
          <DateSelector
            value={flowDate ?? ''}
            onChange={(e) => setFlowDate(e.target.value)}
          />
        </div>
        <div className="col-auto">
          <MultiSelectButton
            title="Filters"
            options={[
              {
                name: 'Hide System Scenarios',
                value: hideSystem,
                onChange: setHideSystem,
              },
              {
                name: 'Only My Scenarios',
                value: onlyMine,
                onChange: setOnlyMine,
              },
            ]}
            isMarked={(item) => item.value}
            keyExtractor={(item) => item.name}
            optionRenderer={(item) => item.name}
            onToggle={(item, value) => item.onChange(value)}
          />
        </div>
      </div>
      <table className="table">
        <thead>
          <tr className="align-middle">
            <th>Scenario</th>
            <th>Description</th>
            <th>Flow Date</th>
            <th>Created By</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {scenarios?.map((scenario) => (
            <tr key={scenario.id} role="link">
              <td>
                <NavLink to={`/scenarios/${scenario.id}`}>
                  {scenario.name}
                </NavLink>
              </td>
              <td style={{ whiteSpace: 'pre-wrap' }}>{scenario.description}</td>
              <td>{new Date(scenario.flow_date).toLocaleDateString()}</td>
              <td>{scenario.creator ?? '--'}</td>
              <td>
                <div className="d-flex gap-2">
                  <button
                    id={`duplicate-scenario-${scenario.id}`}
                    disabled={isLoading}
                    className="btn btn-sm btn-primary"
                    onClick={async () => {
                      const result = await scenarioTrigger({
                        scenarioId: scenario.id,
                      });
                      if (result.error) {
                        alert(result.error.message);
                      }
                      const scenarioBody = result.data;
                      navigate(`/scenarios/new`, {
                        state: {
                          scenario: {
                            ...scenarioBody,
                            id: 'new',
                            name: `${scenarioBody.name} Copy`,
                          },
                        },
                      });
                    }}
                  >
                    <CopyIcon />
                  </button>
                  <button className="btn btn-sm btn-success">
                    <AddToGroup scenarioId={scenario.id} iconColor="white">
                      Add To Group
                    </AddToGroup>
                  </button>
                </div>
                <UncontrolledTooltip
                  target={`duplicate-scenario-${scenario.id}`}
                >
                  Duplicate Scenario
                </UncontrolledTooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <InfiniteScrollObserver height={30} onScrollIn={fetchNextPage} />
      {isFetching && (
        <span className="justify-content-center m-4">
          <LoadingIndicator text="Loading scenarios" />
        </span>
      )}
    </>
  );
};
