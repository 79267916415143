// Edges could have their own folder and slice really.  This is just a prototype for breaking these things out into separate files for cleanness
import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { toast } from 'react-hot-toast';
import * as plotterSelectors from '../plotter/plotterSelectors';
import * as selectedItemsActions from './selectedItemsSlice';
import { ItemSelector, SelectedItems } from './SelectorComponents';
import {
  useCreateUpdateEdgeMutation,
  useDeleteEdgeMutation,
} from '../services/edgeApi';

export function EdgeBuilder(props) {
  // it would be nice to make this all flexible enough to edit multiple edges at once but right now we just need to edit one at a time
  const dispatch = useDispatch();
  const selectedEdgeId = useSelector((state) => state.selectedItems.edge[0]);
  const currentlySelecting = useSelector(
    (state) => state.selectedItems.currentlySelecting,
  );
  // might be worth adding these to selectors in selectedItemsSlice:
  // const edgeData = useSelector(plotterSelectors.selectAllEdges)[selectedEdgeId]
  const isNew = selectedEdgeId === 'new' || !selectedEdgeId;
  // start out in edit mode if it's a new edge
  const [isEditing, setIsEditing] = useState(isNew);
  const [deleteEdge, deleteEdgeResult] = useDeleteEdgeMutation();

  useEffect(() => {
    const keyboardShortcuts = (event) => {
      // tab key
      // this implementation seems a little brittle so i'll fix it if bugs arise
      if (event.keyCode === 9) {
        event.preventDefault();
        // if selecting start node, switch to end node
        const nodeSelectionKey = currentlySelecting.node.selectionKey;
        if (nodeSelectionKey === 'edgeStartNode') {
          dispatch(
            selectedItemsActions.currentlySelectingChanged({
              itemType: 'node',
              selectionKey: 'edgeEndNode',
            }),
          );
        } else {
          // if selecting end node, switch to start node, or submit?
          dispatch(
            selectedItemsActions.currentlySelectingChanged({
              itemType: 'node',
              selectionKey: 'edgeStartNode',
            }),
          );
        }
      }

      // esc key
      if (event.keyCode === 27) {
        cancelEdit();
      }
    };

    window.addEventListener('keydown', keyboardShortcuts);

    // clean up
    return () => {
      window.removeEventListener('keydown', keyboardShortcuts);
    };
  }, [currentlySelecting]);

  const editEdge = () => {
    setIsEditing(true);
  };

  // const handleDelete = () => {
  //   if (window.confirm(`Are you sure you wish to delete node ${props.nodeId}?`)){
  //     deleteNode(props.nodeId)
  //   }
  // }

  const handleDelete = async () => {
    if (
      !window.confirm(`Are you sure you wish to delete edge ${selectedEdgeId}?`)
    ) {
      return null;
    }

    try {
      const edgeResult = await deleteEdge(selectedEdgeId);
      // start creating a new edge with the previous edge's end node as the start node for easy chaining of edges
      dispatch(
        selectedItemsActions.setState({
          edgeStartNode: [],
          edgeEndNode: [],
          edge: ['new'],
        }),
      );
      toast.success('Edge Deleted');
    } catch {
      toast.error('Error deleting edge, please try again');
    }
  };

  const cancelEdit = () => {
    // remove anything changed that selectLocalEdge is pulling from
    if (!isNew) {
      setIsEditing(false);
    }
    batch(() => {
      dispatch(
        selectedItemsActions.setState({
          edgeStartNode: [],
          edgeEndNode: [],
        }),
      );
      dispatch(
        selectedItemsActions.currentlySelectingChanged({
          itemType: 'edge',
          selectionKey: 'edge',
        }),
      );
      dispatch(
        selectedItemsActions.currentlySelectingChanged({
          itemType: 'node',
          selectionKey: 'edgeStartNode',
        }),
      );
    });
  };

  const newEdge = () => {
    // should this go here or in cleanup useEffect of EditNode?
    setIsEditing(true);
    dispatch(
      selectedItemsActions.setState({
        edgeStartNode: [],
        edgeEndNode: [],
        edge: ['new'],
      }),
    );
  };

  return (
    <>
      {isEditing ? (
        <EditEdge key={selectedEdgeId} edgeId={selectedEdgeId} />
      ) : (
        <Edge key={selectedEdgeId} edgeId={selectedEdgeId} />
      )}
      {isEditing && (
        <button
          className="btn btn-outline-warning w-100 mb-3"
          onClick={() => cancelEdit()}
        >
          Cancel
        </button>
      )}
      {!isEditing && (
        <>
          <button
            className="btn btn-outline-warning w-100 mt-1"
            onClick={() => editEdge()}
          >
            Edit Edge
          </button>
          <button className="btn btn-danger w-100 mt-1" onClick={handleDelete}>
            Delete Edge
          </button>
          <button
            className="btn btn-primary w-100 mt-1"
            onClick={() => newEdge()}
          >
            Create New Edge
          </button>
        </>
      )}
    </>
  );
}

function Edge(props) {
  const edgeData = useSelector(plotterSelectors.selectApiEdges)[props.edgeId];

  // after deleting an edge, it won't have any info
  if (!edgeData) {
    return null;
  }

  return (
    <div>
      <div>
        Edge:
        {props.edgeId}
      </div>
      <div>
        StartNode:
        {edgeData.start_node}
      </div>
      <div>
        EndNode:
        {edgeData.end_node}
      </div>
    </div>
  );
}

function EditEdge(props) {
  const dispatch = useDispatch();
  const edgeData = useSelector(plotterSelectors.selectAllEdges)[props.edgeId];
  const apiEdgeData = useSelector(plotterSelectors.selectApiEdges)[
    props.edgeId
  ];
  const edgeStartNode = useSelector(
    (state) => state.selectedItems.edgeStartNode[0],
  );
  const edgeEndNode = useSelector(
    (state) => state.selectedItems.edgeEndNode[0],
  );
  const [createUpdateEdge, createUpdateEdgeResult] =
    useCreateUpdateEdgeMutation();
  const isNew = props.edgeId === 'new' || !props.edgeId;
  const startNodeChangeCount = useRef(0);
  // const [isMounted, setIsMounted] = useState(false)
  useEffect(() => {
    const editEdgeKeyboardShortcuts = (event) => {
      // enter key or "A" key (because when you use a trackpad you have to reach over to press enter)
      if (event.keyCode === 13 || event.keyCode === 65) {
        handleSubmit();
      }
    };
    window.addEventListener('keydown', editEdgeKeyboardShortcuts);

    // clean up
    return () => {
      window.removeEventListener('keydown', editEdgeKeyboardShortcuts);
    };
  }, [edgeData]);

  useEffect(() => {
    // on initial load of edit edge, set the edgeStartNode and edgeEndNode selectors to whatever the edited edge's current nodes are
    // need this to be conditional because 'new' edge doesn't have any apiEdgeData
    if (!isNew) {
      batch(() => {
        dispatch(
          selectedItemsActions.currentlySelectingChanged({
            selectionKey: 'edgeStartNode',
            itemType: 'node',
          }),
        );
        dispatch(
          selectedItemsActions.setState({
            edgeStartNode: [apiEdgeData.start_node],
            edgeEndNode: [apiEdgeData.end_node],
          }),
        );
      });
    } else {
      batch(() => {
        dispatch(
          selectedItemsActions.currentlySelectingChanged({
            selectionKey: 'edgeStartNode',
            itemType: 'node',
          }),
        );
        dispatch(
          selectedItemsActions.setState({
            edge: ['new'],
            edgeStartNode: [null],
          }),
        );
      });
    }

    return () => {
      // clean up when unmounting
      dispatch(
        selectedItemsActions.setState({
          edgeStartNode: [],
          edgeEndNode: [],
        }),
      );
    };
  }, []);

  const handleSubmit = async () => {
    try {
      const edgeResult = await createUpdateEdge(edgeData).unwrap();
      // start creating a new edge with the previous edge's end node as the start node for easy chaining of edges
      dispatch(
        selectedItemsActions.setState({
          edgeStartNode: [edgeEndNode],
          edgeEndNode: [],
          edge: ['new'],
        }),
      );
      toast.success('Edge Created');
    } catch (error) {
      toast.error(`Error saving edge: ${JSON.stringify(error.data)}`);
    }
  };

  return (
    <div>
      <h6>{isNew ? 'Creating New Edge' : `Editing Edge: ${props.edgeId}`}</h6>
      <ItemSelector
        selectionKey="edgeStartNode"
        itemType="node"
        title="Select Start Node"
        hideSearch
      />
      <hr />
      <ItemSelector
        selectionKey="edgeEndNode"
        itemType="node"
        title="Select End Node"
        hideSearch
      />
      <hr />
      <button className="btn btn-primary w-100 mb-3" onClick={handleSubmit}>
        {isNew ? 'Create Edge' : 'Update Edge'}
      </button>
    </div>
  );
}
