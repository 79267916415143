import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import * as pathsActions from '../paths/pathsSlice';
import * as selectedItemsActions from '../selectedItems/selectedItemsSlice';
import { ItemSelector } from '../selectedItems/SelectorComponents';

export function AllPaths() {
  // get all simple paths between two points
  // first example of a full "mode" component.  Should probably be moved to a modes folder
  // it feels like some of the code in here (like the submit functionality) should go in a redux slice file.  However, putting it all in these
  // mode files like this allows it to be compartmentalized. Also if all the mode components end up being structured very similarly, maybe we can
  // turn them into a higher order component and pass the individual specifics into that

  // indended flow of mode components
  // 1. selectors for whatever this mode needs to submit to the API
  // 2. a workflow setup to set "currentlySelecting" on initial mount and potentially for each step.
  // for example, start this by setting currentlySelecting to receiptPoint, once that is selected switch to deliveryPoint
  // 3. submit button validation (inactive until everything is collected)
  // 4. submit handler that does the async API call to the right URL and then dispatches results to the correct slice
  // 5. cleanup when unmounting so currentlySelecting is reset and potentially set entire selectedItemsSlice and pathsSlice back to initialState

  const dispatch = useDispatch();
  const selectedItems = useSelector((state) => state.selectedItems);

  const [searchParams, setSearchParams] = useSearchParams();

  // 2. Basic workflow setup setting receiptPoints as first select type
  useEffect(() => {
    // this is setting the first expected user action automatically.
    // TODO: this causes a re-render -  is there a smoother way to do it
    dispatch(
      selectedItemsActions.currentlySelectingChanged({
        selectionKey: 'receiptPoints',
        itemType: 'point',
        multiSelect: false,
      }),
    );
  }, []);

  // update state with URL search params?  this is going to cause a loop.  Maybe it only makes sense on load, or maybe there's a history changed event we can listen to from router
  useEffect(() => {
    // could also pass the results through URL unless it gets too large
    const deliveryPoints = searchParams.getAll('deliveryPoints');
    const receiptPoints = searchParams.getAll('receiptPoints');
    dispatch(selectedItemsActions.setState({ deliveryPoints, receiptPoints }));
  }, []);

  // update search params in URL
  useEffect(() => {
    // this seems stupid, would rather update URL in redux thunk associated with selectors
    // const urlEncoded = JSON.stringify(selectedItems)
    // can also use the jsurl library if we need much shorter urls
    setSearchParams(selectedItems);
  }, [selectedItems]);

  // 5. cleanup when unmounting
  useEffect(
    () => () => {
      // what's the way to send one action that a bunch of slices respond to to clean up their state?
      dispatch(selectedItemsActions.resetState());
      dispatch(pathsActions.resetState());
    },
    [],
  );

  // 4. submit handler
  const handleSubmit = async () => {
    // TODO: This should probably be a POST?
    const receiptPoint = selectedItems.receiptPoints[0];
    const deliveryPoint = selectedItems.deliveryPoints[0];

    // TODO: make single endpoint for path finding - send mode along with request
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/path_study/?study_type=all_simple_paths&receipt_points=${receiptPoint}&delivery_points=${deliveryPoint}`,
    );
    dispatch(pathsActions.pathDataReceived(res.data));
  };

  // 3. validation - very basic for now
  const submitValidated =
    selectedItems.receiptPoints[0] && selectedItems.deliveryPoints[0];

  // 1. selectors
  return (
    <>
      <ul className="list-group">
        <ItemSelector
          selectionKey="receiptPoints"
          title="Receipt Point"
          itemType="point"
        />
        <ItemSelector
          selectionKey="deliveryPoints"
          title="Delivery Point"
          itemType="point"
        />
      </ul>
      <button
        onClick={() => handleSubmit()}
        disabled={!submitValidated}
        className="btn btn-primary btn-block w-100"
      >
        Submit
      </button>
    </>
  );
}
