import { createSlice, createSelector } from '@reduxjs/toolkit';
import {
  selectApiPoints,
  selectFilteredPoints,
} from '../plotter/plotterSelectors';

let localFilters;

try {
  localFilters = JSON.parse(localStorage.getItem('filters'));
} catch (e) {
  console.warn(e);
}

// const initialState = {
//     filters: localFilters || {},
//     // filters: {up_dn_ferc_cid: true}
// };

const initialState = localFilters || {};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    // filtersChanged: (state, action) => {
    //   const {name, value} = action.payload
    //   return {
    //     ...state,
    //     filters: {
    //       ...state.filters,
    //       [name]: value
    //     }
    //   }
    // },

    updateFilters: (state, action) => action.payload,
  },
});

export const filtersChanged = (payload) => (dispatch, getState) => {
  const prevFilters = getState().filters;
  const { name, value, filterType } = payload;
  const filters = {
    ...prevFilters,
    [filterType]: {
      ...prevFilters[filterType],
      [name]: value,
    },
  };
  localStorage.setItem('filters', JSON.stringify(filters));
  dispatch(updateFilters(filters));
};

export const clearFilters = (_payload) => (dispatch, _getState) => {
  const blankFilters = {};
  localStorage.setItem('filters', JSON.stringify(blankFilters));
  dispatch(updateFilters(blankFilters));
};

// export const customFiltersChanged = (payload) => (dispatch, getState) => {
//   const prevFilters = getState().filters.customFilters
//   const {name, value} = payload
//   const filters = {...prevFilters, [name]: value}
//   localStorage.setItem('filters', JSON.stringify(filters))
//   dispatch(updateFilters(filters))
// }

export const selectUniqueByKey = createSelector(
  [
    selectApiPoints,
    selectFilteredPoints,
    // filterSelection only shows relevant filter options based on the other filters. However it ends up only allowing one type of filter to be selected at a time
    // to do it right, the points need to be filtered by all other filters (not itself) before getting unique values
    (state, { keyString, filterSelection = false }) => ({
      keyString,
      filterSelection,
    }),
  ],
  (allPoints, filteredPoints, params) => {
    const pointCollection = params.filterSelection ? filteredPoints : allPoints;
    const allValues = Object.keys(pointCollection).map(
      (pointId) => pointCollection[pointId][params.keyString],
    );

    return [...new Set(allValues)];
  },
  // Output selector gets (`items, category)` as args
  // (items, category) => items.filter(item => item.category === category)
);

export const { updateFilters } = filtersSlice.actions;

export default filtersSlice.reducer;
