import { useState } from 'react';
import { ReusableModal } from '../modals/ReusableModal';
import { useNoticeComponentsHistory } from '../helpers/hooks';
import { useLocalStorage } from '../helpers/useLocalStorage';
import { toast } from 'react-hot-toast';
import { numberFormatter } from '../helpers/formatters';
import { LoadingIndicator } from '../helpers/LoadingIndicator';
import { InfiniteScrollObserver } from '../helpers/InfiniteScrollObserver';

export const NoticeRecordHistory = ({
  componentId,
  setHistoryModal,
  getFlowsOnDate,
}) => {
  const [option, setOption] = useLocalStorage('notice_history', 'percentage');
  const [rawNoticeModal, setRawNoticeModal] = useState(false);
  const [filePath, setFilePath] = useState('');

  const itemsPerPage = 30;
  const {
    noticeComponent,
    flows: records,
    flowsLoading: capacityComponentLoading,
    error,
    flowsFetching,
    isError: capacityComponentError,
    fetchNextPage,
  } = useNoticeComponentsHistory({ componentId: componentId, itemsPerPage });

  //This option list is used to control which variable you want to see in history modal
  const optionList = [
    { value: 'percentage', title: 'Percent Constrained', show: true },
    { value: 'priority', title: 'Priority', show: true },
    { value: 'oac', title: 'OAC', show: true },
    { value: 'identifier', title: 'Identifier', show: false },
    { value: 'identigier_type', title: 'Identifier Type', show: false },
    { value: 'id', title: 'ID', show: true },
  ];

  const interpolateColor = (c0, c1, f) => {
    if (!f) {
      return '#000000';
    }
    c0 = c0.match(/.{1,2}/g).map((oct) => parseInt(oct, 16) * (1 - f));
    c1 = c1.match(/.{1,2}/g).map((oct) => parseInt(oct, 16) * f);
    let ci = [0, 1, 2].map((i) => Math.min(Math.round(c0[i] + c1[i]), 255));
    return ci
      .reduce((a, v) => (a << 8) + v, 0)
      .toString(16)
      .padStart(6, '0');
  };

  const getColor = (ratio) => '#' + interpolateColor('0096FF', 'FF0000', ratio);

  const gasDates = [
    ...new Set(records.map((record) => record.start_gas_date)),
  ].sort((a, b) => {
    return a > b ? 1 : -1;
  });

  function generateDateRange(startDate, endingDate) {
    const dates = [];
    let currentDate = new Date(startDate);
    currentDate.setDate(currentDate.getDate() + 1);
    let endDate = new Date(endingDate);
    endDate.setDate(endDate.getDate() + 1);

    while (currentDate <= endDate) {
      dates.push(getYYYYMMDD(new Date(currentDate)));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dates;
  }

  function getYYYYMMDD(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const updatedTime = generateDateRange(
    gasDates[0],
    gasDates[gasDates.length - 1],
  );

  // we can either create nomCycle columns based on data, or hard code columns - either way we should force all nom cycles to be consistent name and order in the backend
  const nomCycles = ['T', 'E', 'I1', 'I2', 'I3'];
  // what's the best way to format outputs?  Would be great to do it all in one place.  I tried in the backend serializers, but it caused a problem because turning the numbers into strings means math doesn't work.  Maybe we should send a formatted and non formatted value from backend?
  // should put this function somewhere to be called from other components
  const formatConcept = (concept, value, sign = 1) => {
    if (['percentage', 'oac_unit', 'oac'].includes(concept)) {
      return numberFormatter(sign * value);
    } else if (concept === 'percentage') return `${value}%`;
    return value;
  };

  const RawNoticeModal = () => {
    const link =
      'https://pathpoint-space.nyc3.digitaloceanspaces.com/' + filePath;
    return rawNoticeModal ? (
      <ReusableModal
        size="lg"
        isOpen={rawNoticeModal}
        toggle={() => setRawNoticeModal(false)}
        header="raw notice data"
      >
        {filePath.substring(filePath.length - 4) === '.pdf' ||
        filePath.substring(filePath.length - 5) === '.html' ? (
          <iframe
            title="pdf"
            src={link}
            width="740"
            height="480"
            allow="autoplay"
          ></iframe>
        ) : (
          'No raw data found.'
        )}
      </ReusableModal>
    ) : (
      ''
    );
  };

  return (
    <>
      <ReusableModal
        size="lg"
        isOpen={true}
        toggle={() => setHistoryModal(false)}
        header={'History'}
      >
        {!noticeComponent ? (
          'No Notice History Found'
        ) : (
          <>
            <select
              value={option}
              onChange={(e) => {
                setOption(e.target.value);
              }}
            >
              {optionList.map((item) => {
                return item.show ? (
                  <option value={item.value}>{item.title}</option>
                ) : (
                  ''
                );
              })}
            </select>
            <table
              className="table table-striped"
              style={{ textAlign: 'center' }}
            >
              <thead>
                <tr>
                  <th style={{ width: '150px' }}>Gas Date</th>
                  {nomCycles.map((nomCycle) => (
                    <th key={nomCycle}>{nomCycle}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {updatedTime
                  .sort((a, b) => (a > b ? -1 : 1))
                  .map((gasDate) => (
                    <tr key={gasDate}>
                      <th>{gasDate}</th>
                      {nomCycles.map((nomCycle) => {
                        const record = records.find((record) => {
                          if (
                            record.nomination_cycle === null ||
                            record.nomination_cycle === 'None'
                          )
                            return record.start_gas_date === gasDate;
                          return (
                            record.start_gas_date === gasDate &&
                            record.nomination_cycle === nomCycle
                          );
                        });
                        return (
                          <>
                            <td
                              key={`${gasDate}-${nomCycle}`}
                              style={{
                                background: `${
                                  record?.[option] ? 'lightBlue' : ''
                                }`,
                              }}
                              onClick={() => getFlowsOnDate(gasDate, nomCycle)}
                            >
                              {/* see platts_pipeline_flow.py about whether we want to set backhaul flow records negative in backend, or just show them as negative in front end */}
                              {/* doing it in the frontend for now as a test to see whether negative flows are intuitive */}
                              <div
                                title={
                                  record?.[option]
                                    ? 'click to see raw data'
                                    : ''
                                }
                                style={
                                  record?.[option] ? { cursor: 'pointer' } : {}
                                }
                                onClick={() => {
                                  if (record?.[option]) {
                                    setRawNoticeModal(true);
                                    setFilePath(record?.notice_file_path);
                                  }
                                }}
                              >
                                {record?.[option]
                                  ? formatConcept(option, record?.[option])
                                  : ' - '}
                              </div>
                            </td>
                          </>
                        );
                      })}
                    </tr>
                  ))}
              </tbody>
            </table>
          </>
        )}
        {(flowsFetching || capacityComponentLoading) && <LoadingIndicator />}
        <InfiniteScrollObserver onScrollIn={fetchNextPage} />
      </ReusableModal>
      <RawNoticeModal filePath={filePath}></RawNoticeModal>
    </>
  );
};
