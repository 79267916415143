import {
  EyeIcon,
  EyeOffIcon,
  FilterIcon,
  ZoomInIcon,
} from '@iconicicons/react';
import { generateColor } from '../utils/colors';
import { numberFormatter, priceFormatter } from '../helpers/formatters';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllNodes, selectAllPoints } from '../plotter/plotterSelectors';
import { allPathsActions, selectAllPathsVisibleChains } from './allPathsSlice';
import * as pathsActions from '../paths/pathsSlice';
import React from 'react';
import { Collapse, UncontrolledTooltip } from 'reactstrap';
import { AllPathsOperationDetail } from './AllPathsOperationDetail';
import { useTspFilter } from '../helpers/hooks';
import { fromLonLat } from 'ol/proj';
import { plotterActions } from '../plotter/plotterSlice';

/**
 * @type {React.FC<{
 *  chain: import('../types/allPaths').AllPathsPickedChain;
 *  selectedPathId: number | string;
 *  scenario: import('./scenarioSlice').Scenario;
 * }>}
 */
export const AllPathsChainDetail = ({ chain, selectedPathId }) => {
  const receiptOp = chain.operations[0];
  const deliveryOp = chain.operations[chain.operations.length - 1];
  const chainOperations = chain.operations;

  const dispatch = useDispatch();
  const setTspFilter = useTspFilter();
  const allPoints = useSelector(selectAllPoints);
  const visibleChains = useSelector(selectAllPathsVisibleChains);
  const allNodes = useSelector(selectAllNodes);

  const setVisibleChains = (chainId, visible) => {
    dispatch(allPathsActions.setChainVisibility({ chainId, visible }));
  };

  const selectPath = () => {
    dispatch(pathsActions.pathSelected({ selectedPathId: chain.chain_number }));
  };

  /**
   *
   * @param {import('../types/allPaths').AllPathsPickedChain} chain
   */
  const filterTSPs = (chain) => {
    const tspSet = new Set(
      chain.operations.flatMap((op) => [
        op.delivery_point_tsp,
        op.receipt_point_tsp,
      ]),
    );
    setTspFilter(Array.from(tspSet));
  };

  /**
   *
   * @param {import('../types/allPaths').AllPathsPickedChain} chain
   */
  const zoomToPath = (chain) => {
    const nodes = chain.operations.flatMap(
      (op) => op.path_node_ids?.map((id) => allNodes[id]).filter(Boolean) ?? [],
    );
    const nodePositions = nodes.map((node) => fromLonLat([node.lng, node.lat]));
    dispatch(plotterActions.fitMapView(nodePositions));
  };

  const isSelected = selectedPathId === chain.chain_number;

  return (
    <div onClick={selectPath}>
      <div className="d-flex justify-content-between mb-3">
        <div className="d-flex flex-column lh-1">
          <span
            className={isSelected ? 'fw-bold' : 'fw-normal'}
            style={{
              color: generateColor(chain.chain_number, 80, 60),
            }}
          >
            Chain {chain.chain_number}
          </span>
          <span className="fs-6 text-muted">#{chain.chain_id}</span>
        </div>
        <div className="text-primary">
          <span>
            <FilterIcon
              id={`chain-filter-${chain.chain_id}`}
              role="button"
              onClick={() => filterTSPs(chain)}
            />
            <UncontrolledTooltip
              placement="top"
              target={`chain-filter-${chain.chain_id}`}
            >
              Show only pipelines in this path
            </UncontrolledTooltip>
          </span>
          <span>
            {visibleChains[chain.chain_id] ? (
              <EyeIcon
                id={`chain-toggle-visibility-${chain.chain_id}`}
                role="button"
                onClick={() => setVisibleChains(chain.chain_id, false)}
              />
            ) : (
              <EyeOffIcon
                id={`chain-toggle-visibility-${chain.chain_id}`}
                role="button"
                onClick={() => setVisibleChains(chain.chain_id, true)}
              />
            )}
            <UncontrolledTooltip
              placement="top"
              target={`chain-toggle-visibility-${chain.chain_id}`}
            >
              Toggle visibility
            </UncontrolledTooltip>
          </span>
          <span>
            <ZoomInIcon
              id={`chain-zoom-${chain.chain_id}`}
              role="button"
              onClick={() => zoomToPath(chain)}
            />
            <UncontrolledTooltip
              placement="top"
              target={`chain-zoom-${chain.chain_id}`}
            >
              Zoom to this path
            </UncontrolledTooltip>
          </span>
        </div>
      </div>
      <div>
        <p className="my-0">
          <span className="fw-bold">Receipt Price:</span>{' '}
          {priceFormatter.format(receiptOp?.receipt_asset_base_price)}
        </p>
        <p className="my-0">
          <span className="fw-bold">Delivery Price:</span>{' '}
          {priceFormatter.format(deliveryOp?.delivery_asset_base_price)}
        </p>
        <p className="my-0">
          <span className="fw-bold">Spread:</span>{' '}
          {priceFormatter.format(chain?.spread)}
        </p>
        <p className="my-0">
          <span className="fw-bold">Transport Cost:</span>{' '}
          {priceFormatter.format(
            chainOperations.reduce(
              (cost, op) => cost + op.transport_cost_per_dth,
              0,
            ),
          )}
        </p>
        {/* <p className="my-0">
                <span className="fw-bold">Gross Spread:</span> TODO
              </p> */}
        <p className="my-0">
          <span className="fw-bold">Profit / Dth:</span>{' '}
          {priceFormatter.format(chain?.profit_per_dth)}
        </p>
        <p className="my-0">
          <span className="fw-bold">Max Volume:</span>{' '}
          {numberFormatter(chain.constrained_max_volume)} Dth
        </p>
        <p className="my-0">
          <span className="fw-bold">Profit:</span>{' '}
          {priceFormatter.format(chain?.profit)}
        </p>
        <p className="my-0">
          <span className="fw-bold">Receipt:</span>{' '}
          {receiptOp?.receipt_asset_description}
        </p>
        <p className="my-0">
          <span className="fw-bold">Delivery:</span>{' '}
          {deliveryOp?.delivery_asset_description}
        </p>
      </div>
      <table className="table table-bordered mt-2">
        <thead>
          <tr>
            <th>From</th>
            <th>To</th>
            <th>Priority</th>
            <th>Transport</th>
          </tr>
        </thead>
        <tbody>
          {chainOperations
            .filter((op) => op.contract_id !== null)
            .map((op) => {
              const from = `${op.receipt_point_tsp}\n${
                allPoints[op.receipt_point_id]?.loc_name
              }`;
              const to = `${op.delivery_point_tsp}\n${
                allPoints[op.delivery_point_id]?.loc_name
              }`;
              return (
                <tr key={op.id}>
                  <td style={{ whiteSpace: 'pre-wrap' }}>{from}</td>
                  <td style={{ whiteSpace: 'pre-wrap' }}>{to}</td>
                  <td>{op.transport_priority}</td>
                  <td>{priceFormatter.format(op.transport_cost_per_dth)}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <Collapse isOpen={isSelected}>
        <ul className="list-group">
          {chain.operations.map((operation, index) => {
            if (
              !operation ||
              (!operation.contract_id &&
                index !== 0 &&
                index !== chain.operations.length - 1)
            ) {
              return null;
            }

            return (
              <li key={operation.id} className="list-group-item">
                <AllPathsOperationDetail operation={operation} />
              </li>
            );
          })}
        </ul>
      </Collapse>
      <hr />
    </div>
  );
};
