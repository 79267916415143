import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  CheckIcon,
  CloseIcon,
  EyeIcon,
  EyeOffIcon,
  FloppyDiscIcon,
  UploadIcon,
} from '@iconicicons/react';
import {
  useCreateContractGroupMutation,
  useGetContractGroupsQuery,
  useLazyGetContractsQuery,
} from '../services/edgeApi';
import { toIsoDate } from '../utils/stringUtils';
import { DateSelector } from '../helpers/DateSelector';
import { scenarioActions } from '../scenarios/scenarioSlice';
import { contractActions } from './contractsSlice';
import { useLocation } from 'react-router-dom';
import { EditableTable } from '../helpers/EditableTable';
import { UncontrolledTooltip } from 'reactstrap';
import toast from 'react-hot-toast';

/**
 * @typedef {{
 *  contracts: import('../scenarios/scenarioSlice').Contract[],
 * }} ScenarioContractsProps
 */

/**
 *
 * @param {ScenarioContractsProps} props
 * @returns {React.FC<ScenarioContractsProps>}
 */

export const ScenarioContracts = (_props) => {
  /** @type {"contracts"} */
  const componentType = 'contracts';

  /** @type {string} */
  const flowDate = useSelector((state) => state.scenario.data.flow_date);

  const [activeContractsDate, setActiveContractsDate] = useState(
    new Date(flowDate),
  );

  const [contractsTrigger, { isFetching: fetchingActiveContracts }] =
    useLazyGetContractsQuery();
  const { data: contractGroups } = useGetContractGroupsQuery();
  const [createContractGroupTrigger] = useCreateContractGroupMutation();
  const dispatch = useDispatch();

  const location = useLocation();

  /** @type {import('../scenarios/scenarioSlice').Contract[]} */
  const stateContracts = useSelector((state) => state.scenario.data.contracts);

  /** @type {import('../scenarios/scenarioSlice').Contract[]} */
  const isEditing = useSelector((state) => state.scenario.isEditing);

  /** @type {{[key: string | number]: import('../scenarios/scenarioSlice').Contract}} */
  const visibleContracts = useSelector((state) => state.contracts);

  const [selectedContractGroup, setSelectedContractGroup] = useState(-1);
  const [creatingNewPreset, setCreatingNewPreset] = useState(false);
  const [newPresetName, setNewPresetName] = useState('');
  const [contracts, setContracts] = useState([]);
  const [bulkToggle, setBulkToggle] = useState(false);

  /** @type {import('react').Ref<import('../helpers/EditableTable').EditableTableRef<import('../scenarios/scenarioSlice').Contract>>} */
  const tableRef = useRef(null);

  /** @type {import('react').Ref<HTMLInputElement>} */
  const presetNameInputRef = useRef(null);

  useEffect(() => {
    setActiveContractsDate(new Date(flowDate));
  }, [flowDate]);

  useEffect(async () => {
    const result = await contractsTrigger({
      date: null,
      include_details: true,
    }).unwrap();
    setContracts(Object.values(result));
  }, []);

  useEffect(() => {
    dispatch(contractActions.resetState());
    return () => {
      dispatch(contractActions.resetState());
    };
  }, [location.pathname]);

  const overwriteWithActiveContracts = async () => {
    try {
      const result = await contractsTrigger({
        date: toIsoDate(activeContractsDate),
        include_details: true,
      }).unwrap();
      bulkChangeContracts(Object.values(result), true);
    } catch (e) {
      console.error(e);
    }
  };

  /**
   *
   * @param {import('../scenarios/scenarioSlice').Contract} contract
   * @param {boolean} checked
   */
  const onChangeContract = (contract, checked) => {
    if (checked) {
      dispatch(
        scenarioActions.updateComponent({
          componentType,
          component: { ...contract, id: contract.id },
        }),
      );
    } else {
      dispatch(
        scenarioActions.removeComponent({
          componentType,
          componentId: contract.id,
        }),
      );
    }
  };

  const onChangeContractVisibility = (contract, visible) => {
    if (visible) {
      dispatch(contractActions.addContract(contract));
    } else {
      dispatch(contractActions.removeContract(contract.id));
    }
  };

  /**
   *
   * @param {import('../scenarios/scenarioSlice').Contract[]} contracts
   * @param {boolean} checked
   */
  const bulkChangeContracts = (contracts, checked) => {
    const otherContracts = stateContracts.filter((contract) =>
      contracts.every((c) => c.id !== contract.id),
    );
    if (checked) {
      dispatch(
        scenarioActions.overwrite({
          componentType,
          components: [...otherContracts, ...contracts],
        }),
      );
    } else {
      dispatch(
        scenarioActions.overwrite({
          componentType,
          components: otherContracts,
        }),
      );
    }
  };

  const applyContractFromPreset = async () => {
    if (!selectedContractGroup) {
      return;
    }
    const group = contractGroups[selectedContractGroup];
    dispatch(
      scenarioActions.overwrite({
        componentType,
        components: group.contracts.map((contractId) =>
          contracts.find((c) => c.id === contractId),
        ),
      }),
    );
  };

  const enterCreatePresetMode = () => {
    setCreatingNewPreset(true);
  };

  const saveNewPreset = async () => {
    const contractIds = stateContracts.map((contract) => contract.id);
    try {
      const result = await createContractGroupTrigger({
        name: newPresetName,
        contracts: contractIds,
      }).unwrap();
      setNewPresetName('');
      setCreatingNewPreset(false);
      setSelectedContractGroup(result.id);
    } catch {
      toast.error('Failed to save preset');
    }
  };

  useEffect(() => {
    if (creatingNewPreset) {
      presetNameInputRef?.current?.focus();
    }
  }, [creatingNewPreset]);

  const includedContracts = useMemo(
    () =>
      stateContracts.reduce(
        (acc, contract) => ({ ...acc, [contract.id]: true }),
        {},
      ),
    [stateContracts],
  );

  const columns = useMemo(() => {
    /** @type {import('../helpers/EditableTable').EditableTableColumn[]} */
    const editOnlyColumns = [
      {
        key: 'included',
        type: 'text',
        title: (
          <div className="form-check">
            <label
              className="form-check-label"
              htmlFor="include-scenarios-check"
            >
              Use
            </label>
            <input
              className="form-check-input"
              type="checkbox"
              id="include-scenarios-check"
              checked={bulkToggle}
              onChange={(e) => {
                const filteredContracts = tableRef.current?.getFilteredData();
                setBulkToggle(e.target.checked);
                bulkChangeContracts(filteredContracts, e.target.checked);
              }}
            />
          </div>
        ),
        isEditable: false,
        renderCell: ({ data: contract }) => (
          <input
            type="checkbox"
            className="form-check-input"
            checked={includedContracts[contract.id] === true}
            onChange={(e) => onChangeContract(contract, e.target.checked)}
          />
        ),
      },
    ];

    /** @type {import('../helpers/EditableTable').EditableTableColumn[]} */
    const allColumns = [
      ...(isEditing ? editOnlyColumns : []),
      {
        key: 'visible',
        isEditable: false,
        title: 'Visible',
        renderCell: ({ data: contract }) =>
          visibleContracts[contract.id] ? (
            <EyeIcon
              role="button"
              onClick={() => onChangeContractVisibility(contract, false)}
            />
          ) : (
            <EyeOffIcon
              role="button"
              onClick={() => onChangeContractVisibility(contract, true)}
            />
          ),
      },
      {
        key: 'name',
        isEditable: false,
        title: 'Name',
        allowFiltering: true,
      },
      {
        key: 'owner',
        isEditable: false,
        title: 'Owner',
        allowFiltering: true,
      },
      {
        key: 'k_id',
        isEditable: false,
        title: 'Contract Number',
        allowFiltering: true,
      },
      {
        key: 'tsp_name',
        isEditable: false,
        title: 'TSP',
        allowFiltering: true,
      },
      {
        key: 'type',
        isEditable: false,
        title: 'Type',
        allowFiltering: true,
      },
      {
        key: 'max_daily_quantity',
        isEditable: false,
        title: 'MDQ (dth)',
        allowFiltering: false,
      },
      {
        key: 'start_date',
        isEditable: false,
        title: 'Start Date',
        displayFunction: (value) =>
          value ? new Date(value).toLocaleDateString() : '--',
      },
      {
        key: 'end_date',
        isEditable: false,
        title: 'End Date',
        displayFunction: (value) =>
          value ? new Date(value).toLocaleDateString() : '--',
      },
    ];
    return allColumns;
  }, [isEditing, visibleContracts, includedContracts, stateContracts]);

  return (
    <>
      {isEditing === true && (
        <div className="row justify-content-between m-3">
          <div className="col-3 col-md-4 col-sm-12">
            <DateSelector
              value={toIsoDate(activeContractsDate)}
              onChange={(evt) => setActiveContractsDate(evt.target.valueAsDate)}
              isLoading={fetchingActiveContracts}
              onConfirm={overwriteWithActiveContracts}
              buttonText="Add Active Contracts"
            />
          </div>
          <div className="col-3 col-md-4 col-sm-12">
            <div
              className="input-group"
              style={{
                maxWidth: '100%',
                display: creatingNewPreset ? '' : 'none',
              }}
            >
              <input
                type="text"
                className="form-control"
                ref={presetNameInputRef}
                value={newPresetName}
                onChange={(evt) => setNewPresetName(evt.target.value)}
              />
              <button
                className="btn btn-outline-primary"
                onClick={saveNewPreset}
              >
                <CheckIcon />
              </button>
              <button
                className="btn btn-outline-danger"
                onClick={() => setCreatingNewPreset(false)}
              >
                <CloseIcon />
              </button>
            </div>
          </div>
          {!creatingNewPreset && (
            <div className="col-3 col-md-4 col-sm-12">
              <div
                className="input-group"
                style={{
                  maxWidth: '100%',
                }}
              >
                <select
                  className="form-select"
                  value={selectedContractGroup}
                  onChange={(evt) => setSelectedContractGroup(evt.target.value)}
                >
                  <option disabled value={-1}>
                    Select Contract Group
                  </option>
                  {Object.values(contractGroups ?? {}).map((group) => (
                    <option key={group.id} value={group.id}>
                      {group.name}
                    </option>
                  ))}
                </select>
                <button
                  className="btn btn-outline-primary"
                  id="apply-contract-preset-button"
                  onClick={applyContractFromPreset}
                >
                  <UploadIcon />
                </button>
                <UncontrolledTooltip target="apply-contract-preset-button">
                  Load contracts from preset
                </UncontrolledTooltip>
                <button
                  className="btn btn-outline-primary"
                  id="save-contracts-button"
                  onClick={enterCreatePresetMode}
                >
                  <FloppyDiscIcon />
                </button>
                <UncontrolledTooltip target="save-contracts-button">
                  Save new preset
                </UncontrolledTooltip>
              </div>
            </div>
          )}
        </div>
      )}
      <EditableTable
        ref={tableRef}
        allowSelection={false}
        isEditable
        isEditing={isEditing}
        actions={[]}
        columns={columns}
        data={isEditing ? contracts : stateContracts}
        keyExtractor={(contract) => contract.id}
        onSelect={() => {}}
      />
    </>
  );
};
