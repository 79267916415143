import { useSelector } from 'react-redux';
import { Vector as VectorSource } from 'ol/source.js';
import { useContext, useEffect, useMemo } from 'react';
import { mapContext } from './EdgeMap';
import { selectFilteredEdges } from '../plotter/plotterSelectors';
import GeoJSON from 'ol/format/GeoJSON.js';
import { WebGLLayer } from './webglLayer';
import { fromLonLat } from 'ol/proj';
import { selectContracts } from '../contracts/contractsSlice';

const PRIORITY_CLASSES = ['IN_PATH', 'OUT_OF_PATH', 'IT'];
const PRIORITY_COLORS = {
  1: 'hsl(60,100%,75%)',
  2: 'hsl(130,100%,75%)',
  3: 'hsl(200,100%,75%)',
};
const PRIORITY_LEVELS = PRIORITY_CLASSES.reduce(
  (result, key, index) => ({
    ...result,
    [key]: PRIORITY_CLASSES.length - index,
  }),
  {},
);

const getContractStyle = () => {
  return {
    'stroke-width': 10,
    'stroke-color': ['get', 'color'],
    'stroke-line-cap': 'round',
    'stroke-line-join': 'round',
  };
};

/**
 * @typedef {{
 *  edgeId: string,
 *  IN_PATH: {contracts: number[], total: number},
 *  IT: {contracts: number[], total: number},
 *  OUT_OF_PATH: {contracts: number[], total: number},
 *  color: string,
 *  firm_total: number,
 *  highest_priority_number: number,
 * }} EdgeContracts
 */

/**
 *
 * @param {EdgeContracts} edgeContracts
 * @param {Object<string, import('../services/edgeApi').Edge>} edges
 * @returns {import('ol/Feature').FeatureLike}
 */
const contractToGeoJson = (edgeContracts, edges) => {
  const edge = edges[edgeContracts.edgeId];

  return {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: edge.positions.map(([lat, lon]) => fromLonLat([lon, lat])),
    },
    properties: {
      id: edge.id,
      key: `contract-${edge.id}`,
      color: edgeContracts.color,
    },
  };
};

export const MapContracts = ({ zIndex = 0 }) => {
  const { map } = useContext(mapContext);
  // const { data: contracts } = useGetContractsQuery();
  const contracts = useSelector(selectContracts);
  const edges = useSelector(selectFilteredEdges);
  const showContracts = useSelector((state) => state.plotter.showContracts);

  /**
   * @type {Object<string, EdgeContracts>}
   */
  const edgeContracts = useMemo(() => {
    if (!showContracts || !contracts || !edges) {
      return {};
    }
    const _edgeContracts = {};
    Object.values(contracts)
      .filter(Boolean)
      .forEach((contract) => {
        contract.edge_classification?.forEach((edge_class) => {
          edge_class.edge_ids.forEach((edgeId) => {
            if (!_edgeContracts[edgeId]) {
              _edgeContracts[edgeId] = PRIORITY_CLASSES.reduce(
                (object, priorityClass) => ({
                  ...object,
                  [priorityClass]: { contracts: [], total: 0 },
                }),
                {
                  edgeId: edgeId,
                  firm_total: 0,
                },
              );
            }

            const highest_priority_number = Math.max(
              _edgeContracts[edgeId].highest_priority_number || 0,
              PRIORITY_LEVELS[edge_class.type],
            );
            _edgeContracts[edgeId].highest_priority_number =
              highest_priority_number;
            _edgeContracts[edgeId].color =
              PRIORITY_COLORS[highest_priority_number];
            _edgeContracts[edgeId].firm_total += Number(
              edge_class != 'IT' ? contract.max_daily_quantity : 0,
            );
            _edgeContracts[edgeId][edge_class.type].total += Number(
              contract.max_daily_quantity,
            );
            _edgeContracts[edgeId][edge_class.type].contracts.push(contract.id);
          });
        });
      });
    return _edgeContracts;
  }, [contracts, edges, showContracts]);

  const contractsLayer = useMemo(() => {
    const source = new VectorSource({
      features: new GeoJSON().readFeatures({
        type: 'FeatureCollection',
        features: Object.values(edgeContracts ?? {})
          .filter((edgeContract) => Boolean(edges[edgeContract.edgeId]))
          .map((edgeContract) => contractToGeoJson(edgeContract, edges)),
      }),
      format: new GeoJSON(),
    });

    return new WebGLLayer({
      source,
      style: getContractStyle(),
      zIndex,
    });
  }, [edges, contracts, edgeContracts]);

  useEffect(() => {
    if (!map || !contractsLayer) return;
    map.addLayer(contractsLayer);
    return () => {
      map.removeLayer(contractsLayer);
      contractsLayer.dispose();
    };
  }, [map, contractsLayer]);

  return null;
};
