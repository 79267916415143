import { createSlice, createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import * as pathsActions from '../paths/pathsSlice';
import * as selectedItemsActions from '../selectedItems/selectedItemsSlice';
import * as plotterSelectors from './plotterSelectors';
import process from 'process';
import { edgeApi } from '../services/edgeApi';

/**
 * @typedef {{
 *  id: number,
 *  order: number,
 *  pipe: string,
 *  receipt_point_id: number,
 *  delivery_point_id: number,
 *  contract_num: string,
 *  daily_receipt_volume: number,
 *  daily_delivery_volume: number,
 *  receipt_confirm_num: string,
 *  delivery_confirm_num: string,
 *  path: number[],
 *  chain_id: number,
 *  chain_receipt_volume: number,
 *  chain_delivery_volume: number,
 *  visible: boolean,
 * }} ChainedRiseTransport
 */

/**
 * @typedef {{
 *  chain_id: number,
 *  transports: ChainedRiseTransport[],
 *  interconnectTransports: ChainedRiseTransport[]
 * }} RiseTransportChain
 */

/**
 * @typedef {{
 *  center: [number, number],
 *  zoom: number,
 * }} MapView
 */

/**
 * @typedef {{
 *  id: number,
 *  gas_date: string,
 *  with_edges: number[],
 *  against_edges: number[],
 *  ratio: number,
 *  oac_calc: number,
 *  direction_sign: -1 | 1,
 *  nomination_cycle: string,
 *  scheduled_volume: number,
 *  actual_volume: number,
 *  utilization: number,
 *  design_capacity: number,
 *  operational_capacity: number,
 *  actual_capacity: number,
 *  operationally_available: number,
 * }} CapacityRecord
 */

/**
 * @typedef {{
 *  id: number,
 *  tsp_name: string,
 *  points: number[],
 *  nodes: number[],
 *  best_available: CapacityRecord,
 *  forwardhaul_edges: number[],
 *  backhaul_edges: number[],
 *  is_favorite: boolean,
 *  created_at: string,
 *  updated_at: string,
 *  platts_component_id: number,
 *  capacity_type: "SEGMENT" | "POINT",
 *  flow_direction: string,
 *  description: string,
 *  utilization_rate: number,
 *  has_recent_flows: boolean,
 *  allow_displacement: boolean,
 *  tsp: number,
 *  forwardhaul_override_edges: number[],
 *  backhaul_override_edges: number[],
 *  sources: {
 *    platts_pipeline_flows: CapacityRecord[],
 *  }
 * }} Capacity
 */

/**
 * @typedef {{
 *  appMode?: string,
 *  showCountyPoints: boolean,
 *  showFlowArrows: boolean,
 *  showEdgeDirections: boolean,
 *  showNoticeMarkers: boolean,
 *  showConstraints: boolean,
 *  pathMode: "ANT" | "SIMPLE",
 *  nodes: {[key: string]: import('../services/edgeApi').Node},
 *  points: {[key: string]: import('../scenarios/scenarioSlice').Point},
 *  edges: {[key: string]: import('../services/edgeApi').Edge},
 *  risePaths: ChainedRiseTransport[],
 *  risePathsLoading: boolean,
 *  capacities: Capacity[],
 *  markets: Array<{ description: string, price: nubmer, point: number, id: number }>,
 *  localNode: import('../services/edgeApi').Node,
 *  localPoints: {[key: string]: import('../scenarios/scenarioSlice').Point},
 *  filtered: boolean,
 *  isolated: boolean,
 *  activeNodeId?: string,
 *  editPointId?: string,
 *  activeEdge?: import('../services/edgeApi').Edge,
 *  editNode: boolean,
 *  edgeMode: boolean,
 *  nodeSize: number,
 *  matchingPointId?: string,
 *  modal: boolean,
 *  showContracts: boolean,
 *  showRisePaths: boolean,
 *  mapView: MapView,
 *  mapFit: import('ol/coordinate').Coordinate[],
 * }} PlotterState
 */

// TODO: make localstorage for any slice or variable easy to turn on and off
let localNodeSize;

try {
  localNodeSize = JSON.parse(localStorage.getItem('nodeSize'));
} catch (e) {
  console.warn(e);
}

export const PATH_MODES = {
  ANT: 'ANT',
  SIMPLE: 'SIMPLE',
};

/**
 * @type {PlotterState}
 */
const initialState = {
  appMode: null,
  showCountyPoints: false,
  // This is getting kinda crowded - if we have more stuff to show/hide we should consider a "visibility" object or something
  showFlowArrows: true,
  showEdgeDirections: false,
  showNoticeMarkers: true,
  showConstraints: true,
  pathMode: PATH_MODES.ANT,
  // =====================
  nodes: {},
  points: {},
  edges: {},
  risePaths: [],
  risePathsLoading: false,
  capacities: [],
  markets: [],
  // adding localNode in here to test it out. Not sure of best way to structure state yet
  localNode: {},
  localPoints: {},
  // TODO: add all filter conditions to filtered, instead of creating new things in state each time
  filtered: false,
  isolated: false,
  activeNodeId: null,
  editPointId: null,
  activeEdge: null,
  editNode: false,
  edgeMode: false,
  nodeSize: localNodeSize || 1,
  matchingPointId: null,
  modal: false,
  // showing all contracts by default right now - this should be set on a contract basis and handled in contractSlice
  showContracts: true,
  showRisePaths: false,
  mapView: null,
  mapFit: null,
};

const addMapData = (state, action) => ({
  ...state,
  nodes: action.payload.nodes,
  points: action.payload.points,
  edges: action.payload.edges,
});

/**
 *
 * @param {PlotterState} state
 * @param {{
 *  payload: ChainedRiseTransport[]
 * }} action
 * @returns
 */
const setRisePaths = (state, action) => ({
  ...state,
  risePaths: action.payload,
});

/**
 *
 * @param {PlotterState} state
 * @param {{
 *  payload: boolean
 * }} action
 * @returns
 */
const setRisePathsLoading = (state, action) => ({
  ...state,
  risePathsLoading: action.payload,
});

const toggleFiltered = (state) => ({ ...state, filtered: !state.filtered });

const toggleShowCountyPoints = (state) => ({
  ...state,
  showCountyPoints: !state.showCountyPoints,
});

const setShowFlowArrows = (state, action) => ({
  ...state,
  showFlowArrows: Boolean(action.payload),
});

const setShowEdgeDirections = (state, action) => ({
  ...state,
  showEdgeDirections: Boolean(action.payload),
});

const setShowNoticeMarkers = (state, action) => ({
  ...state,
  showNoticeMarkers: Boolean(action.payload),
});

const setShowConstraints = (state, action) => ({
  ...state,
  showConstraints: Boolean(action.payload),
});

/**
 *
 * @param {PlotterState} state
 * @param {{
 *  payload: boolean
 * }} action
 */
const setShowRisePaths = (state, action) => ({
  ...state,
  showRisePaths: Boolean(action.payload),
});

/**
 *
 * @param {PlotterState} state
 * @param {{ payload: Capacity[] }} action
 */
const setCapacities = (state, action) => ({
  ...state,
  capacities: action.payload,
});

/**
 *
 * @param {PlotterState} state
 * @param {{
 *  payload: Array<{ description: string, price: nubmer, point: number, id: number }>
 * }} action
 * @returns
 */
const setMarkets = (state, action) => ({
  ...state,
  markets: action.payload,
});

const setPathMode = (state, action) => ({
  ...state,
  pathMode: action.payload,
});

/**
 *
 * @param {PlotterState} state
 * @param {{
 *  payload: MapView
 * }} action
 * @returns
 */
const setMapView = (state, action) => ({
  ...state,
  mapView: action.payload,
});

/**
 *
 * @param {PlotterState} state
 * @param {{
 *  payload: import('ol/coordinate').Coordinate[]
 * }} action
 * @returns
 */
const fitMapView = (state, action) => ({
  ...state,
  mapFit: action.payload,
});

const toggleIsolated = (state) => ({ ...state, isolated: !state.isolated });

const setActiveNodeId = (state, action) => {
  // delete state.nodes["new"]
  return {
    ...state,
    activeNodeId: action.payload,
    editNode: false,
    activeEdge: null,
  };
};

const setEditPointId = (state, action) => ({
  ...state,
  editPointId: action.payload,
});

const assignMatchingPoint = (state, _action) => {
  // add point to active node point array (remove from any other nodes?)
  // remove "new" point from node?
  // set point to edit
  // change point.node to activenode id (could be "new")
  const { activeNodeId } = state;
  const activeNode = state.nodes[activeNodeId];
  const { matchingPointId } = state;
  let updatedPoints = activeNode.points.filter(
    (point) => point != state.editPointId,
  );
  updatedPoints = updatedPoints.concat(matchingPointId);
  // remove previously edited point id
  // add matching point id

  return {
    ...state,
    editPointId: matchingPointId,
    nodes: {
      ...state.nodes,
      [activeNodeId]: {
        ...activeNode,
        points: updatedPoints,
      },
    },
  };
};

const assignPointToNode = (state, action) => {
  // add point to active node point array (remove from any other nodes?)
  // remove "new" point from node?
  // set point to edit
  // change point.node to activenode id (could be "new")
  const { node } = action.payload;
  const { point } = action.payload;
  // remove point from node just in case it's already in this node?
  // remove new point from node - do we need to potentially remove other point ids?
  let updatedPoints = node.points.filter(
    (nodePoint) => nodePoint != point.id && nodePoint != 'new',
  );
  // add matching point id
  updatedPoints = updatedPoints.concat(point.id);

  return {
    ...state,
    // editPointId: matchingPointId,
    localNode: {
      ...state.localNode,
      [node.id]: {
        ...node,
        points: updatedPoints,
      },
    },
    localPoints: {
      ...state.localPoints,
      [point.id]: {
        ...point,
        node: node.id,
      },
    },
  };
};

const addNewNode = (state, action) => {
  // console.log(action.payload)
  const lat = String(action.payload.lat);
  const lng = String(action.payload.lng);
  const newNode = {
    id: 'new',
    lat,
    lng,
    points: [],
    ends_edges: [],
    starts_edges: [],
  };
  return { ...state, localNode: newNode };
};

const removeNewNode = (state) =>
  // immer handles this so don't need to return state
  // delete state.localNode["new"]
  ({ ...state, localNode: {} }); // delete state.localPoints["new"]

// removeLocalData: (state, action) => {
//   // immer handles this so don't need to return state
//   if action.payload.type === 'node'
// probably want to be able to send an array of things to remove
//   delete state.localNode["new"]
//   delete state.localPoints["new"]
// },

const setEditNode = (state, action) =>
  // need to set edit flag to true
  ({ ...state, editNode: action.payload });

const addEdges = (state, action) => ({
  ...state,
  edges: action.payload,
  activeEdge: { start: state.activeEdge?.end },
});

const setActiveEdge = (state, action) => {
  return { ...state, activeEdge: action.payload, activeNodeId: null };
};

/**
 *
 * @param {PlotterState} state
 * @param {void} _action
 * @returns
 */
const toggleContractsVisibility = (state, _action) => ({
  ...state,
  showContracts: !state.showContracts,
});

const setContractVisibility = (state, action) => ({
  ...state,
  showContracts: Boolean(action.payload),
});

const setStartNode = (state, action) => {
  // whenever setting first node, second node should be null, whether switching to new edge, or starting an edge to begin with
  // return {...state, firstNode: action.payload, secondNode: null}
  return {
    ...state,
    activeEdge: {
      ...state.activeEdge,
      start_node: action.payload,
    },
  };
};

/**
 *
 * @param {PlotterState} state
 * @param {{
 *  payload: {
 *    visible: boolean,
 *    chainId: number
 *  }
 * }} action
 */
const setRiseChainVisibility = (state, action) => {
  return {
    ...state,
    risePaths: state.risePaths.map((path) =>
      path.chain_id === action.payload.chainId
        ? { ...path, visible: action.payload.visible }
        : path,
    ),
  };
};

/**
 *
 * @param {PlotterState} state
 * @param {{
 *  payload: {
 *    visible: boolean,
 *  }
 * }} action
 */
const setRiseFlowsVisibility = (state, action) => {
  return {
    ...state,
    risePaths: state.risePaths.map((path) => ({
      ...path,
      visible: action.payload.visible,
    })),
  };
};

const setEndNode = (state, action) => {
  // return {...state, secondNode: action.payload}
  return {
    ...state,
    activeEdge: {
      ...state.activeEdge,
      end_node: action.payload,
    },
  };
};

const setMatchingPointId = (state, action) => {
  return { ...state, matchingPointId: action.payload };
};

const updateActiveNode = (state, action) => {
  return {
    ...state,
    nodes: {
      ...state.nodes,
      [state.activeNodeId]: {
        ...state.nodes[state.activeNodeId],
        // this can probably be more general
        lat: action.payload.lat,
        lng: action.payload.lng,
      },
    },
  };
};

const setNodeSize = (state, action) => {
  localStorage.setItem('nodeSize', JSON.stringify(action.payload));
  return { ...state, nodeSize: action.payload };
};

// starting to name actions after events instead of setters - we'll see if it makes things more reusable
// this still just seems like a setter so maybe i'm not grasping the best way to do it.
const appModeChanged = (state, action) => ({
  ...state,
  appMode: action.payload.appMode,
});
const resetStore = (_state) => {
  // From here we can take action only at this "plotter" state
  // But, as we have taken care of this particular "reset" action
  // in rootReducer, we can use it to CLEAR the complete Redux Store's state
};

const reducers = {
  addMapData,
  setRisePaths,
  setRisePathsLoading,
  setCapacities,
  setMarkets,
  toggleFiltered,
  toggleShowCountyPoints,
  setShowFlowArrows,
  setShowEdgeDirections,
  setShowNoticeMarkers,
  setShowConstraints,
  setShowRisePaths,
  setEditPointId,
  setPathMode,
  setMapView,
  fitMapView,
  toggleIsolated,
  setActiveNodeId,
  assignMatchingPoint,
  assignPointToNode,
  addNewNode,
  removeNewNode,
  setEditNode,
  addEdges,
  setActiveEdge,
  setRiseChainVisibility,
  setRiseFlowsVisibility,
  toggleContractsVisibility,
  setContractVisibility,
  setStartNode,
  setEndNode,
  setMatchingPointId,
  updateActiveNode,
  setNodeSize,
  appModeChanged,
  resetStore,
};

export const plotterSlice = createSlice({
  name: 'plotter',
  initialState,
  reducers,
});

export const fetchMapData = () => async (_dispatch) => {
  // let res = await axios.get(`${process.env.REACT_APP_API_URL}/api/map/`)
  //   // catch the error but this is really being handled in the interceptor - decide on best way to error handle
  //   .catch((error)=>{console.log("ERROR DETAIL: " + error.response.data.detail)});
  // if(res){
  //   dispatch(addMapData(res.data))
  //   // should this be a bunch of dispatches or just make one action that sets things back to "default"
  //   dispatch(setEditPointId(null))
  //   dispatch(setEditNode(false))
  // }
  // console.log("fetchMapData turned off")
  edgeApi.util.invalidateTags([
    { type: 'Point', id: 'LIST' },
    { type: 'Node', id: 'LIST' },
    { type: 'Edge', id: 'LIST' },
  ]);
};

export const createEdge = (edge) => (dispatch, _getState) => {
  // is it better to send the edge as a parameter or just get it from active edge state here?

  axios
    .post(`${process.env.REACT_APP_API_URL}/api/edges/`, edge)
    // .then((res) => dispatch(fetchMapData()))
    .then(() => dispatch(fetchEdges()));
};

export const createUpdateNode = () => (dispatch, getState) => {
  const { plotter } = getState();
  const activeNode = plotter.nodes[plotter.activeNodeId];
  if (activeNode.id == 'new' || !activeNode.id) {
    // if there's not an id, create it, otherwise update it
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/nodes/`, activeNode)
      .then((res) => dispatch(setActiveNodeId(res.data)))
      .then(() => dispatch(fetchMapData()));
  } else {
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/nodes/${activeNode.id}/`,
        activeNode,
      )
      .then(() => dispatch(fetchMapData()));
  }
};

export const createUpdatePoint = (point) => async (dispatch, getState) => {
  // const { plotter } = getState();
  const nodeId = point.node;

  // if node has no id or 'new' id, create node first

  // 'new' ID is convenient but only allows one new node at a time.  We could make this more dynamic but we probably don't need multiple new nodes anyway
  if (nodeId === 'new' || !nodeId) {
    // get new node data to create node in database
    const allNodes = plotterSelectors.selectAllNodes(getState());
    const nodeData = allNodes[nodeId];
    const res = await axios
      .post(`${process.env.REACT_APP_API_URL}/api/nodes/`, nodeData)
      .catch((error) => console.error(error));

    // dispatch(setActiveNodeId(res.data.id))
    // set node ID attribute on the point
    point.node = res.data.id;
  }

  if (point.id == 'new' || !point.id) {
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/points/`, point)
      .catch((error) => console.error(error));
  } else {
    await axios
      .put(`${process.env.REACT_APP_API_URL}/api/points/${point.id}/`, point)
      .catch((error) => console.error(error));
  }

  // should we really get all map data every time?  Starting to get slow.  Could just update points and nodes individually.  One issue right now is new node is being created before point and doesn't have the point included in node.points
  await dispatch(fetchMapData());
  // replace any 'new' id in selectedItems of type: 'node' with the id
  // if success, remove localdata 'new' node
  dispatch(removeNewNode());
  dispatch(
    selectedItemsActions.itemClicked({ itemType: 'node', id: point.node }),
  );
};

export const deleteNode = (nodeId) => (dispatch) => {
  axios
    .delete(`${process.env.REACT_APP_API_URL}/api/nodes/${nodeId}/`)
    // this also needs to fetch edges because a node delete could delete associated edges
    .then(() => dispatch(fetchMapData()))
    .then(() => dispatch(setActiveNodeId(null)));
};

export const fetchEdges = () => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/edges/`)
    // .then((res) => console.log(res))
    .then((res) => dispatch(addEdges(res.data)));
};

export const fetchEdge = (edgeId) => (dispatch) => {
  axios
    .get(`${process.env.REACT_APP_API_URL}/api/edges/${edgeId}/`)

    .then((res) => dispatch(setActiveEdge(res.data)));
};

export const deleteEdge = (edgeId) => (dispatch) => {
  axios
    .delete(`${process.env.REACT_APP_API_URL}/api/edges/${edgeId}/`)
    .then(() => dispatch(fetchEdges()));
};

export const checkPointMatch = (pointAttribute) => (dispatch, getState) => {
  // ideally this would be set up to reuse the existing search modal functionality but I don't want to waste much time setting it up when this works
  // this should probably eventually be an API call to search for matching nodes but for now they will just all be in memory so we can do it here
  let matchingPoint;
  if (!pointAttribute) {
    matchingPoint = null;
  } else {
    // let points = getState().plotter.points
    // should this be all points or api points
    const points = plotterSelectors.selectAllPoints(getState());
    // expanding this to match loc_name as well - right now it will only match the most recently changed attribute, not a combination
    // find will only return one perfect match, this probably needs to be a filter
    matchingPoint = Object.keys(points).find(
      (key) =>
        points[key].loc_id === pointAttribute ||
        points[key.loc_name] === pointAttribute,
    );
    // return Object.keys(points).filter((key, index) => points[key].node == null).length
    if (matchingPoint) {
      dispatch(setMatchingPointId(matchingPoint));
    } else {
      dispatch(setMatchingPointId(null));
    }
  }
};

// eslint-disable-next-line react/display-name
export const markerClicked = (nodeId) => (dispatch, getState) => {
  // moved this here instead of in the event handler of the marker itself because the conditional event handler was probably causing excessive re-rendering
  const { appMode } = getState().plotter;
  const startNode = selectStartNode(getState());

  dispatch(setActiveNodeId(nodeId));

  // i'd rather have all this conditional logic for responding to clickHandlers depending on the appModes in pathsSlice but not sure how to do it cleanly
  // or have it in component state of applicable component but since we are having inputs from map and from sidebar, global state seems like the way to go

  switch (appMode) {
    case 'edgeBuilder':
      if (!startNode) {
        dispatch(setStartNode(nodeId));
      } else {
        dispatch(setEndNode(nodeId));
      }
      break;
    case 'allPaths':
      // should this send it over to pathsSlice, or keep it in this slice, or make a new slice for all mode stuff
      // dispatch(pathsActions.simplePathsClicked(nodeId));
      dispatch(pathsActions.allPathsClick(nodeId));
      break;
    default:
      // maybe set active node goes here instead of before this?
      // dispatch(setActiveNodeId(nodeId))
      return null;
  }
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.plotter.value)`

export const selectRemainingPointCount = createSelector(
  plotterSelectors.selectAllPoints,
  (points) =>
    Object.keys(points).filter(
      (key) =>
        points[key].node == null &&
        points[key].id != 'new' &&
        points[key].inact_date == '',
    ).length,
);

// export const selectNoEdgeNodeCount = createSelector(
//   selectAllPoints,
//   (points) => {
//     return Object.keys(points).filter((key, index) => (points[key].node == null && points[key].id != 'new' && points[key].inact_date == '')).length
//   }
// )
/**
 *
 * @param {*} state
 * @returns {import('../services/edgeApi').Node | null}
 */
export const selectActiveNode = (state) =>
  state.plotter.nodes[state.plotter.activeNodeId];

export const selectSidebarKey = createSelector(
  (state) => state.plotter.activeNode,
  (activeNode) => (activeNode ? activeNode.lat : null),
);

// export const selectStartNode = createSelector(
//   (state) => state.plotter.activeEdge,
//   (state) => state.plotter.nodes,
//   (activeEdge, nodes) => (activeEdge?.start ? nodes.find((node)=>node.id == activeEdge.start) : null)
// )

export const selectStartNode = (state) =>
  state.plotter.activeEdge?.start_node
    ? state.plotter.nodes[state.plotter.activeEdge?.start_node]
    : null;

export const selectEndNode = (state) =>
  state.plotter.activeEdge?.end_node
    ? state.plotter.nodes[state.plotter.activeEdge?.end_node]
    : null;

// export const selectEndNode = createSelector(
//   (state) => state.plotter.activeEdge,
//   (state) => state.plotter.nodes,
//   (activeEdge, nodes) => (activeEdge?.end ? nodes.find((node)=>node.id == activeEdge.end) : null)
// )

export const selectEdgeShape = createSelector(
  (state) => state.plotter.activeEdge,
  selectStartNode,
  selectEndNode,
  (activeEdge, startNode, endNode) => {
    // this can all be set up with more complexity if/when we add and edit multiple points per edge.  Still needs to start and end at the nodes but we can put other points between.
    if (activeEdge?.positions) {
      return activeEdge.positions;
    }
    if (startNode && endNode) {
      return [
        [startNode.lat, startNode.lng],
        [endNode.lat, endNode.lng],
      ];
    }
    return null;
  },
);

/**
 *
 * @param {{plotter: PlotterState}} state
 * @returns
 */
export const selectRisePaths = (state) => state.plotter.risePaths ?? [];

/**
 *
 * @param {{plotter: PlotterState}} state
 * @returns
 */
export const selectRisePathsLoading = (state) => state.plotter.risePathsLoading;

export const selectAllRisePaths = createSelector(
  selectRisePaths,
  (transports) => transports,
);

export const selectTraceableRisePaths = createSelector(
  selectRisePaths,
  (transports) => transports.filter((transport) => transport.path?.length > 0),
);

export const selectRisePathsChains = createSelector(
  selectTraceableRisePaths,
  (filteredTransports) => {
    /**
     *
     * @param {{[key: string]: RiseTransportChain}} acc
     * @param {ChainedRiseTransport} transport
     * @returns {{[key: string]: RiseTransportChain}}
     */
    const reducerFunction = (acc, transport) => {
      if (!acc[transport.chain_id]) {
        acc[transport.chain_id] = {
          chain_id: transport.chain_id,
          transports: [],
          interconnectTransports: [],
        };
      } else {
        // add interconnect path
        // TODO: check if there is an interconnect between the two transports before adding
        /** @type {import('../plotter/plotterSlice').ChainedRiseTransport} */
        const prevTransport =
          acc[transport.chain_id].transports[
            acc[transport.chain_id].transports.length - 1
          ];
        const interconnectTransport = {
          ...prevTransport,
          id: `${prevTransport.id}INT${transport.id}`,
          receipt_point_id: prevTransport.delivery_point_id,
          delivery_point_id: transport.receipt_point_id,
          path: [
            prevTransport.path[prevTransport.path.length - 1],
            transport.path[0],
          ],
        };
        acc[transport.chain_id].interconnectTransports.push(
          interconnectTransport,
        );
      }

      acc[transport.chain_id].transports.push(transport);
      return acc;
    };

    return Object.values(filteredTransports.reduce(reducerFunction, {}));
  },
);

export const selectPlotterCapacities = createSelector(
  /**
   *
   * @param {{plotter: PlotterState}} state
   * @returns
   */
  (state) => state?.plotter?.capacities,
  (capacities) => capacities ?? [],
);

export const selectPlotterMarkets = createSelector(
  /**
   *
   * @param {{plotter: PlotterState}} state
   * @returns
   */
  (state) => state.plotter.markets,
  (markets) => markets ?? [],
);

export const plotterActions = plotterSlice.actions;

export default plotterSlice.reducer;
