import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { plotterActions, PATH_MODES } from '../plotter/plotterSlice';
import { useLocalStorage } from '../helpers/useLocalStorage';
import { useLazyGetRiseTransportPathOnDateQuery } from '../services/edgeApi';
import { toIsoDate } from '../utils/stringUtils';
import toast from 'react-hot-toast';

export const PlotterSettings = () => {
  const dispatch = useDispatch();
  const [riseTransportsTrigger] = useLazyGetRiseTransportPathOnDateQuery();

  /** @type {import('react').MutableRefObject<number>} */
  const timeoutRef = useRef(null);

  const _showFlowArrows = useSelector((state) =>
    Boolean(state.plotter.showFlowArrows),
  );
  const [showFlowArrows, setShowFlowArrows] = useLocalStorage(
    'showFlowArrows',
    _showFlowArrows,
  );

  const _showEdgeDirections = useSelector((state) =>
    Boolean(state.plotter.showEdgeDirections),
  );
  const [showEdgeDirections, setShowEdgeDirections] = useLocalStorage(
    'showEdgeDirections',
    _showEdgeDirections,
  );

  const _showNoticeMarkers = useSelector((state) =>
    Boolean(state.plotter.showNoticeMarkers),
  );
  const [showNoticeMarkers, setShowNoticeMarkers] = useLocalStorage(
    'showNoticeMarkers',
    _showNoticeMarkers,
  );

  const _showConstraints = useSelector((state) =>
    Boolean(state.plotter.showConstraints),
  );
  const [showConstraints, setShowConstraints] = useLocalStorage(
    'showConstraints',
    _showConstraints,
  );

  const _pathMode = useSelector((state) => String(state.plotter.pathMode));
  const nodeSize = useSelector((state) => state.plotter.nodeSize);
  const [pathMode, setPathMode] = useLocalStorage('showPaths', _pathMode);
  const [riseFlowDate, setRiseFlowDate] = useState(toIsoDate(new Date()));
  const [showRiseFlows, setShowRiseFlows] = useLocalStorage(
    'showRiseFlows',
    false,
  );

  useEffect(() => {
    dispatch(plotterActions.setShowFlowArrows(showFlowArrows));
    dispatch(plotterActions.setShowEdgeDirections(showEdgeDirections));
    dispatch(plotterActions.setShowNoticeMarkers(showNoticeMarkers));
    dispatch(plotterActions.setShowConstraints(showConstraints));
    dispatch(plotterActions.setPathMode(pathMode));
    dispatch(plotterActions.setShowRisePaths(showRiseFlows));
  }, []);

  const toggleShowFlowArrows = () => {
    dispatch(plotterActions.setShowFlowArrows(!showFlowArrows));
    setShowFlowArrows(!showFlowArrows);
  };

  const toggleShowEdgeDirections = () => {
    dispatch(plotterActions.setShowEdgeDirections(!showEdgeDirections));
    setShowEdgeDirections(!showEdgeDirections);
  };

  const toggleShowNoticeMarkers = () => {
    dispatch(plotterActions.setShowNoticeMarkers(!showNoticeMarkers));
    setShowNoticeMarkers(!showNoticeMarkers);
  };

  const toggleShowConstraints = () => {
    dispatch(plotterActions.setShowConstraints(!showConstraints));
    setShowConstraints(!showConstraints);
  };

  const onPathModeChange = (pathMode) => {
    dispatch(plotterActions.setPathMode(pathMode));
    setPathMode(pathMode);
  };

  const onShowRiseFlowsChange = (showRiseFlows) => {
    dispatch(plotterActions.setShowRisePaths(showRiseFlows));
    setShowRiseFlows(showRiseFlows);
  };

  useEffect(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(async () => {
      dispatch(plotterActions.setRisePathsLoading(true));
      /** @type {import('../services/edgeApi').RiseTransportPathResponse} */
      const response = await riseTransportsTrigger({
        gasDate: toIsoDate(new Date(riseFlowDate)),
      });
      dispatch(plotterActions.setRisePathsLoading(false));
      if (response.error) {
        toast.error('Failed to fetch rise paths');
        dispatch(plotterActions.setRisePaths([]));
        return;
      }
      const transports = response.data;
      dispatch(
        plotterActions.setRisePaths(
          transports.map((t) => {
            return { ...t, visible: true };
          }),
        ),
      );
    }, 1500);
  }, [riseFlowDate]);

  return (
    <div className="row mx-0 w-100">
      <h6 className="text-center">View Settings</h6>
      <div className="col-12">
        <label>Point Size</label>
        <input
          onChange={(evt) =>
            dispatch(plotterActions.setNodeSize(evt.target.value))
          }
          type="range"
          className="form-range"
          value={nodeSize}
          min="0"
          max="14"
          step={2}
          id="customRange2"
        />
      </div>
      <div className="mt-3 mb-1 col-6">
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id="toggle-flow-arrows"
            checked={showFlowArrows}
            onChange={toggleShowFlowArrows}
            role="button"
          />
          <label
            className="form-check-label"
            htmlFor="toggle-flow-arrows"
            role="button"
          >
            Flow Arrows
          </label>
        </div>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id="toggle-segments-directions"
            checked={showEdgeDirections}
            onChange={toggleShowEdgeDirections}
            role="button"
          />
          <label
            className="form-check-label"
            htmlFor="toggle-segments-directions"
            role="button"
          >
            Segment Directions
          </label>
        </div>
      </div>
      <div className="mt-3 mb-1 col-6">
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id="toggle-notice-markers"
            checked={showNoticeMarkers}
            onChange={toggleShowNoticeMarkers}
            role="button"
          />
          <label
            className="form-check-label"
            htmlFor="toggle-notice-markers"
            role="button"
          >
            Notice Markers
          </label>
        </div>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id="toggle-constraints"
            checked={showConstraints}
            onChange={toggleShowConstraints}
            role="button"
          />
          <label
            className="form-check-label"
            htmlFor="toggle-constraints"
            role="button"
          >
            Constraints
          </label>
        </div>
      </div>
      <div className="mt-1 mb-3 col-12">
        <div className="input-group">
          <label className="input-group-text" htmlFor="path-mode-select">
            Path Mode
          </label>
          <select
            className="form-select"
            id="path-mode-select"
            value={pathMode}
            onChange={(e) => onPathModeChange(e.target.value)}
          >
            <option value={PATH_MODES.ANT}>Ant Paths</option>
            <option value={PATH_MODES.SIMPLE}>Simple Paths</option>
          </select>
        </div>
      </div>
      <div className="mt-1 mb-3 col-12">
        <div className="input-group">
          <label
            className="input-group-text"
            htmlFor="rise-flow-date"
            onClick={() => onShowRiseFlowsChange(!showRiseFlows)}
          >
            Show Rise Flows
            <input
              checked={showRiseFlows}
              className="form-check-input ms-3"
              type="checkbox"
              onChange={() => null}
            />
          </label>
          <input
            className="form-control"
            id="rise-flow-date"
            type="date"
            value={riseFlowDate}
            onChange={(e) => setRiseFlowDate(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};
