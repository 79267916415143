import { NavLink } from 'react-router-dom';
import { useGetContractsQuery } from '../services/edgeApi';

export function Contracts() {
  const { data: contracts, error, isLoading } = useGetContractsQuery();
  if (isLoading) {
    return <>Loading Contracts</>;
  }

  if (error) {
    return (
      <div className="danger">Error Loading Contracts, Please Refresh</div>
    );
  }

  return (
    <ul className="list-group">
      {/* <li className="list-group-item">
				<NavLink className="btn btn-success w-100" to={`/scenarios/new`}>New Scenario</NavLink>
			</li> */}
      <li className="list-group-item">
        <NavLink className="btn text-primary" to="/contracts/new">
          Add New Contract
        </NavLink>
      </li>
      {Object.keys(contracts).map((contractId) => {
        const contract = contracts[contractId];
        return (
          <li className="list-group-item" key={contract.id}>
            <NavLink className="btn" to={`/contracts/${contract.id}`}>
              <b>#{contract.id}</b> {contract.name} {contract.tsp_name}
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
}
