import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import PivotTableUI from 'react-pivottable/PivotTableUI';
import { aggregators } from 'react-pivottable/Utilities';
import 'react-pivottable/pivottable.css';
import TableRenderers from 'react-pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from 'react-pivottable/PlotlyRenderers';
import { PivotData } from 'react-pivottable/Utilities';

const PlotlyRenderers = createPlotlyRenderers(Plot);

/**
 * @typedef {Array<Object<string, any>>} PivotDataGroup
 */

/**
 * @typedef {{
 *  getUiState: () => import('react-pivottable').PivotTableUIProps,
 *  getFilteredRecords: () => Object[],
 * }} PivotTableRef
 */

/**
 * @typedef {{
 *  data: PivotDataGroup,
 *  uiProps?: import('react-pivottable').PivotTableUIProps
 *  onChangeView?: (state: import('react-pivottable').PivotTableUIProps) => void,
 *  attributteFilter?: string,
 * }} PivotTableProps
 */

/**
 * @param {PivotTableProps} props
 * @param {React.ForwardedRef<PivotTableRef>} ref
 * @returns {React.FC<PivotTableProps>}
 */
const _PivotTable = (
  { data, uiProps = {}, onChangeView, attributteFilter = null },
  ref,
) => {
  const [tableState, setTableState] = useState(uiProps);
  const [hiddenFromDragDrop, setHiddenFromDragDrop] = useState(
    uiProps.hiddenAttributes ?? [],
  );

  useEffect(() => {
    setTableState(uiProps);
  }, [uiProps]);

  useImperativeHandle(
    ref,
    () => ({
      getUiState: () => tableState,
      getFilteredRecords: () => {
        const filtered = [];
        if (tableState?.data) {
          const filters = Object.keys(tableState.valueFilter).reduce(
            (agg, filter) => {
              const propertyFilter = tableState.valueFilter[filter];
              agg[filter] = Object.keys(propertyFilter).filter(
                (key) => propertyFilter[key],
              );
              return agg;
            },
            {},
          );
          PivotData.forEachRecord(
            tableState.data,
            tableState.derivedAttributes,
            (record) => {
              const match = Object.keys(filters).every((key) => {
                // We are using !includes because PivotTable for some reason
                // makes its filters in a way that the items included in the
                // filter are the ones that are not included in the result
                return record[key] && !filters[key].includes(record[key]);
              });
              if (match) {
                filtered.push(record);
              }
            },
          );
        }
        return filtered;
      },
    }),
    [tableState],
  );

  const agg = {
    ...aggregators,
    // Subtract: () => (data, rowKey, colKey) => {
    //   let groups = {};
    //   let total = 0;
    //   return {
    //     push: (record) => {
    //       groups[colKey][rowKey] =
    //         (groups[colKey]?.[rowKey] ?? 0) + record[colKey];
    //     },
    //     value: () => total,
    //     format: (x) => x.toString(),
    //     numInputs: 1,
    //   };
    // },
  };

  const allAttributes = useMemo(() => {
    /** @type {(agg: string[], record: Object<string, any>) => string[]} */
    const extractor = (agg, record) => {
      Object.keys(record).forEach((key) => {
        if (!agg.includes(key)) {
          agg.push(key);
        }
      });
      return agg;
    };

    /** @type {string[]} */
    const attrs = data.reduce(extractor, []);
    return attrs;
  }, [data]);

  useEffect(() => {
    setHiddenFromDragDrop(
      allAttributes.filter((attr) => {
        if (uiProps.hiddenFromDragDrop?.includes(attr)) {
          return true;
        }
        if (
          tableState.rows?.includes(attr) ||
          tableState.cols?.includes(attr)
        ) {
          return false;
        }
        if (attributteFilter) {
          return !attr
            .toLocaleLowerCase()
            .replace(/ /g, '')
            .includes(attributteFilter.toLocaleLowerCase().replace(/ /g, ''));
        }
        return false;
      }),
    );
  }, [attributteFilter, allAttributes, uiProps.hiddenFromDragDrop, tableState]);

  return (
    <PivotTableUI
      data={data}
      onChange={(s) => {
        setTableState(s);
        onChangeView?.(s);
      }}
      aggregators={agg}
      renderers={Object.assign({}, TableRenderers, PlotlyRenderers)}
      {...tableState}
      hiddenFromDragDrop={hiddenFromDragDrop}
    />
  );
};

export const PivotTable = forwardRef(_PivotTable);
