import React, { useState, useEffect, useMemo, memo } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@iconicicons/react';

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFacetedMinMaxValues,
  sortingFns,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';

import { rankItem, compareItems } from '@tanstack/match-sorter-utils';
import { DebouncedInput } from '../helpers/DebounceInput';
import { deepEqual } from '../utils/objectUtils';

export const renderDateTimeCell = (info) => {
  const value = info.getValue();
  if (value === null || value === undefined) {
    return <div className="fw-light text-center">--</div>;
  } else {
    const date = new Date(value);
    return <div>{date.toLocaleString()}</div>;
  }
};

export const renderDateCell = (info) => {
  const value = info.getValue();
  if (value === null || value === undefined) {
    return <div className="fw-light text-center">--</div>;
  } else {
    const date = new Date(value);
    return (
      <div>
        {date.getMonth() + 1}/{date.getDate()}/{date.getFullYear()}
      </div>
    );
  }
};

export const renderOptionalTextCell = (info) => {
  const value = info.getValue();
  if (value === null || value === undefined || value === '') {
    return <div className="fw-light text-center">--</div>;
  }
  if (typeof value === 'object') {
    console.warn('renderOptionalTextCell: value is an object', value);
    return <div className="fw-light text-center">--</div>;
  }
  return <div className="fw-light text-center">{value}</div>;
};

/**
 * @template {{id: string | number}} T
 */

/**
 * @typedef {{
 *  columns: ColumnDef[];
 *  data: T[];
 *  onFilterChange: (data: unknown[]) => void;
 *  defaultSort: string;
 *  tableId?: string;
 *  newIncomingData?: {id: string | number}[];
 * }} FilterTableProps
 * @param {FilterTableProps} props
 * @returns {React.FC<FilterTableProps>}
 */
const _FilterTable = ({
  data,
  columns,
  onFilterChange,
  defaultSort,
  newIncomingData = [], //used to determine how many data is newly inserted
  tableId,
}) => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    initialState: {
      sorting: defaultSort ? [{ id: defaultSort, desc: true }] : [],
    },
    state: {
      columnFilters,
      globalFilter,
    },
    onColumnFiltersChange: setColumnFilters,
    // shouldAutoRemoveFilter: false,
    onGlobalFilterChange: setGlobalFilter,
    globalFilterFn: fuzzyFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    // getPaginationRowModel: getPaginationRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    debugTable: false,
    debugHeaders: false,
    debugColumns: false,
  });

  const fuzzyFilter = (row, columnId, value, addMeta) => {
    // Rank the item
    const itemRank = rankItem(row.getValue(columnId), value);

    // Store the itemRank info
    addMeta({
      itemRank,
    });

    // Return if the item should be filtered in/out
    return itemRank.passed;
  };

  // eslint-disable-next-line no-unused-vars
  const fuzzySort = (rowA, rowB, columnId) => {
    let dir = 0;

    // Only sort by rank if the column has ranking information
    if (rowA.columnFiltersMeta[columnId]) {
      dir = compareItems(
        rowA.columnFiltersMeta[columnId]?.itemRank,
        rowB.columnFiltersMeta[columnId]?.itemRank,
      );
    }

    // Provide an alphanumeric fallback for when the item ranks are equal
    return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
  };

  useEffect(() => {
    // to expose filtered data
    const filteredData = table
      .getFilteredRowModel()
      .rows.map((row) => row.original);

    if (onFilterChange) {
      onFilterChange(filteredData);
    }
  }, [table.getFilteredRowModel()]);

  const highlightStyles = useMemo(() => {
    return table.getRowModel().rows.reduce((acc, row) => {
      if (newIncomingData.some((item) => item.id === row.original.id)) {
        acc[row.original.id] = { backgroundColor: 'lightgoldenrodyellow' };
      }
      return acc;
    }, {});
  }, [newIncomingData.map((d) => d.id).join('-')]);

  return (
    <table className="table table-responsive table-bordered" id={tableId}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id} colSpan={header.colSpan}>
                {header.isPlaceholder ? null : (
                  <>
                    <div
                      onClick={header.column.getToggleSortingHandler()}
                      className={
                        header.column.getCanSort()
                          ? 'cursor-pointer select-none d-flex'
                          : 'd-flex'
                      }
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                      {{
                        asc: <ChevronUpIcon />,
                        desc: <ChevronDownIcon />,
                      }[header.column.getIsSorted()] ?? null}
                    </div>
                    {header.column.getCanFilter() ? (
                      <div>
                        <Filter column={header.column} table={table} />
                      </div>
                    ) : null}
                  </>
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody id={`${tableId}-body`}>
        {table.getRowModel().rows.map((row) => {
          return (
            <tr
              key={row.id}
              id={'filter-table-row-' + row.original.id}
              style={highlightStyles[row.original.id]}
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  style={{ overflow: 'hidden', overflowWrap: 'break-word' }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        {table.getFooterGroups().map((footerGroup) => (
          <tr key={footerGroup.id}>
            {footerGroup.headers.map((header) => (
              <th key={header.id}>
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.footer,
                      header.getContext(),
                    )}
              </th>
            ))}
          </tr>
        ))}
      </tfoot>
    </table>
  );
};

export const FilterTable = memo(_FilterTable, (prevProps, nextProps) => {
  return (
    deepEqual(prevProps.data, nextProps.data) &&
    deepEqual(prevProps.newIncomingData, nextProps.newIncomingData) &&
    deepEqual(prevProps.columns, nextProps.columns)
  );
});

const Filter = ({ column, table }) => {
  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  const sortedUniqueValues = useMemo(
    () =>
      typeof firstValue === 'number'
        ? []
        : Array.from(column.getFacetedUniqueValues().keys()).sort(),
    [column.getFacetedUniqueValues()],
  );

  return typeof firstValue === 'number' ? (
    <div>
      <div className="flex space-x-2">
        <DebouncedInput
          type="number"
          key="min"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={columnFilterValue?.[0] ?? ''}
          onChange={(value) =>
            column.setFilterValue((old) => [value, old?.[1]])
          }
          placeholder={`Min ${
            column.getFacetedMinMaxValues()?.[0]
              ? `(${column.getFacetedMinMaxValues()?.[0]})`
              : ''
          }`}
          className="w-24 form-control form-control-sm border rounded"
        />
        <DebouncedInput
          type="number"
          key="max"
          min={Number(column.getFacetedMinMaxValues()?.[0] ?? '')}
          max={Number(column.getFacetedMinMaxValues()?.[1] ?? '')}
          value={columnFilterValue?.[1] ?? ''}
          onChange={(value) =>
            column.setFilterValue((old) => [old?.[0], value])
          }
          placeholder={`Max ${
            column.getFacetedMinMaxValues()?.[1]
              ? `(${column.getFacetedMinMaxValues()?.[1]})`
              : ''
          }`}
          className="w-24 form-control form-control-sm border rounded"
        />
      </div>
      <div className="h-1" />
    </div>
  ) : (
    <>
      <datalist id={`${column.id}list`}>
        {sortedUniqueValues.slice(0, 5000).map((value) => (
          <option value={value} key={`${column.id}-list-${value}`} />
        ))}
      </datalist>
      <DebouncedInput
        type="text"
        value={columnFilterValue ?? ''}
        onChange={(value) => column.setFilterValue(value)}
        placeholder={`Search... (${column.getFacetedUniqueValues().size})`}
        className="w-24 form-control form-control-sm border rounded"
        list={`${column.id}list`}
      />
      <div className="h-1" />
    </>
  );
};
