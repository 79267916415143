import { NavLink } from 'react-router-dom';
import { BelowMapSetter } from '../layouts/BelowMapContext';
import { BelowMapTab } from '../layouts/BelowMapTab';
import { ScenarioList } from './ScenarioList';
import { useGetScenarioGroupsQuery } from '../services/edgeApi';
import { useMemo, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@iconicicons/react';

export function Scenarios() {
  const { data } = useGetScenarioGroupsQuery();
  const [openGroups, setOpenGroups] = useState({});

  const groupsList = useMemo(
    () =>
      data?.flatMap((group) => [
        <tr key={group.id}>
          <td>
            <NavLink to={`/scenarios/groups/${group.id}`}>{group.name}</NavLink>
          </td>
          <td>{group.description}</td>
          <td
            className={openGroups[group.id] ? 'table-info' : ''}
            role="button"
            onClick={() =>
              setOpenGroups((prev) => ({
                ...prev,
                [group.id]: !prev[group.id],
              }))
            }
          >
            {group.scenarios.length} scenarios{' '}
            <span>
              {openGroups[group.id] ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </span>
          </td>
        </tr>,

        ...[
          openGroups[group.id] ? (
            <tr key={`${group.id}#children`}>
              <td colSpan={3}>
                <table className="table table-info">
                  <thead>
                    <tr>
                      <th>Scenario</th>
                      <th>Flow Date</th>
                      <th>Created By</th>
                    </tr>
                  </thead>
                  <tbody>
                    {group.scenarios.map((scenario) => (
                      <tr key={`${group.id}#${scenario.id}`}>
                        <td>
                          <NavLink
                            to={`/scenarios/groups/${group.id}/scenarios/${scenario.id}`}
                          >
                            {scenario.name}
                          </NavLink>
                        </td>
                        <td>
                          {new Date(scenario.flow_date).toLocaleDateString()}
                        </td>
                        <td>{scenario.creator ?? '--'}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
            </tr>
          ) : (
            []
          ),
        ],
      ]),
    [data, openGroups],
  );

  return (
    <>
      <h3>Scenarios</h3>
      {/* TOOD: this and other components should be more reusable - for instance, we should be able to use this to show lists of scenarios wherever we want, without necessarily having a header - maybe Scenarios.js should just grab the scenario list raw, and then have a ScenarioPage.js that adds the header or whatever else. */}
      <div className="d-grid gap-2">
        <NavLink className="btn btn-success btn-block" to="/scenarios/new">
          Create New Scenario
        </NavLink>
        <NavLink
          className="btn btn-success btn-block"
          to="/scenarios/groups/new"
        >
          Create New Scenario Group
        </NavLink>
      </div>
      <BelowMapSetter>
        <BelowMapTab
          key={'below-map-scenarios'}
          contextKey="scenarios"
          tabName="Scenarios"
        >
          <ScenarioList key={'below-map-scenarios-list'} />
        </BelowMapTab>
        <BelowMapTab contextKey="scenario-groups" tabName="Scenario Groups">
          <table className="table">
            <thead>
              <tr>
                <th>Group</th>
                <th>Description</th>
                <th>Scenarios</th>
              </tr>
            </thead>
            <tbody>{groupsList}</tbody>
          </table>
        </BelowMapTab>
      </BelowMapSetter>
    </>
  );
}
