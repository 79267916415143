import React, { useRef, useEffect } from 'react';

export function ScrollIntoView({ children, selectedChildId, delay = 0 }) {
  // pass in the id that needs to be scrolled to
  // children need an id exposed somehow to know which one is the correct one
  // alternatively when the children are passed in, one could be marked as "active" ?

  /**
   * @type {React.MutableRefObject<{ [key: string]: Element }>}
   */
  const childRef = useRef({});

  useEffect(() => {
    setTimeout(() => {
      childRef.current[selectedChildId]?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }, delay);
  }, [selectedChildId]);

  return (
    // might want to check for children first
    <>
      {React.Children.map(children, (child) => (
        <div
          key={child.props.id}
          ref={(el) => (childRef.current[child.props.id] = el)}
        >
          {child}
        </div>
      ))}
    </>

    // TODO: some sort of cloning and ref forwarding will probably work to avoid having to add a wrapper to children:
    // <>
    //   {React.Children.map(children, (child) => {
    //     const ref = el => childRef.current[child.props.id] = el
    //     return React.forwardRef((child, ref) => React.cloneElement(child, {
    //       ref: ref
    //     }))
    //   })}
    // </>
  );
}
