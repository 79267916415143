import React, { useRef, useCallback, useEffect } from 'react';

/**
 * @typedef {{
 *  root: HTMLElement | null,
 *  rootMargin: string,
 *  threshold: number,
 * }} InfiniteScrollObserverOptions
 */

/**
 * @typedef {{
 *  onScrollIn?: () => void,
 *  onScrollOut?: () => void,
 *  options?: InfiniteScrollObserverOptions,
 *  height?: number,
 * }} InfiniteScrollObserverProps
 */

/**
 *
 * @param {InfiniteScrollObserverProps} param
 * @returns {React.FC<InfiniteScrollObserverProps>}
 */
export const InfiniteScrollObserver = ({
  onScrollIn = () => null,
  onScrollOut = () => null,
  options = {},
  height = 1,
}) => {
  const observerRef = useRef(null);
  const { root = null, rootMargin = '0px', threshold = 0 } = options;

  const handleObserver = useCallback(
    (entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        onScrollIn();
      } else {
        onScrollOut();
      }
    },
    [onScrollIn, onScrollOut],
  );

  useEffect(() => {
    const option = {
      root,
      rootMargin,
      threshold,
    };
    const observer = new IntersectionObserver(handleObserver, option);
    if (observerRef.current) {
      observer.observe(observerRef.current);
      return () => observer.disconnect();
    }
  }, [handleObserver]);

  return (
    <div style={{ height, width: 1, display: 'block' }} ref={observerRef} />
  );
};
