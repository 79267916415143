// import whyDidYouRender from "@welldone-software/why-did-you-render";

import React, { useEffect, useMemo, useRef } from 'react';
import toast from 'react-hot-toast';
import {
  Marker,
  Popup,
  CircleMarker,
  TileLayer,
  useMap,
  useMapEvents,
  Tooltip,
} from 'react-leaflet';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
// import Polyline from 'react-leaflet-arrowheads'
import * as plotterSelectors from './plotterSelectors';
// was importing everything separately but it was getting big.  selectors aren't really plotterActions, maybe there's a better name for all this, or import selectors separately?
import { fetchMapData } from './plotterSlice';
import * as selectedItemsActions from '../selectedItems/selectedItemsSlice';
import {
  useGetMapEdgesQuery,
  useGetMapNodesQuery,
  useGetMapPointsQuery,
} from '../services/edgeApi';
import * as overlaysSlice from '../overlays/overlaysSlice';
import './Plotter.css';
import CountyPoints from './CountyPoints';
import { NodePopup } from '../nodes/NodePopup';
import { LeafletNodeTooltip } from '../nodes/NodeTooltip';
import { divIcon, icon } from 'leaflet';
// import { MapEdges } from '../edges/MapEdges';

export function Plotter() {
  const dispatch = useDispatch();
  const plotter = useSelector((state) => state.plotter);
  // const edges = useSelector(plotterSelectors.selectAllEdges);
  // const edges = useSelector(plotterSelectors.selectFilteredEdges);
  // throwaway code to get tsp associated with an edge - should probably be derived in django
  // const nodes = useSelector(plotterSelectors.selectAllNodes);
  // const points = useSelector(plotterSelectors.selectAllPoints);
  const activeOverlays = useSelector(overlaysSlice.selectActiveOverlays);

  useEffect(() => {
    dispatch(fetchMapData());
  }, []);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        //  // dispatch(setStartNode(null))
        //  dispatch(plotterActions.setActiveNodeId(null))
        //  // is this the best way to handle new node removal?
        //  dispatch(plotterActions.removeNewNode())
        //  dispatch(plotterActions.setActiveEdge(null))
        // alert('need to fix escape handler in Plotter.js')
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  const map = useMapEvents({
    click(e) {
      dispatch(
        selectedItemsActions.mapClicked({
          lat: e.latlng.lat,
          lng: e.latlng.lng,
        }),
      );
    },
  });

  const activeOverlaysTiles = activeOverlays.map((overlay) => (
    <TileLayer
      key={overlay.id}
      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
      url={overlay.url}
    />
  ));

  return (
    <div>
      {activeOverlaysTiles}
      {/* <MapEdges /> */}
      <MoveMap />
      {/* {plotter.showCountyPoints && <CountyPoints />} */}
    </div>
  );
}

function MoveMap() {
  // this "subsribes" to changes in selected node ids and moves the map to recently selected points if needed
  const map = useMap();
  const selectedNodeIds = useSelector(
    selectedItemsActions.selectActivelySelectedNodeIds,
  );
  const allNodeData = useSelector(plotterSelectors.selectAllNodes);
  const lastNodeId = selectedNodeIds[selectedNodeIds.length - 1];
  const lastNodeData = allNodeData[lastNodeId];

  useEffect(() => {
    // console.log('lastNodeId', selectedNodeIds)
    if (!lastNodeData) {
      return null;
    }

    map.setView(lastNodeData, map.getZoom(), {
      animate: true,
    });
  }, [lastNodeId]);

  return null;
}

export function Test() {
  return <div />;
}

export function NodeMarker(props) {
  // const map = useMap();
  const dispatch = useDispatch();
  const { node, isSelected, color } = props;
  // const [refReady, setRefReady] = useState(false);
  const points = useSelector(plotterSelectors.selectAllPoints);
  // this changes when new node is added, causing re-render
  // could make a selector for nodepoints by id?
  // or pass node points into the nodemarker to keep it simpler

  // const nodePoints = useSelector(plotterSelectors.selectMapResult).data.points
  const nodeSize = useSelector((state) => state.plotter.nodeSize);
  const compressorNodeSize = nodeSize * 1.5;
  const nodeMarkerRef = useRef();

  const nodePoints = useMemo(() => {
    return node?.points?.map((pointId) => points[pointId]) || [];
  }, [node.points.join(',')]);

  useEffect(() => {
    if (isSelected) {
      nodeMarkerRef.current?.openPopup();
    }
  }, [isSelected]);

  const markerProps = useMemo(
    () => ({
      key: node.id + isSelected,
      data: node,
      className: isSelected ? 'isSelected' : `node_${node.id}`,
      pathOptions: { color, opacity: 0.5 },
      pane: isSelected ? 'selectedNodesPane' : 'nodesPane',
      bubblingMouseEvents: false,
      eventHandlers: {
        click: (e) => {
          e.originalEvent.view.L.DomEvent.preventDefault(e);
          e.originalEvent.view.L.DomEvent.stopPropagation(e);
          const node = e.target.options.data;
          dispatch(selectedItemsActions.markerClicked(node.id));
        },
      },
    }),
    [node, isSelected, color],
  );

  if (!node.lat || !node.lng) {
    return null;
  }

  // hide the unselected nodes entirely if the node size is turned down
  if (nodeSize <= 0 && !isSelected) {
    return null;
  }

  const markerChildren = (
    <>
      {/* the node popup will come up for all points when any point in the node is selected, if we need to we can probalby just make the selected point show */}
      {/* also if we have more than one "isSelected" at one time, will it get crowded?  */}
      <Popup
        keepInView={false}
        closeButton={false}
        autoPan={false}
        minWidth="400"
      >
        <div
          className="bg-light"
          style={{
            maxHeight: '60vh',
            overflowY: 'auto',
            margin: '0px -24px 0px -20px',
            padding: '0px 24px 0px 20px',
          }}
        >
          <NodePopup node={node} />
        </div>
      </Popup>
      <Tooltip>
        <LeafletNodeTooltip nodeId={node.id} />
      </Tooltip>
    </>
  );

  // Display squares for compressor stations
  if (nodePoints.some((point) => point && point.loc_type_ind === 'COM')) {
    const verified = nodePoints.every(
      (point) => point.loc_type_ind !== 'COM' || point.verified,
    );
    return (
      <Marker
        {...markerProps}
        ref={nodeMarkerRef}
        position={node}
        icon={divIcon({
          html: '<div></div>',
          iconAnchor: [compressorNodeSize, compressorNodeSize],
          iconSize: [compressorNodeSize * 2, compressorNodeSize * 2],
          className: `square-map-marker ${
            verified ? 'verified' : 'unverified'
          }`,
        })}
      >
        {markerChildren}
      </Marker>
    );
  }

  return (
    <CircleMarker
      {...markerProps}
      ref={nodeMarkerRef}
      center={node}
      radius={isSelected ? 7 : nodeSize}
    >
      {markerChildren}
    </CircleMarker>
  );
}

const MemoizedNodeMarker = React.memo(NodeMarker);

export function AllNodes() {
  const nodes = useSelector(plotterSelectors.selectFilteredNodes);
  // console.log("FILTERED NODES PLOTTER: ", JSON.stringify(nodes))
  // const nodes = useSelector(plotterSelectors.selectAllNodes);
  const points = useSelector(plotterSelectors.selectAllPoints);

  // this is basically the same as selectedNodeIds but can highlight all the receipt points separate from delivery points - just to see if it's helpful for now
  const activelySelectedNodeIds = useSelector(
    selectedItemsActions.selectActivelySelectedNodeIds,
  );
  // is selectedNodeIds going to make these memoized nodes re-render?  Might need a different tactic
  const { error: pointsError } = useGetMapPointsQuery();
  const { error: edgesError } = useGetMapEdgesQuery();
  const { error: nodesError } = useGetMapNodesQuery();
  const error = pointsError || edgesError || nodesError;

  useEffect(() => {
    if (error) {
      toast.error('Something went wrong. Please refresh');
    }
  }, [error]);

  return (
    <>
      {Object.keys(nodes).map((key, index) => {
        // pass in the flow direction for this node. Cleaner to do it inside the node marker but messes up the memoization
        if (!nodes[key]) {
          return null;
        }
        const pointsDirFlo = nodes[key].points.map(
          (pointId) => points[pointId]?.dir_flo,
        );
        let color;
        const isActiveNode = activelySelectedNodeIds.includes(key);
        // console.log(activelySelectedNodeIds)

        if (isActiveNode) {
          color = '#5AAB61';
        } else {
          let nodeDirFlo;
          if (
            pointsDirFlo.includes('B') ||
            (pointsDirFlo.includes('R') && pointsDirFlo.includes('D'))
          ) {
            nodeDirFlo = 'B';
          } else if (pointsDirFlo.includes('D')) {
            nodeDirFlo = 'D';
          } else if (pointsDirFlo.includes('R')) {
            nodeDirFlo = 'R';
          }

          switch (nodeDirFlo) {
            case 'R':
              color = '#ED0907';
              break;
            case 'D':
              color = '#0605EB';
              break;
            case 'B':
              color = '#BE50DB';
              break;
            default:
              color = '#19538C';
          }
        }
        return (
          <MemoizedNodeMarker
            key={key}
            node={nodes[key]}
            color={color}
            isSelected={isActiveNode}
          />
        );
      })}
    </>
  );
}

AllNodes.whyDidYouRender = {
  logOnDifferentValues: true,
};
