import React, { useRef, useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';

const referenceData = require('../example_data/fayette_full_reference.json');

export const gasDay = referenceData.map((e) => e.DATE);
export const maxTemperature = referenceData.map((e) => e.TMAX);
export const minTemperature = referenceData.map((e) => e.TMIN);
export const HDD = referenceData.map((e) => e.HDD);
export const rollingHDD = referenceData.map((e) => e.Rolling_HDD_Sum);
export const rollingHDDLastYear = referenceData.map(
  (e) => e.Rolling_HDD_Sum_Last_Year,
);
export const SONATPrice = referenceData.map((e) => e.SONAT);
export const TranscoZn4Price = referenceData.map((e) => e.TRANSCO_ZN4);
export const scheduledDoD = referenceData.map(
  (e) => e.Scheduled_Quantity_Day_Over_Day * 100,
);
export const scheduledPercent = referenceData.map(
  (e) => e.Scheduled_Quantity_Percent * 100,
);

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
);

export const options = {
  options: {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false,
    },
    stacked: false,
    scales: {
      yAxes: [
        {
          id: 'A',
          type: 'linear',
          position: 'left',
          beginAtZero: true,
        },
        {
          id: 'B',
          type: 'linear',
          position: 'right',
        },
      ],
    },
  },
};

const labels = gasDay;

export const priceVsWeather = {
  labels,
  datasets: [
    {
      type: 'line',
      label: 'SONAT Price',
      data: SONATPrice,
      yAxisID: 'A',
      borderColor: 'rgba(50, 119, 135, 0.5)',
    },
    {
      type: 'line',
      label: 'Transco Zone 4 Price',
      data: TranscoZn4Price,
      yAxisID: 'A',
      borderColor: 'blue',
    },
    {
      type: 'bar',
      label: 'Max Temperature',
      data: maxTemperature,
      yAxisID: 'B',
      backgroundColor: 'rgb(235, 100, 53)',
    },
    {
      type: 'bar',
      label: 'Min Temperature',
      data: minTemperature,
      yAxisID: 'B',
      backgroundColor: 'rgb(100, 100, 235)',
    },
  ],
};

export const HDDYoY = {
  labels,
  datasets: [
    {
      type: 'line',
      label: 'One Month Rolling HDD',
      data: rollingHDD,
      yAxisID: 'A',
      borderColor: 'rgba(50, 119, 135, 1.0)',
    },
    {
      type: 'bar',
      label: 'Rolling HDD Last Year',
      data: rollingHDDLastYear,
      yAxisID: 'A',
      backgroundColor: 'rgba(235, 100, 53, 0.6)',
    },
  ],
};

export const scheduledQuantity = {
  labels,
  datasets: [
    {
      type: 'line',
      label: 'Percent Scheduled Since Yesterday',
      data: scheduledDoD,
      yAxisID: 'A',
      borderColor: 'rgba(50, 119, 135, 1.0)',
    },
    {
      type: 'bar',
      label: 'Percent Scheduled Over Total Quantity',
      data: scheduledPercent,
      yAxisID: 'B',
      backgroundColor: 'rgba(53, 150, 53, 0.7)',
    },
  ],
};

const priceChart = <Chart options={options} data={priceVsWeather} />;
const hddChart = <Chart options={options} data={HDDYoY} />;
const scheduledChart = <Chart options={options} data={scheduledQuantity} />;
class DataViz extends React.Component {
  constructor() {
    super();
    this.state = { content: priceChart };
  }

  onClick1 = () => {
    this.setState({ content: priceChart });
  };

  onClick2 = () => {
    this.setState({ content: hddChart });
  };

  onClick3 = () => {
    this.setState({ content: scheduledChart });
  };

  render() {
    return (
      <div className="accordion" id="accordionChart">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className="accordion-button"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              onClick={() => {
                this.onClick1();
              }}
            >
              Price vs Temperature
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accordionChart"
          >
            <div className="accordion-body">{this.state.content}</div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingTwo">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
              onClick={() => {
                this.onClick2();
              }}
            >
              HDDs Year Over Year
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            aria-labelledby="headingTwo"
            data-bs-parent="#accordionChart"
          >
            <div className="accordion-body">{this.state.content}</div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingThree">
            <button
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
              onClick={() => {
                this.onClick3();
              }}
            >
              Scheduled Quantity
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            aria-labelledby="headingThree"
            data-bs-parent="#accordionChart"
          >
            <div className="accordion-body">{this.state.content}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default DataViz;
