import { Navigate, Outlet, useLocation } from 'react-router-dom';

export function RequireAuth() {
  const location = useLocation();

  // if (!auth.user) {

  // how does this verify that the resulting token is valid?
  if (!localStorage.getItem('token')) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    console.error('RequireAuth: Not authorized');
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return <Outlet />;
}
