import { useMemo } from 'react';

export function LoadingList({ length = 10 } = {}) {
  const random = useMemo(
    () =>
      Array.from({ length }, (_, index) => index + 1).sort(
        () => Math.random() - 0.5,
      ),
    [length],
  );

  return (
    <ul className="list-group list-group-flush">
      {random.map((num) => (
        <li key={num.toString()} className="list-group-item placeholder-wave">
          <span className={`placeholder col-${num}`} />
        </li>
      ))}
    </ul>
  );
}
