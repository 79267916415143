
import { toast } from 'react-hot-toast';
import { toLink } from '../utils/stringUtils';
import { chainSummary } from '../scenarios/scenarioSlice';
import { toHtmlTable } from '../utils/objectUtils';
import { copyToClipboard } from '../utils/clipboardUtils';

/**
*
* @param {import('./scenarioSlice').Scenario} scenario
* @param {import('./scenarioSlice').OperationChain} chain
* @returns {string[]}
*/
export const getUniqueTsps = (scenario, chain) => {
 const tsps = chain.operations.flatMap((op_id) => {
   const operation = scenario.operations.find((op) => op.id === op_id);
   return [
     operation.receipt_point.tsp_name,
     operation.delivery_point.tsp_name,
   ];
 });
 const uniqueTsps = tsps.filter(
   (tsp, index) => tsp && tsps.indexOf(tsp) === index,
 );

 return uniqueTsps;
};


/**
 *
 * @param {import('./scenarioSlice').Scenario} scenario
 * @param {import('./scenarioSlice').OperationChain} chain
 */
export const copyChainToClipboard = (scenario, chain) => {
  // const chain = scenario.operation_chains.find((chain) => chain.id === chainId);
  const summary = chainSummary(scenario, chain);
  const scenarioLink = toLink(
    `${scenario.name}: Chain #${chain.id}`,
    `${window.location.origin}/scenarios/${scenario.id}?selectedChain=${chain.id}`,
  );
  const overviewHtml = toHtmlTable(
    ([summary['overview']]),
    `${chain.callout_list.join('<br/>')}<br/>${scenarioLink}`,
  );
  const endpointsHtml = toHtmlTable(summary['endpoints'], 'Endpoints');
  const operationsHtml = toHtmlTable(summary['operations'], 'Operations');
  const constraintsHtml = toHtmlTable(summary['constraints'], 'Constraints');
  copyToClipboard(
    `${overviewHtml}</br>${endpointsHtml}</br>${operationsHtml}</br>${constraintsHtml}`,
    'text/html',
  );
  toast.success('Copied to clipboard');
};


/**
 *
 * @param {{
 *  operation: import('./scenarioSlice').Operation
 * }} props
 * @returns
 */
export const LinkToRates = ({ operation }) => {
  return (
    <>
      <a
        className={operation.has_commodity_rate ? '' : 'text-danger'}
        href={operation.commodity_rate_url}
        target="_blank"
        rel="noreferrer"
      >
        {operation.has_commodity_rate ? 'Update' : 'Add'} Commodity Rate
      </a>
      <br />
      <a
        className={operation.has_fuel_rate ? '' : 'text-danger'}
        href={operation.fuel_rate_url}
        target="_blank"
        rel="noreferrer"
      >
        {operation.has_fuel_rate ? 'Update' : 'Add'} Fuel Rate
      </a>
    </>
  );
};