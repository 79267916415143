import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';

import { scenarioActions } from './scenarioSlice';

import {
  useGetScenarioQuery,
  useGetScenarioGroupQuery,
  useGetRiseTransportOnDateQuery,
} from '../services/edgeApi';
import { EditScenario } from './EditScenario';
import { BelowMapSetter } from '../layouts/BelowMapContext';
import { BelowMapTab } from '../layouts/BelowMapTab';
import { ScenarioDetail } from './ScenarioDetail';

import { SegmentConstraints } from '../segmentConstraints/SegmentConstraints';
import { PointConstraints } from '../pointConstraints/PointConstraints';
import { Markets } from '../markets/Markets';
import { Trades } from '../trades/Trades';
import { ScenarioContracts } from '../contracts/ScenarioContracts';
import { ScenarioReceiptPoints } from './ScenarioReceiptPoints';
import { ComprehensiveView } from './ComprehensiveView';
import {
  comprehensiveView,
  getDataView,
  riseTransportView,
} from '../services/dataViewService';
import { AllPathsTable } from './AllPathsTable';

export const RenderWithKey = ({ children }) => {
  const params = useParams();
  const { id } = params;
  return children({ key: id, id });
};

/**
 *
 * @param {{
 *  scenarioId: string,
 *  isEditing: boolean,
 *  scenario?: import('../scenarios/scenarioSlice').Scenario
 * }} props
 * @returns
 */
export const Scenario = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scenarioId = props.id;

  const [isEditing, setIsEditing] = useState(
    props.isEditing || scenarioId === 'new',
  );

  const params = useParams();
  const { scenarioGroupId } = params;
  const groupUrlPrepend = scenarioGroupId
    ? `/scenarios/groups/${scenarioGroupId}`
    : '';

  /** @type {import('../scenarios/scenarioSlice').Scenario} */
  const stateScenario = useSelector((state) => state.scenario.data);

  // Don't fetch scenario if we're creating a new one
  const skip = scenarioId === 'new';

  const usingAllPaths = location.pathname.includes('/all-paths');

  const {
    data: fetchedScenario,
    error,
    isLoading,
    isFetching,
  } = useGetScenarioQuery(
    { scenarioId: String(scenarioId), scenarioGroupId },
    { skip },
  );

  const { data: riseTransport } = useGetRiseTransportOnDateQuery(
    { gasDate: stateScenario?.flow_date },
    { skip: isLoading || isEditing || isFetching },
  );

  const { data: scenarioGroup } = useGetScenarioGroupQuery(
    String(scenarioGroupId),
    {
      skip: !scenarioGroupId,
    },
  );

  useEffect(() => {
    return () => dispatch(scenarioActions.resetState());
  }, []);

  useEffect(() => {
    if (usingAllPaths && isEditing) {
      setIsEditing(false);
    }
  }, [usingAllPaths]);

  useEffect(() => {
    if (fetchedScenario) {
      dispatch(
        scenarioActions.setScenario({
          scenario: fetchedScenario,
        }),
      );
    }
  }, [fetchedScenario]);

  const enterEditingState = () => {
    dispatch(scenarioActions.beginEditing());
  };

  const rollbackEditingState = () => {
    dispatch(scenarioActions.rollback());
  };

  const commitEditingState = () => {
    dispatch(scenarioActions.commit());
  };

  const onEditSuccess = (result) => {
    dispatch(
      scenarioActions.setScenario({
        scenario: result,
      }),
    );
    setIsEditing(false);
    commitEditingState();
    // navigate to new scenario URL, but might want to do this conditionally
    // right now it does navigate to the new URL but it doesn't re-render this component, probably because there's no key set on the Scenario component in the routes.  I don't know if we really care because it seems to do what we want
    navigate(`${groupUrlPrepend}/scenarios/${result.id}`);
  };

  const onEditCancel = () => {
    setIsEditing(false);
    rollbackEditingState();
    // depending on where we are, we probably want to do different things when canceling
    // like on scenarios/new, we probably want to go back to where we were previously
    // but if we are editing an existing scenario, we probably want to stay at scenario detail
    if (scenarioId === 'new') {
      navigate(`/${groupUrlPrepend}scenarios`);
    }
  };

  useEffect(() => {
    if (isEditing) {
      enterEditingState();
    }
  }, [isEditing]);

  const comprehensiveViewData = useMemo(() => {
    const providers = [
      {
        args: { scenario: stateScenario },
        providerFunction: comprehensiveView,
      },
      {
        args: { transports: riseTransport },
        providerFunction: riseTransportView,
      },
    ];
    return getDataView({
      providers,
    });
  }, [stateScenario, riseTransport]);

  if (isLoading) {
    return <>Loading Scenario</>;
  }

  if (error) {
    toast.error('Problem loading scenario');
    return null;
  }

  const modes = {
    DEFAULT: 'DEFAULT',
    ALL_PATHS_TO_POINT: 'PATHS_TO_POINT',
    BEST_PATHS_TO_POINT: 'PATHS_TO_POINT',
  };

  return (
    <>
      {isEditing ? (
        <EditScenario
          scenario={stateScenario}
          onSuccess={onEditSuccess}
          onCancel={onEditCancel}
        />
      ) : (
        stateScenario && (
          <ScenarioDetail
            usingAllPaths={usingAllPaths}
            scenarioGroup={scenarioGroup}
            onEditStart={() => setIsEditing(true)}
            onOptimize={onEditSuccess}
          />
        )
      )}
      <ScenarioTabs
        key="scenario-tabs"
        isEditing={usingAllPaths || isEditing}
        scenario={stateScenario}
        comprehensiveViewData={comprehensiveViewData}
        mode={
          usingAllPaths
            ? 'ALL_PATHS'
            : modes[stateScenario?.settings?.optimization_type]
        }
      />
    </>
  );
};

/**
 * @typedef {{
 *  isEditing: boolean,
 *  scenario: import('../scenarios/scenarioSlice').Scenario
 *  comprehensiveViewData: any
 *  mode: 'DEFAULT'| 'PATHS_TO_POINT' | 'ALL_PATHS'
 * }} OptimizationTabsProps
 */

/**
 * @param {OptimizationTabsProps} props
 */
const ScenarioTabs = ({ isEditing, scenario, comprehensiveViewData, mode }) => {
  const commonTabs = [
    <BelowMapTab
      key="scenario-below-map-segment-constraints"
      tabName="Segment Constraints"
      contextKey="segmentConstraints"
      activeTab={Boolean(isEditing)}
    >
      <SegmentConstraints
        tabKey="scenario-below-map-segment-constraints"
        contextKey="segmentConstraints"
      />
    </BelowMapTab>,
    <BelowMapTab
      key="scenario-below-map-point-constraints"
      tabName="Point Constraints"
      contextKey="pointConstraints"
    >
      <PointConstraints contextKey="pointConstraints" />
    </BelowMapTab>,
    <BelowMapTab
      key="scenario-below-map-contracts"
      tabName="Contracts"
      contextKey="contracts"
    >
      <ScenarioContracts isEditing={isEditing} />
    </BelowMapTab>,
    <BelowMapTab
      key="scenario-below-map-markets"
      tabName="Market Prices"
      contextKey="marketPrices"
    >
      <Markets />
    </BelowMapTab>,
  ];

  const defaultTabs = [
    <BelowMapTab
      key="scenario-below-map-trades"
      tabName="Trades"
      contextKey="trades"
    >
      <Trades />
    </BelowMapTab>,
  ];

  const pathsToPointTabs = [
    ...defaultTabs,
    <BelowMapTab
      key="scenario-below-map-receipt-points"
      tabName="Receipt Points"
      contextKey="receiptPoints"
    >
      <ScenarioReceiptPoints isEditing={isEditing} />
    </BelowMapTab>,
  ];

  const allPathsTabs = [
    ...defaultTabs,
    <BelowMapTab
      key="scenario-below-map-all-paths"
      tabName="Paths"
      contextKey="allPaths"
      tabOrder={0}
    >
      <AllPathsTable />
    </BelowMapTab>,
  ];

  const displayTabs = {
    DEFAULT: defaultTabs,
    PATHS_TO_POINT: pathsToPointTabs,
    ALL_PATHS: allPathsTabs,
  };

  return (
    <BelowMapSetter key="scenario-below-map-setter">
      {!isEditing && (
        <BelowMapTab
          key="scenario-below-map-comprehensive-view"
          tabName="Comprehensive View"
          contextKey="comprehensiveView"
          activeTab
        >
          <ComprehensiveView scenario={scenario} data={comprehensiveViewData} />
        </BelowMapTab>
      )}
      {commonTabs}
      {displayTabs[mode] ?? defaultTabs}
    </BelowMapSetter>
  );
};
