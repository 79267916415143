import {
  Routes,
  Route,
  unstable_HistoryRouter as HistoryRouter,
  useParams,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { useState } from 'react';
import axios from 'axios';
import LogRocket from 'logrocket';
import { FlexLayout, SidebarMapLayout } from './layouts/Layouts';
import { MapBuilder } from './modes/MapBuilder';
import { store } from './store';
import { AllPaths } from './modes/AllPaths';
import { Scenario } from './scenarios/Scenario';
import { Scenarios } from './scenarios/Scenarios';
import { ScenarioGroups } from './scenarios/ScenarioGroups';
import { ScenarioGroup } from './scenarios/ScenarioGroup';
import { Segmentation } from './modes/Segmentation';
import DataViz from './modes/DataViz';
import { AllSources } from './modes/AllSources';
import { Optimize } from './modes/Optimize';
import { RequireAuth } from './user/RequireAuth';
import { Login } from './user/Login';
import { NoticesTable } from './notices/NoticesTable';
import browserHistory from './utils/browserHistory';
import { Can } from './user/Can';
import { ContractWrapper } from './contracts/Contract';
import { Contracts } from './contracts/Contracts';
import { MarketData } from './flows/Flows';
import { RateTable } from './rates/RateTable';
import { DayOverDay } from './adhoc/DayOverDay';
import { Export } from './exports/Export';
import process from 'process';

// if map needs to render different things based on trader app vs map builder, change this to render SidebarMapLayout with props.sidebar and props.map components
// https://reactjs.org/docs/composition-vs-inheritance.html#containment
// https://codepen.io/gaearon/pen/gwZOJp?editors=0010

// const axiosService = axios.create({
//     baseURL: process.env["REACT_APP_API_URL"],
//     headers: {
//         'Content-Type': 'application/json',
//     },
// });
// get map data

// store.dispatch(edgeApi.endpoints.getMapData.initiate())

// TODO: move this axios stuff to a different file and include it in here
// this is now handled by RTKQuery in services/edgeApi.js baseQueryWithReauth

// axios interceptors
// Add a request interceptor
const _requestTokenInterceptor = axios.interceptors.request.use(
  (config) => {
    // HERE'S HOW TO DO THIS WITH RTK QUERY:
    // https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery
    // add token to headers before sending request
    const token = localStorage.getItem('token');
    if (token !== null) {
      config.headers.Authorization = `Token ${token}`;
    }
    return config;
  },
  (error) =>
    // Do something with request error
    Promise.reject(error),
);

// axios.interceptors.response.use(
//     response => response,
//     error => {
//       if(error.response.status === 401){
//         localStorage.removeItem('token');
//         browserHistory.replace('/login')
//       }else{
//         throw error;
//       }
//     }
//   );

// const badAuthenticationInterceptor = axios.interceptors.response.use(function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
//   }, function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     // Do something with response error
//     // IF 401 - not authenticated at all - remove token and send to login
//     if(error.response.status === 401){
//       localStorage.removeItem('token');
//
//       //TODO: add state to take them back to the URL they were trying to access
//
//       browserHistory.replace('/login')
//       // window.location.pathname = '/login'
//       return Promise.resolve()
//
//     // IF 403 - authenticated but forbidden (trader trying to edit map, etc.) - display toast error?
//     }else{
//       return Promise.reject(error);
//     }
//   });

const RenderWithKey = ({ children }) => {
  // just return the child with a key for rerendering when URL changes
  const params = useParams();
  const { id } = params;
  return children({ key: id, id });
};

function App() {
  const [heightCss, setHeightCss] = useState('');
  const [navContent, setNavContent] = useState('');

  // initialize logrocket in production
  if (process.env.NODE_ENV === 'production') {
    LogRocket.init('pf7bti/edge');
  }

  return (
    <Provider store={store}>
      <HistoryRouter history={browserHistory}>
        <Routes>
          <Route
            element={
              <FlexLayout navContent={navContent} heightCss={heightCss} />
            }
          >
            <Route element={<RequireAuth />}>
              {/* // ------------ AUTHENTICATION REQUIRED --------------- */}
              <Route path="/notices" element={<NoticesTable />} />
              <Route path="/rates" element={<RateTable />} />
              <Route path="/day-over-day" element={<DayOverDay />} />
              <Route path="/exports" element={<Export />} />
              {/* map */}
              <Route
                element={
                  <SidebarMapLayout
                    setNavContent={setNavContent}
                    setHeightCss={setHeightCss}
                  />
                }
              >
                <Route path="/" element={<></>} />
                <Route path="/market-conditions" element={<MarketData />} />
                <Route path="/explore-map" element={<></>} />
                <Route path="/scenarios" element={<Scenarios />} />
                <Route path="/scenarios/groups" element={<ScenarioGroups />} />
                <Route
                  path="/scenarios/groups/:id"
                  element={<ScenarioGroup />}
                />
                <Route
                  path="/scenarios/groups/:scenarioGroupId/scenarios/:id"
                  element={
                    <RenderWithKey>
                      {({ key, id, ...rest }) => (
                        <Scenario key={key} id={id} rest={rest} />
                      )}
                    </RenderWithKey>
                  }
                />
                <Route
                  path="/scenarios/:id"
                  element={
                    <RenderWithKey>
                      {({ key, id }) => <Scenario key={key} id={id} />}
                    </RenderWithKey>
                  }
                />
                <Route
                  path="/scenarios/:id/all-paths"
                  element={
                    <RenderWithKey>
                      {({ key, id }) => <Scenario key={key} id={id} />}
                    </RenderWithKey>
                  }
                />
                <Route path="/all-paths" element={<AllPaths />} />
                <Route path="/all-receipts" element={<AllSources />} />
                <Route path="/segmentation" element={<Segmentation />} />
                <Route path="/optimize" element={<Optimize />} />
                <Route path="/visualize-data" element={<DataViz />} />
                <Route path="/contracts" element={<Contracts />} />
                <Route
                  path="/contracts/:id"
                  element={<ContractWrapper isEditable detailed />}
                />

                <Route element={<Can type="route" can="add_point" />}>
                  <Route path="/map-builder" element={<MapBuilder />} />
                </Route>
              </Route>
              {/* // ------------ END AUTHENTICATION REQUIRED --------------- */}
            </Route>
            <Route path="/login" element={<Login />} />
          </Route>
        </Routes>
      </HistoryRouter>
    </Provider>
  );
}

export default App;
