import { useSelector, useDispatch } from 'react-redux';
import {
  ZoomInIcon,
  EyeIcon,
  EyeOffIcon,
  FilterIcon,
  ClipboardIcon,
  FloppyDiscIcon,
  FlagIcon,
  MessageIcon,
} from '@iconicicons/react';
import React, { useEffect } from 'react';
import * as pathsActions from './pathsSlice';
import { useCreateTraderPathMutation } from '../services/edgeApi';
import { UncontrolledTooltip } from 'reactstrap';
import { colors } from '../utils/colors';
import { copyToClipboard } from '../utils/clipboardUtils';
import toast from 'react-hot-toast';

export function SidebarPath(props) {
  // const path = useSelector((state) => state.paths.paths[props.pathId])
  // const color = useSelector(state => pathsActions.selectPathColor(state, props.pathId))
  const dispatch = useDispatch();
  const color = useSelector((state) =>
    pathsActions.selectPathColor(state, props.path.id),
  );
  const selectedPathId = useSelector((state) => state.paths.selectedPathId);
  const isSelected = selectedPathId == props.path.id;
  const isVisible = useSelector((state) => state.paths.visiblePathIds).includes(
    String(props.path.id),
  );

  const scenarioId = useSelector((state) => state.scenario.data.id);
  const scenarioName = useSelector((state) => state.scenario.data.name);

  const [traderPathMutation] = useCreateTraderPathMutation();

  const toggleVisibility = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    dispatch(pathsActions.toggleVisibility(props.path.id));
  };

  const { path, operation_chain } = props;

  useEffect(() => {
    dispatch(pathsActions.addPath({ ...path, operation_chain }));
    // cleanup when unmounting
    return () => {
      dispatch(pathsActions.removePath(props.path.id));
    };
  }, []);

  const zoomToPath = (evt) => {
    evt.preventDefault();
    dispatch(pathsActions.zoomToPath(props.path.id));
  };

  const createTraderPath = async () => {
    const traderPath = {
      name: `${scenarioName} (id:${scenarioId}) - ${props.title}`,
      chain_id: props.operation_chain.id,
    };
    await traderPathMutation(traderPath);
  };

  return (
    <div
      onClick={() =>
        dispatch(pathsActions.pathSelected({ selectedPathId: props.path.id }))
      }
    >
      <a href="#" className="float-end" onClick={zoomToPath}>
        <UncontrolledTooltip placement="top" target={`chain-zoom-${props.id}`}>
          Zoom to this path
        </UncontrolledTooltip>
        <ZoomInIcon id={`chain-zoom-${props.id}`} />
      </a>
      <a href="#" className="float-end" onClick={toggleVisibility}>
        <UncontrolledTooltip
          placement="top"
          target={`chain-toggle-visibility-${props.id}`}
        >
          Toggle visibility
        </UncontrolledTooltip>
        {isVisible ? (
          <EyeIcon id={`chain-toggle-visibility-${props.id}`} />
        ) : (
          <EyeOffIcon id={`chain-toggle-visibility-${props.id}`} />
        )}
      </a>
      <a href="#" className="float-end" onClick={props.filterTsps}>
        <UncontrolledTooltip
          placement="top"
          target={`chain-filter-${props.id}`}
        >
          Show only pipelines in this path
        </UncontrolledTooltip>
        <FilterIcon id={`chain-filter-${props.id}`} />
      </a>
      <a
        href="#"
        className="float-end"
        onClick={() => {
          confirm(
            'Are you sure you want to save this path to the trader paths?',
          ) && createTraderPath();
        }}
      >
        <UncontrolledTooltip
          placement="top"
          target={`chain-save-to-trader-paths-${props.id}`}
        >
          Save to trader paths
        </UncontrolledTooltip>
        <FloppyDiscIcon id={`chain-save-to-trader-paths-${props.id}`} />
      </a>
      <a
        href="#"
        style={{ position: 'relative' }}
        className="float-end"
        onClick={props.onOpenAnnotations}
      >
        <UncontrolledTooltip
          placement="top"
          target={`chain-add-comment-${props.id}`}
        >
          Add an annotation
        </UncontrolledTooltip>
        <MessageIcon id={`chain-add-comment-${props.id}`} />
        {props.hasAnnotations && (
          <span
            style={{
              width: 8,
              height: 8,
              backgroundColor: colors.matteRed,
              position: 'absolute',
              bottom: 2,
              right: 2,
              borderRadius: '50%',
              aspectRatio: 1,
            }}
          ></span>
        )}
      </a>
      <a
        href="#"
        className="float-end"
        onClick={() => props.copyToClipboard(operation_chain.id)}
      >
        <UncontrolledTooltip
          placement="top"
          target={`chain-copy-to-clipboard-${props.id}`}
        >
          Copy to clipboard
        </UncontrolledTooltip>
        <ClipboardIcon id={`chain-copy-to-clipboard-${props.id}`} />
      </a>
      <div
        role="button"
        onClick={() => {
          const link = `${window.location.origin}${window.location.pathname}/?selectedChain=${operation_chain.id}`;
          copyToClipboard(link, 'text/plain');
          toast.success('Chain link copied to clipboard');
        }}
        style={{ color, fontWeight: isSelected ? 'bold' : 'normal' }}
      >
        {props.title || `Path ${props.path.id}`}{' '}
        {props.isNew && (
          <>
            <UncontrolledTooltip placement="top" target={`chain-${props.id}`}>
              This is an unseen path
            </UncontrolledTooltip>
            <FlagIcon id={`chain-${props.id}`} />
          </>
        )}
      </div>
      {props.children}
      <hr />
    </div>
  );
}
