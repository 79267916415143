import PropTypes from 'prop-types';

/**
 * @typedef {{
 *  children?: React.ReactNode[],
 *  className?: string,
 *  isLoading?: boolean,
 *  onClick?: import('react').MouseEventHandler<HTMLButtonElement>,
 * }} LoadingButtonProps
 */

/**
 *
 * @param {LoadingButtonProps} props
 * @returns {React.FC<LoadingButtonProps>}
 */
export function LoadingButton({ children, isLoading, className, onClick }) {
  const handleClick = () => {
    // run parent onClick if there is one.  If it's a form submit button it doesn't necessarily need one
    if (onClick) {
      onClick();
    }
  };

  return (
    <button className={className} disabled={isLoading} onClick={handleClick}>
      {isLoading && (
        <span
          className="spinner-border spinner-border-sm me-1"
          role="status"
          aria-hidden="true"
        />
      )}
      {children}
    </button>
  );
}

LoadingButton.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  isLoading: PropTypes.any,
  onClick: PropTypes.func,
};
