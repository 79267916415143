import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import process from 'process';

// probably not just going to let users sign up on their own
// export const signupUser = createAsyncThunk(
//   'users/signupUser',
//   async ({ username, password }, thunkAPI) => {
//     try {
//       let res = await axios.post(`${process.env.REACT_APP_API_URL}/api-token-auth/`,
//         {
//           username: user.username,
//           password: user.password
//         }
//       )
//       let data = await response.json();
//       console.log('data', data);
//
//       if (response.status === 200) {
//         localStorage.setItem('token', data.token);
//         return { ...data, username: name, email: email };
//       } else {
//         return thunkAPI.rejectWithValue(data);
//       }
//     } catch (e) {
//       console.log('Error', e.response.data);
//       return thunkAPI.rejectWithValue(e.response.data);
//     }
//   }
// );

export const loginUser = createAsyncThunk(
  'users/login',
  async ({ username, password }, thunkAPI) => {
    try {
      // TODO: this needs the environment URL
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api-token-auth/`,
        {
          username,
          password,
        },
      );
      const { data } = response;
      if (response.status === 200) {
        localStorage.setItem('token', data.token);
        return data;
      }
      return thunkAPI.rejectWithValue(data);
    } catch (e) {
      console.error('Error', e.response.data);
      thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const fetchUserBytoken = createAsyncThunk(
  'users/fetchUserByToken',
  async ({ token }, thunkAPI) => {
    try {
      const response = await fetch(
        'https://mock-user-auth-server.herokuapp.com/api/v1/users',
        {
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: token,
            'Content-Type': 'application/json',
          },
        },
      );
      const data = await response.json();

      if (response.status === 200) {
        return { ...data };
      }
      return thunkAPI.rejectWithValue(data);
    } catch (e) {
      console.error('Error', e.response.data);
      return thunkAPI.rejectWithValue(e.response.data);
    }
  },
);

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    username: '',
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
  reducers: {
    clearState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;

      return state;
    },
  },
  extraReducers: {
    // [signupUser.fulfilled]: (state, { payload }) => {
    //   console.log('payload', payload);
    //   state.isFetching = false;
    //   state.isSuccess = true;
    //   state.username = payload.user.name;
    // },
    // [signupUser.pending]: (state) => {
    //   state.isFetching = true;
    // },
    // [signupUser.rejected]: (state, { payload }) => {
    //   state.isFetching = false;
    //   state.isError = true;
    //   state.errorMessage = payload.message;
    // },
    [loginUser.fulfilled]: (state, { payload }) => {
      // our backend doesn't send username or anything, just the token
      state.username = payload.username;
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [loginUser.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
    [loginUser.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchUserBytoken.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchUserBytoken.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;

      state.username = payload.username;
    },
    [fetchUserBytoken.rejected]: (state) => {
      state.isFetching = false;
      state.isError = true;
    },
  },
});

export const { clearState } = userSlice.actions;

export const userSelector = (state) => state.user;
