import { createSelector, createSlice } from '@reduxjs/toolkit';

// ================================ Initial State ================================ //

/**
 * @type {import('../types/allPaths').AllPathsState}
 */
const initialState = {
  scenarioId: null,
  chains: [],
  operations: [],
  picked_chains: [],
  visibleChains: {},
};

// ================================ Private Methods ================================ //

/**
 * @param {import('../types/allPaths').AllPathsState} state
 */
const _resetState = (initialState) => initialState;

// ================================ Reducers ================================ //

/**
 * @param {import('../types/allPaths').AllPathsState} state
 * @param {import('@reduxjs/toolkit').PayloadAction<{
 *  scenarioId: string,
 *  chains: import('../types/allPaths').AllPathsChain[],
 *  operations: import('../types/allPaths').AllPathsOperation[],
 *  picked_chains?: import('../types/allPaths').AllPathsChain[],
 * }>} action
 * @returns {void}
 **/
const setIteration = (state, action) => {
  state.chains = action.payload.chains;
  state.operations = action.payload.operations;
  if (action.payload.picked_chains) {
    state.picked_chains = action.payload.picked_chains;

    state.visibleChains = action.payload.picked_chains.reduce((acc, chain) => {
      acc[chain.chain_id] = state.visibleChains[chain.chain_id] ?? true;
      return acc;
    }, {});
  }
};

/**
 *
 * @param {import('../types/allPaths').AllPathsState} state
 * @param {import('@reduxjs/toolkit').PayloadAction<{
 *  chainId: string,
 *  visible: boolean,
 * }>} action
 */
const setChainVisibility = (state, action) => {
  state.visibleChains[action.payload.chainId] = action.payload.visible;
};

/**
 *
 * @param {import('../types/allPaths').AllPathsState} state
 * @param {import('@reduxjs/toolkit').PayloadAction<{}>} action
 */
const hideAllChains = (state, _action) => {
  state.visibleChains = state.picked_chains.reduce((acc, chain) => {
    acc[chain.chain_id] = false;
    return acc;
  }, {});
};

/**
 *
 * @param {import('../types/allPaths').AllPathsState} state
 * @param {import('@reduxjs/toolkit').PayloadAction<{}>} action
 */
const showAllChains = (state, _action) => {
  state.visibleChains = state.picked_chains.reduce((acc, chain) => {
    acc[chain.chain_id] = true;
    return acc;
  }, {});
};

// ================================ Selectors ================================ //

/**
 * @param {{
 *  allPaths: import('../types/allPaths').AllPathsState
 * }} state
 */
const selectState = (state) => state?.allPaths ?? initialState;

export const selectAllPathsChains = createSelector(
  selectState,
  (allPaths) => allPaths.chains,
);

export const selectAllPathsOperations = createSelector(
  selectState,
  (allPaths) => allPaths.operations,
);

export const selectAllPathsPickedChains = createSelector(
  selectState,
  (allPaths) => allPaths.picked_chains,
);

export const selectAllPathsVisibleChains = createSelector(
  selectState,
  (allPaths) => allPaths.visibleChains,
);

// ================================ Slice ================================ //

/**
 *
 * @param {import('../types/allPaths').AllPathsState} initialState
 */
const _allPathsReducers = (initialState) => ({
  resetState: () => _resetState(initialState),
  setIteration,
  setChainVisibility,
  hideAllChains,
  showAllChains,
});

const reducers = _allPathsReducers(initialState);

/** @type {import('@reduxjs/toolkit').Slice<import('../types/allPaths').AllPathsState, typeof reducers, 'allPaths'>} */
export const allPathsSlice = createSlice({
  name: 'allPaths',
  initialState,
  reducers,
});

export const allPathsActions = allPathsSlice.actions;

export default allPathsSlice.reducer;
