import { useContext, useEffect, useMemo } from 'react';
import { mapContext } from './EdgeMap';
import { useSelector } from 'react-redux';
import { selectScenario } from '../scenarios/scenarioSlice';
import { selectFilteredEdgeNodes } from '../plotter/plotterSelectors';
import { selectPaths } from '../paths/pathsSlice';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON.js';
import { generateColor } from '../utils/colors';
import { WebGLLayer } from './webglLayer';
import { fromLonLat } from 'ol/proj';

const getOperationMarkerStyle = () => {
  return {
    'circle-radius': 18,
    'circle-fill-color': '#ffffff15',
    'circle-rotate-with-view': false,
    'circle-stroke-color': ['get', 'color'],
    'circle-stroke-width': 5,
  };
};

export const MapOperationMarkers = (zIndex) => {
  const { map } = useContext(mapContext);

  const scenario = useSelector(selectScenario);
  const selectedPathId = useSelector((state) => state.paths.selectedPathId);
  const nodes = useSelector(selectFilteredEdgeNodes);
  const paths = useSelector(selectPaths);

  const path = useMemo(() => paths[selectedPathId], [paths, selectedPathId]);

  const sortedOperations = useMemo(() => {
    if (!path) return [];
    const chainOperations = path?.operation_chain?.operations ?? [];
    return scenario.operations
      .filter((op) => {
        return chainOperations.includes(Number(op.id));
      })
      .sort((op1, op2) => {
        return (
          chainOperations.indexOf(Number(op1.id)) -
          chainOperations.indexOf(Number(op2.id))
        );
      });
  }, [path, scenario]);

  const sortedNodes = useMemo(() => {
    return sortedOperations.reduce(
      /**
       *
       * @param {import('../services/edgeApi').Node[]} order
       * @param {import('../scenarios/scenarioSlice').Operation} op
       * @param {number} index
       * @returns {import('../services/edgeApi').Node[]}
       */
      (order, op) => {
        const receiptNode = nodes[op.receipt_point.node];
        const deliveryNode = nodes[op.delivery_point.node];
        if (receiptNode && order.length === 0) {
          order.push(receiptNode);
        } else if (receiptNode && order[order.length - 1] !== receiptNode) {
          order.push(receiptNode);
        }
        if (deliveryNode && order[order.length - 1] !== deliveryNode) {
          order.push(deliveryNode);
        }
        return order;
      },
      [],
    );
  }, [sortedOperations, nodes]);

  const operationsLayer = useMemo(() => {
    const source = new VectorSource({
      features: new GeoJSON().readFeatures({
        type: 'FeatureCollection',
        features: sortedNodes.map((node) => {
          return {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: fromLonLat([node.lng, node.lat]),
            },
            properties: {
              id: node.id,
              key: `node-${node.id}`,
              color: generateColor(selectedPathId, 100, 50),
            },
          };
        }),
      }),
      format: new GeoJSON(),
    });

    return new WebGLLayer({
      source,
      style: getOperationMarkerStyle(),
      zIndex,
    });
  }, [sortedNodes]);

  useEffect(() => {
    if (!map || !operationsLayer) return;
    map.addLayer(operationsLayer);
    return () => {
      map.removeLayer(operationsLayer);
    };
  }, [map, operationsLayer]);

  return null;
};
