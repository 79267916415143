import { useEffect, useState } from 'react';

/**
 * @typedef {React.HTMLProps<HTMLInputElement> | React.HTMLProps<HTMLTextAreaElement>} NativeTextInputProps
 */

/**
 *
 * @param {NativeTextInputProps & {
 *  value: string | number;
 *  onChange: (value: string | number) => void;
 *  debounce: number;
 *  textArea: boolean;
 * }} props
 * @returns
 */
export function DebouncedInput({
  value: initialValue,
  onChange,
  debounce = 500,
  textArea = false,
  ...props
}) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value]);

  if (textArea) {
    return (
      <textarea
        {...props}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    );
  }

  return (
    <input
      {...props}
      value={value}
      onChange={(e) => setValue(e.target.value)}
    />
  );
}
