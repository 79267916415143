import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MenuIcon, CloseCircleIcon } from '@iconicicons/react';
import { PlotterSettings } from '../plotter/PlotterSettings';
import { PointFilters } from '../filters/PointFilters';
import * as filtersSlice from '../filters/filtersSlice';

/**
 *
 * @param {{onChangeState: (state: boolean) => void}} props
 */
export const MapSettings = ({ onChangeState }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => onChangeState(isOpen), [isOpen]);

  /** @type {import('react').CSSProperties} */
  const style = useMemo(() => (isOpen ? {} : { display: 'none' }), [isOpen]);

  return (
    <>
      <div>
        <h5 className="w-100">
          <span onClick={() => setIsOpen(!isOpen)}>Map Settings</span>
          <span className="float">
            <button
              onClick={() => dispatch(filtersSlice.clearFilters())}
              className="btn btn-primary-outline m-0 p-0"
            >
              <CloseCircleIcon color="red" />
            </button>
          </span>
          <span className="float-end">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="btn btn-primary-outline"
            >
              <MenuIcon />
            </button>
          </span>
        </h5>
      </div>
      <div style={style}>
        <PlotterSettings />
        <hr />
        <PointFilters />
      </div>
    </>
  );
};
