/**
 * @typedef {number | string} DisplayDataType
 * @typedef {Object<string, DisplayDataType>} ViewData
 */

/**
 * @typedef {{
 *  id: number,
 *  name: string,
 *  description: string,
 *  start_date: string,
 *  end_date: string,
 *  owner: string,
 *  k_id: string,
 *  type: string,
 *  rate_schedule_type: string,
 *  max_daily_quantity: number,
 *  tsp: string,
 *  tsp_name: string,
 *  receipt_points: string[],
 *  delivery_points: string[],
 *  receipt_zones: string[],
 *  delivery_zones: string[],
 * }} Contract
 */

/**
 * @typedef {{
 *  delivery_loc_name: string,
 *  delivery_tsp_name: string,
 *  delivered_dth: number,
 *  receipt_loc_name: string,
 *  receipt_tsp_name: string,
 *  receipt_dth: number,
 *  contract_name: string,
 *  contract_k_id: string,
 *  contracts: {[key: string]: Contract},
 *  transport_cost_per_dth: number,
 *  fuel_volume: number,
 *  receipt_asset_type: "RISETRADE",
 *  delivery_asset_type: "RISETRADE",
 *  tsp_name: string,
 * }} RiseTransport
 */

/**
 * @template {Object} ArgsType
 * @typedef {{
 *  args: ArgsType,
 *  providerFunction: (args: ArgsType) => ViewData[],
 * }} DataViewProvider<ArgsType>
 */

/**
 * @param {{
 *  providers: Array<DataViewProvider<any>>,
 * }}
 * @returns {ViewData[]}
 */
export const getDataView = ({ providers }) => {
  return providers.flatMap((provider) => {
    const { args, providerFunction } = provider;
    return providerFunction(args);
  });
};

/**
 * @param {{
 *  scenario: import('../scenarios/scenarioSlice').Scenario,
 * }} args
 * @returns {ViewData[]}
 */
export const comprehensiveView = ({ scenario }) => {
  if (!scenario) return [];

  const data = scenario.operation_chains.flatMap((chain) => {
    const receiptOp = scenario.operations.find(
      (op) => op.id === chain.operations[0],
    );
    const deliveryOp = scenario.operations.find(
      (op) => op.id === chain.operations[chain.operations.length - 1],
    );
    const operations = scenario.operations.filter((op) =>
      chain.operations.includes(op.id)
    );

    
    const chainOps = chain.chain_links.map((chain_link) => {
        const operation = scenario.operations.find((op) => op.id === chain_link.operation_id);
        return {
          ...operation,
          chain_link
        }
    })
    /**
     * @type {ViewData[]}
     */
    const operationData = chainOps.map((op) => {
      const FAKE_FUEL_COST = 3;
      const variableCost = Number(op.transport_cost_per_dth) * op.received_dth;
      const fuelCost =
        Number(op.fuel_factor - 1) * FAKE_FUEL_COST * op.received_dth;
      const transportCost = variableCost + fuelCost;
      const firstOp = op.chain_link.order == 0
      return {
        // Operation
        'Operation ID': op.id,
        'Operation Delivery TSP': op.delivery_point.tsp_name,
        'Operation Delivery Location': op.delivery_point.loc_name,
        'Operation Receipt TSP': op.receipt_point.tsp_name,
        'Operation Receipt Location': op.receipt_point.loc_name,
        'Operation Delivery Description': op.delivery_description,
        'Operation Receipt Description': op.receipt_description,
        Contract: `(${op.contract_k_id}) ${op.contract_name}`,
        'Operation Variable Cost': variableCost,
        'Operation Fuel Cost': fuelCost,
        'Operation Transport Cost': transportCost,
        'Contract Priority': op.transport_priority,
        'Intra TSP':
          op.delivery_point.tsp_name === op.receipt_point.tsp_name
            ? 'Yes'
            : 'No',
        'Operation Delivery Asset Type': op.delivery_asset_type,
        'Operation Receipt Asset Type': op.receipt_asset_type,
        'Delivery Asset': op.delivery_asset_name,
        'Receipt Asset': op.receipt_asset_name,
        'Operation Dth': Number(op.delivered_dth).toFixed(0),

        // Chain
        'Chain ID': chain.id,
        'Chain Name': chain.chain_info?.name ?? 'Unset',
        'Chain Info ID': chain.chain_info?.id ?? 'Unset', 
        'Chain Delivery TSP': deliveryOp.delivery_point.tsp_name, 
        'Chain Delivery Location': deliveryOp.delivery_point.loc_name,
        'Chain Delivery Description': deliveryOp.delivery_description,
        'Chain Receipt TSP': receiptOp.receipt_point.tsp_name,
        'Chain Receipt Location': deliveryOp.receipt_point.loc_name,
        'Chain Receipt Description': receiptOp.receipt_description,
        'Chain Delivery Asset': deliveryOp.delivery_asset_name,
        'Chain Receipt Asset': receiptOp.receipt_asset_name,
        
        // I grabbed the data from the first op of each chain to prevent us from counting volume or profit multiple times
        // This could be done in some other ways, like having separate data for chains and operations
        // Or adding operation index and having the presets select the ops with index 0 when we want chain based data
        'Chain Delivered Dth': firstOp ? Number(chain.delivered_dth).toFixed(0) : null,
        // chain doesn't have a received_dth, so I'm using the first op's received_dth
        'Chain Received Dth': firstOp ? Number(op.received_dth).toFixed(0) : null,
        'Chain Profit': firstOp ? Number(chain.profit).toFixed(0) : null,
        'Using Market': [op.delivery_asset_type, op.receipt_asset_type].some(
          (type) => ['MARKET'].includes(type),
        ),
        'Operation Origin': 'Labs',
      };
    });
    return operationData;
  });

  return data;
};

/**
 * @param {{
 *  transports: RiseTransport[],
 * }} args
 * @returns {ViewData[]}
 */
export const riseTransportView = ({ transports = [] }) => {
  return transports.map((transport) => {
    const FAKE_FUEL_COST = 3;
    const variableCost =
      Number(transport.transport_cost_per_dth) * transport.receipt_dth;
    const fuelCost = transport.fuel_volume * FAKE_FUEL_COST;
    const transportCost = variableCost + fuelCost;
    const contracts = Object.values(transport?.contracts ?? {});
    const today = new Date();
    const active_contracts = contracts.filter(
      (c) => new Date(c.start_date) <= today && today <= new Date(c.end_date),
    );
    const contract = active_contracts.length > 0 ? active_contracts[0] : null;
    return {
      'Operation Delivery TSP': transport.delivery_tsp_name,
      'Operation Delivery Location': transport.delivery_loc_name,
      'Operation Receipt TSP': transport.receipt_tsp_name,
      'Operation Receipt Location': transport.receipt_loc_name,
      'Operation Dth': transport.receipt_dth,
      Contract: contract ? `(${contract.k_id}) ${contract.name}` : 'Unknown',
      'Operation Variable Cost': variableCost,
      'Operation Fuel Cost': fuelCost,
      'Operation Transport Cost': transportCost,
      'Operation Delivery Asset Type': transport.delivery_asset_type,
      'Operation Receipt Asset Type': transport.receipt_asset_type,
      'Operation Origin': 'Energy',
      'Using Market': 'No',
    };
  });
};
