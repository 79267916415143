import { useDispatch } from 'react-redux';
import { Collapse } from 'reactstrap';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { numberFormatter } from '../helpers/formatters';
import { AddTagButton, Tag } from '../helpers/Tag';
import { SidebarPath } from '../paths/SidebarPaths';
import {
  useLazyGetChainAnnotationsQuery,
  useSaveChainAnnotationMutation,
  useTagItemMutation,
  useUntagItemMutation,
  useUpdateChainNameMutation,
} from '../services/edgeApi';
import { toast } from 'react-hot-toast';
import { titleize } from '../utils/stringUtils';
import { scenarioActions } from './scenarioSlice';
import { CheckCircleIcon, CheckIcon } from '@iconicicons/react';
import { colors } from '../utils/colors';
import { ReusableModal } from '../modals/ReusableModal';
import { useTspFilter } from '../helpers/hooks';
import { getUniqueTsps, copyChainToClipboard } from '../helpers/Scenario';
import { OperationDetail } from './OperationDetail';

/**
 *
 * @typedef {{
 *  id: string,
 *  scenario: import('./scenarioSlice').Scenario,
 *  chain: import('./scenarioSlice').OperationChain,
 *  selectedPathId: number
 * }} ChainDetailProps
 *
 * @param {ChainDetailProps} props
 * @returns {React.FC<ChainDetailProps>}
 */
export const ChainDetail = ({ scenario, chain, selectedPathId }) => {
  const setTspFilter = useTspFilter();
  const dispatch = useDispatch();
  const SHOWED_TAG_NAME = 'Showed';
  /**
   * @type {React.MutableRefObject<HTMLInputElement>}
   */
  const annotationInputRef = useRef(null);
  const [updateNameTrigger] = useUpdateChainNameMutation();
  const [saveAnnotationTrigger] = useSaveChainAnnotationMutation();
  const [getAnnotationsTrigger] = useLazyGetChainAnnotationsQuery();
  const [tagItemTrigger, tagReqState] = useTagItemMutation();
  const [untagItemTrigger, untagReqState] = useUntagItemMutation();
  const [isEditing, setIsEditing] = useState(false);
  const [isAnnotationsOpen, setIsAnnotationsOpen] = useState(false);
  const [annotations, setAnnotations] = useState([]);
  const [annotation, setAnnotation] = useState('');
  const [showed, _setShowed] = useState(false);

  /** @param {string} tagName */
  const tagChain = useCallback(
    async (tagName) => {
      const res = await tagItemTrigger({
        item_id: chain.chain_info.id,
        item_type: 'chaininfo',
        name: tagName,
      });
      if (res.data) {
        /** @type {import('./scenarioSlice').Tag} */
        const tag = res.data;
        // add tag to chain in the frontend
        dispatch(
          scenarioActions.tagChain({
            chainInfoId: chain.chain_info.id,
            tag: tag.type,
          }),
        );
      } else if (res.error) {
        toast.error('Something went wrong, please try again');
      }
      return res;
    },
    [tagItemTrigger, chain, dispatch],
  );

  /** @param {string} tagName */
  const untagChain = useCallback(
    async (tagName) => {
      const res = await untagItemTrigger({
        item_id: chain.chain_info.id,
        item_type: 'chaininfo',
        name: tagName,
      });
      if (res.error) {
        toast.error('Something went wrong, please try again');
      } else {
        // remove tag from chain in frontend
        dispatch(
          scenarioActions.untagChain({
            chainInfoId: chain.chain_info.id,
            tag: chain.chain_info.tags.find((t) => t.type.name === tagName),
          }),
        );
      }
      return res;
    },
    [untagItemTrigger, chain, dispatch],
  );

  const setShowed = async (s) => {
    const res = s
      ? await tagChain(SHOWED_TAG_NAME)
      : await untagChain(SHOWED_TAG_NAME);

    if (!res.error) {
      _setShowed(s);
    }
  };

  const chainName = chain.chain_info?.name ?? '';

  const chainOperations = useMemo(
    () =>
      // should just get these in order from backend so don't need to sort
      chain.chain_links.map((chain_link) => {
        const operation = scenario.operations.find(
          (op) => op.id === chain_link.operation_id,
        );
        return {
          ...operation,
          chain_link,
        };
      }),
    [(scenario.operations, chain.operations)],
  );

  const chainPathNodeIds = chainOperations.reduce((prev, operation) => {
    if (operation?.path_node_ids?.length > 0) {
      return prev.concat(operation.path_node_ids);
    }
    const path_node_ids = [];
    if (operation.receipt_asset_type !== 'KEXCHANGE') {
      path_node_ids.push(operation.receipt_point.node);
    }
    if (operation.delivery_asset_type !== 'KEXCHANGE') {
      path_node_ids.push(operation.delivery_point.node);
    }
    return prev.concat(path_node_ids);
  }, []);

  const receiptOp = chainOperations.find((op) => op.id === chain.operations[0]);
  const receiptDescription = receiptOp?.chain_link?.receipt_description;

  const deliveryOp = chainOperations.find(
    (op) => op.id === chain.operations.slice(-1).pop(),
  );
  const deliveryDescription = deliveryOp?.chain_link?.delivery_description;

  const chainPath = {
    id: chain.id,
    path_node_ids: chainPathNodeIds,
    receipt_description: receiptDescription,
    delivery_description: deliveryDescription,
  };

  useEffect(async () => {
    if (isAnnotationsOpen === true) {
      const response = await getAnnotationsTrigger({
        id: chain.chain_info?.id,
      });
      if (response.isError) {
        toast.error('Something went wrong, please try again');
        setAnnotations([]);
      }
      setAnnotations(Object.values(response.data.results));
    } else {
      setAnnotations([]);
    }
  }, [isAnnotationsOpen]);

  const saveAnnotation = async (content) => {
    setAnnotations((prev) => [
      ...prev,
      {
        id: 'new',
        user: 'You',
        content,
        item_type: 'chain info',
        item_id: chain.chain_info?.id,
        created_at: new Date().toISOString(),
      },
    ]);
    setAnnotation('');
    const response = await saveAnnotationTrigger({
      id: chain.chain_info?.id,
      annotation: content,
    });
    if (response.isError) {
      toast.error('Something went wrong, please try again');
      setAnnotations((prev) => prev.filter((a) => a.id !== 'new'));
    }
  };

  const filterTsps = (scenario, chain) => {
    const tsps = getUniqueTsps(scenario, chain);
    if (tsps.length > 0) {
      setTspFilter(tsps);
    }
  };

  // TODO: move this to backend
  const grossSpread = useMemo(
    () =>
      chainOperations
        .map((op) => {
          const chainVolume = op.chain_link.delivered_dth;
          return (
            op.delivery_asset_price * chainVolume -
            op.receipt_asset_price * chainVolume
          );
        })
        .reduce((acc, val) => acc + val, 0) / chain.delivered_dth,
    [chainOperations, chain.delivered_dth],
  );

  /**
   * @type {Array<{
   *  type: import('./scenarioSlice').TagType,
   *  counter: number
   * }>}
   */
  const tagCounter = useMemo(() => {
    return Object.values(
      chain.chain_info?.tags.reduce((agg, tag) => {
        if (agg[tag.type.id]) {
          agg[tag.type.id]['counter'] += 1;
        } else {
          agg[tag.type.id] = {
            counter: 1,
            type: tag.type,
          };
        }
        return agg;
      }, {}) ?? {},
    );
  }, [chain.chain_info?.tags]);

  const timesShown =
    tagCounter.find((tag) => tag.type.name === SHOWED_TAG_NAME)?.counter ?? 0;

  if (timesShown === 0 && showed) {
    _setShowed(false);
  }

  /**
   * Using absolute value because float point errors can cause the transport cost to be -0
   */
  const transportCost = Math.abs(
    grossSpread - chain.profit / chain.delivered_dth,
  );

  const chainAnnotationsModal = (
    <ReusableModal
      isOpen={isAnnotationsOpen}
      onClose={() => setIsAnnotationsOpen(false)}
      header={chain.chain_info?.name ?? 'N/A'}
      footer={
        <div className="input-group m-1">
          <input
            ref={annotationInputRef}
            className="form-control"
            onChange={(evt) => setAnnotation(evt.target.value)}
            onKeyDown={(evt) => {
              if (evt.key === 'Enter') {
                saveAnnotation(annotation);
                evt.target.value = '';
              }
            }}
          />
          <button
            className="btn btn-outline-primary"
            onClick={() => {
              saveAnnotation(annotation);
              annotationInputRef.current.value = '';
            }}
          >
            Save
          </button>
        </div>
      }
    >
      {annotations.map((annotation) => (
        <div key={annotation.id}>
          <p>
            <strong>
              {new Date(annotation.created_at).toLocaleString()}{' '}
              {annotation.user ?? 'Deleted User'}:
            </strong>{' '}
            {annotation.content}
          </p>
        </div>
      ))}
    </ReusableModal>
  );

  return (
    <SidebarPath
      id={chain.id}
      path={chainPath}
      hasAnnotations={chain.chain_info?.has_annotations ?? false}
      operation_chain={chain}
      key={chain.id}
      title={`Chain #${chain.id}`}
      isNew={chain.is_new}
      filterTsps={() => filterTsps(scenario, chain)}
      onOpenAnnotations={() => setIsAnnotationsOpen(true)}
      copyToClipboard={() => copyChainToClipboard(scenario, chain)}
    >
      {chainAnnotationsModal}
      <div className="my-4 text-center">
        {isEditing ? (
          <>
            <textarea
              className="form-control"
              value={chainName}
              maxLength={255}
              rows={3}
              onChange={(evt) =>
                dispatch(
                  scenarioActions.updateChainName({
                    chainInfoId: chain.chain_info?.id,
                    name: evt.target.value,
                  }),
                )
              }
            />
            <CheckIcon
              color={colors.matteGreen}
              role="button"
              onClick={async () => {
                const name = chainName.trim();
                const res = await updateNameTrigger({
                  id: chain.chain_info?.id,
                  name,
                });
                if (res.error) {
                  toast.error('Something went wrong, please try again');
                  return;
                } else {
                  setIsEditing(false);
                }
              }}
            />
          </>
        ) : (
          <>
            <div className="fw-bold">
              {chain.callout_list.map((callout) => (
                <div key={callout}>{callout}</div>
              ))}
              {/* {chainName || 'N/A'} */}
              <br />
              {/* <EditIcon
                color={colors.primaryBlue}
                role="button"
                onClick={() => setIsEditing(true)}
              />{' '} */}
            </div>
          </>
        )}
        <div className="d-flex flex-column gap-1 my-2 justify-content-center align-items-center">
          <button
            className={`btn btn-${showed ? 'success' : 'light'}`}
            disabled={tagReqState.isLoading || untagReqState.isLoading}
            onClick={() => setShowed(!showed)}
          >
            Showed <CheckCircleIcon color={showed ? 'white' : 'darkgrey'} />
          </button>
          {/* <span className="text-muted fw-normal">
            This chain was showed {timesShown} time
            {timesShown === 1 ? '' : 's'}
          </span> */}
        </div>
      </div>
      <div className="my-2">
        Tags:{' '}
        {tagCounter
          // .filter((tag) => tag.type.name !== SHOWED_TAG_NAME)
          .map((tag) => (
            <Tag
              className="me-1 my-1"
              key={tag.type.id}
              name={titleize(tag.type.name)}
              color={tag.type.color}
              counter={tag.counter}
              onRemove={async () => {
                untagChain(tag.type.name);
              }}
            />
          ))}
        <AddTagButton
          onAdd={async (tag) => {
            tagChain(tag.name);
          }}
        />
      </div>
      <div>
        <b>Receipt Price:</b>{' '}
        {receiptOp
          ? `$${numberFormatter(receiptOp.receipt_asset_price, 3)}`
          : "Can't find receipt price"}
      </div>
      <div>
        <b>Delivery Price:</b>{' '}
        {deliveryOp
          ? `$${numberFormatter(deliveryOp.delivery_asset_price, 3)}`
          : "Can't find delivery price"}
      </div>
      <div>
        <b>Transport Cost:</b> ${numberFormatter(transportCost, 3)}
      </div>
      <div>
        <b>Gross Spread:</b> ${numberFormatter(grossSpread, 3)}
      </div>
      <div>
        <b>Profit / Dth:</b> $
        {chain.delivered_dth
          ? numberFormatter(chain.profit / chain.delivered_dth, 3)
          : '-'}
      </div>
      <div>
        <b>Volume: </b>
        {numberFormatter(chain.delivered_dth)} dth
      </div>
      <div>
        <b>Profit:</b> ${numberFormatter(chain.profit)}
      </div>
      <div>
        <b>Receipt: </b>
        {receiptDescription}
      </div>
      <div>
        <b>Delivery: </b>
        {deliveryDescription}
      </div>
      {/* <ul className='list-unstyled'> */}
      <table className="w-100 table border my-3">
        <thead>
          <tr className="border">
            <th className="border">TSP</th>
            <th className="border">From</th>
            <th className="border">To</th>
            <th className="border">Priority</th>
            <th className="border">VarCost</th>
          </tr>
        </thead>
        <tbody>
          {chain.chain_links.map((chain_link, index) => {
            const op_id = chain_link.operation_id;
            const operation = scenario.operations.find((op) => op.id === op_id);
            if (
              !operation ||
              (!operation.contract_id &&
                index !== 0 &&
                index !== chain.operations.length - 1)
            ) {
              return null;
            }

            const tportCost = operation.transport_cost_per_dth;
            const fuelCost =
              chain.fuel_makeup_price * (operation.fuel_factor - 1);
            const ftCost = parseFloat(tportCost) + fuelCost;

            return (
              <tr key={op_id} className="border">
                <td className="border">{operation.receipt_point.tsp_name}</td>
                <td className="border">{chain_link.receipt_summary}</td>
                <td className="border">{chain_link.delivery_summary}</td>
                <td className="border">{operation.transport_priority}</td>
                <td className="border">{ftCost.toFixed(3)}</td>
              </tr>
            );
          })}
          {/* </ul> */}
        </tbody>
      </table>
      <Collapse isOpen={selectedPathId === chain.id}>
        <ul className="list-group">
          {chainOperations.map((operation, index) => {
            if (
              !operation ||
              (!operation.contract_id &&
                index !== 0 &&
                index !== chain.operations.length - 1)
            ) {
              return null;
            }

            return (
              <li key={operation.id} className="list-group-item">
                <OperationDetail
                  scenario={scenario}
                  operation={operation}
                  fuelMakeupPrice={chain.fuel_makeup_price}
                />
              </li>
            );
          })}
        </ul>
      </Collapse>
    </SidebarPath>
  );
};
