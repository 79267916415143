import { useSelector } from 'react-redux';
import {
  selectFilteredEdgeNodes,
  selectInterconnects,
} from '../plotter/plotterSelectors';
import {
  selectRisePathsChains,
  selectTraceableRisePaths,
} from '../plotter/plotterSlice';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON.js';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { WebGLLayer } from './webglLayer';
import { mapContext } from './EdgeMap';
import { generateColor } from '../utils/colors';
import { fromLonLat } from 'ol/proj';
import { MapTooltip } from './MapTooltip';

const getPathStyle = () => [
  {
    filter: ['<=', ['get', 'missingInterconnect'], 0],
    'stroke-width': ['get', 'width'],
    'stroke-color': ['get', 'color'],
    'stroke-line-cap': 'round',
    'stroke-line-join': 'round',
    'stroke-opacity': 0.8,
  },
  {
    filter: ['>', ['get', 'missingInterconnect'], 0],
    'stroke-width': ['get', 'width'],
    'stroke-color': ['get', 'color'],
    'stroke-line-cap': 'round',
    'stroke-line-join': 'round',
    'stroke-opacity': 0.8,
    'stroke-line-dash': [15, 20, 5, 20],
    'stroke-line-dash-offset': 0,
  },
];

export const MapRisePaths = ({ zIndex }) => {
  const { map, registerHoverListener, unregisterHoverListener } =
    useContext(mapContext);
  const nodes = useSelector(selectFilteredEdgeNodes);
  const showRisePaths = useSelector((state) => state.plotter.showRisePaths);
  const filteredTransports = useSelector(selectTraceableRisePaths);
  const riseChains = useSelector(selectRisePathsChains);
  const interconnects = useSelector(selectInterconnects);

  /**
   * @type {[import('../plotter/plotterSlice').ChainedRiseTransport, React.Dispatch<React.SetStateAction<import('../plotter/plotterSlice').ChainedRiseTransport>>]}
   */
  const [toolttipTransport, setTooltipTransport] = useState(null);

  /** @type {import('./EdgeMap').MapEventListener} */
  const hoverListener = useCallback(
    (feature) => {
      if (
        feature?.getProperties()?.key === `rise-path-${toolttipTransport?.id}`
      )
        return;
      if (!feature?.getProperties()?.key?.startsWith('rise-path-')) {
        setTooltipTransport(null);
        return;
      }
      const transportId = feature?.getProperties()?.id;
      const transport = filteredTransports.find(
        (t) => t.id.toString() === transportId.toString(),
      );
      setTooltipTransport(transport);
    },
    [filteredTransports],
  );

  const risePathsLayer = useMemo(() => {
    const source = new VectorSource({
      features: new GeoJSON().readFeatures({
        type: 'FeatureCollection',
        features: showRisePaths
          ? filteredTransports
              .filter((transport) => transport.visible)
              .map((transport) => {
                const pathNodes = transport.path
                  .map((nodeId) => nodes[nodeId])
                  .filter(Boolean);

                return {
                  type: 'Feature',
                  geometry: {
                    type: 'LineString',
                    coordinates: pathNodes.map((node) =>
                      fromLonLat([node.lng, node.lat]),
                    ),
                  },
                  properties: {
                    id: transport.id,
                    chainId: transport.chain_id,
                    key: `rise-path-${transport.chain_id}-${transport.id}`,
                    width: 20,
                    color: generateColor(transport.chain_id),
                    missingInterconnect: 0,
                  },
                };
              })
              .concat(
                riseChains
                  ?.flatMap((chain) => chain.interconnectTransports)
                  ?.filter((transport) => transport.visible)
                  .map((transport) => {
                    const pathNodes = transport.path
                      .map((nodeId) => nodes[nodeId])
                      .filter(Boolean);

                    const missingInterconnect = !Object.values(
                      interconnects,
                    ).some(
                      (interconnect) =>
                        (interconnect.start_point ===
                          transport.delivery_point_id &&
                          interconnect.end_point ===
                            transport.receipt_point_id) ||
                        (interconnect.end_point ===
                          transport.delivery_point_id &&
                          interconnect.start_point ===
                            transport.receipt_point_id),
                    );

                    return {
                      type: 'Feature',
                      geometry: {
                        type: 'LineString',
                        coordinates: pathNodes.map((node) =>
                          fromLonLat([node.lng, node.lat]),
                        ),
                      },
                      properties: {
                        id: Number(transport.id.toString().split('INT')[0]),
                        chainId: transport.chain_id,
                        key: `rise-path-${transport.id}`,
                        width: 8,
                        color: 'red',
                        missingInterconnect,
                      },
                    };
                  }),
              )
          : [],
      }),
      format: new GeoJSON(),
    });

    return new WebGLLayer({
      source,
      style: getPathStyle(),
      zIndex,
    });
  }, [showRisePaths, riseChains, filteredTransports, interconnects]);

  useEffect(() => {
    if (!map) return;
    registerHoverListener(hoverListener);
    return () => {
      unregisterHoverListener(hoverListener);
    };
  }, [map, hoverListener]);

  useEffect(() => {
    if (!map || !risePathsLayer) return;
    map.addLayer(risePathsLayer);
    return () => {
      map.removeLayer(risePathsLayer);
    };
  }, [map, risePathsLayer]);

  return (
    <>
      <MapTooltip visible={Boolean(toolttipTransport)}>
        <div className="bg-dark p-2 px-3 border-dark text-light rounded">
          <p className="fw-bold">Chain {toolttipTransport?.chain_id}</p>
          {`Rise Transport #${toolttipTransport?.id}: ${toolttipTransport?.chain_delivery_volume} Dth`}
        </div>
      </MapTooltip>
    </>
  );
};
