import { createSlice, createSelector } from '@reduxjs/toolkit';

const initialState = {};

export const contractsSlice = createSlice({
  name: 'contracts',
  initialState,

  reducers: {
    resetState: () => initialState,

    addContract: (state, action) => {
      // just doing one contract right now to get started but this should be able to hold / show many contracts
      const contractId = action.payload.id.toString();
      return { ...state, [contractId]: action.payload };
    },

    removeContract: (state, action) => {
      const contractId = action.payload.toString();
      delete state[contractId];
    },

    removeContracts: (state, action) => {
      const contractIds = action.payload.map((id) => id.toString());
      contractIds.forEach((id) => delete state[id]);
    },

    addContracts: (state, action) => {
      let contracts = {};
      if (Array.isArray(action.payload)) {
        contracts = action.payload.reduce((acc, contract) => {
          acc[contract.id.toString()] = contract;
          return acc;
        }, {});
      } else {
        contracts = action.payload;
      }
      return { ...state, ...contracts };
    },

    overwrite: (state, action) => {
      if (Array.isArray(action.payload)) {
        return action.payload.reduce(
          (acc, contract) => ({ ...acc, [contract.id.toString()]: contract }),
          {},
        );
      }
      return action.payload;
    },
  },
});

export const { resetState, addContract, removeContract, addContracts } =
  contractsSlice.actions;

export const contractActions = contractsSlice.actions;

export const selectContracts = createSelector(
  /**
   *
   * @param {{
   *  contracts: {data: Object<string, import('../scenarios/scenarioSlice').Contract>}
   * }} state
   * @returns
   */
  (state) => state.contracts,
  (contracts) => contracts,
);

export default contractsSlice.reducer;

// should have a selector to combine contracts for each edge
