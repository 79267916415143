import { useDispatch, useSelector } from 'react-redux';
import { selectScenario } from './scenarioSlice';
import React, { useEffect, useRef, useState } from 'react';
import {
  allPathsActions,
  selectAllPathsOperations,
  selectAllPathsVisibleChains,
} from './allPathsSlice';
import { numberFormatter, priceFormatter } from '../helpers/formatters';
import { LoadingIndicator } from '../helpers/LoadingIndicator';
import { EyeIcon, EyeOffIcon, FilterIcon } from '@iconicicons/react';
import { useTspFilter } from '../helpers/hooks';
import { useScenarioAllPaths } from '../hooks/AllPaths';
import { InfiniteScrollObserver } from '../helpers/InfiniteScrollObserver';
import { colors } from '../utils/colors';
import { useGetContractsQuery } from '../services/edgeApi';

export const AllPathsTable = (_props) => {
  const dispatch = useDispatch();
  const setTspFilter = useTspFilter();

  const scenario = useSelector(selectScenario);

  // const chains = useSelector(selectAllPathsChains);
  const operations = useSelector(selectAllPathsOperations);
  const visibleChains = useSelector(selectAllPathsVisibleChains);

  const [tableVisibleChains, setTableVisibleChains] = useState({
    ...visibleChains,
  });
  const [sortBy, setSortBy] = useState('profit');
  const [filters, setFilters] = useState({});
  const [activeFilters, setActiveFilters] = useState({});

  const { data: contracts, isLoading: contractsLoading } =
    useGetContractsQuery();

  const {
    chains,
    isLoading,
    pickLoading,
    startLoading,
    exhausted,
    getNextPage,
    pickPath,
    reset,
  } = useScenarioAllPaths(scenario.id, sortBy, filters, {
    pageSize: 30,
    fromCache: true,
  });

  useEffect(() => {
    setTableVisibleChains(
      chains.reduce((acc, chain) => {
        acc[chain.chain_id] = false;
        return acc;
      }, {}),
    );
  }, [chains]);

  const onHover = (chainId, hovering) => {
    if (hovering) {
      dispatch(allPathsActions.setChainVisibility({ chainId, visible: true }));
    } else {
      dispatch(
        allPathsActions.setChainVisibility({
          chainId,
          visible: tableVisibleChains[chainId],
        }),
      );
    }
  };

  const setChainVisibility = (chainId, visible) => {
    setTableVisibleChains((prev) => ({ ...prev, [chainId]: visible }));
    dispatch(allPathsActions.setChainVisibility({ chainId, visible }));
  };

  /**
   *
   * @param {import('../types/allPaths').AllPathsChain} chain
   */
  const filterTSPs = (chain) => {
    const chainOperations = operations.filter((op) =>
      chain.op_list.includes(op.id),
    );
    const tspSet = new Set(
      chainOperations.flatMap((op) => [
        op.delivery_point_tsp,
        op.receipt_point_tsp,
      ]),
    );
    setTspFilter(Array.from(tspSet));
  };

  const toggleActiveFilters = (key) => {
    if (activeFilters[key]) {
      setFilters((prev) => ({ ...prev, [key]: undefined }));
    }
    setActiveFilters((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const setFilterValue = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  /**
   * @type {React.MutableRefObject<HTMLDivElement>}
   */
  const toolbarRef = useRef();

  return (
    <>
      <div
        ref={toolbarRef}
        className="p-3 d-flex justify-content-between"
        style={{
          position: 'sticky',
          backgroundColor: 'white',
          top: 0,
        }}
      >
        <div style={{ display: 'flex', flexGrow: 1, flexShrink: 0 }}>
          <div style={{ maxWidth: '400px' }} className="input-group me-3">
            <label className="input-group-text" htmlFor="sort-chains-by-select">
              Sort By
            </label>
            <select
              className="form-select"
              id="sort-chains-by-select"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
            >
              <option value={'profit'}>Profit</option>
              <option value={'profit_per_dth'}>Profit/Dth</option>
              <option value={'constrained_max_volume'}>Max Volume</option>
            </select>
          </div>
          <button className="btn btn-danger" onClick={() => setFilters({})}>
            Clear Filters
          </button>
        </div>
        <button className="btn btn-primary" onClick={reset}>
          Reset
        </button>
      </div>
      <table className="table">
        <thead
          style={{
            position: 'sticky',
            top: toolbarRef.current?.offsetHeight || 0,
            backgroundColor: 'white',
          }}
        >
          <tr>
            <th></th>
            <th>
              Buy{' '}
              <FilterIcon
                color={
                  activeFilters['chain_receipt_asset_description']
                    ? colors.primaryBlue
                    : null
                }
                onClick={() =>
                  toggleActiveFilters('chain_receipt_asset_description')
                }
                role="button"
              />
              <div className="form-group">
                <input
                  className="form-control"
                  style={{
                    display: activeFilters['chain_receipt_asset_description']
                      ? 'inline-block'
                      : 'none',
                  }}
                  onChange={(evt) =>
                    setFilterValue(
                      'chain_receipt_asset_description',
                      evt.target.value,
                    )
                  }
                />
              </div>
            </th>
            <th>
              Sell{' '}
              <FilterIcon
                color={
                  activeFilters['chain_delivery_asset_description']
                    ? colors.primaryBlue
                    : null
                }
                onClick={() =>
                  toggleActiveFilters('chain_delivery_asset_description')
                }
                role="button"
              />
              <div className="form-group">
                <input
                  className="form-control"
                  style={{
                    display: activeFilters['chain_delivery_asset_description']
                      ? 'inline-block'
                      : 'none',
                  }}
                  onChange={(evt) =>
                    setFilterValue(
                      'chain_delivery_asset_description',
                      evt.target.value,
                    )
                  }
                />
              </div>
            </th>
            <th>Profit</th>
            <th>Profit/Dth</th>
            <th>Volume</th>
            <th>
              Pipelines
              <FilterIcon
                color={
                  activeFilters['op_delivery_point_tsp_name']
                    ? colors.primaryBlue
                    : null
                }
                onClick={() => {
                  toggleActiveFilters('op_delivery_point_tsp_name');
                }}
                role="button"
              />
              <div className="form-group">
                <input
                  className="form-control"
                  style={{
                    display: activeFilters['op_delivery_point_tsp_name']
                      ? 'inline-block'
                      : 'none',
                  }}
                  onChange={(evt) => {
                    setFilterValue(
                      'op_delivery_point_tsp_name',
                      evt.target.value,
                    );
                  }}
                />
              </div>
            </th>
            <th>Contracts</th>
            <th>Pick</th>
          </tr>
        </thead>
        <tbody>
          {pickLoading === false &&
            startLoading === false &&
            chains.map((chain) => {
              const receiptOp = operations.find(
                (op) => op.id === chain.receipt_op,
              );
              const deliveryOp = operations.find(
                (op) => op.id === chain.delivery_op,
              );
              const chainOperations = operations
                .filter((op) => chain.op_list.includes(op.id))
                .sort(
                  (a, b) =>
                    chain.op_list.indexOf(a.id) - chain.op_list.indexOf(b.id),
                );

              const uniqueTsps = Array.from(
                new Set(
                  chainOperations
                    .flatMap((op) => [
                      op.delivery_point_tsp,
                      op.receipt_point_tsp,
                    ])
                    .filter(Boolean),
                ),
              );

              return (
                <tr
                  key={chain.chain_id}
                  onMouseEnter={() => onHover(chain.chain_id, true)}
                  onMouseLeave={() => onHover(chain.chain_id, false)}
                >
                  <td>
                    <div className="d-flex">
                      {tableVisibleChains[chain.chain_id] ? (
                        <EyeIcon
                          role="button"
                          onClick={() =>
                            setChainVisibility(chain.chain_id, false)
                          }
                        />
                      ) : (
                        <EyeOffIcon
                          role="button"
                          onClick={() =>
                            setChainVisibility(chain.chain_id, true)
                          }
                        />
                      )}
                      <FilterIcon
                        role="button"
                        onClick={() => filterTSPs(chain)}
                      />
                    </div>
                  </td>
                  <td>{receiptOp.receipt_asset_description}</td>
                  <td>{deliveryOp.delivery_asset_description}</td>
                  <td>{priceFormatter.format(chain.profit)}</td>
                  <td>{priceFormatter.format(chain.profit_per_dth)}</td>
                  <td>{numberFormatter(chain.constrained_max_volume)} Dth</td>
                  <td>{uniqueTsps.join(', ')}</td>
                  <td>
                    {contractsLoading
                      ? 'Loading...'
                      : chainOperations
                          .map((op) => contracts[op.contract_id]?.name)
                          .filter(Boolean)
                          .join(', ')}
                  </td>
                  <td>
                    <button
                      className="btn btn-primary"
                      onClick={() => pickPath([chain])}
                    >
                      Pick
                    </button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {!isLoading && !exhausted && (
        <InfiniteScrollObserver
          onScrollIn={getNextPage}
          options={{ threshold: 0, rootMargin: '100px' }}
        />
      )}
      {exhausted && (
        <div className="w-100 d-flex justify-content-center my-4">
          No more paths
        </div>
      )}
      {isLoading && (
        <div className="w-100 d-flex justify-content-center my-4">
          <LoadingIndicator />
        </div>
      )}
    </>
  );
};
