import React from 'react';

/**
 *
 * @param {{
 *  style?: React.CSSProperties,
 *  text?: string,
 *  className?: string
 * }} props
 * @returns
 */
export function LoadingIndicator({ style, text, className }) {
  return (
    <div
      style={style}
      className={`d-flex justify-content-center align-content-center ${className}`}
    >
      <div className="spinner-border text-primary me-3" role="status" />
      <span className="sr-only">{text || 'Loading...'}</span>
    </div>
  );
}
