import React, { useEffect } from 'react';
import { Polyline } from 'react-leaflet';
import 'leaflet-arrowheads';
// eslint-disable-next-line no-unused-vars
import { Polyline as LeafletPolyline } from 'leaflet';

/**
 * @typedef {import('react-leaflet').PolylineProps & {
 *  arrowheads: import('leaflet-arrowheads').ArrowheadOptions,
 * }} ArrowheadsPolylineProps
 * @param {ArrowheadsPolylineProps} props
 * @returns {React.FC<ArrowheadsPolylineProps>}
 */
export const ArrowheadsPolyline = (props) => {
  /** @type {React.Ref<LeafletPolyline>} */
  const polylineRef = React.useRef(null);

  useEffect(() => {
    const polyline = polylineRef.current;
    if (props.arrowheads) {
      polyline.arrowheads(props.arrowheads);
      polyline._update();
    }
    return () => {
      if (props.arrowheads) {
        polyline.deleteArrowheads();
        polyline.remove();
      }
    };
  }, [polylineRef]);

  useEffect(() => {
    if (!polylineRef.current) return;
    const polyline = polylineRef.current;
    if (props.arrowheads) {
      polyline.arrowheads(props.arrowheads);
    } else {
      polyline.deleteArrowheads();
    }
    polyline._update();
  }, [props.arrowheads]);

  return <Polyline {...props} ref={polylineRef} />;
};
