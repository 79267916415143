/**
 *
 * @param {string} data
 * @param {string} mimeType
 */
export const copyToClipboard = (data, mimeType) => {
  /**
   * This would be much better, but it does not support mime types
   * other than text/plain and image/png yet. In the mean time, we are
   * stuck with this ugly document.execCommand('copy') hack
   */
  // navigator.clipboard.write([
  //   new ClipboardItem(
  //     {
  //       mimeType: new Blob([headerCsv], { type: mimeType }),
  //     },
  //     {},
  //   ),
  // ]);

  const oncopy = document.oncopy;
  document.oncopy = (e) => {
    e.preventDefault();
    const dT = e.clipboardData;
    dT.setData(mimeType, data);
  };
  document.execCommand('copy');
  document.oncopy = oncopy;
};
