import toast from 'react-hot-toast';
import { useEffect, useRef, useState } from 'react';
import { useLazyGetScenarioAllPathsQuery } from '../services/edgeApi';
import { useDispatch, useSelector } from 'react-redux';
import {
  allPathsActions,
  selectAllPathsChains,
  selectAllPathsOperations,
} from '../scenarios/allPathsSlice';
// import WebSocket from 'ws';
import process from 'process';

/**
 *
 * @param {string | number} scenarioId
 * @param {string} sortBy
 * @param {Object} filters
 * @param {{
 *  pageSize: number,
 *  fromCache: boolean,
 * }} options
 */
export const useScenarioAllPaths = (
  scenarioId,
  sortBy = 'chain_profit',
  filters = {},
  options = {
    pageSize: 10,
    fromCache: true,
  },
) => {
  const dispatch = useDispatch();
  // const [
  //   startAllPathsTrigger,
  //   { isLoading: startLoading, isError: startError },
  // ] = useStartScenarioAllPathsMutation();
  // const startRequest = useRef(null);
  // const [pickFromAllPathsTrigger, { isLoading: pickLoading }] =
  //   usePickFromScenarioAllPathsMutation();
  const [
    getAllPathsTrigger,
    {
      isLoading: getAllPathsLoading,
      isFetching: getAllPathsFetching,
      isError: fetchError,
    },
  ] = useLazyGetScenarioAllPathsQuery();
  const getRequest = useRef(null);

  // const taskId = useRef(null);

  /** @type {import('react').MutableRefObject<WebSocket | null>} */
  const socket = useRef(null);

  const chains = useSelector(selectAllPathsChains);
  const operations = useSelector(selectAllPathsOperations);

  const [page, setPage] = useState(0);
  const [exhausted, _setExhausted] = useState(false);
  const [startLoading, setStartLoading] = useState(false);
  const [pickLoading, setPickLoading] = useState(false);
  const [socketError, setSocketError] = useState(false);
  const [firstRender, setFirstRender] = useState(true);

  const isError = socketError || fetchError;

  const isLoading =
    startLoading || pickLoading || getAllPathsLoading || getAllPathsFetching;

  const increasePageNumber = () => {
    setPage((prev) => {
      const curr = prev + 1;
      return curr;
    });
  };

  const setExhausted = (value) => {
    _setExhausted(value);
  };

  const beginAllPaths = async (reset = false) => {
    if (scenarioId && scenarioId !== 'new') {
      setStartLoading(true);
      const timeout = setTimeout(() => {
        setStartLoading(false);
        setSocketError(true);
        toast.error('Error, please try again');
      }, 5000);
      const token = localStorage.getItem('token');
      socket.current = new WebSocket(
        `${
          process.env.REACT_APP_STREAMING_API_URL
        }/ws/all-paths/?access_token=${token}&scenario_id=${scenarioId}&use_cache=${
          reset ? 'False' : 'True'
        }`,
      );
      let pingInterval = setInterval(() => {
        socket.current.send(JSON.stringify({ action: 'get' }));
      }, 30000);
      socket.current.onmessage = (event) => {
        const message = JSON.parse(event.data);
        if (message.status === 'completed') {
          clearInterval(pingInterval);
          if (message.result === true) {
            getNextPage(true, true);
          } else {
            setSocketError(true);
            toast.error('Error, please try again');
          }
          setStartLoading(false);
          setPickLoading(false);
        } else {
          console.info('Task is still pending...');
        }
      };
      socket.current.onopen = (_event) => {
        clearTimeout(timeout);
      };
      socket.current.onerror = (_event) => {
        setSocketError(true);
        clearInterval(pingInterval);
        console.error(_event);
        toast.error('Error, please try again');
      };
    }
  };

  const getNextPage = async (clear = false, override = false) => {
    if (clear) {
      dispatch(
        allPathsActions.setIteration({
          scenarioId,
          chains: [],
          operations: [],
        }),
      );
    }
    if ((exhausted && !clear) || (isLoading && !override) || isError) return;
    const request = getAllPathsTrigger({
      scenarioId,
      page,
      sortBy,
      filters,
      pageSize: options.pageSize,
      offset: clear ? 0 : page * options.pageSize,
    });
    getRequest.current = request;
    const response = await request;

    if (response.error) {
      console.error(response.error);
    } else {
      if (clear) {
        setPage(1);
      } else {
        increasePageNumber();
      }
      setExhausted(response.data?.chains?.length < options.pageSize);
      const newChains = clear
        ? response.data.chains
        : chains.concat(response.data.chains);
      const newOperations = clear
        ? response.data.operations
        : operations.concat(
            response.data.operations.filter(
              /**
               * @param {import('../types/allPaths').AllPathsOperation} op
               * @returns {boolean}
               */
              (op) => {
                return operations.every((operation) => operation.id !== op.id);
              },
            ),
          );
      dispatch(
        allPathsActions.setIteration({
          scenarioId,
          chains: newChains,
          operations: newOperations,
          picked_chains: response.data.picked_chains,
        }),
      );
    }
  };

  const resetPagination = () => {
    setPage(0);
    setExhausted(false);
    dispatch(
      allPathsActions.setIteration({
        scenarioId,
        chains: [],
        operations: [],
      }),
    );
  };

  /**
   * @param {import('../types/allPaths').AllPathsChain[]} chains
   */
  const pickPath = async (chains) => {
    setPickLoading(true);
    socket.current.send(
      JSON.stringify({
        action: 'select',
        chains: chains.map((chain) => chain.chain_id),
      }),
    );
  };

  const reset = () => {
    resetPagination();
    if (socket.current) {
      socket.current.close();
    }
    beginAllPaths(true);
  };

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
      return;
    }
    let timeout = setTimeout(() => {
      if (getAllPathsFetching) getRequest.current?.abort();
      getNextPage(true);
    }, 1500);
    return () => {
      clearTimeout(timeout);
    };
  }, [sortBy, filters]);

  useEffect(() => {
    beginAllPaths();
    return () => {
      if (socket.current) {
        socket.current.close();
      }
    };
  }, [scenarioId]);

  return {
    startLoading,
    pickLoading,
    chains,
    isLoading,
    exhausted,
    getNextPage,
    resetPagination,
    pickPath,
    reset,
  };
};
