import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchButton, SearchField } from './SearchComponents';
import { selectAllPoints } from '../plotter/plotterSelectors';
import * as searchActions from './searchSlice';
import * as selectedItemsActions from '../selectedItems/selectedItemsSlice';
import { PinPoint } from '../points/PinPoint';
import { ReusableModal } from '../modals/ReusableModal';
/**
 * @typedef {{
 *  value?: string | number,
 *  placeholder?: string,
 *  isSearching?: boolean,
 *  handler?: (value: any) => void,
 *  className?: string,
 *  style?: React.CSSProperties,
 * }} ReduxPointSearchProps
 */

/**
 *
 * @param {ReduxPointSearchProps} props
 * @returns {React.FC<ReduxPointSearchProps>}
 */
export function ReduxPointSearch(props) {
  const dispatch = useDispatch();
  const [isSearching, setIsSearching] = useState(props.isSearching);
  const searchQuery = useSelector((state) => state.search.searchQuery);
  const endSearch = () => {
    setIsSearching(false);
    dispatch(searchActions.queryChanged({ searchQuery: null }));
  };

  return (
    <>
      <SearchButton
        style={props.style}
        className={props.className}
        value={props.value}
        placeholder={props.placeholder}
        handler={props.handler}
        onClick={setIsSearching}
      />
      <ReusableModal
        header="Point Search"
        isOpen={isSearching}
        onClose={endSearch}
      >
        <SearchField
          onChange={(value) =>
            dispatch(searchActions.queryChanged({ searchQuery: value }))
          }
          value={searchQuery || ''}
        />
        <ReduxPointSearchResults
          handler={props.handler}
          setIsSearching={setIsSearching}
        />
      </ReusableModal>
    </>
  );
}

function ReduxPointSearchResults(props) {
  const dispatch = useDispatch();
  const searchResults = useSelector(searchActions.selectFilteredPointIds);
  const pinnedPoints = useSelector((state) => state.points.pinnedPoints);
  // if specific selectionKey and itemType is set in searchSlice,
  // pass that to searchResult to use with dispatch
  const itemType = useSelector((state) => state.search.itemType) || 'point';
  const selectionKey = useSelector((state) => state.search.selectionKey);
  const [selectedResult, _setSelectedResult] = useState(0);
  const selectedResultRef = useRef(selectedResult);
  const searchResultsRef = useRef(searchResults);

  useEffect(() => {
    // keep searchResultsRef up to date with searchResults for access to keybindings
    searchResultsRef.current = searchResults;
  }, [searchResults]);

  const setSelectedResult = (data) => {
    selectedResultRef.current = data;
    _setSelectedResult(data);
  };

  const handleClick = async (id, itemType, selectionKey) => {
    // having to pass handleClick and setIsSearching so far down makes me wonder if it would be better practice to "flatten" this search modal structure into one component
    // pass props to the handler so it gets all information necessary and allows more complex handlers to be passed
    // https://medium.com/geographit/accessing-react-state-in-event-listeners-with-usestate-and-useref-hooks-8cceee73c559
    if (props.handler) {
      await props.handler({ id, itemType, selectionKey });
    } else {
      // default behavior is to select the item clicked:
      dispatch(
        selectedItemsActions.itemClicked({ id, itemType, selectionKey }),
      );
    }

    // dismiss modal: could just do this in response to success?
    props.setIsSearching(false);
    dispatch(searchActions.queryChanged({ searchQuery: null }));
  };

  // set up keybindings for selecting search results
  useEffect(() => {
    const searchKeys = (event) => {
      // tab key
      if (event.keyCode === 9) {
        event.preventDefault();
        setSelectedResult(selectedResultRef.current + 1);
      }

      // enter key
      if (event.keyCode === 13) {
        // handle click of active search result
        event.preventDefault();
        const itemId = searchResultsRef.current[selectedResultRef.current];
        handleClick(itemId, itemType, selectionKey);
      }
    };

    window.addEventListener('keydown', searchKeys);

    return () => {
      window.removeEventListener('keydown', searchKeys);
    };
  }, []);
  // console.log(JSON.stringify(searchResults))
  return (
    <>
      <ul className="list-group list-group-flush">
        {searchResults.map((result, index) => (
          <ReduxPointSearchResult
            handleClick={handleClick}
            key={result}
            selectedResult={index === selectedResult}
            id={result}
            itemType={itemType}
            selectionKey={selectionKey}
            handler={props.handler}
            setIsSearching={props.setIsSearching}
          />
        ))}
      </ul>
      <h6 className="mt-3">Pinned Points</h6>
      <ul className="list-group list-group-flush">
        {pinnedPoints
          .slice(0)
          .reverse()
          .map((result, index) => (
            <ReduxPointSearchResult
              handleClick={handleClick}
              key={result}
              selectedResult={selectedResult === index + searchResults.length}
              id={result}
              itemType={itemType}
              selectionKey={selectionKey}
              handler={props.handler}
              setIsSearchign={props.setIsSearching}
            />
          ))}
      </ul>
    </>
  );
}

function ReduxPointSearchResult(props) {
  const { id, itemType, selectionKey, selectedResult } = props;
  const itemInfo = useSelector(selectAllPoints)[id];

  if (!itemInfo) {
    return null;
  }

  return (
    <li
      className={`list-group-item list-group-item-action ${
        selectedResult ? 'active' : ''
      }`}
      onClick={() => props.handleClick(id, itemType, selectionKey)}
    >
      <small>
        <strong>#{itemInfo.id}</strong>
      </small>
      <h6>
        {itemInfo.loc_id} -{itemInfo.loc_name}
      </h6>
      {itemInfo.capacity_components?.length > 0 && (
        <>
          <div>
            <small>
              <strong>Capacities</strong>
            </small>
          </div>
          {itemInfo.capacity_components.map((cc) => (
            <div key={cc.id}>
              <small>
                <strong>#{cc.id}</strong> {cc.description}
              </small>
            </div>
          ))}
        </>
      )}
      <div className="clearfix">
        <small className="float-start">
          {itemInfo.tsp_name} -{itemInfo.loc_zone_rec}
        </small>
        <small className="float-end">
          {itemInfo.loc_cnty},{itemInfo.loc_st}
          <PinPoint id={id} />
        </small>
      </div>
      {itemInfo.up_dn_name && (
        <small>
          Connects to:
          {itemInfo.up_dn_name}
        </small>
      )}
      {!itemInfo.node && (
        <div className="float-end text-danger">NOT MAPPED</div>
      )}
    </li>
  );
}
