import { useSelector, useDispatch } from 'react-redux';
import { useContext, useEffect } from 'react';
import * as plotterSelectors from '../plotter/plotterSelectors';
import * as pathsActions from './pathsSlice';
import { mapContext } from '../map/EdgeMap';
import { boundingExtent } from 'ol/extent';
import { fromLonLat } from 'ol/proj';

export function ZoomPath() {
  const { map } = useContext(mapContext);
  const dispatch = useDispatch();
  const zoomPathId = useSelector(pathsActions.selectZoomPathId);
  const zoomPath = useSelector((state) => state.paths.paths)[zoomPathId];
  const nodes = useSelector(plotterSelectors.selectAllNodes);
  const zoomPathNodeIds = zoomPath?.path_node_ids;

  useEffect(() => {
    if (!zoomPathId || !zoomPathNodeIds || zoomPathNodeIds.length == 0) {
      return null;
    }

    const pathPositions = zoomPathNodeIds
      .map((nodeId) => [nodes[nodeId]?.lat, nodes[nodeId]?.lng])
      .filter((latlng) => latlng[0] && latlng[1])
      .map(([lat, lon]) => fromLonLat([lon, lat]));

    const pathBounds = boundingExtent(pathPositions);

    if (pathBounds?.length < 4) {
      return null;
    }

    map.getView().fit(pathBounds, {
      padding: [50, 50, 50, 50],
      duration: 1000,
    });
    dispatch(pathsActions.zoomToPath(null));
  }, [zoomPathId]);

  return null;
}
