import { useEffect, useState, useRef, cloneElement } from 'react';

export function TabSelect({ children }) {
  // children should have "active" prop and "onClick" prop
  // probably could be turned into a hook instead

  const [activeIndex, _setActiveIndex] = useState(0);
  // have to use Refs because event listeners don't have access to state after initialization
  const activeIndexRef = useRef(activeIndex);
  const childrenRef = useRef(children);

  const setActiveIndex = (newIndex) => {
    if (newIndex >= children.length) {
      newIndex = 0;
    }
    activeIndexRef.current = newIndex;
    _setActiveIndex(newIndex);
  };

  useEffect(() => {
    // reset when children change
    setActiveIndex(0);
  }, [children]);

  const searchKeys = (event) => {
    // tab key
    if (event.keyCode === 9) {
      event.preventDefault();
      setActiveIndex(activeIndexRef.current + 1);
    }

    // // enter key
    if (event.keyCode === 13) {
      // handle click of active search result
      event.preventDefault();
      childrenRef.current[activeIndexRef.current].props.onClick();

      // const itemId = searchResultsRef.current[selectedResultRef.current]
      // handleClick(itemId,itemType,selectionKey)
    }
  };

  // set up keybindings for selecting search results
  useEffect(() => {
    window.addEventListener('keydown', searchKeys);

    return () => {
      window.removeEventListener('keydown', searchKeys);
    };
  }, [children]);

  if (!children) {
    return null;
  }

  return children.map((child, i) =>
    cloneElement(child, { ...child.props, active: i === activeIndex }, null),
  );
}
