import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios';
import * as pathsActions from '../paths/pathsSlice';
import * as selectedItemsActions from '../selectedItems/selectedItemsSlice';
import { ItemSelector } from '../selectedItems/SelectorComponents';

export function Segmentation() {
  const dispatch = useDispatch();
  const selectedItems = useSelector((state) => state.selectedItems);

  const [searchParams, setSearchParams] = useSearchParams();

  // update state with URL search params?  this is going to cause a loop.  Maybe it only makes sense on load, or maybe there's a history changed event we can listen to from router
  useEffect(() => {
    // could also pass the results through URL unless it gets too large
    const deliveryPoints = searchParams.getAll('deliveryPoints');
    const receiptPoints = searchParams.getAll('receiptPoints');
    dispatch(selectedItemsActions.setState({ deliveryPoints, receiptPoints }));
  }, []);

  // update search params in URL
  useEffect(() => {
    // this seems stupid, would rather update URL in redux thunk associated with selectors
    // const urlEncoded = JSON.stringify(selectedItems)
    // can also use the jsurl library if we need much shorter urls
    setSearchParams(selectedItems);
  }, [selectedItems]);

  // 2. Basic workflow setup setting receiptPoints as first select type
  useEffect(() => {
    // this is setting the first expected user action automatically.
    dispatch(
      selectedItemsActions.currentlySelectingChanged({
        selectionKey: 'receiptPoints',
        multiSelect: 'true',
      }),
    );
  }, []);

  // 5. cleanup when unmounting
  useEffect(
    () => () => {
      // what's the way to send one action that a bunch of slices respond to to clean up their state?
      dispatch(selectedItemsActions.resetState());
      dispatch(pathsActions.resetState());
    },
    [],
  );

  // 4. submit handler
  const handleSubmit = async () => {
    const { receiptPoints } = selectedItems;
    const receiptPointParams = `receipt_points=${receiptPoints.join(
      '&receipt_points=',
    )}`;

    const { deliveryPoints } = selectedItems;
    const deliveryPointParams = `delivery_points=${deliveryPoints.join(
      '&delivery_points=',
    )}`;

    const { constrainedEdges } = selectedItems;
    const constrainedEdgeParams = `constrained_edges=${constrainedEdges.join(
      '&constrained_edges=',
    )}`;

    const studyType = 'segmentation';

    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/path_study/?study_type=${studyType}&${receiptPointParams}&${deliveryPointParams}&${constrainedEdgeParams}`,
    );
    // TODO: error handling
    dispatch(pathsActions.pathDataReceived(res.data));
  };

  // 3. validation - very basic for now
  const submitValidated =
    selectedItems.receiptPoints[0] && selectedItems.deliveryPoints[0];

  // 1. selectors
  return (
    <>
      <ul className="list-group">
        <ItemSelector
          selectionKey="receiptPoints"
          itemType="point"
          multiSelect
          title="Receipt Points"
        />
        <ItemSelector
          selectionKey="deliveryPoints"
          itemType="point"
          multiSelect
          title="Delivery Points"
        />
        <ItemSelector
          selectionKey="constrainedEdges"
          itemType="edge"
          title="Constrained Edges"
          multiSelect
        />
      </ul>
      <button
        onClick={() => handleSubmit()}
        disabled={!submitValidated}
        className="btn btn-primary btn-block w-100"
      >
        Submit
      </button>
    </>
  );
}
