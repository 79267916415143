import * as xlsx from 'xlsx';
import { constraintToString } from './stringUtils';

/**
 *
 * @param {import("../scenarios/scenarioSlice").Scenario} scenario
 * @param {Object<string, import('../services/edgeApi').Edge>} edges
 * @param {Object<string, import('../services/edgeApi').Point>} points
 * @param {Object<string, import('../services/edgeApi').Node>} nodes
 * @returns {xlsx.WorkBook}
 */
export const scenarioToWorkbook = (scenario, edges, points, nodes) => {
  const segConstraintSheet = segConstraintsToSheet(
    scenario.segment_constraints,
    edges,
    points,
    nodes,
  );
  const pointConstraintSheet = pointConstraintsToSheet(
    scenario.point_constraints,
    points,
  );
  const marketSheet = scenarioMarketsToSheet(scenario.markets);
  const contractSheet = contractsToSheet(scenario.contracts);
  const wb = xlsx.utils.book_new();
  xlsx.utils.book_append_sheet(wb, segConstraintSheet, 'Segment Constraints');
  xlsx.utils.book_append_sheet(wb, pointConstraintSheet, 'Point Constraints');
  xlsx.utils.book_append_sheet(wb, marketSheet, 'Markets');
  xlsx.utils.book_append_sheet(wb, contractSheet, 'Contracts');
  return wb;
};

/**
 *
 * @param {import('../scenarios/scenarioSlice').SegmentConstraint[]} constraints
 * @param {Object<string, import('../services/edgeApi').Edge>} edges
 * @param {Object<string, import('../services/edgeApi').Point>} points
 * @param {Object<string, import('../services/edgeApi').Node>} nodes
 */
export const segConstraintsToSheet = (constraints, edges, points, nodes) => {
  return xlsx.utils.json_to_sheet(
    constraints.map((constraint) => {
      const edge = edges[constraint.edge];
      const sourceNode = nodes[edge?.start_node];
      const targetNode = nodes[edge?.end_node];
      const source = points[sourceNode?.points[0]];
      const target = points[targetNode?.points[0]];
      const edgeName = constraintToString(source, target, constraint);
      return {
        id: constraint.id,
        segment: constraint.edge,
        segment_name: edgeName,
        tsp: edge?.tsp_short_name || edge?.tsp_name || '',
        type: constraint.type,
        source_type: constraint.source_type,
        source_id: constraint.source_id,
        cuts_at_priority: constraint.cuts_at_priority,
        flow_direction: constraint.flow_direction,
        constraint_factor: constraint.constraint_factor,
        max_volume: constraint.max_volume,
        description: constraint.description,
        short_description: constraint.short_description,
        capacity_component_id: constraint.capacity_component_id,
        notice_component_id: constraint.notice_component_id,
        // component_key: constraint.component_key,
        pipeline: constraint.pipeline,
      };
    }),
  );
};

/**
 *
 * @param {import('../scenarios/scenarioSlice').PointConstraint[]} constraints
 * @param {Object<string, import('../services/edgeApi').Point>} points
 */
export const pointConstraintsToSheet = (constraints, points) => {
  return xlsx.utils.json_to_sheet(
    constraints.map((constraint) => {
      const point = points[constraint.point];
      const tsp = point?.tsp_name || '';
      return {
        id: constraint.id,
        point: constraint.point,
        point_name: point?.loc_name || point?.id.toString(),
        tsp: tsp,
        type: constraint.type,
        source_type: constraint.source_type,
        source_id: constraint.source_id,
        cuts_at_priority: constraint.cuts_at_priority,
        flow_direction: constraint.flow_direction,
        constraint_factor: constraint.constraint_factor,
        max_volume: constraint.max_volume,
        description: constraint.description,
        short_description: constraint.short_description,
        capacity_component_id: constraint.capacity_component_id,
        notice_component_id: constraint.notice_component_id,
        // component_key: constraint.component_key,
        pipeline: constraint.pipeline,
      };
    }),
  );
};

/**
 *
 * @param {import('../scenarios/scenarioSlice').Market[]} markets
 * @returns
 */
export const scenarioMarketsToSheet = (markets) => {
  return xlsx.utils.json_to_sheet(markets);
};

/**
 *
 * @param {import('../scenarios/scenarioSlice').Contract[]} contracts
 * @returns
 */
export const contractsToSheet = (contracts) => {
  return xlsx.utils.json_to_sheet(contracts);
};
