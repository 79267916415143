import { createSlice, createSelector } from '@reduxjs/toolkit';
import { toast } from 'react-hot-toast';
import * as plotterSelectors from '../plotter/plotterSelectors';
import { plotterActions } from '../plotter/plotterSlice';

// "selected" is not a great nomenclature because of conflicts with redux "selectors"
// should we have selectedPointsSlice / selectedPointsSlice / selectededgesSlice, etc?
const initialState = {
  // what's the best way to handle single vs multi select state management?
  // should "active point" and "new point" be brought into this slice, and how do we properly manage them
  // currentlySelecting: {
  //   selectionKey: null,
  //   itemType: null,
  //   multiSelect: null,
  //   // map needs to know if it's creatable but ItemSelector could potentially handle editable, etc in component state
  //   isEditable: null,
  //   isCreatable: null,
  //   isDeletable: null
  // },
  currentlySelecting: {
    // edge:
    // node:
    // point:
  },
  // thinking about changing to this format so multiple types of things can be selected at the same time?
  // instead of sending complex state from dispatch, probably dispatch({itemType:'edge', selectionKey: 'constrainedEdges'})
  // and default for everthing else to be false, but can send as true (multiSelect, isEditable, etc.)

  // then we can have multiple things being selected at once, like edges and current edge start node
  // check currentlySelecting.edges to see if we are selecting edges?
  // then get the selection key from there?
  // not sure the best way to do it all

  // currentlySelecting: {
  //   edges: {
  //     selectionKey: 'constrainedEdges',
  //     multiSelect: true,
  //     isEditable: false
  //   },
  //   nodes: {
  //     selectionKey: 'startNode',
  //     multiSelect: false,
  //   }
  // }
  // each of these sets of items should have the item type associated with it somehow
  // for instance
  // constrainedEdges:{itemType: 'edge', items: []}
  // also these probably shouldn't have to be initialized at all

  activePoint: null,
  receiptPoints: [],
  deliveryPoints: [],
  constrainedEdges: [],
  explore: [],
  buildNodeId: [],
  editPointId: [],
  edge: [],
  edgeStartNode: [],
  edgeEndNode: [],
  explorePointId: [],
  pricePointId: [],
  // receiptPoint:null,
  // deliveryPoint:null,
};

export const selectedItemsSlice = createSlice({
  name: 'selectedItems',
  initialState,

  reducers: {
    setState: (state, action) =>
      // action.payload()
      // update just the keys and values that come in from the URL?
      ({ ...state, ...action.payload }),
    resetState: (_state) => initialState,
    singlePointSelected: (_state, _action) => null,
    currentlySelectingChanged: (state, action) => ({
      ...state,
      currentlySelecting: {
        // send overwrite flag true to remove any other currently selecting items
        ...(action.payload.overwrite ? {} : { ...state.currentlySelecting }),
        [action.payload.itemType]: {
          selectionKey: action.payload.selectionKey,
          multiSelect: action.payload.multiSelect,
          isEditable: action.payload.isEditable,
          isCreatable: action.payload.isCreatable,
          isDeletable: action.payload.isDeletable,
          preventToggle: action.payload.preventToggle,
          disabled: action.payload.disabled,
        },
      },
    }),
    // might want to change this to itemSelected? to account for selecting in different ways than clicking the map
    itemClicked: (state, action) => {
      const {
        id,
        itemType,
        selectionKey: overrideSelectionKey,
      } = action.payload;
      const clickedItemId = String(id);
      // send a selectionKey if you need to specify what is being selected, otherwise it will use currentlySelecting
      const selectionKey =
        overrideSelectionKey ||
        state.currentlySelecting[itemType]?.selectionKey;
      const currentSelection = state[selectionKey];

      // will this be false for an empty array? if so declare currentSelection differently
      if (!currentSelection) {
        // if the selection doesn't match, don't do anything to the state
        return state;
      }

      // console.log("currentlySelecting: " + JSON.stringify(currentlySelectingItem))

      // set preventToggle to true to prevent click from removing item if already selected
      const preventToggle =
        action.payload.preventToggle ||
        state.currentlySelecting[itemType]?.preventToggle;
      const multiSelect =
        action.payload.multiSelect ||
        state.currentlySelecting[itemType]?.multiSelect;
      // set disabled to prevent adding and removing selections
      const disabled =
        action.payload.disabled || state.currentlySelecting[itemType]?.disabled;

      if (disabled) {
        // if disabled, don't do anything
        return state;
      }

      if (currentSelection.includes(clickedItemId) && !preventToggle) {
        // if it's already in the set, remove it from the set, except for 'new'
        if (clickedItemId !== 'new') {
          return {
            ...state,
            [selectionKey]: currentSelection.filter(
              (itemId) => itemId != clickedItemId,
            ),
          };
        }
        // if id is new don't do anything
        return state;
      }
      // else add it to the set
      // single select mode
      if (multiSelect) {
        // multi select mode
        return {
          ...state,
          [selectionKey]: [...currentSelection, clickedItemId],
        };
      }
      // single select mode
      return { ...state, [selectionKey]: [clickedItemId] };
    },

    // for instantiating a group of preselected objects
    setSelectedObjects: (state, action) => {
      const { selectionKey } = action.payload;
      const objectIds = action.payload.ids;
      return { ...state, [selectionKey]: objectIds };
    },

    itemRemoved: (state, action) => {
      const pointToRemove = action.payload.pointId;
      const { selectionKey } = action.payload;
      const selection = state[selectionKey];
      return {
        ...state,
        [selectionKey]: selection.filter((pointId) => pointId != pointToRemove),
      };
    },

    itemIdUpdated: (state, action) => {
      const { oldId } = action.payload;
      const { newId } = action.payload;
      const { itemType } = action.payload;
      // get selection sets by item itemType - should be part of the selection sets but not going to change it right now
      if (itemType === 'node') {
        // should get all selection sets based on itemType but that's not how they are set up
        // replace oldId with newId
        const newSet = state.node.map((nodeId) =>
          nodeId === oldId ? newId : nodeId,
        );
        return { ...state, node: newSet };
      }
    },
  },
});

export const markerClicked = (clickedNodeId) => (dispatch, getState) => {
  // had to move this to a thunk to get access to plotter state for point and node data
  const selectedItemsState = getState().selectedItems;
  // what the mode is currently selecting (deliveryPoints, receiptPoints, etc.)
  const { currentlySelecting } = selectedItemsState;
  // what's already selected (deliveryPoints, etc.)
  // const node = getState().plotter.nodes[clickedNodeId]
  const node = plotterSelectors.selectAllNodes(getState())[clickedNodeId];

  if (Object.keys(currentlySelecting).includes('node')) {
    dispatch(itemClicked({ id: clickedNodeId, itemType: 'node' }));
  } else if (Object.keys(currentlySelecting).includes('point')) {
    if (node.points.length === 0) {
      toast.error('No points at this node');
    } else if (node.points.length === 1) {
      // if the node only has one point, just use that point
      const clickedPointId = node.points[0];
      dispatch(itemClicked({ id: clickedPointId, itemType: 'point' }));
    } else {
      // TODO: if there are multiple points in the node, show a modal to allow them to select the specific point

      const clickedPointId = node.points[0];
      dispatch(itemClicked({ id: clickedPointId, itemType: 'point' }));
    }
  } else {
    console.warn('not selecting points or nodes');
  }
};

export const mapClicked = (location) => (dispatch, getState) => {
  // this is sending over to plotterSlice to add the new node.  Best would probably be to have a separate slice for editing local data instead of modifying the API data
  // if selecting node and isCreatable
  const currentlySelectingNode =
    getState().selectedItems.currentlySelecting.node;
  // this should check that currentlySelectingNode exists and is creatable
  if (currentlySelectingNode?.isCreatable) {
    // create a new node
    dispatch(plotterActions.addNewNode(location));
    // select the new node
    dispatch(itemClicked({ itemType: 'node', id: 'new' }));
  }
  // if currently editing a node? for movement
};

export const {
  setState,
  resetState,
  allPathsClick,
  currentlySelectingChanged,
  itemClicked,
  itemRemoved,
  itemIdUpdated,
  setSelectedObjects,
} = selectedItemsSlice.actions;

// ------------ Selectors -------------- //

export const selectSelectedPointIds = (state) =>
  // combine the two.  Might really want multiple selectors, one for each set
  [...state.selectedItems.receiptPoints, ...state.selectedItems.deliveryPoints];

export const selectSelectedNodeIds = createSelector(
  selectSelectedPointIds,
  (state) => state.plotter.points,
  (selectedPointIds, allPoints) =>
    // console.log(`selectedPointIds ${selectedPointIds}`)
    selectedPointIds.map((selectedPointId) => allPoints[selectedPointId]?.node),
);

export const selectActivePoint = createSelector(
  [
    (state) => state.selectedItems.explorePointId,
    plotterSelectors.selectAllPoints,
  ],
  (selectionKey, points) => points[selectionKey],
);

// these selectors names are a little convoluted
export const selectActivelySelectedNodeIds = createSelector(
  (state) => state.selectedItems,
  // state => state.plotter.points,
  plotterSelectors.selectAllPoints, // have to combine localNodes
  (selectedItems, allPoints) => {
    // TODO: this is hacky and can probably be set up more cleanly. Also do we want to combine the two outputs
    let selectionKey;
    if (selectedItems.currentlySelecting.point) {
      selectionKey = selectedItems.currentlySelecting.point.selectionKey;
      // console.log('selecting points'+JSON.stringify(selectedItems[selectionKey].map((selectedPointId)=> allPoints[selectedPointId]?.node)))
      // have to use Number here becuase point.node returns a string.  What is the best way to avoid this string / number potential issue
      return selectedItems[selectionKey].map((selectedPointId) =>
        String(allPoints[selectedPointId]?.node),
      );
    }
    if (selectedItems.currentlySelecting.node) {
      selectionKey = selectedItems.currentlySelecting.node.selectionKey;
      return selectedItems[selectionKey];
    }
    return [];
    // return selectedItems[selectionKey]
  },
);

export default selectedItemsSlice.reducer;
