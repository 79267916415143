import React from 'react';

/**
 *
 * @param {React.HTMLProps<HTMLDivElement> & {
 *  children: React.ReactNode | React.ReactNode[]
 *  tabName: string
 *  contextKey: string
 *  tabOrder?: number
 *  activeTab?: boolean
 * }} props
 */
export function BelowMapTab({
  children,
  tabName: _tabName,
  contextKey: _contextKey,
  tabOrder: _tabOrder,
  activeTab: _activeTab,
  ...props
}) {
  return <div {...props}>{children}</div>;
}
