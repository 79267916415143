import React, {
  useState,
  useEffect,
  useMemo,
  useImperativeHandle,
  forwardRef,
} from 'react';
import {
  CheckIcon,
  CloseIcon,
  FilterIcon,
  TrashIcon,
} from '@iconicicons/react';
import { getDiff } from '../utils/objectUtils';
import { EditFeedbackInput } from './EditFeedbackInput';
import { VirtualizedList } from './VirtualizedList';
import { useClientFiltering } from './hooks';
import { colors } from '../utils/colors';

const DEFAULT_ROW_HEIGHT = 48;
const CHECKBOX_COLUMN_WIDTH = 0.05;
const ACTION_COLUMN_WIDTH = 0.15;
const NEW_ITEM_KEY = 'new';

const toPercentage = (value) => `${value * 100}%`;

/**
 * @template {Object} T
 * @typedef {{
 *  data: T,
 *  editedData: T,
 *  value: string | number,
 *  placeholder: string,
 *  dirty: boolean,
 *  saved: boolean,
 *  onChange: (value: string | number, key?: string) => void,
 * }} RenderInputParameters
 */

/**
 * @template {Object} T
 * @typedef {{
 *  data: T,
 *  value: string | number,
 * }} RenderCellParameters
 */

/**
 *
 * @template {Object} T
 * @typedef {{
 *  title: import('react').ReactNode,
 *  type: 'text' | 'number' | 'select',
 *  placeholder: string | number,
 *  isEditable: boolean,
 *  allowFiltering: boolean,
 *  informedOnCreation?: boolean,
 *  key: keyof T,
 *  selectOptions?: Array<{
 *    value: string | number,
 *    label: string | number
 *  }>,
 *  default?: string | number,
 *  setFunction?: (value: string | number) => string | number,
 *  displayFunction?: (value: string | number) => string | number,
 *  renderCell?: (renderParameters: RenderCellParameters<T>) => React.ReactNode,
 *  renderInput?: (renderParameters: RenderInputParameters<T>) => React.ReactNode,
 *  width?: number,
 * }} EditableTableColumn
 */

/**
 * @template {Object} T
 * @param {EditableTableColumn<T>[]} columns
 * @param {boolean} isEditing
 * @returns {{[key: string]: import('react').CSSProperties['width']}}
 */
const resolveColumnsWidths = (columns, isEditing) => {
  const checkboxColumn = isEditing ? CHECKBOX_COLUMN_WIDTH : 0;
  const actionsColumn = isEditing ? ACTION_COLUMN_WIDTH : 0;
  const total = 1 - checkboxColumn - actionsColumn;
  const fixedWidthColumns = columns.filter(
    (col) => typeof col.width === 'number',
  );
  const remainingColumns = columns.filter(
    (col) => typeof col.width !== 'number',
  );
  const fixedWidth = fixedWidthColumns.reduce((acc, col) => acc + col.width, 0);
  const remainingWidth = total - fixedWidth;
  const remainingWidthPerColumn = remainingWidth / remainingColumns.length;

  return columns.reduce((acc, col) => {
    if (typeof col.width === 'number') {
      acc[col.key] = toPercentage(col.width * total);
    } else {
      acc[col.key] = toPercentage(remainingWidthPerColumn);
    }
    return acc;
  }, {});
};

/**
 * @template {Object} T
 * @typedef {{ getFilteredData: () => T[], }} EditableTableRef<T>
 */

/**
 *
 * @template {Object} T
 * @typedef {{
 *  isEditing: boolean,
 *  isEditable: boolean,
 *  allowSelection?: boolean,
 *  isAdding: boolean,
 *  columns: Array<EditableTableColumn<T>>,
 *  data: Array<T>,
 *  belowHeader?: React.ReactNode,
 *  emptyText?: string,
 *  getRowStyle?: (row: T) => React.CSSProperties,
 *  keyExtractor: (row: T) => string,
 *  onSelect: (id: string, checked: boolean) => void,
 *  onSaveItem: (original: T, edited: T) => void,
 *  onDeleteItem: (id: string | number) => void,
 *  onCancelCreation: () => void,
 *  onSaveBulkEdit: (selection: Array<string | number>, editData: Partial<T>) => void,
 *  onBulkRemove: (ids: Array<string | number>) => void,
 *  actions?: Array<'SAVE' | 'CANCEL' | 'DELETE'>,
 *  virtualized?: boolean,
 *  visible?: boolean,
 *  fullScreen?: boolean,
 *  rowHeight?: number,
 * }} EditableTableProps<T>
 * @param {EditableTableProps<T>} props
 * @param {React.ForwardedRef<EditableTableRef<T>>} ref
 * @returns {React.FC<EditableTableProps<T>>}
 */
const _EditableTable = (
  {
    columns,
    data = [],
    belowHeader,
    actions = ['SAVE', 'CANCEL', 'DELETE'],
    virtualized = false,
    visible = true,
    fullScreen = false,
    rowHeight = DEFAULT_ROW_HEIGHT,
    isEditable,
    allowSelection = true,
    keyExtractor,
    onSelect,
    onSaveItem,
    onDeleteItem,
    onCancelCreation,
    onSaveBulkEdit,
    onBulkRemove,
    ...props
  },
  ref,
) => {
  const defaultData = useMemo(() => {
    return columns.reduce((data, col) => {
      const nullValue = col.type === 'select' ? 'placeholder' : null;
      data[col.key] = col.default ?? nullValue;
      return data;
    }, {});
  }, [columns]);

  const [filter, setFilter] = useState({});
  const [editedItems, setEditedItems] = useState({
    [NEW_ITEM_KEY]: defaultData,
  });
  const [savedFields, setSavedFields] = useState({});
  const [selection, setSelection] = useState({});
  const [bulkEditSavedData, setBulkEditSavedData] = useState(defaultData);
  const [bulkEditData, setBulkEditData] = useState(defaultData);

  /** @type {{[key: keyof T]: (val: string) => string}} */
  const mapFunctions = useMemo(
    () =>
      columns
        .filter((col) => col.displayFunction)
        .reduce((acc, col) => {
          acc[col.key] = col.displayFunction;
          return acc;
        }, {}),
    [columns],
  );

  const filteredData = useClientFiltering(data, filter, mapFunctions);
  const parentRef = React.useRef(null);

  const isEditing = props.isEditing && isEditable;
  const isAdding = props.isAdding && isEditing;

  const useFixedLayout =
    virtualized || columns.some((col) => Boolean(col.width));
  const columnsWidths = useMemo(() => {
    return useFixedLayout
      ? resolveColumnsWidths(columns, isEditing)
      : columns.reduce((acc, col) => {
          acc[col.key] = 'auto';
          return acc;
        }, {});
  }, [columns, isEditing]);

  useEffect(() => {
    const belowMapContextElement = document.getElementById('below-map-context');
    parentRef.current = belowMapContextElement;
    setSavedFields((prev) =>
      data.reduce((acc, item) => {
        const key = keyExtractor(item);
        acc[key] = {};
        return acc;
      }, prev),
    );
  }, []);

  useImperativeHandle(
    ref,
    () => {
      return {
        getFilteredData: () => {
          return [...filteredData];
        },
      };
    },
    [filteredData],
  );

  useEffect(() => {
    setEditedItems((prev) =>
      data.reduce((acc, item) => {
        const key = keyExtractor(item);
        if (!acc[key]) {
          acc[key] = item;
        }
        return acc;
      }, prev),
    );
  }, [data]);

  const setEditedItem = (key, setter) => {
    setEditedItems((prev) => {
      const value = typeof setter === 'function' ? setter(prev[key]) : setter;
      return { ...prev, [key]: value };
    });
  };

  const setSavedField = (key, fields) => {
    const newSavedFields = fields.reduce((acc, field) => {
      acc[field] = true;
      return acc;
    }, savedFields[key] || {});
    setSavedFields((prev) => ({ ...prev, [key]: newSavedFields }));
  };

  const setBulkEditField = (field, value) => {
    setBulkEditData((prev) => ({ ...prev, [field]: value }));
  };

  const saveBulkEdit = () => {
    const updateData = getDiff(bulkEditData, bulkEditSavedData);
    setBulkEditSavedData(bulkEditData);
    onSaveBulkEdit?.(
      Object.keys(selection).filter((id) => selection[id]),
      updateData,
    );
  };

  const cancelBulkEdit = () => {
    setBulkEditData(bulkEditSavedData);
    setSelection({});
  };

  const bulkRemove = () => {
    onBulkRemove?.(Object.keys(selection).filter((id) => selection[id]));
    setSelection({});
  };

  const toggleSelection = (checked) => {
    const nextState = filteredData.reduce(
      (acc, cur) => ({ ...acc, [cur.id]: checked }),
      {},
    );

    setSelection(() => nextState);
  };

  const usingBulkSelection = useMemo(
    () => Object.values(selection).some((v) => v),
    [selection],
  );

  const renderRow = (row, index, style) => {
    const key = keyExtractor(row);
    const itemStyle = props.getRowStyle ? props.getRowStyle(row) : {};
    return (
      <EditableTableRow
        style={{
          ...itemStyle,
          ...style,
          height: rowHeight,
        }}
        allowSelection={allowSelection}
        columnsWidths={columnsWidths}
        key={key}
        rowKey={key}
        isEditing={isEditing}
        isAdding={isAdding}
        actions={actions}
        rowData={row}
        editedData={editedItems[key]}
        setEditedData={(setter) => setEditedItem(key, setter)}
        savedFields={savedFields[key]}
        setSavedFields={(fields) => setSavedField(key, fields)}
        columns={columns}
        selection={selection}
        onSelect={(id, checked) => {
          setSelection((prev) => ({
            ...prev,
            [id]: checked,
          }));
          typeof onSelect?.(id, checked);
        }}
        onSaveItem={onSaveItem}
        onDeleteItem={onDeleteItem}
      />
    );
  };

  const tableLayout = useFixedLayout ? 'fixed' : 'auto';

  return (
    <>
      <table
        className="table"
        style={{
          // minWidth: '100%',
          // maxWidth: 'none',
          width: 'max-content',
          minWidth: '100%',
          ...(virtualized
            ? {
                position: 'relative',
                tableLayout,
              }
            : {}),
        }}
      >
        <thead>
          {isEditing && usingBulkSelection && (
            <tr className="form-group">
              {allowSelection && (
                <th style={{ width: toPercentage(CHECKBOX_COLUMN_WIDTH) }}>
                  {/* Empty column to make up space for the checkbox, which is not present in the bulk edit row */}
                </th>
              )}
              {columns.map((column) => {
                const cellStyle = {
                  width: columnsWidths[column.key],
                };
                const setFunction =
                  typeof column.setFunction === 'function'
                    ? column.setFunction
                    : (value) => value;
                const displayFunction =
                  typeof column.displayFunction === 'function'
                    ? column.displayFunction
                    : (value) => value;
                const value = displayFunction(bulkEditData[column.key]) || '';
                const onChange = (e) =>
                  setBulkEditField(column.key, setFunction(e.target.value));

                if (column.isEditable === false) {
                  return (
                    <th key={`${column.key}-bulk-edit`} style={cellStyle}>
                      {column.title}
                    </th>
                  );
                }

                if (
                  column.renderInput &&
                  typeof column.renderInput === 'function'
                ) {
                  return (
                    <td key={`${column.key}-bulk-edit`} style={cellStyle}>
                      {column.renderInput({
                        data: bulkEditData,
                        value,
                        placeholder: column.placeholder || column.title,
                        dirty:
                          bulkEditData[column.key] !==
                          bulkEditSavedData[column.key],
                        onChange: (value, key = column.key) =>
                          setBulkEditField(key, setFunction(value)),
                      })}
                    </td>
                  );
                }

                return (
                  <td key={`${column.key}-bulk-edit`} style={cellStyle}>
                    {column.type === 'select' ? (
                      <EditFeedbackInput
                        className="form-select form-select-sm"
                        type={column.type}
                        name={column.key}
                        value={value}
                        onChange={onChange}
                        dirty={
                          bulkEditData[column.key] !==
                          bulkEditSavedData[column.key]
                        }
                      >
                        <option value="placeholder" disabled>
                          {column.placeholder}
                        </option>
                        {column.selectOptions.map((option) => (
                          <option
                            key={option.value.toString()}
                            value={option.value}
                          >
                            {option.label}
                          </option>
                        ))}
                      </EditFeedbackInput>
                    ) : (
                      <EditFeedbackInput
                        className="form-control form-control-sm"
                        type={column.type || 'text'}
                        placeholder={column.placeholder || column.title}
                        name={column.key}
                        value={value}
                        onChange={onChange}
                        dirty={
                          bulkEditData[column.key] !==
                          bulkEditSavedData[column.key]
                        }
                      />
                    )}
                  </td>
                );
              })}
              {actions.length > 0 && (
                <td style={{ width: toPercentage(ACTION_COLUMN_WIDTH) }}>
                  <div className="d-flex justify-content-around">
                    {actions.includes('SAVE') && (
                      <CheckIcon
                        color="green"
                        style={{ cursor: 'pointer' }}
                        onClick={saveBulkEdit}
                      />
                    )}
                    {actions.includes('CANCEL') && (
                      <CloseIcon
                        color="red"
                        style={{ cursor: 'pointer' }}
                        onClick={cancelBulkEdit}
                      />
                    )}
                    {actions.includes('DELETE') && (
                      <TrashIcon
                        color="red"
                        style={{ cursor: 'pointer' }}
                        onClick={bulkRemove}
                      />
                    )}
                  </div>
                </td>
              )}
            </tr>
          )}

          <tr>
            {isEditing && allowSelection && (
              <th
                scope="col"
                style={{
                  width: toPercentage(CHECKBOX_COLUMN_WIDTH),
                }}
              >
                <div className="form-check d-flex justify-content-center">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={(e) => toggleSelection(e.target.checked)}
                  />
                </div>
              </th>
            )}
            {columns.map((column) => (
              <th
                key={`${column.key}-header`}
                scope="col"
                style={{
                  width: columnsWidths[column.key],
                }}
              >
                {column.title}
                {column.allowFiltering && (
                  <>
                    <FilterIcon
                      data-bs-toggle="collapse"
                      href={`#${column.key}-filter`}
                      role="button"
                      aria-expanded="false"
                      aria-controls={`#${column.key}-filter`}
                      color={filter[column.key] ? colors.primaryBlue : 'grey'}
                    />
                    <div className="collapse" id={`${column.key}-filter`}>
                      <input
                        placeholder={`Filter ${column.title}`}
                        className="d-flex form-control form-control-sm"
                        onChange={(e) => {
                          if (column.allowFiltering) {
                            setFilter((prev) => ({
                              ...prev,
                              [column.key]: e.target.value,
                            }));
                          }
                        }}
                      ></input>
                    </div>
                  </>
                )}
              </th>
            ))}
            {isEditing && actions?.length > 0 && (
              <th
                scope="col text-center"
                style={{ width: toPercentage(ACTION_COLUMN_WIDTH) }}
              >
                Actions
              </th>
            )}
          </tr>
          {belowHeader}
          {isAdding && (
            <EditableTableRow
              columnsWidths={columnsWidths}
              key={NEW_ITEM_KEY}
              rowKey={NEW_ITEM_KEY}
              allowSelection={allowSelection}
              isEditing
              isNew
              actions={actions}
              rowData={defaultData}
              editedData={editedItems[NEW_ITEM_KEY]}
              setEditedData={(setter) => setEditedItem(NEW_ITEM_KEY, setter)}
              setSavedFields={(fields) => setSavedField(NEW_ITEM_KEY, fields)}
              columns={columns}
              selection={selection}
              onSaveItem={onSaveItem}
              onDeleteItem={onCancelCreation}
            />
          )}
        </thead>
        <tbody
          style={
            virtualized
              ? {
                  position: 'relative',
                }
              : {}
          }
        >
          {virtualized ? (
            <VirtualizedList
              visible={visible}
              items={filteredData}
              itemHeight={rowHeight}
              height={fullScreen ? 1080 : 400}
              batchOffset={3}
              parentRef={parentRef}
              fillerElement={({ height }) => <tr style={{ height }}></tr>}
              renderItem={(row, index, style) =>
                renderRow(row, index, {
                  display: 'table',
                  tableLayout,
                  ...style,
                })
              }
            />
          ) : (
            filteredData.map((row, index) => renderRow(row, index, {}))
          )}
        </tbody>
      </table>
      {data.length === 0 && (
        <p className="lead text-center">
          {props.emptyText || 'No data to display'}
        </p>
      )}
    </>
  );
};
export const EditableTable = forwardRef(_EditableTable);

/**
 * @template {Object} T
 * @typedef {{
 *  rowKey: string,
 *  allowSelection: boolean,
 *  isEditing: boolean,
 *  isNew: boolean,
 *  rowData: T,
 *  editedData: T,
 *  setEditedData: (setter: T | (prev: T) => T) => void,
 *  savedFields: {[key: keyof T]: boolean},
 *  setSavedFields: (fields: Array<keyof T>) => void,
 *  columns: Array<EditableTableColumn>,
 *  columnsWidths: {[key: string]: import('react').CSSProperties['width']},
 *  style: React.CSSProperties,
 *  onSelect: (id: string, checked: boolean) => void,
 *  selection: {[id: string | number]: boolean}
 *  onSaveItem: (original: T, edited: T) => void,
 *  onDeleteItem: (id: string | number) => void,
 *  actions: Array<'SAVE' | 'CANCEL' | 'DELETE'>,
 * }} EditableTableRowProps<T>
 * @param {EditableTableRowProps<T>} props
 * @returns {React.FC<EditableTableRowProps<T>>}
 */
export function EditableTableRow({
  rowKey,
  allowSelection,
  isEditing,
  isNew,
  rowData,
  editedData = {},
  setEditedData,
  savedFields = {},
  setSavedFields,
  columns,
  style,
  columnsWidths,
  selection,
  actions,
  onSelect,
  onSaveItem,
  onDeleteItem,
}) {
  const [data, setData] = useState({ ...rowData });

  const getValue = (column, data, plain = false) => {
    const value = data[column.key] ?? column.default;

    if (plain && typeof column.displayFunction === 'function') {
      return column.displayFunction(value);
    }
    if (column.type === 'select') {
      return (
        column.selectOptions.find((option) => option.value === value)?.value ||
        'placeholder'
      );
    }
    return value;
  };

  const _handleChange = (value, name) => {
    const column = columns.find((column) => column.key === name);
    if (typeof column?.setFunction === 'function') {
      setEditedData((prev) => ({
        ...prev,
        [name]: column.setFunction(value),
      }));
    } else {
      setEditedData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleChange = (evt) => {
    const value =
      evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
    const { name } = evt.target;
    _handleChange(value, name);
  };

  const handleSubmit = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();

    if (isNew) {
      onSaveItem?.(rowData, editedData);
      setData({});
      setEditedData({});
      return;
    }
    const editedFields = Object.keys(getDiff(editedData, data));
    setData(editedData);
    onSaveItem?.(rowData, editedData);
    setSavedFields(editedFields);
  };

  /**
   * @template {Object} T
   * @param {EditableTableColumn<T>} column
   * @param {T} data
   * @returns {React.ReactNode}
   */
  const renderPlainCell = (column, data) => {
    const value = getValue(column, data, true);
    const cellStyle = {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: columnsWidths[column.key],
    };
    if (column.renderCell && typeof column.renderCell === 'function') {
      return (
        <td key={`${rowKey}-${column.key}`} style={cellStyle}>
          {column.renderCell({ data, value })}
        </td>
      );
    }
    return (
      <td key={`${rowKey}-${column.key}`} style={cellStyle}>
        {value}
      </td>
    );
  };

  /**
   * @template {Object} T
   * @param {EditableTableColumn<T>} column
   * @param {T} data
   * @returns {React.ReactNode}
   */
  const renderEditionCell = (column, data) => {
    const value = getValue(column, {
      ...editedData,
      [column.key]: editedData[column.key] ?? column.default,
    });
    const cellStyle = {
      width: columnsWidths[column.key],
    };
    if (column.renderInput && typeof column.renderInput === 'function') {
      return (
        <td key={`${rowKey}-${column.key}`} style={cellStyle}>
          {column.renderInput({
            data,
            editedData,
            value,
            placeholder: column.placeholder || column.title,
            dirty: editedData[column.key] !== data[column.key],
            savedFields: savedFields[column.key],
            onChange: (value, key = column.key) => {
              _handleChange(value, key);
            },
          })}
        </td>
      );
    }
    return (
      <td key={`${rowKey}-${column.key}`} style={cellStyle}>
        {column.type === 'select' ? (
          <EditFeedbackInput
            className="form-select form-select-sm"
            type={column.type}
            name={column.key}
            value={value}
            onChange={handleChange}
            dirty={editedData[column.key] !== data[column.key]}
            saved={savedFields[column.key]}
          >
            <option value="placeholder" disabled>
              {column.placeholder}
            </option>
            {column.selectOptions.map((option) => (
              <option key={option.value.toString()} value={option.value}>
                {option.label}
              </option>
            ))}
          </EditFeedbackInput>
        ) : (
          <EditFeedbackInput
            className="form-control form-control-sm"
            type={column.type || 'text'}
            placeholder={column.placeholder || column.title}
            title={value || column.placeholder}
            name={column.key}
            value={value}
            onChange={handleChange}
            dirty={editedData[column.key] !== data[column.key]}
            saved={savedFields[column.key]}
            min={column.min}
            max={column.max}
          />
        )}
      </td>
    );
  };

  const handleDelete = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    onDeleteItem?.(rowData.id);
  };

  if (isEditing === false) {
    return (
      <tr key={rowKey} style={style}>
        {columns.map((column) => renderPlainCell(column, data))}
      </tr>
    );
  }

  return (
    <tr key={rowKey} className={isNew ? 'table-success' : ''} style={style}>
      {allowSelection && (
        <td style={{ width: toPercentage(CHECKBOX_COLUMN_WIDTH) }}>
          <div className="form-check d-flex justify-content-center">
            <input
              className="form-check-input"
              type="checkbox"
              checked={Boolean(selection[rowData.id])}
              onChange={(e) => onSelect?.(rowData.id, e.target.checked)}
            />
          </div>
        </td>
      )}
      {columns.map((column) => {
        if (
          column.isEditable === false &&
          (isNew !== true || column.informedOnCreation !== true)
        ) {
          return renderPlainCell(column, data);
        }
        return renderEditionCell(column, data);
      })}
      {actions?.length > 0 && (
        <td style={{ width: toPercentage(ACTION_COLUMN_WIDTH) }}>
          <div className="d-flex justify-content-around">
            {actions.includes('SAVE') && (
              <CheckIcon
                color="green"
                style={{ cursor: 'pointer' }}
                onClick={handleSubmit}
              />
            )}
            {actions.includes('CANCEL') && (
              <CloseIcon
                color="red"
                style={{ cursor: 'pointer' }}
                onClick={() => setEditedData(data)}
              />
            )}
            {actions.includes('DELETE') && (
              <TrashIcon
                color="red"
                style={{ cursor: 'pointer' }}
                onClick={handleDelete}
              />
            )}
          </div>
        </td>
      )}
    </tr>
  );
}
