import React, { createContext, useState, useContext, useEffect } from 'react';
// this is all so close to BelowMapContext, should we create a reusable context?  Or possibly one big context with keys for certain display locations? - basically just recreating redux but with components
const MapInjectContext = createContext();

function MapInjectProvider(props) {
  const { children } = props;
  const [components, setComponents] = useState({});

  const addComponents = (objectsToAdd) => {
    setComponents((components) => ({ ...components, ...objectsToAdd }));
  };

  const removeComponents = (keysToRemove) => {
    setComponents((components) => {
      // const {[keyToRemove]:_, ...remainingComponents} = components
      keysToRemove.forEach((key) => delete components[key]);
      // have to spread the components so it's a new object like redux
      return { ...components };
    });
  };

  return (
    <MapInjectContext.Provider
      value={{ components, addComponents, removeComponents }}
    >
      {children}
    </MapInjectContext.Provider>
  );
}

const useMapInject = () => {
  const context = useContext(MapInjectContext);
  if (context === undefined) {
    throw new Error('MapInject must be used within a Provider');
  }
  return context;
};

function MapInjectSetter(props) {
  const { children } = props;
  const { addComponents, removeComponents } = useMapInject();

  useEffect(() => {
    if (children) {
      const childrenToObject = React.Children.toArray(children).reduce(
        (acc, child) => {
          if (!child.props.contextKey) {
            throw new Error('Children need a contextKey');
          }
          return { ...acc, [child.props.contextKey]: child };
        },
        {},
      );
      addComponents(childrenToObject);
    }
    return () =>
      removeComponents(
        React.Children.map(children, (child) => child.props.contextKey),
      );
  }, [children]);

  return null; // should it be a hook if it returns nothing? Only thing is I want it to behave like a normal component otherwise
}

const MapInjectDisplay = () => {
  const { components } = useMapInject();
  return Object.keys(components).map((key) => (
    <React.Fragment key={key}>{components[key]}</React.Fragment>
  ));
};

export { MapInjectProvider, MapInjectDisplay, useMapInject, MapInjectSetter };
