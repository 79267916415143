import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { FileIcon } from '@iconicicons/react';

import { FilterTable, renderDateCell, renderOptionalTextCell } from './Table';
import { LoadingIndicator } from '../helpers/LoadingIndicator';
import { useLazyGetExtractedNoticesQuery } from '../services/edgeApi';
import { ReusableModal } from '../modals/ReusableModal';
import { toast } from 'react-hot-toast';

const SECONDS = 1000;

/**
 *
 * @typedef {{
 *  loading: boolean,
 *  gasDate: string,
 * }} ExtractedNoticeTableProps
 */

/**
 *
 * @param {ExtractedNoticeTableProps} props
 * @returns {React.FC<ExtractedNoticeTableProps>}
 */
export function ExtractedNoticeTable(props) {
  const [extractedNoticesTrigger, { isFetching, isLoading }] =
    useLazyGetExtractedNoticesQuery();
  const columnHelper = createColumnHelper();
  const [extractedNotices, setExtractedNotices] = useState([]);
  // const [searchText, setSearchText] = useState('')
  // control if open the raw notice data modal
  const [rawNoticeModal, setRawNoticeModal] = useState(false);
  // store raw notice data link
  const [rawNoticePath, setRawNoticePath] = useState('');
  const [firstRender, setFirstRender] = useState(true);

  const getNotices = useCallback(async () => {
    const result = await extractedNoticesTrigger({
      gasDate: props.gasDate,
    }).unwrap();
    if (!result) {
      console.error('Failed fetching extracted notices.');
      return;
    }
    const rawNoticeRecords = [...result].sort((a, b) => b.id - a.id);
    setExtractedNotices(rawNoticeRecords);
    if (firstRender) setFirstRender(false);
  }, [props.gasDate, extractedNoticesTrigger]);

  useEffect(() => {
    getNotices();
    const interval = setInterval(getNotices, 30 * SECONDS);
    return () => clearInterval(interval);
  }, [props.gasDate]);

  useEffect(() => {
    if (extractedNotices.length > 0 && !firstRender) {
      toast.success(`New Raw Notices Available`);
    }
  }, [extractedNotices.map((notice) => notice.id).join(',')]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('pipeline_name', {
        id: 'pipeline_name',
        header: 'Pipeline',
        enableColumnFilter: true,
        enableSorting: true,
      }),
      columnHelper.accessor('notice_type', {
        id: 'notice_type',
        header: 'Type',
        enableColumnFilter: true,
        enableSorting: true,
        cell: renderOptionalTextCell,
      }),
      columnHelper.accessor('notice_status_description', {
        id: 'notice_status_description',
        header: 'Status',
        enableColumnFilter: true,
        enableSorting: true,
        cell: renderOptionalTextCell,
      }),
      columnHelper.accessor('posting_date', {
        id: 'posting_date',
        header: 'Posting Date',
        enableColumnFilter: false,
        enableSorting: true,
        cell: renderDateCell,
      }),
      columnHelper.accessor('effective_date', {
        id: 'effective_date',
        header: 'Effective Date',
        enableColumnFilter: false,
        enableSorting: true,
        cell: renderDateCell,
      }),
      columnHelper.accessor('end_date', {
        id: 'end_date',
        header: 'End Date',
        enableColumnFilter: false,
        enableSorting: true,
        cell: renderDateCell,
      }),
      // columnHelper.accessor('full_text', {
      //   id: 'full_text',
      //   header: 'Full Text',
      //   enableColumnFilter: false,
      //   enableSorting: false,
      //   cell: (info) => {
      //     const notice = info.row.original;
      //     return (
      //       <div style={{ maxWidth: 500 }} id={`tooltip-${notice.id}`}>
      //         {notice.full_text}
      //       </div>
      //     );
      //   },
      // }),
      columnHelper.accessor('raw_notice', {
        id: 'raw_notice',
        header: 'Raw Notice',
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info) => {
          const notice = info.row.original;
          return (
            <button
              className="btn btn-primary-outline"
              onClick={() => {
                setRawNoticeModal(true);
                setRawNoticePath(
                  `https://pathpoint-space.nyc3.digitaloceanspaces.com/${notice.notice_file_path}`,
                );
              }}
            >
              <FileIcon />
            </button>
          );
        },
      }),
    ],
    [],
  );

  function RawNoticeModal() {
    return rawNoticeModal ? (
      <ReusableModal
        size="lg"
        isOpen={rawNoticeModal}
        toggle={() => setRawNoticeModal(false)}
        header="raw notice data"
      >
        {rawNoticePath.substring(rawNoticePath.length - 4) === '.pdf' ||
        rawNoticePath.substring(rawNoticePath.length - 5) === '.html' ? (
          <iframe
            title="pdf"
            src={rawNoticePath}
            width="740"
            height="480"
            allow="autoplay"
          />
        ) : (
          'No raw data found.'
        )}
      </ReusableModal>
    ) : (
      ''
    );
  }

  return (
    <>
      {/*
        Not including a search functionality right now,
        but we can use this in the future if we want.
    */}
      {/* <div className="row justify-content-between m-3 position-sticky">
      <div className="col-6 col-sm-8 col-xs-11">
        <div className="input-group">
          <span className="input-group-text"><SearchIcon/></span>
          <DebouncedInput type="text" className="form-control" placeholder="Pipeline or description" onChange={(value) => {
            // setSearchText(value)
            // onSearch(value)
          }}/>
        </div>
      </div>
    </div> */}

      <div style={{ minHeight: 200 }}>
        <div>
          {extractedNotices?.length === 0 && isFetching === false ? (
            <div className="alert alert-info m-3 text-center" role="alert">
              No notices found.
            </div>
          ) : (
            <>
              <FilterTable
                columns={columns}
                data={extractedNotices}
                keyField="id"
              />
              <RawNoticeModal />
            </>
          )}
        </div>
        {(isLoading || isFetching) && <LoadingIndicator />}
        {/* Use this if we want to paginate this table, I don't think we will for now */}
        {/* <InfiniteScrollObserver onScrollIn={onNextPage}/> */}
      </div>
      {isLoading && <LoadingIndicator />}
      {/* Use this if we want to paginate this table, I don't think we will for now */}
      {/* <InfiniteScrollObserver onScrollIn={onNextPage}/> */}
    </>
  );
}
