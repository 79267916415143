import { useSelector } from 'react-redux';
import { selectActiveOverlays } from '../overlays/overlaysSlice';
import { useContext, useEffect, useMemo } from 'react';
import TileLayer from 'ol/layer/Tile';
import { XYZ } from 'ol/source';
import { mapContext } from './EdgeMap';

export const MapOverlays = ({ zIndex }) => {
  const { map } = useContext(mapContext);
  const overlays = useSelector(selectActiveOverlays);

  const overlaysLayers = useMemo(() => {
    return overlays.map((overlay) => {
      const source = new XYZ({
        url: overlay.url,
      });
      return new TileLayer({
        source,
        zIndex,
      });
    });
  }, [overlays]);

  useEffect(() => {
    overlaysLayers.forEach((layer) => {
      map.addLayer(layer);
    });
    return () => {
      overlaysLayers.forEach((layer) => {
        map.removeLayer(layer);
      });
    };
  }, [overlays]);

  return null;
};
