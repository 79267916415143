// import { useMapInject } from "../plotter/mapInjectContext"
import React, { useEffect, useState } from 'react';
import { CheckIcon, ClockIcon, CloseIcon } from '@iconicicons/react';
import { LoadingButton } from '../helpers/LoadingButton';
import { useCapacityComponents } from '../helpers/hooks';
import { Collapse } from 'reactstrap';
import { CapacityComponentTable } from './CapacityComponentTable';
import { BelowMapSetter } from '../layouts/BelowMapContext';
import { FloatingLabelInput } from '../helpers/FloatingLabelInput';
import { PriceComponentTable } from '../priceComponents/PriceComponentTable';
import { toIsoDate } from '../utils/stringUtils';
// import { MapNotices } from './MapNotices';
import { ExtractedNoticeTable } from './ExtractedNoticeTable';
import { NoticeComponentTable } from './NoticeComponentTable';
import { useGetPriceComponentsQuery } from '../services/edgeApi';
import { useDispatch } from 'react-redux';
import { plotterActions } from '../plotter/plotterSlice';

export const MarketData = (props) => {
  const dispatch = useDispatch();
  // this could be broken out into price component and capacity components
  const [gasDate, setGasDate] = useState(props.gasDate);
  const [nomCycle, setNomCycle] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [defaultFilter, _setDefaultFilter] = useState(true);
  const [priceSource, setPriceSource] = useState('PLATTS_DAILY');
  // const { isFetching: priceComponentsFetching }= useGetPriceComponentsQuery();
  const {
    data: priceComponents,
    isFetching: priceComponentsFetching,
    refetch: refetchPriceComponents,
  } = useGetPriceComponentsQuery(
    { gasDate, priceSource },
    { pollingInterval: priceSource === 'ICE_LIVE' ? 10000 : 0 },
  );

  useEffect(() => {
    return () => {
      dispatch(plotterActions.setCapacities([]));
      dispatch(plotterActions.setMarkets([]));
    };
  }, []);

  useEffect(() => {
    refetchPriceComponents();
  }, [gasDate, priceSource]);

  const itemsPerPage = 30;

  // const {addComponent, removeComponent} = useMapInject()
  // const {addComponent: addBelowMap, removeComponent: removeBelowMap} = useBelowMap()

  const { flows, flowsLoading, flowsFetching, fetchNextPage, reset } =
    useCapacityComponents(
      {
        gasDate,
        nomCycle,
        itemsPerPage,
        searchText,
      },
      defaultFilter,
    );

  useEffect(() => {
    reset();
  }, [gasDate, nomCycle]);

  const ICELiveStatus = () => {
    // This function is to make sure all update time are the same
    function checkUpdateAreSame(priceComponents) {
      let firstValue =
        priceComponents[0].record_to_use.values.sent_from_vm_at_utc;
      for (let i = 1; i < priceComponents.length; i++) {
        if (
          priceComponents[i].record_to_use.values.sent_from_vm_at_utc !=
          firstValue
        )
          return false;
      }
      return true;
    }

    const StatusIndicator = (updated = false) => {
      return (
        <div>
          ICE Live Status:
          {updated ? (
            <CheckIcon color="green"></CheckIcon>
          ) : (
            <CloseIcon color="red"></CloseIcon>
          )}
        </div>
      );
    };

    if (!priceComponents) return <StatusIndicator updated={false} />;

    if (
      priceSource === 'ICE_LIVE' &&
      Object.values(priceComponents).length != 0
    ) {
      const updated_at = checkUpdateAreSame(Object.values(priceComponents))
        ? Object.values(priceComponents)[0].record_to_use.values
            .sent_from_vm_at_utc
        : '';
      const utc_time = new Date(updated_at).getTime();
      if (new Date().getTime() - utc_time < 30 * 1000)
        return <StatusIndicator updated={true} />;
      else return <StatusIndicator updated={false} />;
    }
  };

  return (
    <>
      <GasDatePicker
        setGasDate={setGasDate}
        setNomCycle={setNomCycle}
        isFetching={flowsFetching || priceComponentsFetching}
      />
      <div className="form-floating my-3">
        <select
          className="form-select"
          value={priceSource}
          onChange={(evt) => setPriceSource(evt.target.value)}
        >
          <option value="PLATTS_DAILY">Platts Daily</option>
          <option value="ICE_DAILY">ICE Daily</option>
          <option value="ICE_LIVE">ICE Live</option>
          <option value="ICE_FUTURE">ICE Futures</option>
        </select>
        <label>Price Source</label>
        {priceSource == 'ICE_LIVE' ? <ICELiveStatus></ICELiveStatus> : ''}
      </div>
      <BelowMapSetter>
        <CapacityComponentTable
          onNextPage={fetchNextPage}
          loading={flowsLoading}
          tabName="Flows"
          contextKey="capacityComponents"
          flows={flows}
          onFilterChange={(data) =>
            dispatch(plotterActions.setCapacities(Object.values(data)))
          }
          onSearch={setSearchText}
        />
        <PriceComponentTable
          tabName="Prices"
          contextKey="priceComponents"
          priceSource={priceSource}
          priceComponents={priceComponents}
          onFilterChange={(data) =>
            dispatch(
              plotterActions.setMarkets(
                Object.values(data).flatMap((component) =>
                  component.points.map((point) => ({
                    ...component,
                    point,
                    price: component.record_to_use?.values?.close,
                    price_source: priceSource,
                  })),
                ),
              ),
            )
          }
        />
        <NoticeComponentTable
          tabName="Notices"
          contextKey="parsed-notices"
          gasDate={gasDate}
        />
        <ExtractedNoticeTable
          tabName="Raw Notices"
          contextKey="raw-notices"
          gasDate={gasDate}
        />
      </BelowMapSetter>
    </>
  );
};

function GasDatePicker(props) {
  // gasDate, nomCycle, setGasDate, setNomCycle

  const [showCustomPicker, setShowCustomPicker] = useState(false);

  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  const todayString = toIsoDate(today);
  const tomorrowString = toIsoDate(tomorrow);

  // these are internal state, submit sends them external and kicks off query
  const [gasDate, setGasDate] = useState(todayString);
  const [_nomCycle, setNomCycle] = useState('');

  // these are separate so they don't get changed when clicking "today gas date" so you can flip back and forth without reselecting the date
  const [customGasDate, setCustomGasDate] = useState(false);
  const [customNomCycle, setCustomNomCycle] = useState('');

  const handleSubmit = (evt) => {
    evt.preventDefault();
    props.setGasDate(customGasDate);
    props.setNomCycle(customNomCycle);
  };

  return (
    <div className="my-3">
      <div className="btn-group w-100">
        <LoadingButton
          isLoading={props.isFetching && gasDate === todayString}
          className={`btn btn-primary form-control ${
            gasDate === todayString && 'active'
          }`}
          onClick={() => {
            setGasDate(todayString);
            props.setGasDate(todayString);
            setNomCycle('');
            props.setNomCycle('');
          }}
        >
          {"Today's Gas Day"}
        </LoadingButton>

        <LoadingButton
          isLoading={props.isFetching && gasDate === tomorrowString}
          className={`btn btn-primary form-control ${
            gasDate === tomorrowString && 'active'
          }`}
          onClick={() => {
            setGasDate(tomorrowString);
            props.setGasDate(tomorrowString);
            setNomCycle('');
            props.setNomCycle('');
          }}
        >
          {"Tomorrow's Gas Day"}
        </LoadingButton>
        <button
          className="btn btn-outline-primary"
          onClick={() => setShowCustomPicker(!showCustomPicker)}
        >
          <ClockIcon />
        </button>
      </div>
      <Collapse isOpen={showCustomPicker}>
        <form id="custom-date" className="my-3" onSubmit={handleSubmit}>
          <FloatingLabelInput
            label="Gas Date"
            value={customGasDate}
            type="date"
            name="gasDate"
            onChange={(evt) => setCustomGasDate(evt.target.value)}
          />
          <FloatingLabelInput
            label="Nomination Cycle"
            value={customNomCycle}
            type="select"
            name="nomCycle"
            onChange={(evt) => setCustomNomCycle(evt.target.value)}
          >
            <option value="">Best Available</option>
            <option value="I3">I3</option>
            <option value="I2">I2</option>
            <option value="I1">I1</option>
            <option value="E">E</option>
            <option value="T">T</option>
          </FloatingLabelInput>
          <LoadingButton
            isLoading={props.isFetching}
            className="btn btn-primary w-100 form-control"
          >
            {'Get Flows'}
          </LoadingButton>
        </form>
      </Collapse>
    </div>
  );
}
