import { useState, useRef, useEffect } from 'react';
import { CloseIcon, ChevronDownIcon, ChevronUpIcon } from '@iconicicons/react';
import { toast } from 'react-hot-toast';
import {
  useGetPriceComponentsQuery,
  useCreateUpdatePriceComponentMutation,
} from '../services/edgeApi';
import { usePoints } from '../points/usePoints';
import { useDebounce, useConfirm } from '../helpers/hooks';
import { ReusableModal } from '../modals/ReusableModal';
import { LoadingList } from '../helpers/loading';
import { Can } from '../user/Can';

// could call it LocationComponentManager?
// TODO: turn the search in here into reusable component that will also work for the existing point search
// and work in a way that allows it to be hosted outside of a modal when needed
// and recreate the tabular select - don't have time to do it right now

// TODO: auto filter by TSP - don't want to add constraint from a different TSPS
// might also want to add more useful info than just the description, i.e. the total design price could be helpful for knowing if we are selecting the right price component

export function PointToPriceComponent({ pointId }) {
  const points = usePoints();
  const point = points[pointId];
  const [updateComponent, _updateReqInfo] =
    useCreateUpdatePriceComponentMutation();
  const [isSearching, setIsSearching] = useState(false);
  const [isListOpen, setIsListOpen] = useState(false);

  const { data: components, isLoading } = useGetPriceComponentsQuery({
    pointId,
  });

  const confirm = useConfirm();

  const addComponent = async ({ component }) => {
    const priceComponent = {
      ...component,
      points: [...component.points, pointId],
    };
    const proceed = confirm(
      `Add ${component.description} to ${point.loc_name}?`,
    );

    if (!proceed) {
      return null;
    }

    try {
      const response = await updateComponent(priceComponent);
      setIsSearching(false);
      if (response.error) {
        toast.error(response.error.message);
        return;
      }
      response.unwrap();
      toast.success(`Added ${component.description} to ${point.loc_name}`);
    } catch (e) {
      // TODO: move errors into RTKQuery middleware so we don't have to write error handling in individual components
      console.error(JSON.stringify(e));
    }
  };

  const removeComponent = async (componentId) => {
    const component = components[componentId];
    const priceComponent = {
      ...component,
      points: component.points.filter((point) => point != pointId),
    };

    const proceed = confirm(
      `Remove ${component.description} from ${point.loc_name}?`,
    );
    if (!proceed) {
      return null;
    }
    await updateComponent(priceComponent);
  };

  if (isLoading) {
    return <div className="placeholder-wave">Loading...</div>;
  }

  if (!components) {
    return null;
  }

  const collapseId = `collapse-price-components-${pointId}`;
  const componentsArray = Object.values(components);

  const handleCollapse = (e) => {
    setIsListOpen((prev) => !prev);
    e.stopPropagation();
  };

  return (
    <div className="mt-3 border p-1 rounded">
      <h6>
        <span className="flex-fill">
          Price Components ({componentsArray.length})
        </span>
        <button
          className="btn text-primary float-right"
          data-bs-toggle="collapse"
          href={`#${collapseId}`}
          aria-expanded={isListOpen}
          aria-controls={collapseId}
          onClick={handleCollapse}
        >
          {isListOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
        </button>
      </h6>
      {componentsArray.length > 0 ? (
        <ul id={collapseId} className="collapse list-group list-group-flush">
          {componentsArray.map((component) => (
            <li
              className="list-group-item bg-light d-flex align-items-end"
              key={component.id}
            >
              <span className="float-start flex-fill align-text-top lh-lg">
                {component.description}
              </span>
              <Can can="add_point">
                <button
                  className="btn btn-sm btn-primary-outline float-end p-0"
                  onClick={() => removeComponent(component.id)}
                >
                  <CloseIcon color="red" />
                </button>
              </Can>
            </li>
          ))}
        </ul>
      ) : (
        <div id={collapseId} className="collapse text-center text-muted m-3">
          No price components
        </div>
      )}
      <Can can="add_point">
        <ComponentSearch
          handleSelect={addComponent}
          isSearching={isSearching}
          setIsSearching={setIsSearching}
        />
      </Can>
    </div>
  );
}

function ComponentSearch({ handleSelect, isSearching, setIsSearching }) {
  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  return (
    <div>
      <button
        className="btn btn-primary w-100"
        onClick={() => setIsSearching(true)}
      >
        Add Price Components
      </button>
      <ReusableModal
        header="Add Component to Point"
        isOpen={isSearching}
        onClose={() => setIsSearching(false)}
      >
        <SearchField
          value={searchQuery}
          onChange={setSearchQuery}
          placeholder="Search for Price Components"
        />
        {searchQuery.length > 0 && (
          <PriceComponentSearchResults
            handleSelect={handleSelect}
            searchQuery={debouncedSearchQuery}
            debouncing={searchQuery != debouncedSearchQuery}
          />
        )}
      </ReusableModal>
    </div>
  );
}

function SearchField({ value, onChange, placeholder }) {
  const inputRef = useRef();

  useEffect(() => {
    // focus input when searching starts
    inputRef.current.focus();
  }, []);

  return (
    <input
      placeholder={placeholder}
      tabIndex="1"
      className="form-control"
      ref={inputRef}
      value={value}
      onChange={(evt) => onChange(evt.target.value)}
    />
  );
}

function PriceComponentSearchResults({
  handleSelect,
  searchQuery,
  debouncing,
}) {
  const { data: components, isFetching } = useGetPriceComponentsQuery({
    searchQuery,
  });

  if (isFetching || debouncing) {
    return <LoadingList />;
  }

  if (Object.keys(components).length === 0) {
    return (
      <ul className="list-group list-group-flush">
        <li className="list-group-item">No Results</li>
      </ul>
    );
  }

  return (
    // could add the onclick handlers here, it's the simplest.  What about keyboard shortcuts? There has to be an easy reusable way to tab through a list and click enter
    <ul className="list-group list-group-flush">
      {Object.values(components).map((component) => (
        <li
          key={`${component.id}-${component.description}`}
          onClick={() => handleSelect({ component })}
          className="list-group-item"
        >
          {component.description}
          <small className="float-end">
            Locations:
            {component.points.length}
          </small>
        </li>
      ))}
    </ul>
  );
}
