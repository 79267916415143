import { useState } from 'react';
import toast from 'react-hot-toast';
import { Input, FormGroup, Form, Label } from 'reactstrap';
import Select from 'react-select';
import { ItemSelector } from '../selectedItems/SelectorComponents';
import {
  useCreateUpdateContractMutation,
  useGetTSPsQuery,
  useGetZonesQuery,
} from '../services/edgeApi';
import { ToggleContracts } from './ToggleContracts';
import { LoadingButton } from '../helpers/LoadingButton';

export function EditContract({
  contract: originalContract,
  cancelEdit,
  onSuccess,
}) {
  // LOOK AT OVERLAY ARCHITECTURE - I think that's the main one I thought through for making this type of stuff more repeatable
  // one thing that is annoying about the names like overlays/Form is that when searching for file names they don't come up.  make it overlays/OverlayForm
  // is it better to initialize an empty object for contract, or would it be better to set all the fields in the object?
  const blankContract = {
    type: 'FIRM',
    rate_schedule_type: 'FT',
    receipt_zones: [],
    delivery_zones: [],
    receipt_points: [],
    delivery_points: [],
  };
  const [contract, setContract] = useState(originalContract || blankContract);
  const [createUpdateContract, { isLoading: isCreatingUpdating }] =
    useCreateUpdateContractMutation();

  const { data: tsps, isLoading: tspsLoading } = useGetTSPsQuery();
  const tspOptions = tsps
    ? Object.keys(tsps).map((tspId) => ({
        value: tspId,
        label: tsps[tspId].name,
      }))
    : [{ value: contract.tsp, label: contract.tsp }];

  const { data: zones, isLoading: zonesLoading } = useGetZonesQuery();
  // const zoneOptions = zones ? Object.keys(zones).map(zoneId => ({value: zoneId, label: zones[zoneId].name + ' - ' + zones[zoneId].tsp.name})) : []
  // filter zones by tsp?
  const zoneOptions = zones
    ? Object.keys(zones)
        .filter((zoneId) => zones[zoneId].tsp.id == contract.tsp)
        .map((zoneId) => ({
          value: zoneId,
          label: `${zones[zoneId].name} - ${
            zones[zoneId].tsp.short_name || zones[zoneId].tsp.long_name
          }`,
        }))
    : [];

  const handleChange = (evt) => {
    const { name } = evt.target;
    let value =
      evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
    const arrayInputs = [
      'receipt_points',
      'delivery_points',
      'receipt_zones',
      'delivery_zones',
    ];
    // handling array types
    if (arrayInputs.includes(name)) {
      value = value.split(',');
    }
    setContract({ ...contract, [name]: value });

    // dispatch(plotterActions.updateActiveNode({'name':name, 'value':value}))
  };

  const handleSelectChange = (selectedOptions, actionMeta) => {
    let values = [];
    if (Array.isArray(selectedOptions)) {
      values = selectedOptions.map((option) => option.value);
    } else {
      values = selectedOptions.value;
    }
    // console.log(values)
    setContract({ ...contract, [actionMeta.name]: values });
  };

  const handlePointChange = (pointIds) => (name) => {
    // console.log(`Name: ${name}`)
    setContract({ ...contract, [name]: pointIds });
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    try {
      const result = await createUpdateContract(contract).unwrap();
      onSuccess(result);
    } catch (e) {
      // toast.error("Something went wrong, please try again")
      toast.error(JSON.stringify(e));
      // onError()
    }
  };

  return (
    <>
      Show / Hide Contracts <ToggleContracts />
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Name</Label>
          <Input onChange={handleChange} value={contract.name} name="name" />
        </FormGroup>
        <FormGroup>
          <Label>K ID</Label>
          <Input onChange={handleChange} value={contract.k_id} name="k_id" />
        </FormGroup>
        <FormGroup>
          <Label>Owner</Label>
          <Input onChange={handleChange} value={contract.owner} name="owner" />
        </FormGroup>
        <FormGroup>
          <Label>Description</Label>
          <Input
            onChange={handleChange}
            value={contract.description}
            name="description"
          />
        </FormGroup>
        <FormGroup>
          <Label>Start Date</Label>
          <Input
            onChange={handleChange}
            value={contract.start_date}
            name="start_date"
            type="date"
          />
        </FormGroup>
        <FormGroup>
          <Label>End Date</Label>
          <Input
            onChange={handleChange}
            value={contract.end_date}
            name="end_date"
            type="date"
          />
        </FormGroup>
        <FormGroup>
          <Label>Max Daily Quanity (dth)</Label>
          <Input
            onChange={handleChange}
            value={contract.max_daily_quantity}
            name="max_daily_quantity"
          />
        </FormGroup>
        {/* TODO: after selecting TSP maybe we filter the points / zones to that TSP only */}
        <FormGroup>
          <Label>TSP</Label>
          <Select
            // key={tspOptions.length}
            isLoading={tspsLoading}
            value={tspOptions.find((option) => option.value == contract.tsp)}
            name="tsp"
            onChange={handleSelectChange}
            options={tspOptions}
          />
        </FormGroup>
        <FormGroup>
          <Label>Contract Type</Label>
          <Input
            onChange={handleChange}
            type="select"
            value={contract.type}
            name="type"
          >
            <option value="FIRM">FIRM</option>
            <option value="IT">IT</option>
          </Input>
        </FormGroup>
        <FormGroup>
          <Label>Rate Schedule Type</Label>
          <Input
            onChange={handleChange}
            type="select"
            value={contract.rate_schedule_type}
            name="rate_schedule_type"
          >
            <option value="FT">FT</option>
            <option value="FT_NN">FT_NN</option>
            <option value="IT">IT</option>
            <option value="CONTESTING">CONTESTING</option>
            <option value="SETTLEMENT">SETTLEMENT</option>
          </Input>
        </FormGroup>

        <FormGroup>
          <Label>Receipt Points</Label>
          <ItemSelector
            onChange={(pointIds) =>
              handlePointChange(pointIds)('receipt_points')
            }
            selectionKey="receiptPoints"
            itemType="point"
            multiSelect
            preSelectIds={contract.receipt_points || []}
          />
        </FormGroup>

        <FormGroup>
          <Label>Delivery Points</Label>
          <ItemSelector
            onChange={(pointIds) =>
              handlePointChange(pointIds)('delivery_points')
            }
            selectionKey="deliveryPoints"
            itemType="point"
            multiSelect
            preSelectIds={contract.delivery_points || []}
          />
        </FormGroup>

        <FormGroup>
          <Label>Receipt Zones</Label>
          <Select
            isLoading={zonesLoading}
            value={
              zoneOptions
                ? zoneOptions.filter((option) =>
                    contract.receipt_zones?.includes(option.value),
                  )
                : contract.receipt_zones
            }
            // value={zoneOptions ? zoneOptions : contract.receipt_zones}
            // value={[1,2,3]}
            name="receipt_zones"
            onChange={handleSelectChange}
            options={zoneOptions}
            isMulti
          />
        </FormGroup>

        <FormGroup>
          <Label>Delivery Zones</Label>
          <Select
            isLoading={zonesLoading}
            value={
              zoneOptions
                ? zoneOptions.filter((option) =>
                    contract.delivery_zones?.includes(option.value),
                  )
                : contract.delivery_zones
            }
            name="delivery_zones"
            onChange={handleSelectChange}
            options={zoneOptions}
            isMulti
          />
        </FormGroup>

        {/* <Input type="submit" /> */}
        <LoadingButton
          type="submit"
          className="btn btn-primary w-100 mt-3"
          isLoading={isCreatingUpdating}
        >
          {' '}
          Submit{' '}
        </LoadingButton>
      </Form>
      <button
        className="btn btn-outline-warning w-100 mt-3"
        onClick={cancelEdit}
      >
        Cancel
      </button>
    </>
  );
}
