import { useDispatch } from 'react-redux';
import { useParams, useNavigate, NavLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { FormGroup, Label } from 'reactstrap';
import { ItemSelector } from '../selectedItems/SelectorComponents';
import { useGetContractQuery, useGetZonesQuery } from '../services/edgeApi';
import * as contractsActions from './contractsSlice';
import { EditContract } from './EditContract';
import { ToggleContracts } from './ToggleContracts';
import { plotterActions } from '../plotter/plotterSlice';

export function Contract(props) {
  // console.log(JSON.stringify(props.stuff))
  const dispatch = useDispatch();
  const { isEditable, hideRender, detailed } = props;
  // set detailed to true to show everything, otherwise just show title?  Probably a more react way to handle this type of thing like useContract hook
  // if id is 'new' then start blank, otherwise add whatever to contract slice?  Do we need a contract slice? or just use rtkquery for the whole thing?
  // could either conditionally get the id or could have a separate ContractPage component for loading contract by useParams id
  const params = useParams();
  const navigate = useNavigate();
  const contractId = props.id || params.id;
  const [isEditing, setIsEditing] = useState(
    props.isEditing || contractId === 'new',
  );

  const { data: zones } = useGetZonesQuery();

  // can get contract data in a few ways
  // 1. fetch from server by id, where id can come from params or URL
  // 2. pass in contract data directly from a list of contract data
  // 3. after creating / updating a contract, the successful response can be used to overwrite the existing contract data
  // 4. when creating a new contract there won't be any contract data to begin with

  // if there's contract data passed in from props, start with that
  /** @type {[import('../scenarios/scenarioSlice').Contract, React.Dispatch<React.SetStateAction<import('../scenarios/scenarioSlice').Contract>>]} */
  const [contract, setContract] = useState(props.contract);
  // if contract data is already here, skip fetching it
  const skip = contract || contractId === 'new';
  // or if we pass in contract data from a list of contracts, we don't have to run a query for each individual contract
  const {
    data: fetchedContract,
    error,
    isLoading,
  } = useGetContractQuery(String(contractId), { skip });

  useEffect(() => {
    if (fetchedContract) {
      setContract(fetchedContract);
    }
  }, [fetchedContract]);

  useEffect(() => {
    dispatch(plotterActions.setContractVisibility(true));
    return () => {
      dispatch(plotterActions.setContractVisibility(true));
    };
  }, [props.id]);

  const onEditSuccess = (result) => {
    // should we invalidate and refetch this contract, or just handle the response directly?
    setContract(result);
    setIsEditing(false);
    // navigate to new contract URL, but might want to do this conditionally
    // right now it does navigate to the new URL but it doesn't re-render this component, probably because there's no key set on the Contract component in the routes.  I don't know if we really care because it seems to do what we want
    navigate(`/contracts/${result.id}`);
  };

  const onCancel = () => {
    setIsEditing(false);
    // depending on where we are, we probably want to do different things when canceliing
    // like on contracts/new, we probably want to go back to /contracts
    // but if we make a contract modal, cancel edit should just hide the modal
    if (contractId === 'new') {
      // or "back" to wherever we were previously
      navigate('/contracts');
    }
  };

  useEffect(() => {
    if (contract) {
      dispatch(contractsActions.addContract(contract));
    }
  }, [contract]);

  // clean up - remove contract from redux on dismount
  useEffect(
    () => () => {
      if (contract?.id) {
        dispatch(contractsActions.removeContract(contract?.id));
      }
    },
    [contract?.id],
  );

  // don't show anything at all, just handle showing the contract on the map
  if (hideRender) {
    return null;
  }

  if (isLoading) {
    return <div>Loading Contract #{contractId}</div>;
  }

  if (error) {
    toast.error(`Problem loading contract #${contractId}`);
    return null;
  }

  if (isEditing) {
    return (
      <EditContract
        contract={contract}
        onSuccess={onEditSuccess}
        cancelEdit={onCancel}
      />
    );
  }

  // this prevents errors in the first render
  if (!contract) {
    return null;
  }

  return (
    <div>
      <h5>{contract.name}</h5>

      {detailed && (
        <>
          <p>{contract.description}</p>
          <table className="table">
            <tbody>
              <tr>
                <th scope="row">K ID</th>
                <td>{contract.k_id || 'Unknown'}</td>
              </tr>
              <tr>
                <th scope="row">Owner</th>
                <td>{contract.owner || 'Unknown'}</td>
              </tr>
              <tr>
                <th scope="row">Start Date</th>
                <td>{contract.start_date || 'Unknown'}</td>
              </tr>
              <tr>
                <th scope="row">End Date</th>
                <td>{contract.end_date || 'Unknown'}</td>
              </tr>
              <tr>
                <th scope="row">Pipeline</th>
                <td>{contract.tsp_name || 'Unknown'}</td>
              </tr>
              <tr>
                <th scope="row">MDQ</th>
                <td>{contract.max_daily_quantity || 'Unknown'} dth</td>
              </tr>
              <tr>
                <th scope="row">Contract Type</th>
                <td>{contract.type || 'Unknown'}</td>
              </tr>
              <tr>
                <th scope="row">Rate Schedule Type</th>
                <td>{contract.rate_schedule_type || 'Unknown'}</td>
              </tr>
            </tbody>
          </table>

          <h5>Receipt</h5>
          {contract.receipt_points.length > 0 && (
            <FormGroup>
              <Label className="fw-bold">Points</Label>
              <ItemSelector
                selectionKey="receiptPoints"
                hideSearch
                disabled
                itemType="point"
                multiSelect
                preSelectIds={contract.receipt_points || []}
              />
            </FormGroup>
          )}

          <FormGroup>
            <Label className="fw-bold">Zones</Label>
            {contract.receipt_zones.map((zoneId) => (
              <li key={zoneId} className="list-group-item">
                {zones
                  ? `${zones[zoneId].name} - ${
                      zones[zoneId].tsp.short_name ??
                      zones[zoneId].tsp.long_name
                    }`
                  : zoneId}
              </li>
            ))}
          </FormGroup>

          <h5>Delivery</h5>
          {contract.delivery_points.length > 0 && (
            <FormGroup>
              <Label className="fw-bold">Points</Label>
              <ItemSelector
                selectionKey="deliveryPoints"
                hideSearch
                disabled
                itemType="point"
                multiSelect
                preSelectIds={contract.delivery_points || []}
              />
            </FormGroup>
          )}

          <FormGroup>
            <Label className="fw-bold">Zones</Label>
            {contract.delivery_zones.map((zoneId) => (
              <li key={zoneId} className="list-group-item">
                {zones
                  ? `${zones[zoneId].name} - ${
                      zones[zoneId].tsp.short_name ??
                      zones[zoneId].tsp.long_name
                    }`
                  : zoneId}
              </li>
            ))}
          </FormGroup>
        </>
      )}
      {isEditable && (
        <button
          className="btn btn-outline-warning w-100"
          onClick={() => setIsEditing(true)}
        >
          Edit
        </button>
      )}
    </div>
  );
}

export function ContractWrapper(props) {
  const params = useParams();
  const contractId = props.id || params.id;

  return (
    <>
      <h3>
        Contract #{contractId}
        <ToggleContracts className="float-end" />
      </h3>
      <Contract {...props} />
      <hr />
      <NavLink className="btn btn-primary my-3 w-100" to="/contracts/new">
        Add New Contract
      </NavLink>
    </>
  );
}
