import { useSelector, useDispatch } from 'react-redux';
import { Popup, Tooltip } from 'react-leaflet';
import * as plotterSelectors from '../plotter/plotterSelectors';
import * as selectedItemsActions from '../selectedItems/selectedItemsSlice';
import { EdgePopup } from './EdgePopup';
import { ArrowheadsPolyline } from '../helpers/ArrowheadsPolyline';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import { EdgeFlowTable } from './EdgeFlow';
import { generateColor } from '../utils/colors';

export const LeafletMapEdges = () => {
  const edges = useSelector(plotterSelectors.selectFilteredEdges);
  /**
   * @type {import('../scenarios/scenarioSlice').ScenarioState}
   */
  const { data: scenario, isEditing } = useSelector((state) => state.scenario);
  const showEdgeDirections = useSelector(
    (state) => state.plotter.showEdgeDirections,
  );
  const location = useLocation();

  // const edges = useSelector(plotterSelectors.selectAllEdges);
  const constrainedEdges = useSelector(
    (state) => state.selectedItems.constrainedEdges,
  );
  const selectedEdge = useSelector((state) => state.selectedItems.edge[0]);
  const nodes = useSelector(plotterSelectors.selectAllNodes);
  const points = useSelector(plotterSelectors.selectAllPoints);

  const isScenarioPage = location.pathname.match(/\/scenarios\/\d+$/);
  const flowsOnEdges = useMemo(() => {
    if (!isScenarioPage || isEditing) {
      return {};
    }
    const flows = scenario.operations.reduce((agg, op) => {
      const pathEdgeKeys = op.path_node_ids.slice(0, -1).map((id, i) => {
        return `${id}_to_${op.path_node_ids[i + 1]}`;
      });
      pathEdgeKeys.forEach((key) => {
        if (!agg[key]) {
          agg[key] = [];
        }
        agg[key].push(op);
      });
      return agg;
    }, {});
    return flows;
  }, [isScenarioPage, scenario.id, isEditing]);
  // console.log({ flowsOnEdges });

  return Object.keys(edges).map((key, _index) => {
    const edge = edges[key];
    if (!edge) {
      return null;
    }

    const isSelected = selectedEdge?.toString() === key?.toString();
    const endPointId = nodes[edge.end_node]?.points[0];
    const endPoint = points[endPointId];
    const startTSP = endPoint?.tsp_id || 0;
    // const tspNum = startTSP?.charCodeAt()*5 || 100
    // console.log('startTSP' + startTSP)

    const tspColor = generateColor(startTSP);

    // if(key == '1484'){console.log(startTSP)}
    // let color = isSelected ? '#00FF00' : '#C0C0C0';
    // let color = isSelected ? '#00FF00' : '#ffa500';
    const color = tspColor || '#00FF00';
    const isConstrained = constrainedEdges.includes(edge.id);
    // className change does not update so the key has to change to force react to re-render with the new class
    // if positions doesn't exist or are null, don't try to plot the edge

    if (!edge.positions?.[0]?.[0]) {
      return null;
    }
    const edgeKey = `${edge.id}_${isConstrained}_${isSelected}`;
    return (
      <MapEdge
        operations={[
          ...(flowsOnEdges[`${edge.start_node}_to_${edge.end_node}`] ?? []),
          ...(flowsOnEdges[`${edge.end_node}_to_${edge.start_node}`] ?? []),
        ]}
        key={edgeKey}
        edgeKey={edgeKey}
        edge={edge}
        isConstrained={isConstrained}
        isSelected={isSelected}
        color={color}
        endPoint={endPoint}
        showEdgeDirections={showEdgeDirections}
      />
    );
  });
};

/**
 * @typedef {{
 *  operations: import('../scenarios/scenarioSlice').Operation[],
 *  edge: any,
 *  isConstrained: boolean,
 *  isSelected: boolean,
 *  color: string,
 *  endPoint: any,
 *  showEdgeDirections: boolean,
 *  edgeKey: string,
 * }} MapEdgeProps
 */

/**
 *
 * @param {MapEdgeProps} props
 * @returns {React.FC<MapEdgeProps>}
 */
export function MapEdge({
  operations = [],
  edge,
  isConstrained,
  isSelected,
  color,
  endPoint,
  showEdgeDirections,
  edgeKey,
}) {
  const dispatch = useDispatch();
  const calcColor = isSelected ? '#00FF00' : color;

  return (
    <ArrowheadsPolyline
      key={edgeKey}
      arrowheads={showEdgeDirections ? { size: '10px' } : null}
      data={edge}
      className={isConstrained ? 'constrained' : ''}
      positions={edge.positions}
      pane="edgesPane"
      pathOptions={{ color: calcColor }}
      eventHandlers={{
        click: (e) => {
          e.originalEvent.view.L.DomEvent.stopPropagation(e);
          // dispatch(plotterActions.fetchEdge(e.target.options.data.id))
          const edge = e.target.options.data;
          dispatch(
            selectedItemsActions.itemClicked({
              id: edge.id,
              itemType: 'edge',
            }),
          );
        },
      }}
    >
      <Popup
        pane="popupsPane"
        keepInView={false}
        closeButton={false}
        autoPan={false}
      >
        <EdgePopup edge={edge} endPoint={endPoint} operations={operations} />
      </Popup>

      <Tooltip pane="tooltipsPane" opacity={1} sticky>
        <small>#{edge.id}</small>
        <div>
          {endPoint?.tsp_name} -{endPoint?.zone_name}{' '}
          {endPoint?.seg_nbr ? `Seg ${endPoint.seg_nbr}` : null}
        </div>
        <EdgeFlowTable operations={operations} />
      </Tooltip>
    </ArrowheadsPolyline>
  );
}
