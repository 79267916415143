import { CheckIcon, FilterIcon } from '@iconicicons/react';
import React from 'react';
import { colors } from '../utils/colors';

/**
 * @typedef {{
 *  active: boolean,
 *  iconSize: number,
 *  activeColor?: string,
 *  inactiveColor?: string,
 *  className?: string,
 * }} FilterStateIconProps
 */

/**
 *
 * @param {FilterStateIconProps} props
 * @returns {React.FC<FilterStateIconProps>}
 */
export const FilterStateIcon = ({
  active,
  iconSize = 22,
  activeColor = colors.primaryBlue,
  inactiveColor = colors.lightGrey,
  className = '',
}) => {
  const checkScale = 0.7;
  const checkIconSize = iconSize * checkScale;
  const topOffset = iconSize + 1 - checkIconSize;
  const leftOffset = iconSize + 2 - checkIconSize;
  const marginOffset = checkIconSize * 0.25;
  return (
    <span className={className} style={{ position: 'relative' }}>
      <FilterIcon
        style={{
          height: iconSize + 'px',
        }}
        fontSize={`${iconSize}px`}
        color={active ? activeColor : inactiveColor}
      />
      <CheckIcon
        style={{
          position: 'absolute',
          top: topOffset + 'px',
          left: leftOffset + 'px',
          maxHeight: checkIconSize + 'px',
          maxWidth: checkIconSize + 'px',
          backgroundColor: 'white',
          borderRadius: '50%',
          margin: marginOffset + 'px',
        }}
        fill="white"
        color={active ? colors.matteGreen : inactiveColor}
      />
    </span>
  );
};
