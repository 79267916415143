import { useSelector } from 'react-redux';
import { getRawNoticeUrl, selectNoticeRecords } from '../notices/noticeSlice';
import { selectFilteredTsps } from '../plotter/plotterSelectors';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Vector as VectorSource } from 'ol/source.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import { fromLonLat } from 'ol/proj';
import { WebGLLayer } from './webglLayer';
import { mapContext } from './EdgeMap';
import { MapTooltip } from './MapTooltip';
import process from 'process';

const PUBLIC_FOLDER = process.env.PUBLIC_URL;

const getStyle = () => {
  return {
    'icon-src': `${PUBLIC_FOLDER}/marker-icon-2x.png`,
    'icon-scale': 0.5,
    'icon-displacement': [0, 24],
  };
};

export const MapNotices = ({ zIndex }) => {
  const {
    map,
    registerHoverListener,
    unregisterHoverListener,
    registerClickListener,
    unregisterClickListener,
  } = useContext(mapContext);
  const notices = useSelector(selectNoticeRecords);
  const includedTsps = useSelector(selectFilteredTsps);
  const showNoticeMarkers = useSelector(
    (state) => state.plotter.showNoticeMarkers,
  );

  /** @type {[import('../notices/noticeSlice').NoticeRecord, React.Dispatch<React.SetStateAction<import('../notices/noticeSlice').NoticeRecord>>]} */
  const [tooltipNotice, setTooltipNotice] = useState(null);

  /** @type {import('./EdgeMap').MapEventListener} */
  const hoverListener = useCallback(
    (feature) => {
      if (feature?.getProperties()?.key === `notice-${tooltipNotice?.id}`)
        return;
      if (!feature || !feature?.getProperties()?.key?.startsWith('notice-')) {
        setTooltipNotice(null);
        return;
      }
      const noticeId = Number(feature.getProperties()?.id);
      const notice = notices.find((notice) => notice.id === noticeId);
      setTooltipNotice(notice);
    },
    [notices],
  );

  useEffect(() => {
    registerHoverListener(hoverListener);
    return () => {
      unregisterHoverListener(hoverListener);
    };
  }, [hoverListener]);

  /** @type {import('./EdgeMap').MapEventListener} */
  const clickListener = useCallback(
    (feature) => {
      const noticeId =
        feature && feature.getProperties()?.key?.startsWith('notice-')
          ? feature.getProperties()?.id
          : null;
      const notice = notices.find((notice) => notice.id === noticeId);
      const noticeUrl = getRawNoticeUrl(notice);
      if (noticeUrl.endsWith('.pdf') || noticeUrl.endsWith('.html')) {
        window.open(noticeUrl, '_blank');
      }
    },
    [notices],
  );

  useEffect(() => {
    registerClickListener(clickListener);
    return () => {
      unregisterClickListener(clickListener);
    };
  }, [clickListener]);

  const filteredNotices = useMemo(() => {
    return notices.filter((notice) => {
      if (includedTsps.length === 0) {
        return true;
      }
      return includedTsps.includes(notice.tsp_name);
    });
  }, [notices, includedTsps]);

  const noticesLayer = useMemo(() => {
    const features = new GeoJSON().readFeatures({
      type: 'FeatureCollection',
      features: showNoticeMarkers
        ? filteredNotices
            .flatMap((notice) => {
              return notice.nodes.map((position) => {
                return {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: fromLonLat([position[1], position[0]]),
                  },
                  properties: {
                    id: notice.id,
                    key: `notice-${notice.id}`,
                  },
                };
              });
            })
            .sort(
              (a, b) => b.geometry.coordinates[1] - a.geometry.coordinates[1],
            )
        : [],
    });
    const source = new VectorSource({
      features,
      format: new GeoJSON(),
    });
    return new WebGLLayer({
      source,
      zIndex,
      style: getStyle(),
    });
  }, [filteredNotices, showNoticeMarkers]);

  useEffect(() => {
    if (!map || !noticesLayer) return;
    map.addLayer(noticesLayer);
    return () => {
      map.removeLayer(noticesLayer);
      noticesLayer.dispose();
    };
  }, [map, noticesLayer]);

  return (
    <>
      <MapTooltip visible={Boolean(tooltipNotice)}>
        {tooltipNotice && (
          <div className="bg-dark p-2 px-3 border-dark text-light rounded">
            <p className="fs-4">{tooltipNotice.identifier}</p>
            <div
              style={{
                overflow: 'hidden',
                display: '-webkit-box',
                WebkitLineClamp: '10',
                WebkitBoxOrient: 'vertical',
                width: '300px',
                whiteSpace: 'pre-line',
              }}
            >
              <b>Full Text</b>:{tooltipNotice.full_text.replace(/\n+/g, '\n')}
            </div>
            <div>
              <b>Date</b>:{' '}
              {new Date(tooltipNotice.effective_datetime).toLocaleDateString()}
            </div>
            <div>
              <b>Amount</b>: {tooltipNotice.amount ?? '--'}
            </div>
            <div>
              <b>Tsp</b>: {tooltipNotice.tsp_name ?? '--'}
            </div>
            <div>
              <b>Percent</b>: {tooltipNotice.percent ?? '--'}
            </div>
            <div>
              <b>Priority</b>: {tooltipNotice.priority ?? '--'}
            </div>
          </div>
        )}
      </MapTooltip>
    </>
  );
};
