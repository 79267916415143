import { useContext, useMemo } from 'react';
import { mapContext } from './EdgeMap';
import { useSelector } from 'react-redux';
import { selectMarkets, selectTrades } from '../scenarios/scenarioSlice';
import {
  selectFilteredEdgeNodes,
  selectFilteredEdgePoints,
} from '../plotter/plotterSelectors';
import { fromLonLat } from 'ol/proj';
import { useEffect } from 'react';
import { WebGLLayer } from './webglLayer';
import { Vector as VectorSource } from 'ol/source.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import { selectPlotterMarkets } from '../plotter/plotterSlice';

const getMarketStyle = () => {
  return {
    'circle-radius': 10,
    'circle-fill-color': ['get', 'fillColor'],
    'circle-stroke-color': ['get', 'color'],
    'circle-stroke-width': 2,
    'circle-rotate-with-view': false,
    'circle-opacity': 0.8,
  };
};

const interpolateColor = (c0, c1, f) => {
  c0 = c0.match(/.{1,2}/g).map((oct) => parseInt(oct, 16) * (1 - f));
  c1 = c1.match(/.{1,2}/g).map((oct) => parseInt(oct, 16) * f);
  const ci = [0, 1, 2].map((i) => Math.min(Math.round(c0[i] + c1[i]), 255));
  return ci
    .reduce((a, v) => (a << 8) + v, 0)
    .toString(16)
    .padStart(6, '0');
};

/**
 *
 * @param {{
 *  zIndex: number,
 *  markets: import("../scenarios/scenarioSlice").Market[]
 * }} props
 * @returns
 */
export const MapMarkets = ({ zIndex = 0, ...props }) => {
  const { map } = useContext(mapContext);
  const scenarioMarkets = useSelector(selectMarkets);
  const scenarioTrades = useSelector(selectTrades);
  const plotterMarkets = useSelector(selectPlotterMarkets);

  const points = useSelector(selectFilteredEdgePoints);
  const nodes = useSelector(selectFilteredEdgeNodes);

  const markets = useMemo(
    () => [...scenarioMarkets, ...(props.markets || [])],
    [scenarioMarkets, props.markets],
  );
  const marketsAndTrades = useMemo(
    () => [...markets, ...plotterMarkets, ...scenarioTrades],
    [markets, scenarioTrades, plotterMarkets],
  );

  const prices = marketsAndTrades
    .map((market) => parseFloat(market.price))
    .filter(Number);
  const maxPrice = Math.max(...prices);
  const minPrice = Math.min(...prices);

  const marketsLayer = useMemo(() => {
    return new WebGLLayer({
      source: new VectorSource({
        features: new GeoJSON().readFeatures({
          type: 'FeatureCollection',
          features: marketsAndTrades
            .map((market) => {
              const nodeId = points[market.point]?.node;
              const node = nodes[nodeId];

              if (node) {
                const ratio =
                  (parseFloat(market.price) - minPrice) / (maxPrice - minPrice);
                const color = `#${interpolateColor('0096FF', 'FF0000', ratio)}`;
                const fillColor = `${color}30`;

                return {
                  type: 'Feature',
                  geometry: {
                    type: 'Point',
                    coordinates: fromLonLat([node.lng, node.lat]),
                  },
                  properties: {
                    id: nodeId,
                    key: `node-${nodeId}`,
                    color,
                    fillColor,
                  },
                };
              }

              return null;
            })
            .filter(Boolean),
        }),
        format: new GeoJSON(),
      }),
      zIndex,
      style: getMarketStyle(),
    });
  }, [marketsAndTrades, nodes, points]);

  useEffect(() => {
    if (!map || !marketsLayer) return;
    map.addLayer(marketsLayer);
    return () => {
      map.removeLayer(marketsLayer);
      marketsLayer.dispose();
    };
  }, [map, marketsLayer]);

  return null;
};
