import React, { useEffect, useRef } from 'react';

/**
 * @typedef {{
 *  value: string | number,
 *  onChange: React.ChangeEventHandler<HTMLInputElement>,
 *  placeholder?: string,
 *  className?: string,
 * }} SearchFieldProps
 * @param {SearchFieldProps} props
 * @returns {React.FC<SearchFieldProps>}
 */
export const SearchField = ({ value, onChange, placeholder, className }) => {
  const inputRef = useRef();

  useEffect(() => {
    // focus input when searching starts
    inputRef.current.focus();
  }, []);

  return (
    <input
      placeholder={placeholder}
      tabIndex="1"
      className={`form-control ${className}`}
      ref={inputRef}
      value={value}
      onChange={(evt) => onChange(evt.target.value)}
    />
  );
};

/**
 * @typedef {{
 *  value?: string | number,
 *  placeholder?: string,
 *  className?: string,
 *  onClick?: React.MouseEventHandler<HTMLInputElement>,
 *  style?: React.CSSProperties,
 * }} SearchButtonProps
 */

/**
 *
 * @param {SearchButtonProps} props
 * @returns {React.FC<SearchButtonProps>}
 */
export function SearchButton({
  style,
  className,
  onClick,
  placeholder,
  value,
}) {
  return (
    <input
      style={style}
      onClick={onClick}
      className={`form-control ${className || ''}`}
      placeholder={placeholder || 'Search'}
      value={value || ''}
      readOnly
    />
  );
}
