import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import toast from 'react-hot-toast';
import { CloseIcon, SearchIcon } from '@iconicicons/react';
import { ReduxPointSearch } from '../search/ReduxPointSearch';
import * as selectedItemsActions from './selectedItemsSlice';
import { Point } from '../modes/Sidebar';
import * as plotterSelectors from '../plotter/plotterSelectors';
import {
  useCreateUpdatePointMutation,
  useDeleteNodeMutation,
  useGetMapPointsQuery,
  useGetMapNodesQuery,
  useGetMapEdgesQuery,
} from '../services/edgeApi';
import { useDelete } from '../helpers/hooks';
import { usePanMap } from '../plotter/usePanMap';
import { PinPoint } from '../points/PinPoint';
import { ErrorBoundary } from '../helpers/ErrorBoundary';

export function ItemSelector(props) {
  const dispatch = useDispatch();
  // props.hideItems hides the SelectedItems from the ItemSelector card itself
  // SelectedItems can be shown independently of the selector card

  // what is currently being selected
  const currentlySelecting = useSelector(
    (state) => state.selectedItems.currentlySelecting[props.itemType],
  );
  // if this component is what is currently being selected, it's "active"
  const active = currentlySelecting?.selectionKey === props.selectionKey;

  // set disabled to true to prevent selecting and removing more items (for contract show, for instance)
  const { disabled } = props;

  const activate = () => {
    dispatch(
      selectedItemsActions.currentlySelectingChanged({
        selectionKey: props.selectionKey,
        multiSelect: props.multiSelect,
        itemType: props.itemType,
        disabled: props.disabled,
        isEditable: props.isEditable,
        isCreatable: props.isCreatable,
        isDeletable: props.isDeletable,
        preventToggle: props.preventToggle,
        overwrite: props.overwrite,
      }),
    );
  };

  useEffect(() => {
    // send startActive for one selector to be activated on render
    if (props.startActive) {
      activate();
    }

    // send preSelect ids to have objects selected on render
    if (props.preSelectIds) {
      dispatch(
        selectedItemsActions.setSelectedObjects({
          selectionKey: props.selectionKey,
          ids: props.preSelectIds,
        }),
      );
    }
  }, []);

  return (
    <div
      onClick={() => activate()}
      className={`card${active ? ' bg-primary text-white' : ''}`}
    >
      {props.title && (
        <div className="card-header flex-fill">{props.title}</div>
      )}
      {!props.hideItems && (
        <ul className="list-group list-group-flush">
          <SelectedItems
            selectionKey={props.selectionKey}
            itemType={props.itemType}
            detailed={props.detailed}
            onChange={props.onChange}
            disabled={disabled}
          />
        </ul>
      )}
      {!props.hideSearch && <ReduxPointSearch />}
    </div>
  );
}

export const SelectedItems = (props) => {
  // get selected point of select type
  const selectedItems = useSelector(
    (state) => state.selectedItems[props.selectionKey],
  );
  // console.log("Selection Key: "+ props.selectionKey)
  // console.log("SELECTED ITEMS " + selectedItems)

  useEffect(() => {
    if (props.onChange) {
      props.onChange(selectedItems);
    }
  }, [selectedItems]);

  if (selectedItems === undefined) {
    throw "ERROR: You are probably calling a selectionKey that isn't initialized in selectedItemsSlice";
  }
  return selectedItems.map((itemId) => {
    if (itemId) {
      return props.itemType === 'edge' ? (
        <SelectedEdge
          key={itemId}
          edgeId={itemId}
          selectionKey={props.selectionKey}
          detailed={props.detailed}
          disabled={props.disabled}
        />
      ) : props.itemType === 'point' ? (
        <ErrorBoundary key={itemId}>
          <SelectedPoint
            key={itemId}
            pointId={itemId}
            selectionKey={props.selectionKey}
            detailed={props.detailed}
            disabled={props.disabled}
          />
        </ErrorBoundary>
      ) : (
        <SelectedNode
          key={itemId}
          nodeId={itemId}
          selectionKey={props.selectionKey}
          detailed={props.detailed}
          disabled={props.disabled}
        />
      );
    }
    return null;
  });
};

function SelectedPoint(props) {
  const dispatch = useDispatch();
  const pointData = useSelector(plotterSelectors.selectAllPoints)[
    props.pointId
  ];
  const nodeData = useSelector(plotterSelectors.selectAllNodes)[
    pointData?.node
  ];
  // console.log(pointData)
  // console.log(nodeData)

  const removePoint = (pointId) => {
    dispatch(
      selectedItemsActions.itemRemoved({
        selectionKey: props.selectionKey,
        pointId,
      }),
    );
  };

  const panMap = usePanMap();

  if (!pointData) {
    return null;
  }

  // this shows the internal point ID of selected points from URL until point data is loaded.  Could also just not show anything until data is loaded.
  return (
    <li className="list-group-item">
      {pointData ? (
        <>
          <div>
            <span>
              <PinPoint id={props.pointId} />
            </span>
            <small>
              <b>#{pointData.id}</b>
            </small>
            {!pointData.node && (
              <div className="float-end text-danger">NOT MAPPED</div>
            )}
            {nodeData?.lat && nodeData?.lng && (
              <button
                className="float-end m-0 p-0 btn-sm btn btn-primary-oubtline"
                onClick={() => panMap([nodeData.lat, nodeData.lng])}
              >
                <SearchIcon height={24} color="blue" />
              </button>
            )}
          </div>
          <div>{pointData.loc_name}</div>
          <div>
            Loc ID:
            {pointData.loc_id}
          </div>
          {props.detailed && (
            <>
              <div>
                TSP:
                {pointData.tsp_name}
              </div>
              <div>
                Segment:
                {pointData.seg_nbr}
              </div>
              <div>
                Type:
                {pointData.loc_type_ind}
              </div>
              <div>
                Flow Direction:
                {pointData.dir_flo}
              </div>
              <div>
                Receipt Zone:
                {pointData.loc_zone_rec}
              </div>
              <div>
                Delivery Zone:
                {pointData.loc_zone_del}
              </div>
              <div>
                Connects To:
                {pointData.up_dn_name} -{pointData.up_dn_loc_name}
              </div>
            </>
          )}
          {!props.disabled && (
            <button
              className="float-end m-0 p-0 btn-sm btn btn-primary-outline"
              onClick={() => removePoint(props.pointId)}
            >
              <CloseIcon height={24} color="red" />
            </button>
          )}
        </>
      ) : (
        <div>
          Point ID:
          {props.pointId}
        </div>
      )}
    </li>
  );
}

function SelectedEdge(props) {
  const dispatch = useDispatch();
  const edgeData = useSelector((state) => state.plotter.edges[props.edgeId]);
  const removeItem = (pointId) => {
    dispatch(
      selectedItemsActions.itemRemoved({
        selectionKey: props.selectionKey,
        pointId,
      }),
    );
  };

  // const [isEditing, setIsEdting] = useState(true)

  return (
    <li className="list-group-item">
      {edgeData ? (
        <div>{edgeData.id}</div>
      ) : (
        <div>
          Edge ID:
          {props.edgeId}
        </div>
      )}
      <button
        onClick={() => removeItem(props.edgeId)}
        className="float-end btn btn-sm  btn-danger"
      >
        X
      </button>
      <ItemSelector
        itemType="node"
        selectionKey="edgeStartNode"
        title="Start Node"
      />
      <ItemSelector
        itemType="node"
        selectionKey="edgeEndNode"
        title="End Node"
      />
    </li>
  );
}

export function SelectedNode(props) {
  // might want to make a selector for allnodesbyid
  const nodeData = useSelector(plotterSelectors.selectAllNodes)[props.nodeId];
  const [updatePoint] = useCreateUpdatePointMutation();
  const [deleteNode] = useDeleteNodeMutation();
  const { isLoading: pointsLoading } = useGetMapPointsQuery();
  const { isLoading: nodesLoading } = useGetMapNodesQuery();
  const { isLoading: edgesLoading } = useGetMapEdgesQuery();

  const mapIsLoading = pointsLoading || nodesLoading || edgesLoading;

  const removePoint = async (pointId) => {
    // to remove a point from a node, just update the point with null as its node
    const pointData = { id: pointId, node: null };
    try {
      // even though the node part of createUpdatePoint is not needed here, it's easier than replicating the pessimistic update across multiple mutations at the moment - maybe break out the pessimistic update as a shared method
      await updatePoint({ point: pointData, node: nodeData });
      toast.success('Point Removed from Node');
    } catch (e) {
      toast.error(JSON.stringify(e));
    }
  };
  const handleDelete = useDelete();
  // const handleDelete = () => {
  //   if (window.confirm(`Are you sure you wish to delete node ${props.nodeId}?`)){
  //     deleteNode(props.nodeId)
  //   }
  // }

  return mapIsLoading ? (
    <li className="list-group-item">LOADING</li>
  ) : (
    <li className="list-group-item">
      {`Node: ${nodeData?.id}`}
      {props.children}
      {nodeData?.points.map((pointId) => (
        <>
          <Point
            key={pointId}
            id={pointId}
            isEditable={props.pointIsEditable}
            nodeId={props.nodeId}
            detailed={props.detailed}
          />
          {props.isEditable && pointId !== 'new' && (
            <button
              onClick={() => removePoint(pointId)}
              className="btn btn-danger w-100 mt-1"
            >
              Remove Point from Node
            </button>
          )}
        </>
      ))}
      <hr />
      {props.isDeletable && nodeData?.id !== 'new' && (
        <button
          type="button"
          onClick={() =>
            handleDelete(() => deleteNode(props.nodeId), `node ${props.nodeId}`)
          }
          className="btn btn-danger w-100 mt-1"
        >
          Delete Node
        </button>
      )}
    </li>
  );
}
