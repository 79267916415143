import { createSlice, createSelector } from '@reduxjs/toolkit';
import { edgeApi } from '../services/edgeApi';

let localActiveOverlayIds;

try {
  localActiveOverlayIds = JSON.parse(localStorage.getItem('activeOverlayIds'));
} catch (e) {
  console.warn(e);
}

const initialState = {
  activeOverlayIds: localActiveOverlayIds || [],
  modal: false,
};

export const overlaysSlice = createSlice({
  name: 'overlays',
  initialState,
  reducers: {
    updateActiveOverlayIds: (state, action) => ({
      ...state,
      activeOverlayIds: action.payload,
    }),

    setModal: (state, action) => {
      // this could probably be handled outside of Redux but it's working so whatever
      return { ...state, modal: action.payload };
    },
  },
});

export const updateActiveOverlaysThunk = (payload) => (dispatch, getState) => {
  // doing this in a thunk so I can update localStorage with the values and read from localstorage on load to persist the user's selected maps
  // can also subscribe a listener to the store but that would run every time anything changed and this doesn't change that often

  let { activeOverlayIds } = getState().overlays;
  const payloadId = Number(payload.id);

  if (payload.value === true) {
    // activeOverlayIds.push(payload.id)
    activeOverlayIds = [...activeOverlayIds, payloadId];
  } else {
    activeOverlayIds = activeOverlayIds.filter(
      (overlayId) => overlayId != payloadId,
    );
  }

  // let activeOverlayIds = getState().overlays.activeOverlayIds.map((overlayId) => {
  //   if(overlayId == id){
  //     return {...overlayId, active: payload.value}
  //   } else {
  //     return overlayId
  //   }
  // })
  localStorage.setItem('activeOverlayIds', JSON.stringify(activeOverlayIds));
  dispatch(updateActiveOverlayIds(activeOverlayIds));
};

// initiate get map overlays query as a selector:
export const selectOverlaysResult = edgeApi.endpoints.getOverlays.select();

export const selectOverlays = createSelector(
  selectOverlaysResult,
  (overlaysResult) => overlaysResult?.data ?? [],
);

export const selectActiveOverlayIds = (state) =>
  state.overlays.activeOverlayIds;

// the overlays API view isn't using the serializer that turns it into an object.  It's using the standard array of objects.
// we can change it over for consistency across the app but I also want to try it this way for now to see how much simpler it might be
// This is one way to filter an object by key and return an new object if we go that route:

// export const selectActiveOverlays = createSelector(
//     selectOverlays,
//     selectActiveOverlayIds,
//     // state => state.overlays.activeOverlayIds,
//     (allOverlays, activeOverlayIds) => {
//      console.log("ALL OVERLAYS: " + JSON.stringify(allOverlays))
//       return Object.keys(allOverlays)
//       .filter(key => activeOverlayIds.includes(key))
//       .reduce((obj, key) => {
//         return {...obj, [key]: allOverlays[key]};
//       }, {})
//     }
// )

export const selectActiveOverlays = createSelector(
  selectOverlays,
  selectActiveOverlayIds,
  (allOverlays, activeOverlayIds) =>
    allOverlays.filter((overlay) => activeOverlayIds.includes(overlay.id)),
);

export const { updateActiveOverlayIds, setModal } = overlaysSlice.actions;

export default overlaysSlice.reducer;
