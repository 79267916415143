import { Input, FormGroup, Label } from 'reactstrap';

import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import * as plotterSelectors from '../plotter/plotterSelectors';
import * as filtersSlice from './filtersSlice';
import { Can } from '../user/Can';
// inputs with onchange to dispatch filter changed action

// -- filter change reducer
// save to localstorage
// -- show all options for tsp
// filter by point type
// filter by up/dn
// -- just put this in the sidebar for now but later make a collapsible sidebar]

export const PointFilters = () => {
  const points = useSelector(plotterSelectors.selectApiPoints);
  // iterate over points, add each ferc cid and tsp name to object with ferc_cid as key - should end up with unique ferc_cids because of the list keys
  const fercCidOptionsObject = Object.keys(points)
    .map((pointId) => points[pointId])
    // filter out nulls
    .filter((point) => point.tsp_ferc_cid)
    // add to object, let keys handle uniqueness - don't care much about label, just that key is unique
    .reduce(
      (obj, point) => ({
        ...obj,
        [point.tsp_ferc_cid]: {
          value: point.tsp_ferc_cid,
          label: point.tsp_name,
        },
      }),
      {},
    );

  // map over the object to turn the labels and values into an array of objects
  const fercCidOptions = Object.keys(fercCidOptionsObject).map(
    (key) => fercCidOptionsObject[key],
  );

  return (
    <div className="row mx-0 w-100">
      <h6 className="text-center">Point Filters</h6>
      <div className="col-12">
        <FormGroup>
          <Label>TSP Name</Label>
          <MultiSelectFilter
            keyString="tsp_name"
            filterType="pointAttributes"
          />
        </FormGroup>

        <FormGroup>
          <Label>Point Type</Label>
          <MultiSelectFilter
            keyString="loc_type_ind"
            filterType="pointAttributes"
          />
        </FormGroup>

        <FormGroup>
          <Label>Connects To</Label>
          <MultiSelectFilter
            keyString="up_dn_ferc_cid"
            customLabelMap={fercCidOptions}
            filterType="pointAttributes"
          />
        </FormGroup>
      </div>

      <div className="col-6">
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id="known-int-filter-checkbox"
          />
          <CheckBoxFilter
            keyString="onlyKnownInterconnects"
            filterType="custom"
          />
          <Label className="form-check-label">Only Known Interconnects</Label>
        </div>

        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id="potential-int-filter-checkbox"
          />
          <CheckBoxFilter
            keyString="onlyPotentialInterconnects"
            filterType="custom"
          />
          <Label className="form-check-label">
            Only Potential Interconnects
          </Label>
        </div>
      </div>
      <div className="col-6">
        <Can can="add_point">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="missing-zone-filter-checkbox"
            />
            <CheckBoxFilter keyString="missingZone" filterType="custom" />
            <Label className="form-check-label">Missing Zone</Label>
          </div>
        </Can>

        <Can can="add_point">
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              id="node-edges-filter-checkbox"
            />
            <CheckBoxFilter keyString="noEdges" filterType="custom" />
            <Label className="form-check-label">No Edges</Label>
          </div>
        </Can>
      </div>
    </div>
  );
};

function MultiSelectFilter(props) {
  const dispatch = useDispatch();
  const { filterType } = props;

  // this is based on point attributes, probably want to be more flexible if possible
  const uniqueValues = useSelector((state) =>
    filtersSlice.selectUniqueByKey(state, {
      keyString: props.keyString,
      filterSelection: props.filterSelection,
    }),
  );

  const currentFilters = useSelector((state) => state.filters[filterType]);
  let selectOptions;
  if (props.customLabelMap) {
    selectOptions = props.customLabelMap.map((option) => ({
      value: option.value,
      label: option.label,
    }));
  } else {
    selectOptions = uniqueValues.map((value) => ({
      value,
      label: value ?? 'blank',
    }));
  }

  let currentSelection;
  try {
    currentSelection = selectOptions.filter((option) =>
      currentFilters[props.keyString]?.includes(option.value),
    );
  } catch (error) {
    // remove filters if they cause some sort of problem.  Should probably be handled closer to the source
    localStorage.removeItem('filters');
  }

  const handleChange = (selectedOptions, name) => {
    const value = [...selectedOptions].map((option) => option.value);
    dispatch(filtersSlice.filtersChanged({ name, value, filterType }));
  };

  // TrueFalseMultiSelect and this one should both have shared output or child components for output instead of repeating code
  return (
    <Select
      // value={[{value: 'FLORIDA GAS TRANSMISSION COMPANY, LLC', label: "FGT"}]}
      value={currentSelection}
      onChange={(selectedOptions) =>
        handleChange(selectedOptions, props.keyString)
      }
      isMulti
      options={selectOptions}
    />
  );
}

function CheckBoxFilter(props) {
  const { keyString, filterType } = props;
  const dispatch = useDispatch();
  const currentFilters = useSelector((state) => state.filters);
  const isChecked = currentFilters?.[filterType]?.[keyString];
  const handleChange = (evt) => {
    dispatch(
      filtersSlice.filtersChanged({
        name: evt.target.name,
        value: evt.target.checked,
        filterType,
      }),
    );
  };
  return (
    <Input
      type="checkbox"
      name={keyString}
      checked={isChecked || false}
      onChange={handleChange}
    />
  );
}
