import React, { useEffect, useState } from 'react';
import { ReusableModal } from '../modals/ReusableModal';
import { DebouncedInput } from '../helpers/DebounceInput';
import { useLazyGetPriceComponentsQuery } from '../services/edgeApi';
import { EditFeedbackInput } from '../helpers/EditFeedbackInput';
import toast from 'react-hot-toast';

/**
 * @typedef {import('../scenarios/scenarioSlice').Price} Price
 * @typedef {{
 *  data: Price,
 *  editedData: Price,
 *  value: string | number,
 *  dirty: boolean,
 *  saved: boolean,
 *  onChange: (value: string | number, key?: keyof Price) => void
 * }} MarketPriceProps
 * @param {MarketPriceProps} props
 * @returns {React.FC<MarketPriceProps>}
 */
export function MarketPrice({ data, editedData = {}, dirty, saved, onChange }) {
  const [isFixedPrice, setIsFixedPrice] = useState(!data.price_component);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [priceComponents, setPriceComponents] = useState([]);

  const [priceComponentsTrigger, { isError, isFetching }] =
    useLazyGetPriceComponentsQuery();

  const onChangeBasePrice = (e) => {
    const value = Number(e.target.value);
    onChange(value, 'base_price');
    onChange(value, 'price');
  };

  useEffect(() => {
    if (isError) {
      toast.error('Error fetching price components');
    }
  }, [isError]);

  const onChangePriceComponent = (priceComponent) => {
    if (priceComponent) {
      onChange(`${priceComponent?.description}+${editedData.adder}`, 'price');
    } else {
      onChange(editedData.base_price, 'price');
    }
    onChange(priceComponent?.id, 'price_component');
    onChange(priceComponent?.description ?? '', 'price_component_description');
  };

  const onChangeAdder = (e) => {
    const value = Number(e.target.value);
    onChange(value, 'adder');
    onChange(`${editedData.price_component}${value}`, 'price');
  };

  if (isFixedPrice) {
    return (
      <div
        style={{
          minWidth: '250px',
        }}
        className="input-group input-group-sm"
      >
        <EditFeedbackInput
          type="number"
          key="fixed-price-input"
          className="form-control form-control-sm"
          value={editedData.base_price}
          aria-label="fixed-price"
          placeholder="Fixed price"
          onChange={onChangeBasePrice}
          dirty={dirty}
          saved={saved}
          style={{
            minWidth: '100px',
          }}
        />
        <button
          className="btn btn-outline-secondary btn-sm"
          type="button"
          onClick={() => setIsFixedPrice(false)}
        >
          Edit variable price
        </button>
      </div>
    );
  }

  return (
    <div
      style={{
        minWidth: '360px',
      }}
      className="input-group input-group-sm"
    >
      <EditFeedbackInput
        type="text"
        key="price-component-input"
        className="form-control form-control-sm"
        value={editedData.price_component_description ?? ''}
        readOnly={true}
        aria-label="market"
        placeholder="Price reference"
        onClick={() => setIsModalOpen(true)}
        dirty={dirty}
        saved={saved}
        style={{
          minWidth: '150px',
        }}
      />
      <EditFeedbackInput
        type="number"
        key="adder-input"
        className="form-control form-control-sm"
        value={editedData.adder}
        placeholder="Adder"
        aria-label="adder"
        onChange={onChangeAdder}
        dirty={dirty}
        saved={saved}
        style={{
          minWidth: '100px',
        }}
      />
      <button
        className="btn btn-outline-secondary btn-sm"
        type="button"
        onClick={() => {
          setIsFixedPrice(true);
          onChangePriceComponent(null);
        }}
      >
        Edit fixed price
      </button>
      <ReusableModal
        header="Point Search"
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <DebouncedInput
          className="form-control"
          value={''}
          onChange={async (searchQuery) => {
            if (!searchQuery) return;
            const result = await priceComponentsTrigger({
              searchQuery,
            });
            if (result.isSuccess) {
              setPriceComponents(Object.values(result.data));
            }
          }}
        />

        {isFetching ? (
          <div>Loading...</div>
        ) : (
          <div className="d-grid gap-2 mt-2">
            {priceComponents.map((priceComponent) => (
              <div
                key={priceComponent.id}
                className="btn btn-outline-secondary"
                onClick={() => {
                  onChangePriceComponent(priceComponent);
                  setIsModalOpen(false);
                }}
              >
                <p>{priceComponent.description}</p>
              </div>
            ))}
          </div>
        )}
      </ReusableModal>
    </div>
  );
}
