import { useEffect, useRef, useState, Fragment, cloneElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchButton, SearchField } from './SearchComponents';
import { ReusableModal } from '../modals/ReusableModal';
import { useGetPointsQuery } from '../services/edgeApi';
import { LoadingList } from '../helpers/loading';
import { useDebounce } from '../helpers/hooks';
import { TabSelect } from '../helpers/TabSelect';
import { urlFilterFormatter } from '../helpers/formatters';
import { PointListItem } from '../points/PointListItem';

export function DjangoPointSearch({ onSelect, filters = '' }) {
  const [isSearching, setIsSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  // const [filters, setFilters] = useState(_filters)
  const urlFilters = urlFilterFormatter(filters);

  const selectPoint = (pointId) => {
    // could also just dismiss modal on success
    onSelect(pointId);
    setIsSearching(false);
  };

  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  // could return a variable from useDebounce for this purpose
  const isDebouncing = searchQuery != debouncedSearchQuery;

  const { data: results, isFetching } = useGetPointsQuery({
    searchQuery: debouncedSearchQuery,
    filters: urlFilters,
  });

  return (
    <>
      <SearchButton onClick={() => setIsSearching(true)} />
      <ReusableModal
        header="Point Search"
        isOpen={isSearching}
        onClose={() => setIsSearching(false)}
      >
        <SearchField
          onChange={(value) => setSearchQuery(value)}
          value={searchQuery}
        />
        {isFetching || isDebouncing || !results ? (
          <LoadingList />
        ) : (
          <ul className="list-group list-group-flush">
            <TabSelect>
              {Object.values(results).map((point) => (
                <PointListItem
                  key={point.id}
                  active={false}
                  point={point}
                  onClick={() => selectPoint(point.id)}
                />
              ))}
            </TabSelect>
          </ul>
        )}
      </ReusableModal>
    </>
  );
}

// const TabList

// const ReduxPointSearchResults = (props) => {

//   const [selectedResult, _setSelectedResult] = useState(0)
//   const selectedResultRef = useRef(selectedResult)
//   const searchResultsRef = useRef(searchResults)

//   useEffect(()=>{
//     // keep searchResultsRef up to date with searchResults for access to keybindings
//     searchResultsRef.current = searchResults
//   },[searchResults])

//   const setSelectedResult = data => {
//     selectedResultRef.current = data;
//     _setSelectedResult(data);
//   }

//   const handleClick = async (id, itemType, selectionKey) => {

//     if(props.handler){
//       await props.handler({id, itemType, selectionKey})
//     }else{
//       // default behavior is to select the item clicked:
//       dispatch(selectedItemsActions.itemClicked({id, itemType, selectionKey}) )
//     }

//     // dismiss modal: could just do this in response to success?
//     props.setIsSearching(false)
//     dispatch(searchActions.queryChanged({searchQuery:null}))
//   }

//   // set up keybindings for selecting search results
//   useEffect(() => {
//     const searchKeys = (event) => {

//       // tab key
//       if (event.keyCode === 9) {
//         event.preventDefault()
//         setSelectedResult(selectedResultRef.current+1)
//       }

//       // enter key
//       if (event.keyCode === 13){
//         // handle click of active search result
//         event.preventDefault()
//         const itemId = searchResultsRef.current[selectedResultRef.current]
//         handleClick(itemId,itemType,selectionKey)
//       }

//     };

//     window.addEventListener('keydown', searchKeys);

//     return () => {
//       window.removeEventListener('keydown', searchKeys);
//     };
//   }, []);
//   // console.log(JSON.stringify(searchResults))
//   return(
//     <>
//       <ul className="list-group list-group-flush">
//         {searchResults.map((result, index) => <ReduxPointSearchResult  handleClick={handleClick} key={result} selectedResult={index===selectedResult} id={result} itemType={itemType} selectionKey={selectionKey} handler={props.handler} setIsSearching={props.setIsSearching} />)}
//       </ul>
//       <h6 className="mt-3">Pinned Points</h6>
//       <ul className="list-group list-group-flush">
//         {pinnedPoints.slice(0).reverse().map((result, index) => <ReduxPointSearchResult handleClick = {handleClick} key={result} selectedResult={selectedResult===index+searchResults.length} id={result} itemType={itemType} selectionKey={selectionKey} handler={props.handler} setIsSearchign={props.setIsSearching} />)}
//       </ul>
//     </>
//   )
// }
