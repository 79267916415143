import React, { useEffect, useState } from 'react';
import { MapContainer, Pane, TileLayer } from 'react-leaflet';
import ResizeListener from '../helpers/ResizeListener';
import { AllNodes, Plotter } from '../plotter/Plotter';
// import { ZoomPath } from '../paths/ZoomPath';
import leafletMapContext from '../plotter/leafletMapContext';
import { LeafletMapEdges } from '../edges/LeafletMapEges';
import { LeafletMapInterconnects } from '../interconnects/LeafletMapInterconnects';

/**
 * @param {{}} props
 * @returns {React.FC<{}>}
 */
export const LeafletMap = (_props) => {
  const [mapReady, setMapReady] = useState(false);
  const [map, setMap] = useState(false);

  useEffect(() => {
    // this seems really dumb - need to fix the root of the problem or call invalidateSize when things are resized
    setInterval(() => {
      setMapReady(true);
    }, 100);
  }, []);

  const onResize = () => {
    map.invalidateSize();
  };

  if (!mapReady) return null;
  return (
    <leafletMapContext.Provider value={{ map, setMap }}>
      <MapContainer
        key="map-container"
        whenCreated={setMap}
        className="flex-grow-1"
        id="map-container"
        center={[33.9, -87]}
        zoom={6}
        minZoom={4}
        maxBounds={[
          [0, -127.3648],
          [49.4554, -64.5165],
        ]}
        scrollWheelZoom
      >
        <ResizeListener onResize={onResize} />
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
        />
        <Pane name="tooltipsPane" style={{ zIndex: 609 }} />
        <Pane name="popupsPane" style={{ zIndex: 608 }} />
        <Pane name="operationsPane" style={{ zIndex: 607 }} />
        <Pane name="constraintsPane" style={{ zIndex: 606 }} />
        <Pane name="flowsPane" style={{ zIndex: 605 }} />
        <Pane name="selectedPathsPane" style={{ zIndex: 604 }} />
        <Pane name="pathsPane" style={{ zIndex: 603 }} />
        <Pane name="selectedNodesPane" style={{ zIndex: 602 }} />
        <Pane name="nodesPane" style={{ zIndex: 601 }} />
        <Pane name="marketsPane" style={{ zIndex: 600 }} />
        <Pane name="edgesPane" style={{ zIndex: 599 }} />
        <Pane name="risePathsPane" style={{ zIndex: 598 }} />
        <Pane name="contractsPane" style={{ zIndex: 597 }} />
        <Plotter />
        <AllNodes />
        <LeafletMapEdges />
        <LeafletMapInterconnects />
      </MapContainer>
    </leafletMapContext.Provider>
  );
};
