import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import plotterReducer from './plotter/plotterSlice';
import pathsReducer from './paths/pathsSlice';
import selectedItemsReducer from './selectedItems/selectedItemsSlice';
import searchReducer from './search/searchSlice';
import overlaysReducer from './overlays/overlaysSlice';
import filtersReducer from './filters/filtersSlice';
import { userSlice } from './user/userSlice';
import { edgeApi } from './services/edgeApi';
import { jsonBinApi } from './services/jsonBinApi';
import pointsReducer from './points/pointsSlice';
import scenarioReducer from './scenarios/scenarioSlice';
import allPathsReducer from './scenarios/allPathsSlice';
import noticeReducer from './notices/noticeSlice';
import contractsReducer from './contracts/contractsSlice';

import process from 'process';

// having issues with redux store persisting between tests so setting up a way to reset it to initial state each time
// https://stackoverflow.com/questions/59061161/how-to-reset-state-of-redux-store-when-using-configurestore-from-reduxjs-toolki

const combinedReducer = combineReducers({
  plotter: plotterReducer,
  paths: pathsReducer,
  selectedItems: selectedItemsReducer,
  search: searchReducer,
  points: pointsReducer,
  overlays: overlaysReducer,
  filters: filtersReducer,
  user: userSlice.reducer,
  notices: noticeReducer,
  contracts: contractsReducer,
  scenario: scenarioReducer,
  allPaths: allPathsReducer,
  [edgeApi.reducerPath]: edgeApi.reducer,
  [jsonBinApi.reducerPath]: jsonBinApi.reducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'plotter/resetStore') {
    // check for action type
    state = undefined;
  }
  return combinedReducer(state, action);
};

// const persistedState = localStorage.getItem('persistentState')
//                        ? JSON.parse(localStorage.getItem('reduxState'))
//                        : {}

export const store = configureStore({
  reducer: rootReducer,
  // preloadedState: loadFromLocalStorage(),
  // middleware: [...getDefaultMiddleware()],

  // serializableCheck was causing an error about slowness so has been disabled
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      edgeApi.middleware,
    ),
});

if (process.env.NODE_ENV !== 'production') {
  window.store = store;
}

// this all works for saving and loading state to localstorage but seems to be interfering with the way the app is set up
// i only want to save some things to state right now but it could be useful for faster initial load of map data

// // convert object to string and store in localStorage
// function saveToLocalStorage(state) {
//   try {
//     const serializedState = JSON.stringify(state);
//     localStorage.setItem("persistentState", serializedState);
//   } catch (e) {
//     console.warn(e);
//   }
// }
//
// // load string from localStorage and convert into an Object
// // invalid output must be undefined
// function loadFromLocalStorage() {
//   try {
//     const serializedState = localStorage.getItem("persistentState");
//     if (serializedState === null) return undefined;
//     return JSON.parse(serializedState);
//   } catch (e) {
//     console.warn(e);
//     return undefined;
//   }
// }
//
// // listen for store changes and use saveToLocalStorage to
// // save them to localStorage
// store.subscribe(() => saveToLocalStorage(store.getState()));

setupListeners(store.dispatch);
