import { CloseIcon, PlusIcon } from '@iconicicons/react';
import { getContrastColor } from '../utils/colors';
import { DebouncedInput } from './DebounceInput';
import { useMemo, useState } from 'react';
import { useGetTagTypesQuery } from '../services/edgeApi';
import { titleize } from '../utils/stringUtils';
import { CircleCounter } from './CircleCounter';

/**
 * @typedef {{
 *  name: string,
 *  color: string,
 *  counter?: number,
 *  style?: React.CSSProperties,
 *  className?: string,
 *  onRemove?: (tag: import('../scenarios/scenarioSlice').TagType) => void
 * }} TagProps
 */

/**
 * @typedef {{
 *  onAdd: (tag: import('../scenarios/scenarioSlice').TagType) => void,
 *  ignoreTags?: import('../scenarios/scenarioSlice').TagType[]
 * }} AddTagButtonProps
 */

/**
 * @param {TagProps} props
 * @returns {React.FC<TagProps>}
 */
export const Tag = ({
  name,
  color,
  counter = 1,
  style,
  className,
  onRemove,
}) => {
  const textColor = getContrastColor(color);
  return (
    <>
      <span
        style={{
          backgroundColor: color,
          color: textColor,
          height: 27,
          ...style,
        }}
        className={`inline-block px-2 pe-${
          onRemove ? 1 : 2
        } py-1 mr-1 rounded d-inline-flex justify-content-center align-content-center text-xs font-bold ${className}`}
      >
        <strong style={{ alignSelf: 'center' }}>{name}</strong>
        {counter > 1 && (
          <CircleCounter
            count={counter}
            color={textColor}
            textColor={color}
            style={{
              marginLeft: 5,
              marginRight: -5,
              fontWeight: 'bold',
            }}
          />
        )}
        {onRemove && (
          <CloseIcon
            style={{ maxHeight: 20, alignSelf: 'center' }}
            className="ms-1"
            role="button"
            color={textColor}
            onClick={onRemove}
          />
        )}
      </span>
    </>
  );
};

/**
 * @param {AddTagButtonProps} props
 * @returns {React.FC<AddTagButtonProps>}
 */
export const AddTagButton = ({ onAdd, ignoreTags = [] }) => {
  const [search, setSearch] = useState('');
  const { data, isFetching, isLoading } = useGetTagTypesQuery({ name: search });

  const tags = useMemo(
    () =>
      Object.values(data?.results || {}).filter((tag) =>
        ignoreTags.every((t) => t.name !== tag.name),
      ),
    [data, ignoreTags],
  );

  return (
    <div style={{ overflow: 'visible' }} className="dropdown d-inline-flex">
      <button
        style={{ height: 27, padding: 1 }}
        className="btn btn-sm btn-light"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <PlusIcon />
      </button>
      <ul className="dropdown-menu px-2" style={{ width: 300 }}>
        <li>
          <DebouncedInput
            className="form-control form-control-sm mb-2"
            placeholder="Tag name"
            value={search}
            onChange={(value) => setSearch(value)}
          />
        </li>
        {isFetching === false && search.length > 0 && tags.length === 0 && (
          <li>
            <span className="text-muted">No results</span>
          </li>
        )}
        {isLoading ? (
          [1, 2, 3].map((i) => (
            <li
              key={i.toString()}
              className="list-group-item placeholder-wave w-80"
            >
              <span className={`placeholder col-12`} />
            </li>
          ))
        ) : (
          <>
            {tags.map((tag) => (
              <li
                className="dropdown-item"
                key={tag.id.toString()}
                onClick={() => onAdd(tag)}
              >
                {titleize(tag.name)}
              </li>
            ))}
            {isFetching && (
              <li className="list-group-item placeholder-wave w-80">
                <span className={`placeholder col-12`} />
              </li>
            )}
          </>
        )}
      </ul>
    </div>
  );
};
