import { StarIcon } from '@iconicicons/react';
import { useSelector, useDispatch } from 'react-redux';
import * as pointsActions from './pointsSlice';

export function PinPoint({ id: pointId }) {
  const dispatch = useDispatch();
  const pinnedPoints = useSelector((state) => state.points.pinnedPoints);
  const isPinned = pinnedPoints.includes(pointId);
  const color = isPinned ? 'gold' : 'gray';
  const handleClick = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    dispatch(pointsActions.togglePinnedPoint(pointId));
  };
  return (
    <a href="#" onClick={handleClick}>
      <StarIcon color={color} />
    </a>
  );
}
