import { fromLonLat } from 'ol/proj';

/**
 * @typedef {{
 *  positions: [number, number][];
 * }} EdgeLike
 */

/**
 * @template {EdgeLike} T
 * @param {T} edge
 * @param {(edge: T, arrowHead: boolean) => Object} propertyExtractor
 * @param {boolean} [displayArrowhead]
 * @returns {import('ol/Feature').FeatureLike[]}
 */
export const edgeToGeoJson = (
  edge,
  propertyExtractor,
  displayArrowhead = false,
  arrowheadProps = {},
) => {
  if (!edge?.positions) {
    return [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [[0, 0][(0, 0)]],
        },
        properties: propertyExtractor(edge, false),
      },
    ];
  }
  const edgeEnd = edge.positions[edge.positions.length - 1];
  const properties = propertyExtractor(edge, false);

  const edgeFeature = {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: edge.positions.map(([lat, lon]) => fromLonLat([lon, lat])),
    },
    properties: { ...properties, head: 0, angle: 0 },
  };

  /** @type {import('ol/Feature').FeatureLike[]} */
  const features = [edgeFeature];

  // Calculating arrowheads
  const { frequency = 0 } = arrowheadProps;
  if (displayArrowhead && edgeEnd.length > 1) {
    if (frequency < 1) {
      const arrowheadCount = Math.floor(1 / (1 - frequency));

      const pathLength = edge.positions.reduce((acc, [lat, lon], i, arr) => {
        if (i === 0) return acc;
        const [prevLat, prevLon] = arr[i - 1];
        return acc + Math.hypot(lat - prevLat, lon - prevLon);
      }, 0);
      const arrowheadDistance = pathLength / arrowheadCount;

      let distance = 0;
      let arrowheadIndex = 1;
      let totalDist = 0;
      for (let i = 1; i < edge.positions.length; i++) {
        const [lat, lon] = edge.positions[i];
        const [prevLat, prevLon] = edge.positions[i - 1];
        const segmentLength = Math.hypot(lat - prevLat, lon - prevLon);
        distance += segmentLength;
        totalDist += segmentLength;

        while (
          distance >= arrowheadDistance &&
          arrowheadIndex < arrowheadCount
        ) {
          const rem =
            segmentLength - (totalDist - arrowheadDistance * arrowheadIndex);
          const angle = Math.atan2(lat - prevLat, lon - prevLon);
          const arrowPos = [
            prevLat + Math.sin(angle) * rem,
            prevLon + Math.cos(angle) * rem,
          ];
          features.push({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: fromLonLat([arrowPos[1], arrowPos[0]]),
            },
            properties: { ...properties, head: 1, angle: Math.PI / 2 - angle },
          });
          arrowheadIndex++;
          distance -= arrowheadDistance;
        }
      }
    }

    // Add last arrowhead
    const angle = Math.atan2(
      edgeEnd[0] - edge.positions[edge.positions.length - 2][0],
      edgeEnd[1] - edge.positions[edge.positions.length - 2][1],
    );
    features.push({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: fromLonLat([edgeEnd[1], edgeEnd[0]]),
      },
      properties: { ...properties, head: 1, angle: Math.PI / 2 - angle },
    });
  }

  return features;
};
