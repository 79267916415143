import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { useCreateUpdateScenarioGroupMutation } from '../services/edgeApi';
import { LoadingButton } from '../helpers/LoadingButton';

export function EditScenarioGroup({
  scenarioGroup: originalScenarioGroup,
  cancelEdit,
  onSuccess,
  onError,
}) {
  const [createUpdateScenarioGroup, { isLoading: isCreatingUpdating }] =
    useCreateUpdateScenarioGroupMutation();
  const blankScenarioGroup = { name: '', description: '' };
  const [scenarioGroup, setScenarioGroup] = useState(
    originalScenarioGroup || blankScenarioGroup,
  );

  const handleChange = (evt) => {
    const { name } = evt.target;
    const value =
      evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
    setScenarioGroup({ ...scenarioGroup, [name]: value });
  };
  const handleSubmit = async (evt) => {
    evt.preventDefault();
    try {
      const result = await createUpdateScenarioGroup(scenarioGroup).unwrap();
      onSuccess(result);
    } catch (e) {
      toast.error(JSON.stringify(e));
    }
  };

  return (
    <>
      <h5>
        {scenarioGroup.id
          ? `Editing Scenario Group #${scenarioGroup.id}`
          : 'Creating Scenario Group'}
      </h5>
      <form className="form" onSubmit={handleSubmit}>
        <div className="form-floating mb-3">
          <input
            value={scenarioGroup.name}
            name="name"
            id="name"
            className="form-control"
            onChange={handleChange}
          />
          <label>Name</label>
        </div>

        <div className="form-floating mb-3">
          <textarea
            rows={5}
            value={scenarioGroup.description}
            name="description"
            id="description"
            className="form-control h-100"
            onChange={handleChange}
          />
          <label>Description</label>
        </div>
        <LoadingButton
          type="submit"
          className="btn btn-primary w-100 mt-3"
          isLoading={isCreatingUpdating}
        >
          {' '}
          Submit{' '}
        </LoadingButton>
      </form>
      <button
        className="btn btn-outline-warning w-100 mt-3"
        onClick={cancelEdit}
      >
        Cancel
      </button>
    </>
  );
}
