import React from 'react';
import { colors } from '../utils/colors';

/**
 * @typedef {{
 *  count: number,
 *  style?: React.CSSProperties,
 *  className?: string,
 *  color?: string,
 *  textColor?: string,
 * }} CircleCounterProps
 */

/**
 *
 * @param {CircleCounterProps} props
 * @returns {React.FC<CircleCounterProps>}
 */
export const CircleCounter = ({
  count,
  style,
  className,
  color = colors.primaryBlue,
  textColor = 'white',
}) => {
  return (
    <span
      className={`justify-content-center align-content-center ${className}`}
      style={{
        backgroundColor: color,
        color: textColor,
        borderRadius: '50%',
        width: '1.5em',
        aspectRatio: 1,
        fontSize: '0.75em',
        display: 'inline-flex',
        ...style,
      }}
    >
      {count}
    </span>
  );
};
