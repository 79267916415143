import { useSelector, useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import * as selectedItemsActions from '../selectedItems/selectedItemsSlice';
import { ItemSelector } from '../selectedItems/SelectorComponents';
import { ReduxPointSearch } from '../search/ReduxPointSearch';

export function ExploreMap() {
  const dispatch = useDispatch();
  const selectedItems = useSelector((state) => state.selectedItems);

  const [_searchParams, setSearchParams] = useSearchParams();

  // useEffect(() => {
  //   //TODO: selectionKey should be "explore" for selecting points or edges.  I set it to receipt points since i just want to select points for demo
  //   dispatch(selectedItemsActions.currentlySelectingChanged({selectionKey: 'explorePointId', itemType: "point", multiSelect: false}))
  // },[])

  // useEffect(() => {
  //   const deliveryPoints = searchParams.getAll('deliveryPoints');
  //   const receiptPoints = searchParams.getAll('receiptPoints');
  //   const constrainedEdges = searchParams.getAll('constrainedEdges');
  //   dispatch(selectedItemsActions.setState({deliveryPoints: deliveryPoints, receiptPoints: receiptPoints, constrainedEdges: constrainedEdges}))
  // },[])

  // update search params in URL
  useEffect(() => {
    setSearchParams((params) => {
      const newSearchParams = new URLSearchParams();
      params.forEach((value, key) => {
        if (value?.length > 0) newSearchParams.set(key, value);
      });
      Object.keys(selectedItems).forEach((key) => {
        if (selectedItems[key]?.length > 0)
          newSearchParams.set(key, selectedItems[key]);
      });
      return newSearchParams;
    });
  }, [selectedItems]);

  useEffect(
    () => () => {
      // what's the way to send one action that a bunch of slices respond to to clean up their state?
      dispatch(selectedItemsActions.resetState());
    },
    [],
  );

  return (
    <ul className="list-group">
      <ItemSelector
        selectionKey="explorePointId"
        itemType="point"
        title="Select A Point"
        detailed
        startActive
        preventToggle
      >
        <ReduxPointSearch />

        {/* <ReduxPointSearch isSearching={initSearch} handler={addPointToNode} buttonTitle="Add Existing Point to Node"/> */}
      </ItemSelector>
    </ul>
  );
}
