import { useState } from 'react';
import { Input } from 'reactstrap';
import Select from 'react-select';
import { ExportTable } from './Table';
import {
  useGetPlattsPipelineFlowsQuery,
  useGetTSPsQuery,
} from '../services/edgeApi';
import { useDebounce } from '../helpers/hooks';
import { numberFormatter, urlFilterFormatter } from '../helpers/formatters';
import { LoadingButton } from '../helpers/LoadingButton';
import { FloatingLabelInput } from '../helpers/FloatingLabelInput';
import { toIsoDate } from '../utils/stringUtils';

export function Export() {
  const {
    data: tsps,
    isLoading: tspsLoading,
    isError: tspLoadError,
  } = useGetTSPsQuery();

  const tspOptions = tsps
    ? Object.keys(tsps).map((tspId) => ({
        value: tspId,
        label: tsps[tspId].name,
      }))
    : [];
  const nomCycleOptions = ['T', 'E', 'I1', 'I2', 'I3'].map((cycle) => ({
    value: cycle,
    label: cycle,
  }));
  const today = toIsoDate(new Date());
  // defaulting to very recent data to cut down on a huge inital query
  const [filters, setFilters] = useState({ gas_date__gte: today });

  const debouncedFilters = useDebounce(filters, 500);

  // for (var key in myData) {
  //   if (myData.hasOwnProperty(key)) {
  //       out.push(key + '=' + encodeURIComponent(myData[key]));
  //   }
  // }

  // const urlFilters = Object.keys(debouncedFilters).reduce((acc, key) => `${acc + key}=${encodeURIComponent(debouncedFilters[key])}&`,'')

  const urlFilters = urlFilterFormatter(debouncedFilters);

  const { data, isLoading, isError, isFetching } =
    useGetPlattsPipelineFlowsQuery({ urlFilters });

  const handleChange = (evt) => {
    const { name } = evt.target;
    const value =
      evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
    setFilters({ ...filters, [name]: value });
  };

  const handleSelectChange = (selectedOptions, actionMeta) => {
    let values = [];
    if (Array.isArray(selectedOptions)) {
      values = selectedOptions.map((option) => option.value);
    } else {
      values = selectedOptions.value;
    }
    // console.log(values)
    setFilters({ ...filters, [actionMeta.name]: values });
  };

  // dynamic columns - instead of basing on data which may be null, we should send all fields in OPTIONS
  // const columns = Object.keys(data.results[0]).map(key=>{
  //   console.log(key)
  //   return {
  //     header: key,
  //     accessorKey: key
  //   }
  // })
  const fields = [
    'id',
    'pipeline_id',
    'location_type',
    'flow_dir',
    'valid_from',
    'valid_to',
    'data_active',
    'data_source',
    'create_date',
    'modified_date',
    'gas_date',
    'nomination_cycle',
    'interruptible_flow',
    'scheduled_volume',
    'actual_volume',
    'utilization',
    'design_capacity',
    'operational_capacity',
    'actual_capacity',
    'operationally_available',
    'capacity_component',
    'meta_id',
    'meta_pipeline_id',
    'meta_pipeline_name',
    'meta_number',
    'meta_point_segment_number',
    'meta_point_type',
    'meta_short_name',
    'meta_point_name',
    'meta_drn_number',
    'meta_common_code',
    'meta_alias',
    'meta_county',
    'meta_state',
    'meta_country',
    'meta_area',
    'meta_block',
    'meta_storage_region',
    'meta_zone',
    'meta_basin',
    'meta_field',
    'meta_field_type',
    'meta_connecting_party_name',
    'meta_facility_type',
  ];
  const columns = fields.map((field) => ({
    header: field,
    accessorKey: field,
    enableColumnFilter: false,
    enableSorting: false,
  }));

  // should we filter in eable itself, or have a filters area?
  // options call should tell us what can be filtered
  // can make this dynamic and able to automatically handle any endpoint, but for now just setting it up to work for this one endpoint with these specific filters and choices

  // filters
  // maybe data should be separate
  // columns shouldn't be dynamic

  // if(isLoading){
  //   return <div>Loading</div>
  // }
  // if(isError){
  //   return <div>Error</div>
  // }

  return (
    <div className="col-12">
      <div className="card col-4">
        <div className="card-header">Filters</div>
        <div className="form-group mb-3">
          <FloatingLabelInput
            label="Start Date"
            columns={3}
            onChange={handleChange}
            name="gas_date__gte"
            value={filters.gas_date__gte}
            type="date"
          />
        </div>
        <div className="form-group mb-3">
          <FloatingLabelInput
            label="End Date"
            onChange={handleChange}
            name="gas_date__lte"
            value={filters.gas_date__lte}
            type="date"
          />
        </div>
        <div className="form-group mb-3">
          <div>Pipelines</div>
          <Select
            value={tspOptions.filter((option) =>
              filters.capacity_component__tsp__in?.includes(option.value),
            )}
            onChange={handleSelectChange}
            name="capacity_component__tsp__in"
            options={tspOptions}
            isMulti
          />
        </div>
        <div className="form-group mb-3">
          <div>Nom Cycles</div>
          <Select
            value={nomCycleOptions.filter((option) =>
              filters.nomination_cycle__in?.includes(option.value),
            )}
            onChange={handleSelectChange}
            name="nomination_cycle__in"
            options={nomCycleOptions}
            isMulti
          />
        </div>

        <LoadingButton className="btn btn-primary" isLoading={isFetching}>
          <a
            className="text-white"
            download="platts_pipeline_flows.csv"
            href={`${process.env.REACT_APP_API_URL}/api/platts_pipeline_flows/csv/?${urlFilters}`}
          >
            Export
            {numberFormatter(data?.count)} Records
          </a>
        </LoadingButton>
      </div>
      {/* <div>{urlFilters}</div> */}
      <div className="overflow-scroll">
        <h3>Sample Data</h3>
        <ExportTable columns={columns} initialData={data?.results || []} />
      </div>
    </div>
  );
}
