import { v4 as uuid } from 'uuid';
import { useSelector, useDispatch } from 'react-redux';
import { numberFormatter } from '../helpers/formatters';
import { useState, useEffect, useMemo, useContext } from 'react';
import { EditableTable } from '../helpers/EditableTable';
import { useLazyGetPointConstraintsQuery } from '../services/edgeApi';
import { DateSelector } from '../helpers/DateSelector';
import {
  pointConstraintKeyExtractor,
  scenarioActions,
  selectPointConstraints,
} from '../scenarios/scenarioSlice';
import { toIsoDate } from '../utils/stringUtils';
import { colors } from '../utils/colors';
import { AnnotationWarningIcon, ZoomInIcon } from '@iconicicons/react';
import { UncontrolledTooltip } from 'reactstrap';
import * as plotterSelectors from '../plotter/plotterSelectors';
import { EditFeedbackPointInput } from '../helpers/EditFeedbackInput';
import { usePanMap } from '../plotter/usePanMap';
import { BelowMapContext } from '../layouts/BelowMapContext';

/**
 *
 * @param {{
 *  editedRows: string[],
 *  onClickEditionWarning: (constraint: import('../scenarios/scenarioSlice').PointConstraint) => void,
 *  allPoints: import('../scenarios/scenarioSlice').Point[],
 *  isEditing: boolean,
 *  allNodes: Object<string, import('../services/edgeApi').Node>,
 *  onLocateNode: (node: Node) => void,
 * }} params
 * @return {import('../helpers/EditableTable').EditableTableColumn<import('../scenarios/scenarioSlice').PointConstraint>[]}
 */
const createColumns = ({
  editedRows,
  onClickEditionWarning,
  allPoints,
  isEditing,
  allNodes,
  onLocateNode,
}) => [
  {
    title: 'Point',
    allowFiltering: true,
    key: 'point',
    type: 'text',
    placeholder: 'Point',
    displayFunction: (value) => {
      const pointName = allPoints[value]?.loc_name;
      return `#${value} ${pointName}`;
    },
    renderInput: EditFeedbackPointInput,
    isEditable: false,
    informedOnCreation: true,
    width: 0.29,
    renderCell: ({ data }) => {
      const pointName =
        Object.keys(allPoints).length > 0
          ? allPoints[data.point]?.loc_name ?? '--'
          : 'Points Loading...';
      const node = allNodes[allPoints[data.point]?.node];

      return (
        <>
          <button
            className="btn btn-light me-2"
            disabled={!node}
            onClick={() => onLocateNode(node)}
          >
            <ZoomInIcon color={node ? 'black' : 'grey'} />
          </button>
          <strong className="me-2">
            #{data.point}
            {isEditing &&
              editedRows.includes(pointConstraintKeyExtractor(data)) && (
                <>
                  <span
                    id={`point-constraint-${data.point}-edition-warning`}
                    style={{ cursor: 'pointer' }}
                    onClick={() => onClickEditionWarning(data)}
                  >
                    <AnnotationWarningIcon color={colors.matteYellow} />
                  </span>
                  <UncontrolledTooltip
                    target={`point-constraint-${data.point}-edition-warning`}
                  >
                    This constraint has been manually edited and some of its
                    data will not be updated. Click to revert this setting.
                  </UncontrolledTooltip>
                </>
              )}
          </strong>{' '}
          {pointName}
        </>
      );
    },
  },
  {
    title: 'TSP',
    allowFiltering: true,
    key: 'pipeline',
    type: 'text',
    placeholder: 'TSP',
    isEditable: false,
    default: '',
    width: 0.06,
  },
  {
    title: 'Direction',
    allowFiltering: true,
    key: 'flow_direction',
    type: 'select',
    placeholder: 'Select Direction',
    width: 0.1,
    selectOptions: [
      { value: 'RECEIPT', label: 'Receipt' },
      { value: 'DELIVERY', label: 'Delivery' },
    ],
  },
  {
    title: 'Description',
    allowFiltering: true,
    key: 'description',
    type: 'text',
    placeholder: 'Description',
    width: 0.18,
    renderCell: ({ data, value }) => {
      const id = `segment-constraint-${data.id}-description`;
      return (
        <>
          <span id={id}>{value}</span>
          <UncontrolledTooltip target={id}>{value}</UncontrolledTooltip>
        </>
      );
    },
  },
  {
    title: 'Constraint Factor (0-1)',
    key: 'constraint_factor',
    type: 'number',
    placeholder: 'Constraint Factor',
    default: 0,
    min: 0,
    max: 1,
    width: 0.09,
    setFunction: (value) => Math.min(1, Math.max(0, Number(value))),
  },
  {
    title: 'Max Volume',
    key: 'max_volume',
    type: 'number',
    placeholder: 'Max Volume',
    default: 1000000,
    width: 0.1,
    displayFunction: numberFormatter,
  },
  {
    title: 'Cuts at Priority',
    allowFiltering: true,
    key: 'cuts_at_priority',
    type: 'select',
    placeholder: 'Select Priority',
    width: 0.08,
    selectOptions: [
      { value: 'PIP', label: 'PIP' },
      { value: 'SIP_PS', label: 'SIP_PS' },
      { value: 'SIP_SP', label: 'SIP_SP' },
      { value: 'SIP_SS', label: 'SIP_SS' },
      { value: 'SOP', label: 'SOP' },
      { value: 'IT', label: 'IT' },
    ],
  },
];

/**
 *
 * @param {{
 *  contextKey: string
 * }} props
 * @returns
 */
export function PointConstraints({ contextKey }) {
  const componentType = 'point_constraints';

  const belowMapContext = useContext(BelowMapContext);
  const fullScreen = belowMapContext?.fullScreen || false;
  const visible = belowMapContext?.activeTab === contextKey;

  const allPoints = useSelector(plotterSelectors.selectAllPoints);
  const allNodes = useSelector(plotterSelectors.selectAllNodes);

  const _pointConstraints = useSelector(selectPointConstraints);

  /** @type {string} */
  const flowDate = useSelector((state) => state.scenario.data.flow_date);

  /**
   * @type {import('../scenarios/scenarioSlice').ScenarioComponentSettingsAction<"point_constraints">[]}
   */
  const pointConstraintActions = useSelector(
    (state) => state.scenario.data.settings.point_constraints?.actions || [],
  );

  /** @type {boolean} */
  const isEditing = useSelector((state) => state.scenario.isEditing);

  /**
   * @type {import('../scenarios/scenarioSlice').SegmentConstraint[]}
   */
  const ignoredConstraints = useMemo(
    () =>
      pointConstraintActions
        .filter((action) => action.type === 'REMOVE')
        .map((action) => action.data) || [],
    [pointConstraintActions],
  );

  /**
   * @type {string[]}
   */
  const updatedConstraints = useMemo(
    () =>
      pointConstraintActions
        ?.filter((action) => action.type === 'UPDATE')
        .map((action) => pointConstraintKeyExtractor(action.data)) || [],
    [pointConstraintActions],
  );

  const dispatch = useDispatch();
  const panMap = usePanMap();
  const [pointConstraintsTrigger, { isFetching: pointConstraintsFetching }] =
    useLazyGetPointConstraintsQuery();

  const [isAdding, setIsAdding] = useState(false);
  const [showIgnored, setShowIgnored] = useState(false);
  const [constraintDate, setConstraintDate] = useState(new Date(flowDate));

  useEffect(() => {
    setConstraintDate(new Date(flowDate));
  }, [flowDate]);

  const pointConstraints = useMemo(() => {
    return _pointConstraints.map((constraint) => {
      const point = allPoints[constraint.point];
      return {
        ...constraint,
        pipeline: point?.tsp_name || '',
      };
    });
  }, [_pointConstraints]);

  const saveBulkEdit = (selection, updateData) => {
    dispatch(
      scenarioActions.bulkUpdate({
        componentType,
        componentIds: selection,
        data: updateData,
      }),
    );
  };

  const saveItem = (original, edited) => {
    const source = {
      source_type: original?.source_type || 'USER_DEFINED',
      source_id: original?.source_id || uuid(),
    };

    dispatch(
      scenarioActions.updateComponent({
        componentType,
        component: {
          ...source,
          ...edited,
        },
      }),
    );
    if (!original || !original.id) {
      setIsAdding(false);
    }
  };

  const getPointConstraints = async () => {
    try {
      const result = await pointConstraintsTrigger({
        source: 'PLATTS',
        date: toIsoDate(constraintDate),
      }).unwrap();

      dispatch(
        scenarioActions.overwrite({
          componentType,
          components: result,
        }),
      );
    } catch (e) {
      console.error(e);
    }
  };

  const removeItem = (id) => {
    dispatch(
      scenarioActions.removeComponent({ componentType, componentId: id }),
    );
  };

  /**
   *
   * @param {import('../scenarios/scenarioSlice').PointConstraint} constraint
   */
  const restoreItem = (constraint) => {
    dispatch(
      scenarioActions.restoreRemovedComponent({
        componentType,
        component: constraint,
      }),
    );
  };

  const bulkRemove = (ids) => {
    dispatch(scenarioActions.bulkDelete({ componentType, componentIds: ids }));
  };

  const ignoredConstraintsRows = useMemo(
    () =>
      ignoredConstraints.map((constraint) => (
        <tr
          style={{ borderLeft: `5px solid ${colors.matteRed}` }}
          className="table-danger"
          key={`ignored-segment-constraint-${constraint.id}`}
        >
          <td></td>
          <td>{constraint.point}</td>
          <td>
            {allPoints[constraint.point] && Object.keys(allPoints).length !== 0
              ? allPoints[constraint.point].loc_name
              : 'No Point Found'}
          </td>
          <td>{constraint.flow_direction}</td>
          <td
            style={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              maxWidth: 200,
            }}
            title={constraint.description?.replace(/\n+/g, '\n') ?? ''}
          >
            {constraint.description}
          </td>
          <td>{Number(constraint.constraint_factor)}</td>
          <td>{constraint.max_volume}</td>
          <td>{constraint.cuts_at_priority}</td>
          <td>
            <button
              className="btn btn-sm btn-outline-danger"
              onClick={() => restoreItem(constraint)}
            >
              Restore Constraint
            </button>
          </td>
        </tr>
      )),
    [ignoredConstraints.map((c) => c.id).join(',')],
  );

  const onClickEditionWarning = (constraint) => {
    dispatch(
      scenarioActions.revertUpdateSettings({
        componentType,
        component: constraint,
      }),
    );
  };

  const columns = useMemo(
    () =>
      createColumns({
        editedRows: updatedConstraints,
        onClickEditionWarning,
        allPoints,
        allNodes,
        isEditing,
        onLocateNode: (node) => {
          panMap(node, 12);
        },
      }),
    [updatedConstraints.join(','), isEditing, allPoints],
  );

  const belowHeader = isEditing && ignoredConstraints.length > 0 && (
    <>
      <tr>
        <td className="position-relative" colSpan={columns.length + 2}>
          <div className="position-relative m-3">
            <div className="progress" style={{ height: 1 }}></div>
            <button
              type="button"
              className="position-absolute top-0 start-50 translate-middle btn btn-sm btn-secondary rounded-pill"
              style={{ height: '2rem' }}
              onClick={() => setShowIgnored((p) => !p)}
            >
              {showIgnored ? 'Hide' : 'Show'} {ignoredConstraints.length}{' '}
              ignored constraint
              {ignoredConstraints.length > 1 && 's'}
            </button>
            {showIgnored ? (
              <button
                type="button"
                className="position-absolute top-0 end-0 translate-middle btn btn-sm btn-danger rounded-pill"
                style={{ height: '2rem' }}
                onClick={() => {
                  ignoredConstraints.map((item) => restoreItem(item));
                }}
              >
                Restore All Constraints
              </button>
            ) : (
              ''
            )}
          </div>
        </td>
      </tr>
      {showIgnored && ignoredConstraintsRows}
    </>
  );

  /**
   *
   * @param {import('../scenarios/scenarioSlice').PointConstraint} item
   * @returns {React.CSSProperties}
   */
  const getRowStyle = (item) => {
    if (item.source_type === 'USER_DEFINED') {
      return { borderLeft: `5px solid ${colors.matteGreen}` };
    }
    if (updatedConstraints.includes(pointConstraintKeyExtractor(item))) {
      return { borderLeft: `5px solid ${colors.matteYellow}` };
    }
    return {};
  };

  return (
    <>
      {isEditing && (
        <div className="row justify-content-between m-3">
          <div className="col-6 col-md-8 col-sm-12">
            <DateSelector
              value={toIsoDate(constraintDate)}
              onChange={(evt) => setConstraintDate(evt.target.valueAsDate)}
              isLoading={pointConstraintsFetching}
              onConfirm={getPointConstraints}
              buttonText="Get Constraints"
            />
          </div>
          <div className="col-auto">
            <button
              onClick={() => setIsAdding((prev) => !prev)}
              className="btn btn-success"
            >
              {isAdding ? 'Cancel' : 'Add Point Constraint'}
            </button>
          </div>
        </div>
      )}
      <EditableTable
        belowHeader={belowHeader}
        columns={columns}
        data={pointConstraints}
        keyExtractor={pointConstraintKeyExtractor}
        isAdding={isAdding}
        isEditing={isEditing}
        isEditable={isEditing}
        getRowStyle={getRowStyle}
        onBulkRemove={bulkRemove}
        onSaveBulkEdit={saveBulkEdit}
        onSaveItem={saveItem}
        onDeleteItem={removeItem}
        onCancelCreation={() => setIsAdding(false)}
        emptyText="No Point Constraints"
        virtualized
        visible={visible}
        fullScreen={fullScreen}
      />
    </>
  );
}
