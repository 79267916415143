import { toast } from 'react-hot-toast';
import { useLocalStorage } from '../helpers/useLocalStorage';
import { numberFormatter } from '../helpers/formatters';
import { LoadingIndicator } from '../helpers/LoadingIndicator';
import { InfiniteScrollObserver } from '../helpers/InfiniteScrollObserver';
import { useCapacityComponentsHistory } from '../helpers/hooks';
import { interpolateColor } from '../utils/colors';

export function CapacityComponentHistory({ componentId, getFlowsOnDate }) {
  // TODO: should be able to select the source (platts, notices, pointlogic, etc)

  const itemsPerPage = 30;
  const {
    capacityComponent,
    flows: records,
    flowsLoading: capacityComponentLoading,
    error,
    flowsFetching,
    isError: capacityComponentError,
    fetchNextPage,
  } = useCapacityComponentsHistory({ componentId, itemsPerPage });
  const [concept, setConcept] = useLocalStorage('concept', 'utilization');

  const getColor = (ratio) => interpolateColor('0096FF', 'FF0000', ratio);

  if (capacityComponentLoading) {
    // body = <div>Loading</div>
    // should make the clicked button spin
  }

  if (capacityComponentError) {
    console.error(error);
    toast.error(error);
    return null;
  }

  if (!capacityComponent) {
    return null;
  }

  const gasDates = [...new Set(records.map((record) => record.gas_date))];
  // we can either create nomCycle columns based on data, or hard code columns - either way we should force all nom cycles to be consistent name and order in the backend
  const nomCycles = ['T', 'E', 'I1', 'I2', 'I3'];
  // what's the best way to format outputs?  Would be great to do it all in one place.  I tried in the backend serializers, but it caused a problem because turning the numbers into strings means math doesn't work.  Maybe we should send a formatted and non formatted value from backend?
  // should put this function somewhere to be called from other components
  const formatConcept = (concept, value, sign) => {
    if (
      ['scheduled_volume', 'operationally_available', 'oac_calc'].includes(
        concept,
      )
    ) {
      return numberFormatter(sign * value);
    }
    if (concept === 'utilization') {
      return `${numberFormatter(sign * value)}%`;
    }
    return value;
  };

  return (
    <>
      {/* could select source, but also could have indicator that a pipeline notice exists for a given date / cycle when looking at platts source */}
      {/* <select value={source} onChange={evt => setSource(evt.target.value)} >
        {Object.keys(capacityComponent.records).map(source => (
          <option value={source}>{source}</option>)
        )}
      </select> */}
      <select value={concept} onChange={(evt) => setConcept(evt.target.value)}>
        <option value="utilization">Utilization</option>
        <option value="scheduled_volume">Scheduled Volume</option>
        <option value="operationally_available">OAC Platts</option>
        <option value="oac_calc">OAC Calc</option>
        <option value="interruptible_flow">Interruptible Flow?</option>
      </select>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Gas Date</th>
            {nomCycles.map((nomCycle) => (
              <th key={nomCycle}>{nomCycle}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {gasDates.map((gasDate) => (
            <tr key={gasDate}>
              <th>{gasDate}</th>
              {nomCycles.map((nomCycle) => {
                const record = records.find(
                  (record) =>
                    record.gas_date === gasDate &&
                    record.nomination_cycle === nomCycle,
                );
                return (
                  <td
                    key={`${gasDate}-${nomCycle}`}
                    style={{
                      background: getColor(Math.min(record?.ratio / 100, 1)),
                    }}
                    onClick={() => getFlowsOnDate(gasDate, nomCycle)}
                  >
                    {/* see platts_pipeline_flow.py about whether we want to set backhaul flow records negative in backend, or just show them as negative in front end */}
                    {/* doing it in the frontend for now as a test to see whether negative flows are intuitive */}
                    {formatConcept(
                      concept,
                      record?.[concept],
                      record?.direction_sign,
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      {(flowsFetching || capacityComponentLoading) && <LoadingIndicator />}
      <InfiniteScrollObserver onScrollIn={fetchNextPage} />
    </>
  );
}
