import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mapContext } from './EdgeMap';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON.js';
import {
  selectFilteredEdgeNodes,
  selectFilteredNodes,
} from '../plotter/plotterSelectors';
import { selectScenario } from '../scenarios/scenarioSlice';
import { generateColor } from '../utils/colors';
import { selectPaths, pathSelected, zoomToPath } from '../paths/pathsSlice';
import { MapTooltip } from './MapTooltip';
import { priceFormatter } from '../helpers/formatters';
import { edgeToGeoJson } from '../utils/geoJsonUtils';
import { WebGLLayer } from './webglLayer';
// import * as pathsActions from './pathsSlice';

const getEdgeStyle = () => [
  {
    filter: ['>', ['get', 'head'], 0],
    'shape-points': 3,
    'shape-radius': ['get', 'arrowheadWidth'],
    'shape-fill-color': ['get', 'color'],
    'shape-stroke-color': ['get', 'color'],
    'shape-stroke-width': 4,
    'shape-rotate-with-view': false,
    // 'shape-displacement': [15, 15],
    'shape-opacity': 1,
    'shape-angle': ['get', 'angle'],
  },
  {
    filter: ['<=', ['get', 'head'], 0],
    'stroke-width': ['get', 'width'],
    'stroke-color': ['get', 'color'],
    'stroke-line-cap': 'round',
    'stroke-line-join': 'round',
    // 'stroke-offset': -15,
    'shape-angle': 0,
  },
];

/**
 *
 * @type {React.FC<{zIndex: number}>}
 */
export const MapChains = ({ zIndex }) => {
  const {
    map,
    registerHoverListener,
    unregisterHoverListener,
    registerClickListener,
    unregisterClickListener,
  } = useContext(mapContext);

  const dispatch = useDispatch();
  const scenario = useSelector(selectScenario);
  const selectedPathId = useSelector((state) => state.paths.selectedPathId);
  const nodes = useSelector(selectFilteredNodes);
  const visiblePathIds = useSelector((state) => state.paths.visiblePathIds);
  const edgeNodes = useSelector(selectFilteredEdgeNodes);
  const paths = useSelector(selectPaths);

  /** @type {[import('../paths/pathsSlice').Path, React.Dispatch<React.SetStateAction<import('../paths/pathsSlice').Path>>]} */
  const [popupPath, setPopupPath] = useState(null);

  /** @type {import('./EdgeMap').MapEventListener} */
  const hoverListener = useCallback(
    (feature) => {
      if (feature?.getProperties()?.key === `path-${popupPath?.id}`) return;
      if (!feature?.getProperties()?.key?.startsWith('path-')) {
        setPopupPath(null);
        return;
      }
      const pathId = feature?.getProperties()?.id;
      const path = paths?.[pathId];
      setPopupPath(path);
    },
    [paths],
  );

  /** @type {import('./EdgeMap').MapEventListener} */
  const clickListener = useCallback(
    (feature) => {
      if (!feature || !feature?.getProperties()?.key?.startsWith('path-'))
        return;
      if (feature?.getProperties()?.key === `path-${selectedPathId}`) return;

      const pathId = feature.getProperties()?.id;
      dispatch(
        pathSelected({
          selectedPathId: pathId,
        }),
      );
      dispatch(zoomToPath(pathId));
    },
    [selectedPathId, pathSelected, zoomToPath, dispatch],
  );

  const chainsLayer = useMemo(() => {
    const source = new VectorSource({
      features: new GeoJSON().readFeatures({
        type: 'FeatureCollection',
        features: Object.keys(paths)
          .filter(
            (pathId) =>
              paths[pathId] &&
              paths[pathId].operation_chain &&
              (visiblePathIds.includes(pathId) ||
                pathId?.toString() === selectedPathId?.toString()),
          )
          .map((pathId) => paths[pathId])
          .sort((pathA, pathB) => {
            if (Number(pathA.id) === selectedPathId) return 1;
            if (Number(pathB.id) === selectedPathId) return -1;
            return (
              pathB.operation_chain?.profit - pathA.operation_chain?.profit
            );
          })
          .flatMap((path) => {
            const pathId = Number(path.id);
            const isSelected = pathId === selectedPathId;
            const pathNodeIds = path.path_node_ids;
            const loadedPathNodeIds = pathNodeIds.filter((pathNodeId) =>
              Object.keys(edgeNodes).includes(String(pathNodeId)),
            );
            const pathPositions = loadedPathNodeIds
              .filter((nodeId) => Boolean(edgeNodes[nodeId]))
              .map((nodeId) => [
                Number(edgeNodes[nodeId].lat),
                Number(edgeNodes[nodeId].lng),
              ]);

            if (loadedPathNodeIds.length < 2) return [];
            return edgeToGeoJson(
              { positions: pathPositions, isSelected, pathId },
              () => ({
                id: pathId,
                key: `path-${pathId}`,
                width: isSelected ? 14 : 10,
                arrowheadWidth: isSelected ? 20 : 16,
                color: generateColor(pathId, 100, 50),
              }),
              true,
              { frequency: isSelected ? 0.8 : 0.5 },
            );
          }),
      }),
      format: new GeoJSON(),
    });

    return new WebGLLayer({
      source,
      style: getEdgeStyle(),
      zIndex,
    });
  }, [selectedPathId, paths, scenario, visiblePathIds, edgeNodes, nodes]);

  useEffect(() => {
    if (!map || !chainsLayer) return;
    map.addLayer(chainsLayer);
    return () => {
      map.removeLayer(chainsLayer);
    };
  }, [map, chainsLayer]);

  useEffect(() => {
    if (!map) return;
    registerClickListener(clickListener);
    registerHoverListener(hoverListener);
    return () => {
      unregisterClickListener(clickListener);
      unregisterHoverListener(hoverListener);
    };
  }, [map, clickListener, hoverListener]);

  return (
    <MapTooltip visible={Boolean(popupPath)}>
      <div className="bg-dark p-2 px-3 border-dark text-light rounded">
        {Boolean(popupPath) && (
          <div className="d-flex flex-column">
            <div className="fs-6 fw-bold">Chain #{popupPath.id}</div>
            <div>
              $/dth:{' '}
              {priceFormatter.format(
                Number(
                  popupPath.operation_chain.profit /
                    popupPath.operation_chain.delivered_dth,
                ).toFixed(3),
              )}
            </div>
            <div>
              Volume:{' '}
              {Number(popupPath.operation_chain.delivered_dth).toFixed(0)}
              dth
            </div>
            <div>
              Profit:{' '}
              {priceFormatter.format(
                Number(popupPath.operation_chain.profit).toFixed(2),
              )}
            </div>
          </div>
        )}
      </div>
    </MapTooltip>
  );
};
