import { useContext, useEffect, useMemo } from 'react';
import { WebGLLayer } from './webglLayer';
import { useSelector } from 'react-redux';
import { selectFilteredEdges } from '../plotter/plotterSelectors';
import { Vector as VectorSource } from 'ol/source.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import { mapContext } from './EdgeMap';
import { edgeToGeoJson } from '../utils/geoJsonUtils';
import { selectPlotterCapacities } from '../plotter/plotterSlice';

const getEdgeStyle = () => [
  {
    filter: ['<=', ['get', 'head'], 0],
    'stroke-width': 4,
    'stroke-color': ['get', 'color'],
    'stroke-line-cap': 'round',
    'stroke-line-join': 'round',
  },
  {
    filter: ['>', ['get', 'head'], 0],
    'shape-points': 3,
    'shape-radius': 10,
    'shape-fill-color': ['get', 'color'],
    'shape-stroke-color': ['get', 'color'],
    'shape-stroke-width': 4,
    'shape-rotate-with-view': false,
    'shape-displacement': [0, 0],
    'shape-opacity': 1,
    'shape-angle': ['get', 'angle'],
  },
];

/**
 *
 * @param {{edgeId: number, capacityId: number} &
 *  import('../plotter/plotterSlice').CapacityRecord &
 *  import('../services/edgeApi').Edge
 * } data
 * @param {boolean} arrowHead
 * @returns {Object}
 */
const extractGeoJsonProps = (data, _arrowHead) => {
  let color = 'blue';
  if (data.utilization > 80) {
    color = 'red';
  } else if (data.utilization > 50) {
    color = 'orange';
  } else if (data.utilization == 0) {
    color = 'grey';
  }

  return {
    key: `edge-${data.edgeId}`,
    id: data.edgeId,
    color: color,
  };
};

export const MapFlows = ({ zIndex = 1 }) => {
  const {
    map,
    // registerHoverListener,
    // unregisterHoverListener,
    // registerClickListener,
    // unregisterClickListener,
  } = useContext(mapContext);
  const edges = useSelector(selectFilteredEdges);
  const showFlowArrows = useSelector((state) => state.plotter.showFlowArrows);

  const capacities = useSelector(selectPlotterCapacities);

  const capacitiesLayer = useMemo(() => {
    const source = new VectorSource({
      features: new GeoJSON().readFeatures({
        type: 'FeatureCollection',
        features: showFlowArrows
          ? capacities
              .filter((capacity) => capacity.capacity_type === 'SEGMENT')
              .flatMap((capacity) => {
                const record = capacity.best_available;
                if (!record) return [];
                const edgeIds =
                  capacity.flow_direction === 'BACKHAUL'
                    ? [...record.against_edges]
                    : [...record.with_edges];

                return edgeIds
                  .map((edgeId) => ({
                    edge: edges[edgeId],
                    capacity: record,
                    direction: capacity.flow_direction,
                  }))
                  .filter(({ edge }) => edge);
              })
              .flatMap(({ edge, capacity, direction }) => {
                const positions =
                  direction === 'FORWARDHAUL'
                    ? edge.positions
                    : edge.positions.slice().reverse();
                return edgeToGeoJson(
                  {
                    ...capacity,
                    ...edge,
                    positions,
                    edgeId: edge.id,
                    capacityId: capacity.id,
                  },
                  extractGeoJsonProps,
                  true,
                );
              })
          : [],
      }),
      format: new GeoJSON(),
    });

    return new WebGLLayer({
      source,
      style: getEdgeStyle(),
      zIndex,
    });
  }, [edges, showFlowArrows, capacities]);

  useEffect(() => {
    if (!map || !capacitiesLayer) return;
    map.addLayer(capacitiesLayer);
    return () => {
      map.removeLayer(capacitiesLayer);
      capacitiesLayer.dispose();
    };
  }, [map, capacitiesLayer]);

  return null;
};
