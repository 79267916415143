import { createSlice, createSelector } from '@reduxjs/toolkit';
import axios from 'axios';
import process from 'process';
import { generateColor } from '../utils/colors';

/**
 * @typedef {{
 *  id: number,
 *  path_node_ids: number[],
 *  receipt_description: string,
 *  delivery_description: string,
 *  operation_chain: import('../scenarios/scenarioSlice').OperationChain,
 * }} Path
 */

const initialState = {
  paths: {
    // meta: could have name, cost, color, volume, whatever is useful
    // should have start and end point
    // is it better to send edge ids from api, or should we just send the points in order and create the edges dynamically
    // also we could make a path one big polyline instead of a bunch of edges.  Pros and cons to each.  One polyline might render faster? Individual edges can each have separate event listeners so clicking different parts of the path can show different info
    // we can also just send the points instead of edges.  The benefit to that would be that if all the points aren't loaded in the state when we optimize, this can just connect the points that are loaded with no errors.
    // edges: [456,457,458,459,460,461,462,463,464,465,466,467,468,469,470,471,472,473,474,475,476,477,478,479,480,481,482,483,484,485,486,487,488,489,490,491,492,905,906,907,495,496,497,498,819,821,822,500,824,827,635,636,637,638,639,640,641,642,643,644,645,646,647,648,649,650,651,652,653,654,655,687,688,689,690,691,692,693]}
  },
  selectedPathId: null,
  visiblePathIds: [],
};

export const pathsSlice = createSlice({
  name: 'paths',
  initialState,

  reducers: {
    resetState: () => initialState,
    allPathsClick: (state, action) =>
      // need a more robust setup for this.
      // some modes will need multiple source and sinks
      // should be able to change first or second node as needed.
      // should each mode have its own piece of state to hold all this stuff

      !state.firstNode
        ? { ...state, firstNode: action.payload }
        : { ...state, secondNode: action.payload },

    pathDataReceived: (state, action) => {
      // {1:{},3:{}} instead of {1:{},2:{}}
      // don't like how the paths aren't consecutively numbered.  It's a funciton of the optimizer but this is a workaround until we decide how we want to do it
      const results = action.payload;
      const paths = {};
      Object.keys(results).map((resultId, index) => {
        paths[index + 1] = results[resultId];
      });
      return { ...state, paths };
    },

    addPath: (state, action) => {
      const path = action.payload;

      return {
        ...state,
        paths: {
          ...state.paths,
          [path.id]: path,
        },
      };
    },

    removePath: (state, action) => {
      const pathId = action.payload;
      delete state.paths[pathId];
    },

    pathSelected: (state, action) => {
      // this could potentially go in selectedItemsSlice but it's very path specific so i'm putting it here for now
      // for some reason id is being sent as a string and as an int from different dispatches
      const pathId = Number(action.payload.selectedPathId);
      return { ...state, selectedPathId: pathId };
    },

    zoomToPath: (state, action) => ({
      ...state,
      zoomPathId: action.payload,
    }),

    toggleVisibility: (state, action) => {
      // TODO: having to convert to string or number in different occcasions like this is a good argument for using typescript
      const pathId = String(action.payload);
      const pathVisible = state.visiblePathIds.indexOf(pathId) > -1;
      // copy of old array
      let visiblePathIds = state.visiblePathIds.slice();

      if (pathVisible) {
        visiblePathIds = visiblePathIds.filter((id) => id != pathId);
      } else {
        visiblePathIds.push(pathId);
      }

      return { ...state, visiblePathIds };
    },

    setPathsVisible: (state, action) => {
      const pathIds = action.payload;
      return { ...state, visiblePathIds: pathIds };
    },

    hideAll: (state, _action) => ({ ...state, visiblePathIds: [] }),

    showAll: (state, _action) => {
      const allPathIds = Object.keys(state.paths);
      return { ...state, visiblePathIds: allPathIds };
    },
  },
});

// export const fetchMapData = () => async (dispatch) => {
//     let res = await axios.get(`${process.env.REACT_APP_API_URL}/api/map/`)
//     // dispatch(addMapData(res.data))
//
// }

export const markerClicked = () => (_dispatch, _getState) => {
  // console.log('marker clicked in path slice');
};

export const submit = () => async (dispatch, getState) => {
  // console.log('submit paths');
  // moving this logic to the individual mode components so they can each have their own custom submit functionality
  // should submit have the mode passed into it or just get it from here?  Don't like passing things back and forth, seems too coupled.
  // each mode should probably have its own submit function
  // TODO: This should probably be a POST
  // TODO: this URL is shortest path, not all paths
  const receiptPoint = getState().selectedItems.receiptPoints[0];
  const deliveryPoint = getState().selectedItems.deliveryPoints[0];
  // make single endpoint for path finding - send mode along with request
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/shortest_path/?receipt_point=${receiptPoint}&delivery_point=${deliveryPoint}`,
  );
  dispatch(pathDataReceived(res.data));
};

export const {
  resetState,
  allPathsClick,
  pathDataReceived,
  pathSelected,
  addPath,
  removePath,
  zoomToPath,
  toggleVisibility,
  setPathsVisible,
  hideAll,
  showAll,
} = pathsSlice.actions;

// Selectors
export const selectAllNodes = (state) => state.plotter.nodes;
export const selectPoints = (state) => state.plotter.points;
export const selectZoomPathId = (state) => state.paths.zoomPathId;

export const selectPaths = createSelector(
  /**
   * @param {{
   *  paths: {
   *    paths: Object<string, Path>
   *  }
   * }} state
   */
  (state) => state?.paths?.paths,
  (paths) => paths ?? {},
);

export const selectActivePath = createSelector(
  [selectPaths, (state) => state.paths.selectedPathId],
  (paths, selectedPathId) => paths[selectedPathId],
);

export const selectZoomPath = createSelector(
  selectZoomPathId,
  (state) => state.paths.paths,
  (zoomPathId, paths) => paths[zoomPathId],
);
// this is a weird selector, which should really be more like a helper function
// it doesn't actually need anything from the state.  Just here for convenience
// TODO: need a better color palette - lots of options on the internet
export const selectPathColor = (state, pathId) =>
  generateColor(pathId, 100, 50); // HSV bright color

// export const selectPathProfit = (state, pathId) =>
// TODO: probably should be a selector for each path's profit and then a selector that reduces it to a total
export const selectTotalProfit = (state) => {
  const { paths } = state.paths;
  if (Object.keys(paths).length > 0) {
    const profit = Object.keys(paths)
      .map((pathId) => paths[pathId].flow * paths[pathId].profit_per_dth)
      .reduce((previous, current) => previous + current);
    return profit;
  }
  return null;
};

export const selectTotalVolume = (state) => {
  const { paths } = state.paths;
  if (Object.keys(paths).length > 0) {
    const volume = Object.keys(paths)
      .map((pathId) => paths[pathId].flow)
      .reduce((previous, current) => previous + current);
    return volume;
  }
  return null;
};

export default pathsSlice.reducer;
