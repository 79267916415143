import { Collapse, Input } from 'reactstrap';
import { useState, useEffect } from 'react';
import { CloseIcon, EditIcon } from '@iconicicons/react';
import {
  useGetPriceComponentQuery,
  useCreateUpdatePriceComponentMutation,
} from '../services/edgeApi';
import { usePoints } from '../points/usePoints';
import { useDelete, useConfirm } from '../helpers/hooks';
import { SearchButton, SearchField } from '../search/SearchComponents';
import { Can } from '../user/Can';
import { LoadingButton } from '../helpers/LoadingButton';
import { FloatingLabelInput } from '../helpers/FloatingLabelInput';
import { ReusableModal } from '../modals/ReusableModal';
import { DjangoPointSearch } from '../search/DjangoPointSearch';
import { PointListItem } from '../points/PointListItem';

// TODO: refactor
// REFACTOR: this is basically a copy of ComponentLocationManager for capacity components

export function PriceComponentManager({ componentId, setComponent }) {
  const points = usePoints();
  const [
    updateComponent,
    { isLoading: isCreatingUpdating, isSuccess, reset: resetMutation },
  ] = useCreateUpdatePriceComponentMutation();

  // get component - could pass results that we already have, or grab from backend
  const {
    data: component,
    isFetching,
    isLoading,
  } = useGetPriceComponentQuery({ componentId });
  const confirm = useConfirm();
  const [metaOpen, setMetaOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  // component isn't ready on initial load, set in useEffect once component is ready
  const [alteredComponent, setAlteredComponent] = useState(false);

  useEffect(() => {
    setAlteredComponent(component);
  }, [component]);

  useEffect(() => {
    setIsEditing(false);
    resetMutation();
  }, [isSuccess]);

  useEffect(() => {
    if (component && setComponent) {
      setComponent(component);
    }
  }, [component]);

  const addPoint = (id) => {
    // add point ID to point field, update price component
    const priceComponent = {
      ...component,
      points: [...component.points, id],
    };
    updateComponent(priceComponent);
  };

  const removePoint = (removePointId) => {
    if (!confirm('Are you sure?')) {
      return null;
    }
    const priceComponent = {
      ...component,
      points: component.points.filter((point) => point != removePointId),
    };
    updateComponent(priceComponent);
  };

  const handleChange = (evt) => {
    const { name } = evt.target;
    const value =
      evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
    setAlteredComponent({ ...alteredComponent, [name]: value });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (!component) {
    return null;
  }

  return (
    <div>
      {/* TODO: put this in header of modal */}
      <form className="mb-3" onSubmit={() => updateComponent(alteredComponent)}>
        {isEditing ? (
          <FloatingLabelInput
            label="Description"
            onChange={handleChange}
            name="description"
            value={alteredComponent.description}
          />
        ) : (
          <div>
            {component.description}
            <EditIcon
              className="float-end text-warning"
              onClick={() => setIsEditing(!isEditing)}
            />
          </div>
        )}
        {isEditing && (
          <>
            <LoadingButton
              className="btn btn-primary w-50"
              onClick={() => updateComponent(alteredComponent)}
              isLoading={isCreatingUpdating}
            >
              Save
            </LoadingButton>
            <button
              className="btn btn-outline-warning w-50"
              onClick={() => {
                setAlteredComponent(component);
                setIsEditing(false);
              }}
            >
              Cancel
            </button>
          </>
        )}
      </form>
      {/* <EditableField name="flow_direction" value={component.flow_direction} isEditing={isEditing} onChange={}/> */}
      {component.platts_metadata && (
        <div className="card mb-3">
          <div className="card-header" onClick={() => setMetaOpen(!metaOpen)}>
            Platts Metadata -{component.platts_metadata.point_name}
          </div>
          <Collapse isOpen={metaOpen}>
            <div className="card-body">
              {Object.entries(component.platts_metadata).map(([key, value]) => (
                <div key={key}>
                  <span>{key}:</span> <span className="float-end">{value}</span>
                </div>
              ))}
            </div>
          </Collapse>
        </div>
      )}
      <h6>Points</h6>
      <ul className="list-group">
        {component.points.map((pointId) => {
          const point = points[pointId];
          if (!point) {
            return null;
          }
          return (
            <PointListItem point={point} key={point.id}>
              <Can can="add_point">
                <button
                  className="float-end btn btn-sm btn-primary-outline text-danger"
                  onClick={() => removePoint(pointId)}
                >
                  <CloseIcon />
                </button>
              </Can>
            </PointListItem>
          );
        })}
      </ul>
      <Can can="add_point">
        {/* need to calculate results from query and additional filters, which we can pass in */}
        {/* need to add an onclick handler - does it have to be passed in as prop or can it be composed somehow? */}
        <DjangoPointSearch onSelect={addPoint} />
      </Can>
    </div>
  );
}

{
  /* <Results>
  <PointItem />
</Results> */
}
