import { useDispatch, useSelector } from 'react-redux';
import { selectAllNodes, selectAllPoints } from '../plotter/plotterSelectors';
import { BelowMapSetter } from '../layouts/BelowMapContext';
import { BelowMapTab } from '../layouts/BelowMapTab';
import {
  EyeIcon,
  EyeOffIcon,
  FilterIcon,
  ZoomInIcon,
} from '@iconicicons/react';
import {
  plotterActions,
  selectRisePathsChains,
  selectRisePathsLoading,
} from '../plotter/plotterSlice';
import { useTspFilter } from '../helpers/hooks';
import { generateColor } from '../utils/colors';
import { useFitMap } from '../plotter/usePanMap';
import { LoadingIndicator } from '../helpers/LoadingIndicator';

export const RiseTransportTable = () => {
  const dispatch = useDispatch();
  const setTspFilter = useTspFilter();

  const fitMap = useFitMap();

  /** @type {{[key: string]: import('../plotter/plotterSlice').Node}} */
  const allNodes = useSelector(selectAllNodes);

  const points = useSelector(selectAllPoints);

  const riseChains = useSelector(selectRisePathsChains);
  const risePathsLoading = useSelector(selectRisePathsLoading);

  const showRisePaths = useSelector((state) => state.plotter.showRisePaths);

  /**
   *
   * @param {RiseTransportChain} chain
   */
  const zoomToPath = (nodes) => {
    if (nodes.length === 0) {
      return;
    }

    fitMap(nodes);
  };

  /**
   * @param {RiseTransportChain} chain
   */
  const filterTsps = (points) => {
    if (points.length === 0) {
      return;
    }
    setTspFilter(points.map((point) => point.tsp_name));
  };

  const allHidden = riseChains.every((chain) => !chain.transports[0].visible);

  if (!showRisePaths) {
    return null;
  }
  return (
    <BelowMapSetter>
      <BelowMapTab
        key={'rise-transports-table'}
        tabName="Rise Transports"
        contextKey={'rise-transports-table'}
        tabOrder={-1}
      >
        <table className="table">
          <thead>
            <tr>
              <th>
                {allHidden ? (
                  <EyeOffIcon
                    role="button"
                    onClick={() =>
                      dispatch(
                        plotterActions.setRiseFlowsVisibility({
                          visible: true,
                        }),
                      )
                    }
                  />
                ) : (
                  <EyeIcon
                    role="button"
                    onClick={() =>
                      dispatch(
                        plotterActions.setRiseFlowsVisibility({
                          visible: false,
                        }),
                      )
                    }
                  />
                )}
              </th>
              <th>Chain</th>
              <th>Receipt Point</th>
              <th>Delivery Point</th>
              <th>Contract</th>
              <th>Delivery Volume</th>
            </tr>
          </thead>
          <tbody>
            {risePathsLoading ? (
              <tr>
                <td colSpan={100}>
                  <LoadingIndicator className="my-3" />
                </td>
              </tr>
            ) : riseChains.length > 0 ? (
              riseChains.map((chain) => {
                const sortedTransports = chain.transports
                  .slice()
                  .sort((a, b) => a.order - b.order);
                const receiptTransport = sortedTransports[0];
                const deliveryTransport =
                  sortedTransports[sortedTransports.length - 1];
                const receiptPoint = points[receiptTransport.receipt_point_id];
                const deliveryPoint =
                  points[deliveryTransport.delivery_point_id];
                const toggleVisibility = () => {
                  dispatch(
                    plotterActions.setRiseChainVisibility({
                      chainId: receiptTransport.chain_id,
                      visible: !receiptTransport.visible,
                    }),
                  );
                };
                const pathNodes = chain.transports.flatMap((transport) =>
                  transport.path
                    .map((nodeId) => allNodes[nodeId])
                    .filter(Boolean),
                );
                const pathPoints = chain.transports
                  .flatMap((transport) => points[transport.receipt_point_id])
                  .filter(Boolean);
                const hasNodes = pathNodes.length > 0;
                const hasPoints = pathPoints.length > 0;
                return (
                  <tr key={receiptTransport.chain_id}>
                    <td style={{ whiteSpace: 'nowrap' }}>
                      {receiptTransport.visible ? (
                        <EyeIcon role="button" onClick={toggleVisibility} />
                      ) : (
                        <EyeOffIcon role="button" onClick={toggleVisibility} />
                      )}
                      <ZoomInIcon
                        className="ms-2"
                        onClick={() => zoomToPath(pathNodes)}
                        role={hasNodes ? 'button' : 'none'}
                        color={hasNodes ? 'black' : 'gray'}
                      />
                      <FilterIcon
                        className="ms-2"
                        onClick={() => filterTsps(pathPoints)}
                        role={hasPoints ? 'button' : 'none'}
                        color={hasPoints ? 'black' : 'gray'}
                      />
                    </td>
                    <td>
                      <span
                        className="me-2"
                        style={{
                          content: ' ',
                          width: 12,
                          height: 12,
                          borderRadius: '50%',
                          display: 'inline-block',
                          backgroundColor: generateColor(
                            chain.chain_id,
                            80,
                            70,
                          ),
                        }}
                      ></span>
                      {receiptTransport.chain_id}
                    </td>
                    <td>{receiptPoint?.loc_name}</td>
                    <td>{deliveryPoint?.loc_name}</td>
                    <td>
                      {chain.transports
                        .map((t) => t.contract_num)
                        .filter(Boolean)
                        .join(', ')}
                    </td>
                    <td>{deliveryTransport.chain_delivery_volume}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan={100}>
                  <p className="text-center my-3">No Rise Transports</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </BelowMapTab>
    </BelowMapSetter>
  );
};
