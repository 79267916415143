import { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

export function ReusableModal({
  header,
  children,
  footer,
  onClose,
  isOpen,
  ...rest
}) {
  // TODO: Make it pretty
  // TODO: also make a little one that pops up like a tooltip or dropdown menu

  const handleToggle = () => {
    if (isOpen) {
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={handleToggle} {...rest}>
      <ModalHeader>{header}</ModalHeader>
      <ModalBody>{children}</ModalBody>
      <ModalFooter>{footer}</ModalFooter>
    </Modal>
  );
}
