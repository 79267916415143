/**
 * @typedef {{
 *  id: string,
 *  name: string,
 *  tableState: Partial<import('react-pivottable').PivotTableUIProps>
 * }} ViewPreset
 */

/**
 * @type {ViewPreset[]}
 */
export const comprehensiveViewPresets = [
  {
    id: 'blank',
    name: 'Blank',
    tableState: {
      cols: [],
      rows: [],
      vals: [],
      aggregatorName: 'Count',
      rendererName: 'Table',
      valueFilter: {},
    },
  },
  {
    id: 'chain-transport-tsp',
    name: 'Chain Per TSP',
    tableState: {
      cols: [],
      rows: ['Chain Receipt TSP', 'Chain Delivery TSP'],
      vals: ['Chain Delivered Dth'],
      aggregatorName: 'Sum',
      rendererName: 'Table',
    },
  },
  {
    id: 'chain-transport-trade',
    name: 'Chain Per Trade',
    tableState: {
      cols: [],
      rows: ['Chain Receipt Description', 'Chain Delivery Description'],
      vals: ['Chain Delivered Dth'],
      aggregatorName: 'Sum',
      rendererName: 'Table',
    },
  },
  {
    id: 'chain-transport-loc',
    name: 'Chain Per Location',
    tableState: {
      cols: [],
      rows: ['Chain Receipt Location', 'Chain Delivery Location'],
      vals: ['Chain Delivered Dth'],
      aggregatorName: 'Sum',
      rendererName: 'Table',
    },
  },
  {
    id: 'op-transport-tsp',
    name: 'Operation Per TSP',
    tableState: {
      cols: [],
      rows: ['Operation Receipt TSP', 'Operation Delivery TSP'],
      vals: ['Operation Dth'],
      aggregatorName: 'Sum',
      rendererName: 'Table',
      valueFilter: {
        'Operation Origin': {
          null: true,
          undefined: true,
          Energy: true,
        },
      },
    },
  },
  {
    id: 'op-transport-trade',
    name: 'Operation Per Trade',
    tableState: {
      cols: [],
      rows: ['Operation Receipt Description', 'Operation Delivery Description'],
      vals: ['Operation Dth'],
      aggregatorName: 'Sum',
      rendererName: 'Table',
      valueFilter: {
        'Operation Origin': {
          null: true,
          undefined: true,
          Energy: true,
        },
      },
    },
  },
  {
    id: 'op-transport-loc',
    name: 'Operation Per Location',
    tableState: {
      cols: [],
      rows: ['Operation Receipt Location', 'Operation Delivery Location'],
      vals: ['Operation Dth'],
      aggregatorName: 'Sum',
      rendererName: 'Table',
      valueFilter: {
        'Operation Origin': {
          null: true,
          undefined: true,
          Energy: true,
        },
      },
    },
  },
  {
    id: 'labs-vs-energy-tsp',
    name: 'Labs vs Energy Per TSP',
    tableState: {
      cols: ['Operation Origin'],
      rows: ['Operation Receipt TSP', 'Operation Delivery TSP'],
      vals: ['Operation Dth'],
      aggregatorName: 'Sum',
      rendererName: 'Table Col Heatmap',
      valueFilter: {
        'Operation Origin': {
          null: true,
          undefined: true,
        },
        'Operation Receipt TSP': {
          null: true,
          undefined: true,
        },
        'Intra TSP': {
          null: true,
          No: true,
        },
      },
    },
  },
  {
    id: 'labs-vs-energy-loc',
    name: 'Labs vs Energy Per Location',
    tableState: {
      cols: ['Operation Origin'],
      rows: ['Operation Receipt Location', 'Operation Delivery Location'],
      vals: ['Operation Dth'],
      aggregatorName: 'Sum',
      rendererName: 'Table Col Heatmap',
      valueFilter: {
        'Operation Origin': {
          null: true,
          undefined: true,
        },
        'Operation Receipt TSP': {
          null: true,
          undefined: true,
        },
        'Intra TSP': {
          null: true,
          No: true,
        },
      },
    },
  },
  {
    id: 'labs-vs-energy-trade',
    name: 'Labs vs Energy Per Trade',
    tableState: {
      cols: ['Operation Origin'],
      rows: ['Operation Receipt Description', 'Operation Delivery Description'],
      vals: ['Operation Dth'],
      aggregatorName: 'Sum',
      rendererName: 'Table Col Heatmap',
      valueFilter: {
        'Operation Origin': {
          null: true,
          undefined: true,
        },
        'Operation Receipt TSP': {
          null: true,
          undefined: true,
        },
        'Intra TSP': {
          null: true,
          No: true,
        },
      },
    },
  },
];
