import { Input } from 'reactstrap';

export function FloatingLabelInput(props) {
  return (
    <div className="form-floating mb-3">
      <Input {...props} />
      <label>{props.label}</label>
    </div>
  );
}
