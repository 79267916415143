import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import toast from 'react-hot-toast';
import { CloseIcon } from '@iconicicons/react';
import { plotterActions } from '../plotter/plotterSlice';
import * as overlaysActions from './overlaysSlice';
import OverlayForm from './OverlayForm';
import {
  useGetOverlaysQuery,
  useDeleteOverlayMutation,
} from '../services/edgeApi';
import { useDelete } from '../helpers/hooks';
import { Can } from '../user/Can';

export function MapOverlayPicker() {
  const dispatch = useDispatch();
  // console.log('overlays' + JSON.stringify(overlays))

  // const activeOverlayIds = useSelector(state => state.overlays.activeOverlayIds)
  const activeOverlayIds = useSelector(overlaysActions.selectActiveOverlayIds);

  const modal = useSelector((state) => state.overlays.modal);

  /** @type {{data: Array<{id: number, name: string, url: string}, isError: boolean, isLoading: boolean>}} */
  const { data: overlays, error, isLoading } = useGetOverlaysQuery();
  const handleChange = (evt) => {
    const value =
      evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
    const id = evt.target.name;
    dispatch(overlaysActions.updateActiveOverlaysThunk({ id, value }));
    // dispatch(updateActiveNode({'name':name, 'value':value}))
  };

  const [isAddingOverlay, setIsAddingOverlay] = useState(false);

  const [deleteOverlay, result] = useDeleteOverlayMutation();

  const handleDelete = useDelete();

  return (
    <Modal
      isOpen={modal}
      toggle={() => dispatch(overlaysActions.setModal(false))}
    >
      <ModalHeader>
        <div>Select Map Overlays</div>
      </ModalHeader>
      <ModalBody>
        <ul className="list-group list-group-flush">
          {overlays ? (
            overlays
              .slice()
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((overlay) => (
                <li key={overlay.id} className="py-0 list-group-item">
                  <FormGroup key={overlay.id} check>
                    <Input
                      type="checkbox"
                      checked={activeOverlayIds.includes(overlay.id)}
                      // value={mapUrl.active}
                      name={overlay.id}
                      onChange={handleChange}
                    />
                    <Label check>{overlay.name}</Label>
                    <Can can="delete_overlay">
                      <button
                        className="float-end btn btn-outline-default btn-sm text-danger"
                        aria-label="delete-overlay"
                        type="button"
                        onClick={() =>
                          handleDelete(
                            () => deleteOverlay(overlay.id),
                            overlay.name,
                          )
                        }
                      >
                        <CloseIcon />
                      </button>
                    </Can>
                  </FormGroup>
                </li>
              ))
          ) : (
            <div>Loading Overlays</div>
          )}
        </ul>
      </ModalBody>
      <ModalFooter>
        {isAddingOverlay ? (
          <OverlayForm onSuccess={() => setIsAddingOverlay(false)} />
        ) : (
          <button onClick={() => setIsAddingOverlay(true)}>Add Overlay</button>
        )}
      </ModalFooter>
    </Modal>
  );
}
