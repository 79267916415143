import { useMemo } from 'react';
import { numberFormatter } from '../helpers/formatters';

/**
 * @typedef {{
 *  operations: import('../scenarios/scenarioSlice').Operation[],
 *  className: string,
 * }} EdgeFlowProps
 *
 * @param {EdgeFlowProps} props
 * @returns {React.FC<EdgeFlowProps>}
 */
export const EdgeFlowTable = ({ operations, className }) => {
  /**
   * @type {{
   *  totalVolume: number,
   *  totalPerContract: {[k_id: string]: number}
   * }}
   */
  const flowData = useMemo(
    () =>
      operations?.reduce(
        (agg, op) => {
          agg.totalVolume += op.received_dth;
          if (!agg.totalPerContract[op.contract_k_id]) {
            agg.totalPerContract[op.contract_k_id] = 0;
          }
          agg.totalPerContract[op.contract_k_id] += op.received_dth;
          return agg;
        },
        {
          totalVolume: 0,
          totalPerContract: {},
        },
      ) ?? [],
    [operations],
  );

  if (!operations || operations.length == 0) {
    return null;
  }

  return (
    <div>
      <table className={className}>
        <thead>
          <tr>
            <th colSpan="3" className="text-center">
              Flows
            </th>
          </tr>
          <tr>
            <th>#</th>
            <th>Volume</th>
            <th>Contract</th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(flowData.totalPerContract).map((k_id, i) => (
            <tr key={k_id}>
              <th scope="row">{i + 1}</th>
              <td>{numberFormatter(flowData.totalPerContract[k_id])} dth</td>
              <td>{k_id}</td>
            </tr>
          ))}
          <tr>
            <th scope="row">Total</th>
            <td colSpan="2">{numberFormatter(flowData.totalVolume)} dth</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
