import { useMemo, useEffect, useState, useCallback, useRef } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { LoadingIndicator } from '../helpers/LoadingIndicator';
import {
  useGetTSPsQuery,
  useLazyGetNoticeComponentsQuery,
} from '../services/edgeApi';

import { FilterTable, renderOptionalTextCell } from './Table';
import { ChartIcon, FileIcon, SearchIcon } from '@iconicicons/react';
import { NoticeRecordHistory } from './NoticeRecordHistory';
import { toast } from 'react-hot-toast';
import { ReusableModal } from '../modals/ReusableModal';
import { DebouncedInput } from '../helpers/DebounceInput';
import { getRawNoticeUrl, noticeActions } from '../notices/noticeSlice';
import { useDispatch } from 'react-redux';

const SECONDS = 1000;

/**
 * @typedef {{
 *  gasDate: string,
 * }} NoticeComponentTableTableProps
 */

/**
 *
 * @param {NoticeComponentTableTableProps} props
 * @returns {React.FC<NoticeComponentTableTableProps>}
 */
export const NoticeComponentTable = (props) => {
  const { data: tsps } = useGetTSPsQuery();

  const [parsedNotices, setParsedNotices] = useState([]);
  const [noticeComponentsTrigger, { isFetching, isLoading }] =
    useLazyGetNoticeComponentsQuery();
  const columnHelper = createColumnHelper();
  const [componentHistoryId, setComponentHistoryId] = useState(false);
  const callbackSetComponentHistoryId = useCallback(setComponentHistoryId, []);
  const [historyModal, setHistoryModal] = useState(false);
  const [rawNoticeModal, setRawNoticeModal] = useState(false);
  const [rawNoticePath, setRawNoticePath] = useState('');
  const firstRender = useRef(true);
  const maxRecordId = useRef(0);
  const [searchText, setSearchText] = useState('');
  const [filterTSP, setFilterTSP] = useState('');

  const pollingInterval = useRef(null);
  const dispatch = useDispatch();

  const getNotices = async (search = null) => {
    const response = await noticeComponentsTrigger({
      gasDate: props.gasDate,
      search,
      tsp: filterTSP.length > 0 ? filterTSP : null,
    }).unwrap();
    if (!response) {
      console.error('Failed to fetch notice components.');
      return;
    }
    const results = response.results;
    const noticeRecords = Object.values(results).map((result) => {
      return {
        tsp_name: result.tsp_name,
        flow_direction: result.flow_direction,
        oac: result.best_available_notice_record?.oac
          ? `${result.best_available_notice_record.oac} ${result.best_available_notice_record.oac_unit}`
          : `--`,
        ...result.best_available_notice_record,
        id: result.id,
        identifier: result.identifier,
        max_record_id: result.max_record_id, //this field is used to sort by the most recent
        nodes: result.nodes,
        has_notice_records: result.notice_records?.length > 0,
      };
    });
    dispatch(noticeActions.setNoticeRecords({ noticeRecords }));
    setParsedNotices(noticeRecords);
    if (firstRender.current) {
      firstRender.current = false;
      maxRecordId.current = Math.max.apply(
        Math,
        noticeRecords.map(function (o) {
          return o.max_record_id;
        }),
      );
    }
  };

  useEffect(() => {
    setParsedNotices([]);
    getNotices(searchText);
  }, [props.gasDate, filterTSP]);

  useEffect(() => {
    if (searchText.length > 0) {
      clearInterval(pollingInterval.current);
    } else {
      pollingInterval.current = setInterval(getNotices, 30 * SECONDS);
    }
    return () => {
      clearInterval(pollingInterval.current);
    };
  }, [searchText, filterTSP]);

  const [incomingNotices, setIncomingNotices] = useState([]);
  const toastIDs = useRef([]);

  useEffect(() => {
    if (parsedNotices.length > 0 && !firstRender.current) {
      const arr = parsedNotices.filter(
        (item) => item.max_record_id > maxRecordId.current,
      );
      //sameArr is to check if the incoming notices are the same with the previous incoming notices
      //if same, don't pop up a new toast; otherwise, display a new one
      let sameArr = true;
      arr.map((item) => {
        if (!incomingNotices.some((b) => b.id === item.id)) sameArr = false;
      });
      if (arr.length !== 0 && !sameArr) {
        setIncomingNotices(arr);
        toastIDs.current.map((id) => {
          toast.dismiss(id);
        });
        toast(
          (t) => {
            toastIDs.current.push(t.id);
            return (
              <span>
                <b>{arr.length} </b>New Notices Available
                <button
                  style={{
                    border: 'none',
                    borderRadius: '5px',
                    marginLeft: '5px',
                  }}
                  onClick={() => {
                    toast.dismiss(t.id);
                    setIncomingNotices([]);
                    toastIDs.current = [];
                    maxRecordId.current = Math.max.apply(
                      Math,
                      arr.map(function (o) {
                        return o.max_record_id;
                      }),
                    );
                  }}
                >
                  Dismiss
                </button>
              </span>
            );
          },
          { duration: Infinity },
        );
      }
    }
  }, [parsedNotices.map((notice) => notice.id).join(','), parsedNotices]);

  const renderDateCell = (date) => {
    if (date === null || date === undefined) {
      return <div className="fw-light text-center">--</div>;
    } else {
      return <div>{date}</div>;
    }
  };

  const columns = useMemo(
    () => [
      columnHelper.accessor('id', {
        id: 'history',
        header: 'History',
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info) => (
          <button
            className="btn btn-primary-outline w-100"
            onClick={() => {
              callbackSetComponentHistoryId(
                info.row.original?.notice_component ?? false,
              );
              setHistoryModal(true);
            }}
          >
            <ChartIcon></ChartIcon>
          </button>
        ),
      }),
      columnHelper.accessor('max_record_id', {
        id: 'max_record_id',
        header: 'Latest',
        enableColumnFilter: false,
        enableSorting: true,
        cell: renderOptionalTextCell,
      }),
      columnHelper.accessor('raw_notice', {
        id: 'raw_notice',
        header: 'Raw Notice',
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info) => {
          const notice = info.row.original;
          return (
            <button
              className="btn btn-primary-outline"
              onClick={() => {
                setRawNoticeModal(true);
                setRawNoticePath(getRawNoticeUrl(notice));
              }}
            >
              <FileIcon></FileIcon>
            </button>
          );
        },
      }),
      columnHelper.accessor('tsp_name', {
        id: 'tsp_name',
        header: 'Pipeline',
        enableColumnFilter: false,
        enableSorting: true,
        cell: renderOptionalTextCell,
      }),
      columnHelper.accessor('identifier', {
        id: 'identifier',
        header: 'Identifier',
        enableColumnFilter: false,
        enableSorting: true,
        cell: renderOptionalTextCell,
      }),
      columnHelper.accessor('oac', {
        id: 'oac',
        header: 'OAC',
        enableColumnFilter: false,
        enableSorting: true,
        cell: renderOptionalTextCell,
      }),
      columnHelper.accessor('percentage', {
        id: 'percentage',
        header: 'Percentage',
        enableColumnFilter: false,
        enableSorting: true,
        cell: renderOptionalTextCell,
      }),
      columnHelper.accessor('priority', {
        id: 'priority',
        header: 'Priority',
        enableColumnFilter: false,
        enableSorting: true,
        cell: renderOptionalTextCell,
      }),
      columnHelper.accessor('reason', {
        id: 'reason',
        header: 'Reason',
        enableColumnFilter: false,
        enableSorting: true,
        cell: renderOptionalTextCell,
      }),
      columnHelper.accessor('start_gas_date', {
        id: 'start_gas_date',
        header: 'Start Date',
        enableColumnFilter: false,
        enableSorting: true,
        size: 120,
        cell: (info) => {
          return renderDateCell(info.row.original?.start_gas_date);
        },
      }),
      columnHelper.accessor('end_gas_date', {
        id: 'end_gas_date',
        header: 'End Date',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (info) => {
          return renderDateCell(info.row.original?.end_gas_date);
        },
      }),
      // columnHelper.accessor('effective_datetime', {
      //   id: 'effective_datetime',
      //   header: 'Effective Date/Time',
      //   enableColumnFilter: false,
      //   enableSorting: true,
      //   cell: renderDateTimeCell,
      // }),
      columnHelper.accessor('nomination_cycle', {
        id: 'nomination_cycle',
        header: 'Nomination Cycle',
        enableColumnFilter: false,
        enableSorting: true,
        cell: renderOptionalTextCell,
      }),
      columnHelper.accessor('flow_direction', {
        id: 'flow_direction',
        header: 'Direction',
        enableColumnFilter: false,
        enableSorting: true,
        cell: renderOptionalTextCell,
      }),
      columnHelper.accessor('full_text', {
        id: 'full_text',
        header: 'Full Text',
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info) => {
          const style = {
            overflow: 'hidden',
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            height: '70px',
          };
          const title =
            typeof info.getValue() === 'string'
              ? info.getValue().replaceAll(/\n+/g, '\n')
              : info.getValue();
          return (
            <div className="fw-light text-center" style={style} title={title}>
              {info.getValue() ?? '--'}
            </div>
          );
        },
      }),
      // columnHelper.accessor('additional_information', {
      //   id: 'additional_information',
      //   header: 'Additional Information',
      //   enableColumnFilter: false,
      //   enableSorting: false,
      //   cell: renderOptionalTextCell,
      // }),
    ],
    [],
  );

  function RawNoticeModal() {
    return (
      <ReusableModal
        size="lg"
        isOpen={rawNoticeModal}
        toggle={() => setRawNoticeModal(false)}
        header="raw notice data"
      >
        {rawNoticePath.endsWith('.pdf') || rawNoticePath.endsWith('.html') ? (
          <iframe
            title="pdf"
            src={rawNoticePath}
            width="740"
            height="480"
            allow="autoplay"
          />
        ) : (
          'No raw data found.'
        )}
      </ReusableModal>
    );
  }

  return (
    <>
      <div className="row justify-content-between m-3 position-sticky">
        <div className="col-6 col-sm-8 col-xs-11">
          <div className="input-group">
            <span className="input-group-text">
              <SearchIcon />
            </span>
            <DebouncedInput
              type="text"
              className="form-control"
              placeholder="Pipeline or identifier"
              value={searchText}
              onChange={(value) => {
                getNotices(value, 0);
                setParsedNotices([]);
                setSearchText(value);
              }}
            />
          </div>
        </div>
        <div className="col-6 col-sm-4 col-xs-1">
          <div className="input-group">
            <span className="input-group-text">Pipeline</span>
            <select
              value={filterTSP}
              className="form-select"
              onChange={(evt) => setFilterTSP(evt.target.value)}
            >
              <option value="">All</option>
              {tsps &&
                Object.values(tsps).map((tsp) => (
                  <option value={tsp.name} key={tsp.id}>
                    {tsp.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </div>
      <div style={{ minHeight: 200 }}>
        <FilterTable
          columns={columns}
          data={parsedNotices}
          keyField={'id'}
          tableId="notice-component-table"
          defaultSort="max_record_id"
          newIncomingData={incomingNotices}
        />
        {!isFetching && parsedNotices.length === 0 && (
          <div className="alert alert-info m-3 text-center" role="alert">
            No notices found.
          </div>
        )}
        {(isLoading || isFetching) && <LoadingIndicator />}
        {historyModal ? (
          <NoticeRecordHistory
            componentId={componentHistoryId}
            setHistoryModal={setHistoryModal}
            getFlowsOnDate={() => {}}
          ></NoticeRecordHistory>
        ) : (
          ''
        )}
        {rawNoticeModal ? RawNoticeModal() : ''}
      </div>
    </>
  );
};
