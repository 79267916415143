import Layer from 'ol/layer/Layer.js';
import WebGLVectorLayerRenderer from 'ol/renderer/webgl/VectorLayer.js';

export class WebGLLayer extends Layer {
  constructor(options) {
    super(options);
  }
  createRenderer() {
    return new WebGLVectorLayerRenderer(this, {
      className: this.getClassName(),
      style: this.get('style'),
    });
  }
}
