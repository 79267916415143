import { v4 as uuid } from 'uuid';
import {
  createSelector,
  // createAsyncThunk,
  createSlice,
  // createSelector,
} from '@reduxjs/toolkit';
import { toIsoDate } from '../utils/stringUtils';
import { getDiff } from '../utils/objectUtils';
import { priceFormatter, priceFormatterNoCents } from '../helpers/formatters';

// ================================ Types ================================ //
// TODO: We should move some of these types into a more appropriate location
/**
 * @typedef {'PIP' | 'SIP_PS' | 'SIP_SP' | 'SIP_SS' | 'SOP' | 'IT'} CutPriority
 */

/**
 * @typedef {'FORWARDHAUL' | 'BACKHAUL'} SegmentFlowDirection
 */

/**
 * @typedef {'RECEIPT' | 'DELIVERY'} PointFlowDirection
 */

/**
 * @typedef {'markets' | 'trades' | 'segment_constraints' | 'point_constraints' | 'contracts'} ScenarioComponentType
 */

/**
 * @typedef {{ id: string }} ScenarioComponent
 */

/**
 * @typedef {'FLOW' | 'NOTICE' | 'USER_DEFINED'} ConstraintSourceType
 */

/**
 * @typedef {'PLATTS_DAILY' | 'ICE_DAILY' | 'ICE_LIVE' | 'ICE_FUTURE'} PriceDataProvider
 */

/**
 * @typedef {PriceDataProvider | 'MARK_TO_MARKET' | 'SIMULATED_SPREAD' | 'USER_DEFINED'} PriceSource
 */

/**
 * @typedef {'LAST_TRADE' | 'BID_ASK'} PriceType
 */

/**
 * @typedef {'DEFAULT' | 'ALL_PATHS_TO_POINT' | 'BEST_PATHS_TO_POINT' } OptimizationType
 */

/**
 * @typedef {{
 *  id: number,
 *  node: number,
 *  tsp_name: string,
 *  receipt_zone_name: string,
 *  delivery_zone_name: string,
 *  loc_name: string,
 *  loc_id: string,
 *  dir_flo: string,
 *  needs_review: boolean,
 *  compressor: boolean,
 *  verified: boolean,
 * }} Point
 */

/**
 * @typedef {ScenarioComponent & {
 *  cuts_at_priority: CutPriority,
 *  constraint_factor: number,
 *  max_volume: number,
 *  description: string,
 *  short_description?: string,
 *  capacity_component_id?: number,
 *  notice_component_id?: number,
 *  source_id: string,
 *  source_type: ConstraintSourceType
 *  component_key: string
 * }} ScenarioConstraint
 */

/**
 * @typedef {ScenarioConstraint & {
 *  type: 'SEGMENT',
 *  flow_direction: SegmentFlowDirection,
 *  edge: number,
 * }} SegmentConstraint
 */

/**
 * @typedef {ScenarioConstraint & {
 *  type: 'POINT',
 *  flow_direction: PointFlowDirection,
 *  point: number,
 * }} PointConstraint
 */

/**
 * @typedef {ScenarioComponent & {
 *  point: number,
 *  description: string,
 *  buy_sell: 'BUY' | 'SELL' | 'BOTH',
 *  price: number,
 *  base_price: number,
 *  price_component?: number,
 *  price_component_description?: string,
 *  adder?: number
 * }} Price
 */

/**
 * @typedef {Price & {
 *  price_source: PriceSource,
 *  max_volume: number,
 *  component_key: string,
 * }} Market
 */

/**
 * @typedef {Price & {
 *  volume: number
 * }} Trade
 */

/**
 * @typedef {ScenarioComponent & {
 *  k_id: string,
 *  owner: string,
 *  name: string,
 *  description: string,
 *  tsp: string,
 *  tsp_name: string,
 *  type: string,
 *  rate_schedule_type: string,
 *  max_daily_quantity: number,
 *  star_date: string,
 *  end_date: string,
 *  max_daily_quantity: number,
 *  delivery_points: string[],
 *  delivery_zones: string[],
 *  receipt_points: string[],
 *  receipt_zones: string[],
 *  edge_classification:
 *    Array<{edge_ids: Array<number>, type: string}>
 * }} Contract
 */

/**
 * @typedef {'ADD' | 'REMOVE' | 'UPDATE'} SettingsActionType
 */

/**
 * @template {ScenarioComponentType} T
 * @typedef {{
 *  type: SettingsActionType,
 *  data: Partial<Scenario[T][0]>,
 * }} ScenarioComponentSettingsAction
 */

/**
 * @template {ScenarioComponentType} T
 * @typedef {{
 *  actions: ScenarioComponentSettingsAction<T>[]
 * }} ScenarioComponentSettings
 */

/**
 * @typedef {string | number} OperationChainItem
 */

/**
 * @typedef {{id: number, name: string, color: string}} TagType
 */

/**
 * @typedef {{
 *  color: string,
 *  id: number,
 *  item_id: number,
 *  item_type: string,
 *  name: string,
 *  order: number,
 *  payload: Object,
 *  type: TagType,
 *  user: string,
 * }} Tag
 */

/**
 * @typedef {{
 *  id: string,
 *  profit: number,
 *  delivered_dth: number,
 *  operations: OperationChainItem[],
 *  is_new?: boolean,
 *  chain_info?: {
 *    id: number,
 *    symbol: string,
 *    name: string,
 *    tags: Tag[],
 *    has_annotations: boolean,
 *  }
 * }} OperationChain
 */

/**
 * @typedef {'KEXCHANGE' | 'MARKET' | 'TRADE'} AssetType
 */

/**
 * @typedef {{
 *  id: number,
 *  is_reciprocal_op: boolean,
 *  total_profit: number,
 *  delivery_asset_type: AssetType,
 *  receipt_asset_type: AssetType,
 *  delivered_dth: number,
 *  received_dth: number,
 *  receipt_asset_price: number,
 *  receipt_asset_object_id: string,
 *  delivery_asset_price: number,
 *  delivery_asset_object_id: string,
 *  contract_id: string,
 *  contract_name: string,
 *  contract_k_id: string,
 *  tsp_name: string,
 *  path_node_ids: string[],
 *  receipt_description: string,
 *  delivery_description: string,
 *  receipt_point: Point,
 *  delivery_point: Point,
 *  transport_priority: string,
 *  has_commodity_rate: boolean,
 *  commodity_rate_url: string,
 *  has_fuel_rate: boolean,
 *  fuel_rate_url: string,
 *  fuel_factor: number,
 *  constraints: Array<{
 *    id: number,
 *    type: "SEGMENT" | "POINT",
 *  }>,
 *  chain_links: Array<{
 *    operation_chain_id: number,
 *    order: number,
 *    delivered_dth: number,
 *    received_dth: number,
 *    delivery_description: string,
 *    receipt_description: string,
 *  }>
 *  receipt_asset_name: string | null,
 *  delivery_asset_name: string | null,
 * }} Operation
 */

/**
 * @typedef {{
 *  markets: ScenarioComponentSettings<"markets">,
 *  contracts: ScenarioComponentSettings<"contracts">,
 *  trades: ScenarioComponentSettings<"trades">,
 *  segment_constraints: ScenarioComponentSettings<"segment_constraints">,
 *  point_constraints: ScenarioComponentSettings<"point_constraints">,
 *  price_source: PriceSource,
 *  price_type: PriceType,
 *  simulate_spread: boolean,
 *  optimization_type: OptimizationType,
 *  optimization_settings: {
 *    delivery: number,
 *    receipt: Array<{
 *      id: number,
 *      loc_name?: string,
 *    }>,
 *  }
 * }} ScenarioSettings We can use this to store user settings data about scenario creation/configuration
 */

/**
 * @typedef {{
 *  id: string,
 *  name: string,
 *  description: string,
 *  flow_date: string,
 *  optimize: boolean,
 *  multiCycle: boolean,
 *  prev_in_group: string | null,
 *  next_in_group: string | null,
 *  previous_version: number | null,
 *  locked: boolean,
 *  settings: ScenarioSettings,
 *  trades: Trade[],
 *  contracts: Contract[],
 *  segment_constraints: SegmentConstraint[],
 *  point_constraints: PointConstraint[],
 *  markets: Market[],
 *  operation_chains: OperationChain[],
 *  operations: Operation[]
 * }} Scenario This is the data structure that will be used to create a scenario
 */

/**
 * @typedef {{
 *  ids: {[key: string]: boolean},
 *  tsps: {[key: string]: boolean},
 *  delivery: {[key: string]: boolean},
 *  receipt: {[key: string]: boolean},
 *  tags: {[key: string]: boolean},
 *  negative: boolean,
 *  conjunctive: boolean,
 * }} ChainFilters
 */

/**
 * @typedef {{
 *  isEditing: boolean,
 *  chainFilters: ChainFilters,
 *  pristineData: Scenario | null,
 *  data: Scenario,
 * }} ScenarioState
 */

/**
 * @type {ScenarioComponentType[]}
 */
export const componentTypes = [
  'markets',
  'trades',
  'segment_constraints',
  'point_constraints',
  'contracts',
];

// ================================ Initial State ================================ //

const now = new Date();
const defaultDate = toIsoDate(now);
const defaultTime =
  ('0' + now.getHours()).slice(-2) + ('0' + now.getMinutes()).slice(-2);

/**
 * @type {ScenarioState}
 */
const initialState = {
  isEditing: false,
  chainFilters: {
    ids: {},
    tsps: {},
    delivery: {},
    receipt: {},
    tags: {},
    negative: false,
    conjunctive: false,
  },
  pristineData: null,
  data: {
    id: 'new',
    name: `Scenario ${defaultDate} ${defaultTime}`,
    flow_date: defaultDate,
    optimize: true,
    multiCycle: false,
    trades: [],
    contracts: [],
    segment_constraints: [],
    point_constraints: [],
    markets: [],
    operation_chains: [],
    operations: [],
    settings: {
      price_source: 'ICE_LIVE',
      price_type: 'BID_ASK',
      simulate_spread: false,
      optimization_type: 'DEFAULT',
      optimization_settings: {
        delivery: null,
        receipt: [],
      },
      markets: {
        actions: [],
      },
      contracts: {
        actions: [],
      },
      trades: {
        actions: [],
      },
      segment_constraints: {
        actions: [],
      },
      point_constraints: {
        actions: [],
      },
    },
  },
};

// ================================ Private Methods ================================ //

/**
 * @template {ScenarioComponentType} T
 * @type {{ [key in T]: (a: Scenario[key][0], b: Scenario[key][0]) => boolean }}}
 */
const _compareFunctions = {
  markets: (a, b) => a.point === b.point && a.buy_sell === b.buy_sell,
  trades: (a, b) => a.point === b.point && a.buy_sell === b.buy_sell,
  contracts: (a, b) => a.id === b.id,
  segment_constraints: (a, b) => {
    return a.component_key === b.component_key;
  },
  point_constraints: (a, b) => {
    return a.component_key === b.component_key;
  },
};

/**
 * @param {string} componentType
 * @returns {boolean}
 * @private
 */
const _validateComponentType = (componentType) =>
  componentTypes.includes(componentType);

/**
 *
 * @param {Scenario} scenario
 * @param {ScenarioComponentType} componentType
 * @param {string} componentId
 * @returns {number | null}
 * @private
 */
const _findComponentIndex = (scenario, componentType, componentId) => {
  if (_validateComponentType(componentType) === false) {
    console.error(`Invalid componentType: ${componentType}`);
    return null;
  }

  if (!componentId) {
    return -1;
  }

  const componentIndex = scenario[componentType].findIndex(
    (c) => c.id?.toString() === componentId.toString(),
  );

  return componentIndex;
};

/**
 *
 * @template {ScenarioComponentType} T
 *
 * @param {T} componentType
 * @param {Scenario[T]} components
 * @param {ScenarioComponentSettingsAction<T>} action
 * @returns {Scenario[T]}
 */
const _applyAddSetting = (componentType, components, action) => {
  return [action.data, ...components];
};

/**
 *
 * @template {ScenarioComponentType} T
 *
 * @param {T} componentType
 * @param {Scenario[T]} components
 * @param {ScenarioComponentSettingsAction<T>} action
 * @returns {Scenario[T]}
 */
const _applyRemoveSetting = (componentType, components, action) => {
  let compare = _compareFunctions[componentType];
  return components.filter((c) => compare(c, action.data) === false);
};

/**
 *
 * @template {ScenarioComponentType} T
 *
 * @param {T} componentType
 * @param {Scenario[T]} components
 * @param {ScenarioComponentSettingsAction<T>} action
 * @returns {Scenario[T]}
 */
const _applyUpdateSetting = (componentType, components, action) => {
  let compare = _compareFunctions[componentType];
  return components.map((c) => {
    if (compare(c, action.data)) {
      return { ...c, ...action.data };
    }
    return c;
  });
};

/**
 *
 * @template {ScenarioComponentType} T
 *
 * @param {ScenarioComponentSettings} settings
 * @param {T} componentType
 * @param {Scenario[T]} components
 * @returns {Scenario[T]}
 */
const _applySettingsForComponent = (settings, componentType, components) => {
  let componetsCopy = [...components];
  settings[componentType].actions.forEach((action) => {
    switch (action.type) {
      case 'ADD':
        componetsCopy = _applyAddSetting(componentType, componetsCopy, action);
        break;
      case 'REMOVE':
        componetsCopy = _applyRemoveSetting(
          componentType,
          componetsCopy,
          action,
        );
        break;
      case 'UPDATE':
        componetsCopy = _applyUpdateSetting(
          componentType,
          componetsCopy,
          action,
        );
        break;
    }
  });
  return componetsCopy;
};

/**
 * @template {ScenarioComponentType} T
 *
 * @param {T} componentType
 * @param {ScenarioComponentSettings<T>} settings
 * @param {SettingsActionType} actionType
 * @param {Scenario[T][0]} component
 * @returns {ScenarioComponentSettings<T>}
 */
const _revertSettingsForComponent = (
  componentType,
  settings,
  actionType,
  component,
) => {
  const compare = _compareFunctions[componentType];
  const actions = settings[componentType].actions.filter(
    (action) =>
      action.type !== actionType || compare(action.data, component) === false,
  );
  return { actions };
};

/**
 *
 * @param {Scenario} scenario
 * @returns {Scenario}
 */
const _applySettings = (scenario) => {
  const updatedScenario = { ...scenario };

  componentTypes
    .filter((componentType) => componentType !== 'contracts')
    .forEach((componentType) => {
      updatedScenario.data[componentType] = _applySettingsForComponent(
        scenario.settings,
        componentType,
        scenario.data[componentType],
      );
    });

  return updatedScenario;
};

/**
 *
 * @param {Scenario} scenario
 * @returns  {Scenario}
 */
const _toAllPathsToAPointFormat = (scenario) => {
  const receipt = scenario.settings.optimization_settings.receipt;
  const delivery = scenario.settings.optimization_settings.delivery;
  const simulateSpread = scenario.settings.simulate_spread;
  /** @type {Market[]} */
  let markets = [];

  markets = scenario.markets.reduce((acc, market) => {
    const isReceiptPoint =
      receipt.length === 0 ||
      receipt.some((point) => point.id.toString() === market.point.toString());
    const isDeliveryPoint = delivery.toString() === market.point.toString();
    const isBuy = market.buy_sell === 'BUY' || market.buy_sell === 'BOTH';
    const isSell = market.buy_sell === 'SELL' || market.buy_sell === 'BOTH';

    if (isDeliveryPoint && isSell) {
      const price = simulateSpread ? 10 : market.price;
      acc.push({ ...market, price, base_price: price, buy_sell: 'SELL' });
    } else if (isReceiptPoint && isBuy) {
      const price = simulateSpread ? 1 : market.price;
      acc.push({ ...market, price, base_price: price, buy_sell: 'BUY' });
    }
    return acc;
  }, []);

  /** @type {Scenario} */
  const updatedScenario = {
    ...scenario,
    markets,
    settings: {
      ...scenario.settings,
      optimization_settings: {
        delivery: Number(delivery),
        receipt: receipt.map((point) => Number(point.id)),
      },
    },
  };
  return updatedScenario;
};

// ================================ Public Methods ================================ //

/**
 *
 * @param {SegmentConstraint} constraint
 * @returns {string}
 */
export const segmentConstraintKeyExtractor = (constraint) => {
  return constraint.component_key;
};

/**
 *
 * @param {PointConstraint} constraint
 * @returns {string}
 */
export const pointConstraintKeyExtractor = (constraint) => {
  return constraint.component_key;
};

/**
 *
 * @param {Market} market
 * @returns {string}
 */
export const marketKeyExtractor = (market) => {
  return market.component_key;
};

/**
 * @param {Scenario} scenario
 * @param {OperationChain} chain
 * @returns {string}
 */
export const chainSummary = (scenario, chain) => {
  const chainOperations = chain.chain_links
    .filter((chain_link) => chain_link.has_flow == true)
    .map((chain_link) => {
      const operation = scenario.operations.find(
        (op) => op.id === chain_link.operation_id,
      );
      return {
        ...operation,
        chain_link,
      };
    });

  const receiptOp = chainOperations[0];
  const deliveryOp = chainOperations[chainOperations.length - 1];

  const receiptMarket =
    scenario.markets.find(
      (market) =>
        market.point.toString() === receiptOp.receipt_point.id.toString() &&
        market.buy_sell !== 'SELL',
    ) ||
    scenario.trades.find((trade) => {
      return (
        trade.point.toString() === receiptOp.receipt_point.id.toString() &&
        trade.buy_sell !== 'SELL'
      );
    });

  const deliveryMarket =
    scenario.markets.find(
      (market) =>
        market.point.toString() === deliveryOp.delivery_point.id.toString() &&
        market.buy_sell !== 'BUY',
    ) ||
    scenario.trades.find((trade) => {
      return (
        trade.point.toString() === deliveryOp.delivery_point.id.toString() &&
        trade.buy_sell !== 'BUY'
      );
    });

  let overallTransportCost = 0;

  const endpoints = [
    {
      Type: 'Receipt',
      Pipeline: receiptOp.tsp_name,
      Point: receiptOp.receipt_point.loc_name,
      'Loc ID': receiptOp.receipt_point.loc_id,
      Operation: 'Buy',
      Market: receiptMarket.description,
      Price: priceFormatter.format(receiptMarket.price),
    },
    {
      Type: 'Delivery',
      Pipeline: deliveryOp.tsp_name,
      Point: deliveryOp.delivery_point.loc_name,
      'Loc ID': deliveryOp.delivery_point.loc_id,
      Operation: 'Sell',
      Market: deliveryMarket.description,
      Price: priceFormatter.format(deliveryMarket.price),
    },
  ];
  const operations = chainOperations.map((op) => {
    const fuelCost = chain.fuel_makeup_price * (op.fuel_factor - 1);
    const transportCost = Number(op.transport_cost_per_dth ?? 0) + fuelCost;
    overallTransportCost += transportCost;
    return {
      Receipt: `${op.receipt_point.loc_id} - ${op.receipt_point.loc_name} - ${op.receipt_point.receipt_zone_name}`,
      Delivery: `${op.delivery_point.loc_id} - ${op.delivery_point.loc_name} - ${op.delivery_point.delivery_zone_name}`,
      Pipeline: op.tsp_name,
      'Received Volume': op.chain_link.received_dth.toFixed(0),
      'Delivered Volume': op.chain_link.delivered_dth.toFixed(0),
      Priority: op.transport_priority,
      Contract: `${op.contract_name} (${op.contract_k_id})`,
      'Variable Cost': priceFormatter.format(op.transport_cost_per_dth),
      'Fuel Cost': priceFormatter.format(fuelCost),
      'Transport Cost': priceFormatter.format(transportCost),
    };
  });
  const segmentConstraints = chainOperations.flatMap((op) => {
    return op.constraints
      .filter((constraint) => constraint.type === 'SEGMENT')
      .map(({ id: constraintId }) => {
        const constraint = scenario.segment_constraints.find(
          (constraint) => Number(constraint.id) === constraintId,
        );
        if (!constraint) return null;
        return {
          'Constraint Type': 'Segment',
          Description: constraint.short_description ?? constraint.description,
          // Add this back when we start using constraint_factor
          // 'Utilization Rate': `${constraint.constraint_factor * 100}%`,
          'Max Capacity': `${constraint.max_volume} dth`,
          Direction: constraint.flow_direction,
          'Cut Priority': constraint.cuts_at_priority,
        };
      })
      .filter((item) => item !== null);
  });
  const pointConstraints = chainOperations.flatMap((op) => {
    return op.constraints
      .filter((constraint) => constraint.type === 'POINT')
      .map(({ id: constraintId }) => {
        const constraint = scenario.point_constraints.find(
          (constraint) => Number(constraint.id) === constraintId,
        );
        if (!constraint) return null;
        return {
          'Constraint Type': 'Point',
          Description: constraint.short_description ?? constraint.description,
          // Add this back when we start using constraint_factor
          // 'Utilization Rate': `${constraint.constraint_factor * 100}%`,
          'Max Capacity': `${constraint.max_volume} dth`,
          Direction: constraint.flow_direction,
          'Cut Priority': constraint.cuts_at_priority,
        };
      })
      .filter((item) => item !== null);
  });
  const constraints = [...pointConstraints, ...segmentConstraints];
  const overview = {
    Profit: priceFormatterNoCents.format(Number(chain.profit).toFixed(0)),
    Volume: chain.delivered_dth.toFixed(0),
    'Profit/Dth': priceFormatter.format(chain.profit / chain.delivered_dth),
    'Total Transport Cost': priceFormatter.format(overallTransportCost),
  };

  return {
    overview,
    endpoints,
    operations,
    constraints,
  };
};

/**
 *
 * @param {PriceSource} price_source
 * @returns {string}
 */
export const formatPriceSource = (price_source) => {
  switch (price_source) {
    case 'PLATTS_DAILY':
      return 'Platts Daily';
    case 'ICE_DAILY':
      return 'ICE Daily';
    case 'ICE_LIVE':
      return 'ICE Live';
    case 'USER_DEFINED':
      return 'User Defined';
    case 'MARK_TO_MARKET':
      return 'Mark to Market';
    case 'ICE_FUTURE':
      return 'ICE Future';
    case 'SIMULATED_SPREAD':
      return 'Simulated Spread';
    default:
      return price_source;
  }
};

/**
 * @param {Scenario} scenario
 * @returns {Object}
 */
export const toApiFormat = (scenario) => {
  let apiFormatScenario = scenario;
  if (
    scenario.settings.optimization_type === 'ALL_PATHS_TO_POINT' ||
    scenario.settings.optimization_type === 'BEST_PATHS_TO_POINT'
  ) {
    apiFormatScenario = _toAllPathsToAPointFormat(scenario);
  }

  const { contracts, segment_constraints, point_constraints, ...rest } =
    apiFormatScenario;

  return {
    ...rest,
    point_constraints: point_constraints.map((constraint) => ({
      ...constraint,
      short_description: constraint.short_description ?? constraint.description,
    })),
    segment_constraints: segment_constraints.map((constraint) => ({
      ...constraint,
      short_description: constraint.short_description ?? constraint.description,
    })),
    markets: apiFormatScenario.markets.map((market) => ({
      ...market,
      base_price: market.base_price ?? 0,
    })),
    trades: apiFormatScenario.trades.map((trade) => ({
      ...trade,
      base_price: trade.base_price ?? 0,
    })),
    contracts: contracts.map((contract) => contract.id.toString()),
  };
};

/**
 *
 * @param {Object} scenario
 * @returns {Scenario}
 */
export const fromApiFormat = (scenario = {}) => {
  const {
    id = 'new',
    name = 'New Scenario',
    description = '',
    flow_date = toIsoDate(new Date()),
    segment_constraints = [],
    point_constraints = [],
    markets = [],
    trades = [],
    contracts = [],
    operation_chains = [],
    operations = [],
    settings,
    ...rest
  } = scenario;

  return {
    ...rest,
    id,
    name,
    description,
    flow_date,
    segment_constraints,
    point_constraints,
    markets,
    trades,
    contracts: Object.values(contracts).map((contract) => {
      return { id: contract.toString(), ...contract };
    }),
    operation_chains: operation_chains.map((chain) => ({
      ...chain,
      chain_info: {
        ...chain.chain_info,
        tags: Object.values(chain.chain_info.tags ?? {}),
      },
    })),
    operations,
    settings: {
      price_source: 'PLATTS_DAILY',
      price_type: 'LAST_TRADE',
      segment_constraints: { actions: [] },
      point_constraints: { actions: [] },
      markets: { actions: [] },
      contracts: { actions: [] },
      trades: { actions: [] },
      ...settings,
      optimization_settings: {
        ...settings.optimization_settings,
        receipt:
          settings.optimization_settings?.receipt?.map((point) => ({
            id: point,
          })) ?? [],
      },
    },
    optimize: true,
    multiCycle: false,
  };
};

// ================================ Reducers ================================ //

/**
 * @param {ScenarioState} initialState
 */
const resetState = (initialState) => initialState;

/**
 *
 * @param {ScenarioState} state
 * @param {void} _action
 * @returns {void}
 */
const beginEditing = (state, _action) => {
  if (state.isEditing === true) {
    console.warn('State beginEdit called but isEditing is already true');
    return;
  }
  state.pristineData = state.data;
  state.isEditing = true;
};

/**
 *
 * @param {ScenarioState} state
 * @param {void} _action
 * @returns {void}
 */
const rollback = (state, _action) => {
  if (state.isEditing !== true) {
    console.warn('State rollback called but isEditing is not true');
    return;
  }
  state.data = state.pristineData;
  state.isEditing = false;
};

/**
 *
 * @param {ScenarioState} state
 * @param {void} _action
 * @returns {void}
 */
const commit = (state, _action) => {
  if (state.isEditing !== true) {
    console.warn('State commit called but isEditing is not true');
    return;
  }
  state.pristineData = [];
  state.isEditing = false;
};

// TODO: We must consider checking if the scenario is in edit mode before modifying the state
// maybe throw an error or simply return if not in edit mode

/**
 *
 * @template {ScenarioComponentType} T
 *
 * @param {ScenarioState} state
 * @param {{
 *  payload: {
 *    componentType: T,
 *    components: Scenario[T],
 *    applySettings: boolean,
 *  },
 * }} action
 * @returns {void}
 */
const overwrite = (state, action) => {
  const {
    componentType,
    components = [],
    applySettings = false,
  } = action.payload;

  if (_validateComponentType(componentType) === false) {
    console.error(`Invalid componentType: ${componentType}`);
    return;
  }

  /** @type {Scenario[T]} */
  let targetComponents = components.map((scenarioComponent) => {
    if (!scenarioComponent.id) {
      return { ...scenarioComponent, id: uuid() };
    }
    return scenarioComponent;
  });

  if (applySettings) {
    targetComponents = _applySettingsForComponent(
      state.data.settings,
      componentType,
      targetComponents,
    );
  }

  state.data[componentType] = targetComponents;
};

/**
 * @param {ScenarioState} state
 * @template {keyof ChainFilters} T
 * @param {{
 *  payload: {
 *    type: T,
 *    value: ChainFilters[T]
 *  }
 * }} action
 */
const setChainFilters = (state, action) => {
  const { type, value } = action.payload;
  state.chainFilters[type] = value;
};

/**
 * @param {ScenarioState} state
 * @param {{
 *  payload: undefined,
 * }} action
 */
const resetFilters = (state, _action) => {
  state.chainFilters = initialState.chainFilters;
};

/**
 *
 * @param {ScenarioState} state
 * @param {{
 *  payload: {
 *    scenario: Scenario
 *  }
 * }} action
 */
const setScenario = (state, action) => {
  const { scenario } = action.payload;
  state.data = { ...initialState.data, ...scenario };
  state.chainFilters = initialState.chainFilters;
};

/**
 *
 * @param {ScenarioState} state
 * @param {{
 *  payload: {
 *    name: string
 *  }
 * }} action
 */
const setName = (state, action) => {
  const { name } = action.payload;
  state.data.name = name;
};

/**
 *
 * @param {ScenarioState} state
 * @param {{
 *  payload: {
 *    description: string
 *  }
 * }} action
 */
const setDescription = (state, action) => {
  const { description } = action.payload;
  state.data.description = description;
};

/**
 *
 * @param {ScenarioState} state
 * @param {{
 *  payload: {
 *    source: PriceSource
 *  }
 * }} action
 */
const setPriceSource = (state, action) => {
  const { source } = action.payload;
  state.data.settings.price_source = source;
};

/**
 *
 * @param {ScenarioState} state
 * @param {{
 *  payload: {
 *    priceType: PriceType
 *  }
 * }} action
 */
const setPriceType = (state, action) => {
  const { priceType } = action.payload;
  state.data.settings.price_type = priceType;
};

/**
 *
 * @param {ScenarioState} state
 * @param {{
 *  payload: {
 *    simulateSpread: boolean
 *  }
 * }} action
 */
const setSimulateSpread = (state, action) => {
  const { simulateSpread } = action.payload;
  state.data.settings.simulate_spread = simulateSpread;
};

/**
 *
 * @param {ScenarioState} state
 * @param {{
 *  payload: {
 *    flow_date: string
 *  }
 * }} action
 */
const setFlowDate = (state, action) => {
  const { flow_date } = action.payload;
  state.data.flow_date = flow_date;
};

/**
 *
 * @param {ScenarioState} state
 * @param {{
 *  payload: {
 *    optimizationType: OptimizationType,
 *  }
 * }} action
 */
const setOptimizationType = (state, action) => {
  const { optimizationType } = action.payload;
  state.data.settings.optimization_type = optimizationType;
};

/**
 *
 * @param {ScenarioState} state
 * @param {{
 *  payload: {
 *    optimizationSettings: ScenarioSettings['optimization_settings'],
 *  }
 * }} action
 */
const setOptimizationSettings = (state, action) => {
  state.data.settings.optimization_settings = {
    ...state.data.settings.optimization_settings,
    ...action.payload.optimizationSettings,
  };
};

/**
 * TODO: Maybe use a different action for saving to user settings
 * @template {ScenarioComponentType} T
 *
 * @param {ScenarioState} state
 * @param {{
 *  payload: {
 *    componentType: T,
 *    component: Scenario[T][0],
 *    saveSetting: boolean,
 *  }
 * }} action
 * @brief Modifies the specified component and saves the modification to user settings if saveSetting is true
 */
const updateComponent = (state, action) => {
  const { componentType, component, saveSetting = true } = action.payload;

  const componentIndex = _findComponentIndex(
    state.data,
    componentType,
    component.id,
  );

  if (componentIndex === null) return;

  if (componentIndex === -1) {
    const id = component.id ? component.id : uuid();
    if (
      ['segment_constraints', 'point_constraints', 'markets'].includes(
        componentType,
      )
    ) {
      component.component_key =
        component.component_key?.length > 0 ? component.component_key : uuid();
    }
    state.data[componentType].unshift({
      ...component,
      id,
    });
    if (saveSetting)
      state.data.settings[componentType].actions.push({
        type: 'ADD',
        data: { ...component, id },
      });
    return;
  }

  const oldComponent = state.data[componentType][componentIndex];
  state.data[componentType][componentIndex] = component;
  if (saveSetting)
    state.data.settings[componentType].actions.push({
      type: 'UPDATE',
      data: {
        ...getDiff(component, oldComponent),
        id: component.id,
        ...(componentType === 'segment_constraints' ||
        componentType === 'point_constraints'
          ? {
              source_type: component.source_type,
              source_id: component.source_id,
              edge: component.edge,
              point: component.point,
            }
          : {}),
        ...(componentType === 'markets' || componentType === 'trades'
          ? {
              buy_sell: component.buy_sell,
              point: component.point,
            }
          : {}),
      },
    });
};

/**
 *
 * @template {ScenarioComponentType} T
 *
 * @param {ScenarioState} state
 * @param {{
 *  payload: {
 *    componentType: T,
 *    components: Array<Scenario[T][0]>,
 *    saveSetting: boolean,
 *  }
 * }} action
 */
const appendComponents = (state, action) => {
  const { componentType, saveSetting = true } = action.payload;

  const components = action.payload.components.map((component) => {
    return {
      ...component,
      id: component.id ? component.id : uuid(),
    };
  });

  state.data[componentType] = [...components, ...state.data[componentType]];

  if (saveSetting) {
    const actions = components.map((component) => {
      return {
        type: 'ADD',
        data: { ...component },
      };
    });
    state.data.settings[componentType].actions = [
      ...actions,
      ...state.data.settings[componentType].actions,
    ];
  }
};

/**
 * @template {ScenarioComponentType} T
 *
 * @param {ScenarioState} state
 * @param {{
 *  payload: {
 *    componentType: T,
 *    component: Partial<Scenario[T][0]>
 *  }
 * }} action
 */
const revertUpdateSettings = (state, action) => {
  const { componentType, component } = action.payload;
  state.data.settings[componentType] = _revertSettingsForComponent(
    componentType,
    state.data.settings,
    'UPDATE',
    component,
  );
};

/**
 *
 * @param {ScenarioState} state
 * @param {{
 *  payload: {
 *    componentType: ScenarioComponentType,
 *    componentId: string,
 *    saveSetting: boolean,
 *  }
 * }} action
 * @returns {void}
 */
const removeComponent = (state, action) => {
  const { componentType, componentId, saveSetting = true } = action.payload;

  const componentIndex = _findComponentIndex(
    state.data,
    componentType,
    componentId,
  );
  if (componentIndex === null) {
    console.error(`Invalid componentType: ${componentType}`);
    return;
  }

  if (componentIndex === -1) {
    console.warn(`Component ${componentId} not found in ${componentType}`);
    return;
  }

  const removedComponent = state.data[componentType].splice(
    componentIndex,
    1,
  )[0];
  // state.data[componentType].filter((component) => component.id !== componentId);
  if (saveSetting)
    state.data.settings[componentType].actions.push({
      type: 'REMOVE',
      data: removedComponent,
    });
};

/**
 *
 * @template {ScenarioComponentType} T
 * @param {ScenarioState} state
 * @param {{
 *  payload: {
 *    componentType: T,
 *    component: Scenario<T>[0],
 *  }
 * }} action
 */
const restoreRemovedComponent = (state, action) => {
  const { componentType, component } = action.payload;

  if (_validateComponentType(componentType) === false) {
    console.error(`Invalid componentType: ${componentType}`);
    return;
  }

  // const compare = _compareFunctions[componentType];
  // const actions = state.data.settings[componentType].actions.filter(
  //   (action) =>
  //     action.type !== 'REMOVE' || compare(action.data, component) === false,
  // );
  // state.data.settings[componentType].actions = actions;
  state.data.settings[componentType] = _revertSettingsForComponent(
    componentType,
    state.data.settings,
    'REMOVE',
    component,
  );
  state.data[componentType] = [component, ...state.data[componentType]];
};

/**
 * @template {ScenarioComponentType} T
 * @param {ScenarioState} state
 * @param {{
 *  payload: {
 *    componentType: T,
 *    components: Scenario[T],
 *  }
 * }} action
 */
const bulkCreate = (state, action) => {
  const { componentType, components } = action.payload;

  if (_validateComponentType(componentType) === false) {
    console.error(`Invalid componentType: ${componentType}`);
    return;
  }

  const newComponents = components
    .filter((component) => {
      // Filter out components that already exist
      return _findComponentIndex(state.data, 'contracts', component.id) === -1;
    })
    .map((component) => {
      return {
        ...component,
        id: component.id ? component.id : uuid(),
      };
    });

  state.data[componentType] = [...newComponents, ...state.data[componentType]];
};

/**
 * @template {ScenarioComponentType} T
 * @param {ScenarioState} state
 * @param {{
 *  payload: {
 *    componentType: T,
 *    componentIds: string[],
 *    data: Partial<Scenario[T]>,
 *    saveSetting: boolean,
 *  }
 * }} action
 */
const bulkUpdate = (state, action) => {
  const {
    componentType,
    componentIds,
    data: updateData,
    saveSetting = true,
  } = action.payload;

  if (_validateComponentType(componentType) === false) {
    console.error(`Invalid componentType: ${componentType}`);
    return;
  }

  const updatedComponents = state.data[componentType].map((component) => {
    if (!componentIds.includes(component.id)) {
      return component;
    }
    const newComponent = { ...component, ...updateData };

    if (saveSetting)
      state.data.settings[componentType].actions.push({
        type: 'UPDATE',
        data: newComponent,
      });
    return newComponent;
  });

  state.data[componentType] = updatedComponents;
};

/**
 *
 * @param {ScenarioState} state
 * @param {{
 *  payload: {
 *    componentType: ScenarioComponentType,
 *    componentIds: string[],
 *  }
 * }} action
 * @returns
 */
const bulkDelete = (state, action) => {
  const { componentType, componentIds } = action.payload;

  let deleteIds = componentIds.map((id) => id.toString());

  if (_validateComponentType(componentType) === false) {
    console.error(`Invalid componentType: ${componentType}`);
    return;
  }

  state.data[componentType] = state.data[componentType].filter((component) => {
    if (!deleteIds.includes(component.id.toString())) {
      return true;
    }

    state.data.settings[componentType].actions.push({
      type: 'REMOVE',
      data: component,
    });
    return false;
  });
};

/**
 *
 * @param {ScenarioState} state
 */
const applySettings = (state) => {
  state.data = _applySettings(state);
};

/**
 * @param {ScenarioState} state
 * @param {{
 *  payload: {
 *    chainInfoId: string,
 *    tag: TagType,
 *  }
 * }} action
 */
const tagChain = (state, action) => {
  state.data.operation_chains = state.data.operation_chains.map((c) => {
    const chain = { ...c };
    if (chain.chain_info.id === action.payload.chainInfoId) {
      chain.chain_info.tags.push({
        name: action.payload.tag.name,
        color: action.payload.tag.color,
        id: uuid(),
        type: action.payload.tag,
      });
    }
    return chain;
  });
};

/**
 * @param {ScenarioState} state
 * @param {{
 *  payload: {
 *    chainInfoId: string,
 *    tag: Tag,
 *  }
 * }} action
 */
const untagChain = (state, action) => {
  state.data.operation_chains = state.data.operation_chains.map((c) => {
    const chain = { ...c };
    if (chain.chain_info.id === action.payload.chainInfoId) {
      chain.chain_info.tags = chain.chain_info.tags.filter(
        (t) => t.id !== action.payload.tag.id,
      );
    }
    return chain;
  });
};

/**
 * @param {ScenarioState} state
 * @param {{
 *  payload: {
 *    chainInfoId: string,
 *    name: string,
 *  }
 * }} action
 */
const updateChainName = (state, action) => {
  state.data.operation_chains = state.data.operation_chains.map((c) => {
    const chain = { ...c };
    if (chain.chain_info.id === action.payload.chainInfoId) {
      chain.chain_info.name = action.payload.name;
    }
    return chain;
  });
};

/**
 *
 * @param {ScenarioState} state
 * @param {{
 *  payload: {
 *    locked: boolean,
 *  }
 * }} action
 */
const setLockState = (state, action) => {
  state.data.locked = action.payload.locked;
};

/**
 *
 * @param {ScenarioState} initialState
 */
const _scenarioReducers = (initialState) => ({
  resetState: () => resetState(initialState),
  beginEditing,
  rollback,
  commit,
  overwrite,
  setChainFilters,
  resetFilters,
  setScenario,
  setName,
  setDescription,
  setFlowDate,
  setPriceSource,
  setPriceType,
  setSimulateSpread,
  setOptimizationType,
  setOptimizationSettings,
  updateComponent,
  appendComponents,
  revertUpdateSettings,
  removeComponent,
  delete: removeComponent, // alias
  restoreRemovedComponent,
  bulkCreate,
  bulkUpdate,
  bulkDelete,
  applySettings,
  tagChain,
  untagChain,
  updateChainName,
  setLockState,
});

const reducers = _scenarioReducers(initialState);

/** @type {import('@reduxjs/toolkit').Slice<ScenarioState, typeof reducers, 'scenario'>} */
export const scenarioSlice = createSlice({
  name: 'scenario',
  initialState,
  reducers,
});

export const scenarioActions = scenarioSlice.actions;

export const selectScenario = createSelector(
  /**
   * @param {{
   *  scenario: ScenarioState
   * }} state
   * @returns {Scenario}
   */
  (state) => state?.scenario.data ?? {},
  (scenario) => scenario,
);

export const selectMarkets = createSelector(
  /**
   * @param {{
   *  scenario: ScenarioState
   * }} state
   * @returns {Scenario}
   */
  (state) => state?.scenario.data ?? {},
  (scenario) => scenario.markets ?? [],
);

export const selectTrades = createSelector(
  /**
   * @param {{
   *  scenario: ScenarioState
   * }} state
   * @returns {Scenario}
   */
  (state) => state?.scenario.data ?? {},
  (scenario) => scenario.trades ?? [],
);

export const selectSegmentConstraints = createSelector(
  /**
   * @param {{
   *  scenario: ScenarioState
   * }} state
   * @returns {Scenario}
   */
  (state) => state?.scenario?.data ?? {},
  (scenario) => scenario.segment_constraints ?? [],
);

export const selectPointConstraints = createSelector(
  /**
   * @param {{
   *  scenario: ScenarioState
   * }} state
   * @returns {Scenario}
   */
  (state) => state?.scenario?.data ?? {},
  (scenario) => scenario.point_constraints ?? [],
);

export const selectConstraintsAtPoints = createSelector(
  selectPointConstraints,
  (constraints) => {
    return constraints.reduce(
      /**
       *
       * @param {Object<string, PointConstraint[]} acc
       * @param {PointConstraint} constraint
       * @returns {Object<string, PointConstraint[]}
       */
      (acc, constraint) => {
        if (!acc[constraint.point]) {
          acc[constraint.point] = [];
        }
        acc[constraint.point].push(constraint);
        return acc;
      },
      {},
    );
  },
);

export const selectOperations = createSelector(
  /**
   * @param {{
   *  scenario: ScenarioState
   * }} state
   * @returns {Scenario}
   */
  (state) => state?.scenario.data ?? {},
  (scenario) => scenario?.operations ?? [],
);

export const selectEditState = createSelector(
  /**
   * @param {{
   *  scenario: ScenarioState
   * }} state
   * @returns {boolean}
   */
  (state) => state?.scenario?.isEditing ?? false,
  (isEditing) => isEditing,
);

export default scenarioSlice.reducer;
