export const colors = {
  matteRed: '#F05E4B',
  matteGreen: '#2DC963',
  matteYellow: '#E6E657',
  primaryBlue: '#0D6EFD',
  lightGrey: '#E5E7EB',
};

/**
 * @typedef {{ r: number, g: number, b: number, a?: number}} RGB
 */

/**
 *
 * @param {string} hex hex color string starting with #
 * @returns {boolean} Returns true if the hex is valid
 */
export const isValidHex = (hex) => {
  return /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3}|[A-Fa-f0-9]{8})$/.test(hex);
};

/**
 *
 * @param {string} color hex color string starting with #
 * @returns {string} Returns either #000000 or #FFFFFF depending on the contrast of the color
 */
export const getContrastColor = (color) => {
  if (!color || !isValidHex(color)) return '#000000';

  const hex = color.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);

  const yiq = (r * 299 + g * 587 + b * 114) / 1000;

  return yiq >= 186 ? '#000000' : '#FFFFFF';
};

/**
 *
 * @param {number | string} key
 * @param {number | undefined} saturation
 * @param {number | undefined} luminosity
 * @returns {string} Returns a color based on the key
 */
export const generateColor = (key, saturation = 50, luminosity = 80) => {
  return `hsl(${(100 * Number(key)) % 361},${saturation}%,${luminosity}%)`;
};

/**
 * Converts a hex color to rgb
 * @param {string} hex
 * @returns {RGB | null} Returns an object with r, g, and b values
 */
export const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(
    hex,
  );
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
        a: result[4] ? parseInt(result[4], 16) : 255,
      }
    : null;
};

/**
 * Converts an rgb color to hex
 * @param {RGB} rgb
 * @returns {string} Returns a hex color string
 */
export const rgbToHex = (rgb) => {
  return (
    '#' +
    ((1 << 24) + (rgb.r << 16) + (rgb.g << 8) + rgb.b)
      .toString(16)
      .slice(1)
      .toUpperCase()
  );
};

/**
 *
 * @param {string} hsl string in format 'hsl(0, 0%, 0%)'
 * @param {number} opacity
 * @returns {RGB} Returns an object with r, g, and b values
 */
export const hslToRgb = (hsl, opacity) => {
  const hslValues = hsl.match(/\d+/g);
  const h = hslValues[0] / 360;
  const s = hslValues[1] / 100;
  const l = hslValues[2] / 100;

  let r, g, b;

  if (s === 0) {
    r = g = b = l;
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return {
    r: Math.round(r * 255),
    g: Math.round(g * 255),
    b: Math.round(b * 255),
    a: opacity,
  };
};

/**
 *
 * @param {RGB} rgb
 * @returns {RGB} Returns an object with r, g, and b values
 */
export const rgb255ToRgb1 = (rgb) => {
  return {
    r: rgb.r / 255,
    g: rgb.g / 255,
    b: rgb.b / 255,
    a: rgb.a,
  };
};

/**
 *
 * @param {import("../services/edgeApi").Node} node
 * @returns {string} Returns a color based on the node
 */
export const getNodeColor = (node, points, activelySelectedNodeIds) => {
  const pointsDirFlo = node.points.map((pointId) => points[pointId]?.dir_flo);
  let color;
  const isActiveNode = activelySelectedNodeIds.includes(node.id);

  if (isActiveNode) {
    color = '#5AAB61';
  } else {
    let nodeDirFlo;
    if (
      pointsDirFlo.includes('B') ||
      (pointsDirFlo.includes('R') && pointsDirFlo.includes('D'))
    ) {
      nodeDirFlo = 'B';
    } else if (pointsDirFlo.includes('D')) {
      nodeDirFlo = 'D';
    } else if (pointsDirFlo.includes('R')) {
      nodeDirFlo = 'R';
    }

    switch (nodeDirFlo) {
      case 'R':
        color = '#ED0907';
        break;
      case 'D':
        color = '#0605EB';
        break;
      case 'B':
        color = '#BE50DB';
        break;
      default:
        color = '#19538C';
    }
  }
  return color;
};

export const interpolateColor = (c0, c1, f) => {
  if (f === null || f === undefined) {
    return '#00000000';
  }
  c0 = c0.match(/.{1,2}/g).map((oct) => parseInt(oct, 16) * (1 - f));
  c1 = c1.match(/.{1,2}/g).map((oct) => parseInt(oct, 16) * f);
  const ci = [0, 1, 2].map((i) => Math.min(Math.round(c0[i] + c1[i]), 255));
  const rgb = { r: ci[0], g: ci[1], b: ci[2] };
  const hex = rgbToHex(rgb);
  return hex;
};
