import { toast } from 'react-hot-toast';
import { priceFormatter } from '../helpers/formatters';
import { ReusableModal } from '../modals/ReusableModal';
import { usePriceComponentHistory } from '../helpers/hooks';
import { InfiniteScrollObserver } from '../helpers/InfiniteScrollObserver';
import { LoadingIndicator } from '../helpers/LoadingIndicator';
import { getContrastColor, interpolateColor } from '../utils/colors';
import { useState } from 'react';

const getColor = (ratio) => interpolateColor('0096FF', 'FF0000', ratio);

export function PriceComponentHistory({
  componentId,
  setComponentId,
  ...props
}) {
  /**
   * @type {import('../types/types').State<import('../scenarios/scenarioSlice').PriceDataProvider>}
   */
  const [priceSource, setPriceSource] = useState(
    props.priceSource ?? 'PLATTS_DAILY',
  );

  const {
    priceComponent: component,
    prices,
    isLoading,
    isFetching,
    isError,
    error,
    exhausted,
    fetchNextPage,
  } = usePriceComponentHistory({
    componentId,
    priceSource,
  });

  if (isError) {
    toast.error(error?.data?.error || 'An error occurred');
    return null;
  }

  const getNextPage = () => {
    if (isFetching || isLoading || exhausted) {
      return;
    }
    fetchNextPage();
  };

  const bates = [
    { header: 'High', key: 'h' },
    { header: 'Low', key: 'l' },
    { header: 'Close', key: 'c' },
  ];

  return (
    <ReusableModal
      size="lg"
      isOpen
      toggle={() => setComponentId(false)}
      header={<div>{component?.description}</div>}
    >
      <div className="input-group">
        <label className="input-group-text" htmlFor="price-source-select">
          Price Source
        </label>
        <select
          id="price-source-select"
          className="form-select"
          value={priceSource}
          onChange={(e) => setPriceSource(e.target.value)}
          aria-label="Price source selector"
        >
          <option value={'PLATTS_DAILY'}>Platts Daily</option>
          <option value={'ICE_DAILY'}>ICE Daily</option>
        </select>
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Gas Date</th>
            {bates.map((bate) => (
              <th key={bate.key}>{bate.header}</th>
            ))}
            {/* <th>Volume</th> */}
          </tr>
        </thead>
        <tbody>
          {prices?.map((record) => (
            <tr key={record.gas_date}>
              <th scope="row">{record.gas_date}</th>
              {bates.map((bate) => {
                const backgroundColor = record?.[bate.key]
                  ? getColor(Math.min(record[bate.key] / 5, 1))
                  : '#00000000';
                const color = record?.[bate.key]
                  ? getContrastColor(backgroundColor)
                  : '#000000';
                return (
                  <td
                    key={bate.key}
                    style={{
                      backgroundColor,
                      color,
                    }}
                  >
                    {record?.[bate.key]
                      ? priceFormatter.format(record[bate.key])
                      : 'N/A'}
                  </td>
                );
              })}
              {/* <td>{numberFormatter(Number(record?.w))}</td> */}
            </tr>
          ))}
        </tbody>
      </table>
      <InfiniteScrollObserver onScrollIn={getNextPage} />
      {isFetching && <LoadingIndicator />}
    </ReusableModal>
  );
}
