import { numberFormatter } from '../helpers/formatters';

/**
 * @type {React.FC<{
 *  operation: import('../types/allPaths').AllPathsPickedOperation;
 * }>}
 */
export const AllPathsOperationDetail = ({ operation }) => {
  return (
    <div className="d-flex flex-column">
      <h5 className="text-center">{operation.receipt_point_tsp}</h5>
      <div className="mt-1">
        <b>Receipt:</b>
        <div>
          {operation.receipt_point_loc_name} ({operation.receipt_point_tsp}{' '}
          {operation.receipt_point_zone})
        </div>
      </div>
      <div className="mt-1">
        <b>Delivery:</b>
        <div>
          {operation.delivery_point_loc_name} ({operation.delivery_point_tsp}{' '}
          {operation.delivery_point_zone})
        </div>
      </div>
      <div className="mt-1">
        <b className="me-1">Contract:</b>
        {operation.contract_k_id ? (
          <a
            href={`http://api.pathpointlabs.ai/admin/edge/contract/${operation.contract_id}`}
            target="_blank"
            rel="noreferrer"
          >
            {operation.contract_k_id}
          </a>
        ) : (
          'N/A'
        )}
      </div>
      <div className="mt-1">
        <b>Priority: </b>
        {operation.transport_priority ?? 'N/A'}
      </div>
      <div>
        Transport cost:
        <span className="float-end monospace">
          ${numberFormatter(operation.transport_cost_per_dth, 3)}
        </span>
      </div>
    </div>
  );
};
