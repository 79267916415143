import React, {
  forwardRef,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { mapContext } from './EdgeMap';
import Overlay from 'ol/Overlay';

/**
 * @typedef {{
 *  children: React.ReactNode[]
 *  onClose: () => void,
 *  closeButton?: boolean,
 *  autoPan?: boolean,
 *  fixedPosition: import('ol/coordinate').Coordinate,
 *  className?: string,
 *  style?: React.CSSProperties
 * }} MapPopupProps
 */

/**
 * @typedef {{
 *  show: (position: import('ol/coordinate').Coordinate) => void,
 *  hide: () => void,
 * }} MapPopupRef
 */

/**
 *
 * @param {MapPopupProps} props
 * @param {MapPopupRef} ref
 * @returns {React.FC<MapPopupProps>}
 */
const _MapPopup = (
  {
    children,
    onClose,
    fixedPosition,
    autoPan = false,
    closeButton = true,
    className = '',
    style = {},
  },
  ref,
) => {
  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const popupRef = useRef(null);

  /**
   * @type {[Overlay, React.Dispatch<React.SetStateAction<Overlay>>]}
   */
  const [overlay, setOverlay] = useState(null);

  const { map } = useContext(mapContext);

  useEffect(() => {
    const _overlay = new Overlay({
      element: popupRef.current,
      positioning: 'bottom-center',
      stopEvent: true,
      insertFirst: true,
      autoPan: autoPan
        ? {
            animation: {
              duration: 250,
            },
            margin: 50,
          }
        : false,
    });
    setOverlay(_overlay);
    return () => {
      _overlay.dispose();
    };
  }, [autoPan, popupRef]);

  useEffect(() => {
    overlay?.setElement(popupRef.current ?? document.createElement('div'));
  }, [popupRef, overlay]);

  useEffect(() => {
    if (!map || !overlay) return;
    map.addOverlay(overlay);
    return () => {
      map.removeOverlay(overlay);
    };
  }, [map, overlay]);

  useImperativeHandle(
    ref,
    () => ({
      show: (position) => {
        overlay?.setPosition(position);
      },
      hide: () => {
        overlay?.setPosition(undefined);
      },
    }),
    [popupRef, overlay],
  );

  useEffect(() => {
    if (!map || !overlay) return;
    overlay.setPosition(fixedPosition ?? undefined);
  }, [fixedPosition, overlay, map]);

  return (
    <div className="d-none">
      <div
        ref={popupRef}
        className={`d-flex flex-column bg-light border rounded ${className}`}
        style={{ cursor: 'auto', marginBottom: 16, ...style }}
      >
        {closeButton && (
          <div className="d-block p-2">
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={onClose}
            />
          </div>
        )}
        <div className="p-2">{children}</div>
      </div>
    </div>
  );
};

export const MapPopup = forwardRef(_MapPopup);
