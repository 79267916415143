import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { mapContext } from './EdgeMap';
import VectorSource from 'ol/source/Vector';
import GeoJSON from 'ol/format/GeoJSON.js';
import { generateColor } from '../utils/colors';
import { MapTooltip } from './MapTooltip';
import { edgeToGeoJson } from '../utils/geoJsonUtils';
import { WebGLLayer } from './webglLayer';
import {
  selectAllPathsChains,
  selectAllPathsOperations,
  selectAllPathsPickedChains,
  selectAllPathsVisibleChains,
} from '../scenarios/allPathsSlice';
import {
  selectAllNodes,
  // selectFilteredEdgeNodes,
} from '../plotter/plotterSelectors';
// import { fromLonLat } from 'ol/proj';
import * as pathsActions from '../paths/pathsSlice';
import { priceFormatter } from '../helpers/formatters';

const getEdgeStyle = () => [
  {
    filter: ['>', ['get', 'head'], 0],
    'shape-points': 3,
    'shape-radius': ['get', 'arroheadSize'],
    'shape-fill-color': ['get', 'color'],
    'shape-stroke-color': ['get', 'color'],
    'shape-stroke-width': 4,
    'shape-rotate-with-view': false,
    'shape-displacement': [0, 0],
    'shape-opacity': 1,
    'shape-angle': ['get', 'angle'],
  },
  {
    filter: ['all', ['==', ['get', 'lineDash'], 0], ['<=', ['get', 'head'], 0]],
    'stroke-width': ['get', 'width'],
    'stroke-color': ['get', 'color'],
    'stroke-line-cap': 'round',
    'stroke-line-join': 'round',
    // 'stroke-offset': -15,
    'shape-angle': 0,
  },
  {
    filter: ['all', ['==', ['get', 'lineDash'], 1], ['<=', ['get', 'head'], 0]],
    'stroke-line-dash': [15, 15, 5, 15],
    'stroke-width': ['get', 'width'],
    'stroke-color': ['get', 'color'],
    'stroke-line-cap': 'round',
    'stroke-line-join': 'round',
    // 'stroke-offset': -15,
    'shape-angle': 0,
  },
];

/**
 *
 * @type {React.FC<{zIndex: number}>}
 */
export const MapAllPathsChains = ({ zIndex }) => {
  const {
    map,
    registerHoverListener,
    unregisterHoverListener,
    registerClickListener,
    unregisterClickListener,
  } = useContext(mapContext);

  const dispatch = useDispatch();

  const chains = useSelector(selectAllPathsChains);
  const operations = useSelector(selectAllPathsOperations);
  const pickedChains = useSelector(selectAllPathsPickedChains);
  const nodes = useSelector(selectAllNodes);
  const visibleChains = useSelector(selectAllPathsVisibleChains);
  const selectedPathId = useSelector((state) => state.paths.selectedPathId);
  const selectedChain = useMemo(() => {
    return chains.find((chain) => chain.chain_number === selectedPathId);
  }, [selectedPathId]);

  /** @type {[import('../types/allPaths').AllPathsChain, React.Dispatch<React.SetStateAction<import('../types/allPaths').AllPathsChain>>]} */
  const [popupChain, setPopupChain] = useState(null);

  /** @type {import('./EdgeMap').MapEventListener} */
  const hoverListener = useCallback(
    (feature) => {
      if (
        feature?.getProperties()?.key ===
        `all-paths-chain-${popupChain?.chain_id}`
      )
        return;
      if (!feature?.getProperties()?.key?.startsWith('all-paths-chain-')) {
        setPopupChain(null);
        return;
      }
      const chainId = feature?.getProperties()?.id;
      const chain = pickedChains.find((chain) => chain.chain_id === chainId);
      setPopupChain(chain);
    },
    [chains],
  );

  /** @type {import('./EdgeMap').MapEventListener} */
  const clickListener = useCallback(
    (feature) => {
      if (
        !feature ||
        !feature?.getProperties()?.key?.startsWith('all-paths-chain-')
      )
        return;
      if (
        feature?.getProperties()?.key ===
        `all-paths-chain-${selectedChain?.chain_id}`
      )
        return;

      const chainId = feature.getProperties()?.id;
      const chain = pickedChains.find(
        (chain) => chain.chain_id.toString() === chainId?.toString(),
      );
      dispatch(
        pathsActions.pathSelected({
          selectedPathId: chain?.chain_number,
        }),
      );
      const pathBounds = feature.getGeometry().getExtent();
      map?.getView().fit(pathBounds, {
        padding: [50, 50, 50, 50],
        duration: 1000,
      });
    },
    [selectedChain, dispatch, map],
  );

  const chainsLayer = useMemo(() => {
    const features = [...chains, ...pickedChains]
      .sort((a, b) => {
        if (a.chain_number === selectedPathId) return 1;
        if (b.chain_number === selectedPathId) return -1;
        return 0;
      })
      .filter(
        (chain) =>
          visibleChains[chain.chain_id] ||
          chain.chain_number === selectedPathId,
      )
      .map((chain) => {
        const chainOperations =
          chain.operations ??
          operations
            .filter((op) => chain.op_list.includes(op.id))
            .sort(
              (a, b) =>
                chain.op_list.indexOf(a.id) - chain.op_list.indexOf(b.id),
            );
        const pathPositions = chainOperations
          .filter((op) => Array.isArray(op.path_node_ids))
          .flatMap((op) =>
            op.path_node_ids
              .map((nodeId) => {
                const node = nodes[nodeId];
                return node;
              })
              .filter(Boolean)
              .map((node) => {
                return [Number(node.lat), Number(node.lng)];
              }),
          );
        return {
          path: { positions: pathPositions },
          chain,
        };
      })
      .filter(({ path }) => path.positions?.length > 1)
      .flatMap(({ path, chain }) => {
        const color = pickedChains.includes(chain)
          ? generateColor(chain.chain_number, 80, 60)
          : generateColor(chain.chain_number, 60, 60);
        return edgeToGeoJson(
          path,
          () => ({
            id: chain.chain_id,
            key: `all-paths-chain-${chain.chain_id}`,
            lineDash: pickedChains
              .map((c) => c.chain_id)
              .includes(chain.chain_id)
              ? 0
              : 1,
            color,
            width: selectedPathId === chain.chain_number ? 14 : 10,
            arroheadSize: selectedPathId === chain.chain_number ? 16 : 13,
          }),
          true,
          { frequency: 0.8 },
        );
      });

    const source = new VectorSource({
      features: new GeoJSON().readFeatures({
        type: 'FeatureCollection',
        features,
      }),
      format: new GeoJSON(),
    });

    return new WebGLLayer({
      source,
      style: getEdgeStyle(),
      zIndex,
    });
  }, [nodes, chains, operations, visibleChains, pickedChains, selectedPathId]);

  useEffect(() => {
    if (!map || !chainsLayer) return;
    map.addLayer(chainsLayer);
    return () => {
      map.removeLayer(chainsLayer);
    };
  }, [map, chainsLayer]);

  useEffect(() => {
    if (!map) return;
    registerClickListener(clickListener);
    registerHoverListener(hoverListener);
    return () => {
      unregisterClickListener(clickListener);
      unregisterHoverListener(hoverListener);
    };
  }, [map]);

  return (
    <MapTooltip visible={Boolean(popupChain)}>
      <div className="bg-dark p-2 px-3 border-dark text-light rounded">
        {Boolean(popupChain) && (
          <div className="d-flex flex-column">
            <div className="fs-6 fw-bold">
              Chain #{popupChain?.chain_number}
            </div>
            <div>
              $/dth:{' '}
              {priceFormatter.format(
                Number(popupChain?.profit_per_dth).toFixed(3),
              )}
            </div>
            <div>
              Volume: {Number(popupChain?.constrained_max_volume).toFixed(0)}
              dth
            </div>
            <div>
              Profit:{' '}
              {priceFormatter.format(Number(popupChain?.profit).toFixed(2))}
            </div>
          </div>
        )}
      </div>
    </MapTooltip>
  );
};
