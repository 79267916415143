import DataTable from 'react-data-table-component';
import { useState } from 'react';
import { useGetNoticesQuery } from '../services/edgeApi';
import { toIsoDate } from '../utils/stringUtils';

// probably want to have NoticesPage that renders the notices table for reusability of the table itself

export function NoticesTable(props) {
  // pass date as a prop?
  const [noticeDate, setNoticeDate] = useState(toIsoDate(new Date()));

  const { data: notices } = useGetNoticesQuery(noticeDate);

  // const columns = notices ? Object.keys(notices[0])
  window.notices = notices;
  const columns = [
    {
      name: 'effective_datetime',
      selector: 'effective_datetime',
      sortable: true,
    },
    {
      name: 'tsp_name',
      selector: (row) => row.tsp_name,
      sortable: true,
    },
    {
      name: 'identifier',
      selector: 'identifier',
      sortable: true,
    },
    {
      name: 'identifier_type',
      selector: 'identifier_type',
    },
    {
      name: 'priority',
      selector: 'priority',
    },
    {
      name: 'is_backhaul',
      selector: 'is_backhaul',
    },
    {
      name: 'reason',
      selector: 'reason',
    },
    {
      name: 'notice_id',
      selector: 'notice_id',
    },
    {
      name: 'amount',
      selector: 'amount',
    },
    {
      name: 'percent',
      selector: 'percent',
    },
    {
      name: 'full_text',
      selector: 'full_text',
    },
    {
      name: 'metadata',
      selector: 'metadata',
    },
  ];

  // const data = [
  //   {
  //       id: 1,
  //       title: 'Beetlejuice',
  //       year: '1988',
  //   },
  //   {
  //       id: 2,
  //       title: 'Ghostbusters',
  //       year: '1984',
  //   },
  // ]

  return (
    <div className="col">
      <input
        value={noticeDate}
        onChange={(evt) => setNoticeDate(evt.target.value)}
        className=""
        type="date"
      />
      <DataTable columns={columns} data={notices} />
    </div>
  );
}
