import { useState } from 'react';
import toast from 'react-hot-toast';
import { Button, FormGroup, Form, Input, Label } from 'reactstrap';
import { useCreateUpdateOverlayMutation } from '../services/edgeApi';

export default function OverlayForm(props) {
  // trying to make reusable components but it seems like having to change parent state with
  // optional callbacks is a weird way to do things.  What is the right way?

  // you can pass props.onSuccess and / or props.onError callbacks
  const [overlay, setOverlay] = useState(overlay || {});
  const [createUpdateOverlay, { isLoading: isCreatingUpdating }] =
    useCreateUpdateOverlayMutation();

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    try {
      await createUpdateOverlay(overlay).unwrap();
      props.onSuccess();
    } catch {
      toast.error('Something went wrong, please try again');
      props.onError();
    }
  };

  const handleChange = (evt) => {
    const value =
      evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
    const { name } = evt.target;

    setOverlay({ ...overlay, [name]: value });
  };

  return (
    <Form className="row" onSubmit={handleSubmit}>
      <FormGroup className="row">
        <Label htmlFor="name" className="col-form-label col-sm-4">
          Name
        </Label>
        <div className="col-sm-8">
          <Input
            type="text"
            name="name"
            id="name"
            value={overlay.name || ''}
            onChange={handleChange}
          />
        </div>
      </FormGroup>

      <FormGroup className="row">
        <Label htmlFor="url" className="col-form-label col-sm-4">
          URL
        </Label>
        <div className="col-sm-8">
          <Input
            type="text"
            name="url"
            id="url"
            value={overlay.url || ''}
            onChange={handleChange}
          />
        </div>
      </FormGroup>
      <Input type="submit" />
    </Form>
  );
}
