import { createSlice, createSelector } from '@reduxjs/toolkit';
// import { selectAllPoints, selectFilteredPoints } from '../plotter/plotterSelectors';

let localPinnedPoints;

try {
  localPinnedPoints = JSON.parse(localStorage.getItem('pinnedPoints')) || [];
} catch (e) {
  console.warn(e);
}

const initialState = {
  pinnedPoints: localPinnedPoints,
};

// there are a lot of point related things in other slices, could move them here eventually if we want

export const pointsSlice = createSlice({
  name: 'points',
  initialState,

  reducers: {
    togglePinnedPoint: (state, action) => {
      const pointId = action.payload;
      // if it's in the array, remove it
      // else add it
      const { pinnedPoints } = state;
      let result;
      if (pinnedPoints.includes(pointId)) {
        result = {
          ...state,
          pinnedPoints: pinnedPoints.filter((id) => id !== pointId),
        };
      } else {
        result = {
          ...state,
          pinnedPoints: [...state.pinnedPoints, pointId],
        };
      }
      // running a side effect in a reducer is highly frowned upon, but does it really matter?  If so, we can do this before the reducer in a thunk like filtersSlice
      localStorage.setItem('pinnedPoints', JSON.stringify(result.pinnedPoints));
      return result;
    },
  },
});

export const { togglePinnedPoint } = pointsSlice.actions;

export default pointsSlice.reducer;
