import { useDispatch } from 'react-redux';
import { plotterActions } from './plotterSlice';
import { fromLonLat } from 'ol/proj';

/**
 *
 * @typedef {[number, number] | {lat: number, lng: number}} LocationLike
 */

export const usePanMap = () => {
  const dispatch = useDispatch();

  /**
   * @template {LocationLike} T
   * @param {T} location Object with lat and lng properties or an array in the form [lat, lng]
   * @param {number} zoom
   */
  const panMap = (location, zoom = 8) => {
    if (!Array.isArray(location) && location?.lat && location?.lng) {
      location = [location.lat, location.lng];
    }
    dispatch(plotterActions.setMapView({ center: location, zoom }));
  };
  return panMap;
};

export const useFitMap = () => {
  const dispatch = useDispatch();

  /**
   * @param {LocationLike[]} locations
   */
  const fitMap = (locations) => {
    locations = locations.map((location) => {
      if (!Array.isArray(location) && location?.lat && location?.lng) {
        return fromLonLat([location.lng, location.lat]);
      }
      return fromLonLat([location[1], location[0]]);
    });
    dispatch(plotterActions.fitMapView(locations));
  };
  return fitMap;
};
