export var priceFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 3, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const priceFormatterNoCents = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

export const numberFormatter = (number, decimals = 0) =>
  new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(number);

export const urlFilterFormatter = (filters) =>
  // pass in array of of url filter objects like {'point__tsp__name__in': ['fgt','sonat']} and it will give a URL representation
  Object.keys(filters).reduce(
    (acc, key) => `${acc + key}=${encodeURIComponent(filters[key])}&`,
    '',
  );
