export function DayOverDay() {
  return (
    <iframe
      src="https://datastudio.google.com/embed/reporting/33c0f176-10a9-4280-b788-c6e42b510be1/page/bkICD"
      frameBorder="0"
      style={{ border: 0, width: '100vw', height: '100vh' }}
      allowFullScreen
    />
  );
}
