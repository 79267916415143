import { HelpCircleIcon, SearchIcon, UploadIcon } from '@iconicicons/react';
import { UncontrolledTooltip } from 'reactstrap';
import { PivotTable } from '../helpers/PivotTable';
import { utils, writeFileXLSX } from 'xlsx';
import React, { useEffect, useState } from 'react';
import { comprehensiveViewPresets } from './ViewPresets';
import { useLocalStorage } from '../helpers/useLocalStorage';
import { useWorkbook } from '../helpers/hooks';
import { colors } from '../utils/colors';
import { useDispatch } from 'react-redux';
import { scenarioActions } from './scenarioSlice';
import { FilterStateIcon } from '../helpers/FilterStateIcon';

/**
 *
 * @param {{workBook: import('xlsx').WorkBook}} params
 * @returns {number | null}
 */
const calculateMetric = ({ workBook }) => {
  if (!workBook) return -1;
  const sheet = workBook.Sheets[workBook.SheetNames[0]];
  if (!sheet) return -1;
  const numberOfRecords = sheet['!ref'].split(':')[1].match(/[0-9]+/)[0];
  const table = Object.keys(sheet).reduce((acc, key) => {
    if (key.startsWith('!')) return acc;
    const col = key.match(/[A-Z]+/)[0];
    const row = key.match(/[0-9]+/)[0];
    if (!acc[col]) acc[col] = {};
    acc[col][row] = sheet[key].v;
    return acc;
  }, {});
  const labsColumn = Object.values(table).find((col) => col[1] === 'Labs');
  const energyColumn = Object.values(table).find((col) => col[1] === 'Energy');

  if (!labsColumn || !energyColumn) return -1;

  const data = Object.keys(labsColumn).reduce(
    (acc, key) => {
      if (key === '1' || key === numberOfRecords) return acc;
      acc.diff += Math.abs(Number(labsColumn[key]) - Number(energyColumn[key]));
      acc.total += Number(labsColumn[key]) + Number(energyColumn[key]);
      return acc;
    },
    { diff: 0, total: 0 },
  );

  if (data.total === 0) return -1;

  const metric = data.diff / data.total;
  return metric;
};

/**
 *
 * @param {{
 *  scenario: import('./scenarioSlice').Scenario
 *  data: Array<import('../helpers/PivotTable').PivotDataGroup>
 * }} props
 * @returns
 */
export const ComprehensiveView = ({ data, scenario }) => {
  const dispatch = useDispatch();
  /**
   * @type {[import('./ViewPresets').ViewPreset[], React.Dispatch<React.SetStateAction<import('./ViewPresets').ViewPreset[]>>]}
   */
  const [userPresets, setUserPresets] = useLocalStorage(
    'userComprehensiveViewPresets',
    [],
  );

  const defaultViewFileName = `${scenario.name
    .replace(/[^a-z0-9]/gi, '_')
    .toLowerCase()}_view.xlsx`;
  const defaultRawFileName = `${scenario.name
    .replace(/[^a-z0-9]/gi, '_')
    .toLowerCase()}_raw.xlsx`;

  /**
   * @type {[import('react-pivottable').PivotTableUIProps, React.Dispatch<React.SetStateAction<import('react-pivottable').PivotTableUIProps>>]}
   */
  const [tableView, setTableView] = useState(null);

  /** @type {React.MutableRefObject<import('../helpers/PivotTable').PivotTableRef>} */
  const ref = React.useRef(null);

  /**
   * @type {import('./ViewPresets').ViewPreset[]}
   */
  const allPresets = [...comprehensiveViewPresets, ...userPresets];

  /**
   * @type {[Partial<import('react-pivottable').PivotTableUIProps>, React.Dispatch<React.SetStateAction<Partial<import('react-pivottable').PivotTableUIProps>>>]}
   */
  const [uiProps, setUiProps] = useState({
    rowOrder: 'value_z_to_a',
    tableOptions: {
      clickCallback: function (_e, _value, _filters, _pivotData) {
        // We can execute actions on click!
      },
    },
    hiddenFromAggregators: [
      // 'Operation Delivery TSP',
      // 'Operation Delivery Location',
      // 'Operation Receipt TSP',
      // 'Operation Receipt Location',
      // 'Operation Delivery Description',
      // 'Operation Receipt Description',
      // 'Operation Delivery Asset Type',
      // 'Operation Receipt Asset Type',
      // 'Chain Delivery TSP',
      // 'Chain Delivery Location',
      // 'Chain Receipt TSP',
      // 'Chain Receipt Location',
      // 'Chain Delivery Description',
      // 'Chain Receipt Description',
      // 'Intra TSP',
      // 'Using Market',
      // 'Operation Origin',
    ],
    hiddenFromDragDrop: [],
    ...allPresets?.find((p) => p.id === 'chain-transport-tsp').tableState,
  });
  const [selectedPreset, setSelectedPreset] = useState('chain-transport-tsp');
  const [userPresetName, setUserPresetName] = useState('');
  const [difference, setDifference] = useState(-1);
  const [shouldApplyFilters, setShouldApplyFilters] = useState(false);
  const [attributteFilter, setAttributeFilter] = useState('');

  useWorkbook(
    'td.pvtOutput',
    (wb) => setDifference(calculateMetric({ workBook: wb })),
    [tableView],
  );

  const xportView = () => {
    const viewElement = document.querySelector('td.pvtOutput');
    const table = viewElement?.firstElementChild;
    const wb = utils.table_to_book(table);
    writeFileXLSX(wb, defaultViewFileName);
  };

  const xportRaw = () => {
    const wb = utils.book_new();
    const opData = data.filter((d) => d['Operation Origin'] === 'Labs');
    const energyBook = data.filter((d) => d['Operation Origin'] === 'Energy');
    utils.book_append_sheet(
      wb,
      utils.json_to_sheet(opData.flat()),
      'Operations',
    );
    utils.book_append_sheet(
      wb,
      utils.json_to_sheet(energyBook.flat()),
      'Energy',
    );
    writeFileXLSX(wb, defaultRawFileName);
  };

  const applyFilters = () => {
    const chainIds = shouldApplyFilters
      ? ref.current?.getFilteredRecords().reduce((agg, r) => {
          agg[r['Chain ID']] = true;
          return agg;
        }, {})
      : {};
    dispatch(
      scenarioActions.setChainFilters({
        type: 'ids',
        value: chainIds ?? {},
      }),
    );
  };

  useEffect(applyFilters, [tableView?.valueFilter, shouldApplyFilters]);

  const savePreset = () => {
    if (!userPresetName) return;
    /** @type {import('./ViewPresets').ViewPreset} */
    const preset = {
      id: userPresetName.toLocaleLowerCase().split(' ').join('-'),
      name: userPresetName,
      tableState: {
        aggregatorName: tableView.aggregatorName,
        cols: tableView.cols,
        rows: tableView.rows,
        vals: tableView.vals,
        rendererName: tableView.rendererName,
        valueFilter: tableView.valueFilter,
        rowOrder: tableView.rowOrder,
        colOrder: tableView.colOrder,
      },
    };
    setUserPresets([...userPresets, preset]);
    setSelectedPreset(preset.id);
    setUserPresetName('');
  };

  const exportableFormats = [
    'Table',
    'Table Heatmap',
    'Table Col Heatmap',
    'Table Row Heatmap',
  ];

  return (
    <>
      <div className="d-flex m-3 gap-3 justify-content-between">
        <div style={{ flexShrink: 0 }}>
          <input
            type="checkbox"
            className="btn-check"
            id="apply-filters-button"
            checked={shouldApplyFilters}
            onChange={(e) => setShouldApplyFilters(e.target.checked)}
          />
          <label
            className="btn btn-outline-primary rounded-1"
            htmlFor="apply-filters-button"
          >
            <FilterStateIcon
              className="me-2"
              activeColor="white"
              active={shouldApplyFilters}
            />
            Apply Filters To Scenario
          </label>
        </div>
        <div className="input-group">
          {exportableFormats.includes(tableView?.rendererName ?? 'Table') && (
            <button className="btn btn-sm btn-primary" onClick={xportView}>
              <UploadIcon /> Export View
            </button>
          )}
          <button className="btn btn-sm btn-primary" onClick={xportRaw}>
            <UploadIcon /> Export Raw
          </button>
        </div>
        <div className="input-group">
          <span className="input-group-text">Presets</span>
          <select
            className="form-select"
            style={{ maxWidth: 500 }}
            value={selectedPreset}
            onChange={(e) => {
              const preset = allPresets.find((p) => p.id === e.target.value);
              if (preset) {
                setUiProps({
                  ...uiProps,
                  ...tableView,
                  ...preset.tableState,
                  hiddenFromDragDrop: uiProps.hiddenFromDragDrop,
                });
                setSelectedPreset(preset.id);
              }
            }}
          >
            {allPresets.map((p) => (
              <option key={p.name} value={p.id}>
                {p.name}
              </option>
            ))}
          </select>
        </div>
        <div className="input-group">
          <span className="input-group-text">Save Preset As...</span>
          <input
            className="form-control"
            value={userPresetName}
            type="text"
            placeholder="Preset Name"
            onChange={(ev) => setUserPresetName(ev.target.value)}
          />
          <button className="btn btn-sm btn-primary" onClick={savePreset}>
            Save
          </button>
        </div>
      </div>
      <div className="m-3 d-flex">
        <div>
          {difference >= 0 && (
            <p className="d-flex gap-1">
              <strong>Discrepancy Coefficient: </strong> {difference.toFixed(4)}
              <span
                id="metric-help-text-icon"
                className="text-muted d-flex align-content-center"
              >
                <HelpCircleIcon color={colors.lightGrey} />
              </span>
              <UncontrolledTooltip
                placement="top"
                target="metric-help-text-icon"
              >
                The discrepancy coefficient is a measure of the difference
                between the labs and energy data. It is calculated as the
                absolute difference between the labs and energy data divided by
                the sum of the labs and energy data. The smaller the value, the
                closer the labs and energy flows are.
              </UncontrolledTooltip>
            </p>
          )}
        </div>
      </div>
      <div className="mx-3 input-group" style={{ maxWidth: 500 }}>
        <label htmlFor="attribute-filter-input" className="input-group-text">
          <SearchIcon /> Attributes
        </label>
        <input
          id="attribute-filter-input"
          className="form-control"
          value={attributteFilter}
          onChange={(e) => setAttributeFilter(e.target.value)}
        />
      </div>
      <div className="p-3" style={{ height: '100%', minHeight: '500px' }}>
        <PivotTable
          data={data}
          onChangeView={setTableView}
          uiProps={uiProps}
          attributteFilter={attributteFilter}
          ref={ref}
        />
      </div>
    </>
  );
};
