import { UncontrolledTooltip } from 'reactstrap';
import React from 'react';
import { numberFormatter } from '../helpers/formatters';
import { ChevronDownIcon } from '@iconicicons/react';

import { LinkToRates } from '../helpers/Scenario';

/**
 *
 * @param {{
 *  scenario: import('./scenarioSlice').Scenario
 *  operation: import('./scenarioSlice').Operation
 *  fuelMakeupPrice: number
 * }} props
 * @returns
 */
export const OperationDetail = ({
  scenario = {},
  operation,
  fuelMakeupPrice,
}) => {
  // we don't take reservation cost for firm transport into account in these calcs because it's a sunk cost
  const variableCost = parseFloat(operation.transport_cost_per_dth);
  const fuelFactor = parseFloat(operation.fuel_factor);
  const fuelCost = fuelMakeupPrice * (fuelFactor - 1);
  // this is super confusing nomenclature - ask Matt what he would call each part for clarity
  const transportCost = variableCost + fuelCost;

  const pointConstraintIds = operation.constraints
    .filter((constraint) => constraint.type === 'POINT')
    .map((constraint) => constraint.id);
  const segmentConstraintIds = operation.constraints
    .filter((constraint) => constraint.type === 'SEGMENT')
    .map((constraint) => constraint.id);

  const pointConstraints =
    scenario.point_constraints?.filter((constraint) =>
      pointConstraintIds.includes(Number(constraint.id)),
    ) ?? [];
  const segmentConstraints =
    scenario.segment_constraints?.filter((constraint) =>
      segmentConstraintIds.includes(Number(constraint.id)),
    ) ?? [];
  const constraints = [...pointConstraints, ...segmentConstraints];
  return (
    <div className="d-flex flex-column">
      <h5 className="text-center">{operation.receipt_point.tsp_name}</h5>
      {/* <li>Profit: ${numberFormatter(operation.total_profit)}</li> */}
      <div className="mt-1">
        <b>Receipt:</b>
        <div>
          {operation.chain_link?.receipt_description} (
          {operation.receipt_point.tsp_name}{' '}
          {operation.receipt_point.receipt_zone_name})
        </div>
      </div>
      <div className="mt-1">
        <b>Delivery:</b>
        <div>
          {operation.chain_link?.delivery_description} (
          {operation.delivery_point.tsp_name}{' '}
          {operation.delivery_point.delivery_zone_name})
        </div>
      </div>
      <div className="mt-1">
        <b className="me-1">Contract:</b>
        <a
          href={`http://api.pathpointlabs.ai/admin/edge/contract/${operation.contract_id}`}
          target="_blank"
          rel="noreferrer"
        >
          {
            scenario.contracts.find(
              (contract) => contract.id === operation.contract_id,
            )?.name
          }
        </a>
      </div>
      <div className="mt-1">
        <b>Priority:</b>
        {operation.transport_priority}
      </div>
      <div className="mt-1">
        <b>Variable cost:</b>
        <span className="float-end monospace">
          ${numberFormatter(variableCost, 5)}
        </span>
      </div>
      <div>
        Fuel cost:
        <u id={`tooltip-${operation.id}`} className="float-end monospace">
          + ${numberFormatter(fuelCost, 5)}
        </u>
      </div>
      <UncontrolledTooltip target={`tooltip-${operation.id}`}>
        <div>
          {numberFormatter(fuelMakeupPrice, 5)} *{' '}
          {numberFormatter(100 * (fuelFactor - 1), 2)}%
        </div>
      </UncontrolledTooltip>
      <div>
        Transport cost:
        <span className="float-end monospace">
          ${numberFormatter(transportCost, 5)}
        </span>
      </div>
      <div>
        <LinkToRates operation={operation} />
      </div>

      <div className="mt-2">
        <p className="fw-bold fs-6">
          Constraints in path:{' '}
          <ChevronDownIcon
            role="button"
            data-bs-toggle="collapse"
            data-bs-target={`#operation-${operation.id}-constraints`}
            aria-expanded="false"
            aria-controls={`#operation-${operation.id}-constraints`}
          />
        </p>
        <div className="collapse" id={`operation-${operation.id}-constraints`}>
          {constraints.length === 0 && (
            <p className="text-muted">No constraints in path</p>
          )}
          {constraints.map((constraint) => (
            <div key={constraint.id}>
              <p
                style={{
                  whiteSpace: 'nowrap',
                  textWrap: 'none',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
              >
                {constraint.short_description || constraint.description}
              </p>
              {/**
               * This is useful to have, but right now we only use 0 or 1 as constraint factors
               * (if this is no longer the case, we should add this back in)
               */}
              {/* <p className="lh-1 mb-1">
                <span className="fw-bold">Utilization:</span>{' '}
                {constraint.constraint_factor * 100}%
              </p> */}
              <p className="lh-1 mb-1">
                <span className="fw-bold">OAC:</span> {constraint.max_volume}{' '}
                dth
              </p>
              <p className="lh-1 mb-1">
                <span className="fw-bold">Priority:</span>{' '}
                {constraint.cuts_at_priority}
              </p>
              <hr />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
