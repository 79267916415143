// import DataTable from "react-data-table-component"
import { useMemo, useState } from 'react';
import {
  ZoomInIcon,
  ChartIcon,
  StarIcon,
  SearchIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@iconicicons/react';
import { useSelector } from 'react-redux';
import { UncontrolledTooltip } from 'reactstrap';
import { numberFormatter } from '../helpers/formatters';
import { usePanMap } from '../plotter/usePanMap';
import * as plotterSelectors from '../plotter/plotterSelectors';
import { ReusableModal } from '../modals/ReusableModal';
import { ComponentLocationManager } from '../capacityComponents/ComponentLocationManager';
import { CapacityComponentHistory } from './CapacityComponentHistory';
import { LoadingIndicator } from '../helpers/LoadingIndicator';
import { InfiniteScrollObserver } from '../helpers/InfiniteScrollObserver';
import { useToggleFavorite } from '../helpers/hooks';
import { DebouncedInput } from '../helpers/DebounceInput';

// doing it this portal way just to see how it works.  Wanting to have the data table be coupled with the map, so filtering in the data table affects the map, and vice versa
/**
 * Capacity react component
 * @param {{
 *  flows: Object<string, import('../plotter/plotterSlice').Capacity>,
 *  onNextPage: () => void,
 *  loading: boolean,
 *  onSearch: (searchText: string) => void
 *  onFilterChange: (data: Object<string, import('../plotter/plotterSlice').Capacity>) => void
 * }} props
 */
export const CapacityComponentTable = ({
  flows,
  onNextPage,
  loading,
  onSearch = () => null,
  onFilterChange = () => null,
}) => {
  const panMap = usePanMap();
  const allNodes = useSelector(plotterSelectors.selectAllNodes);
  const [sortKey, setSortKey] = useState('isFavorite');
  const [sortOrder, setSortOrder] = useState('desc');
  const [componentHistoryId, setComponentHistoryId] = useState(false);
  const [componentId, setComponentId] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [component, setComponent] = useState('');
  const initialFavorites = useMemo(() => {
    if (!flows) {
      return {};
    }
    return Object.values(flows).reduce(
      (acc, curr) => ({ ...acc, [curr.id]: curr.is_favorite }),
      {},
    );
  }, [loading]);
  const {
    getFavorite,
    toggleFavorite,
    isLoading: toggleFavoriteLoading,
  } = useToggleFavorite('capacitycomponent', initialFavorites);

  const data = useMemo(() => {
    onFilterChange(flows ?? []);
    if (!flows) {
      return null;
    }
    return Object.values(flows)
      .map((flow) => ({
        id: flow.id,
        isFavorite: getFavorite(flow.id),
        pipeline: flow.tsp_name,
        description: flow.description,
        noticePriority: flow.best_available_notice?.priority,
        noticeOAC: flow.best_available_notice?.amount,
        noticePercent: flow.best_available_notice?.percent,
        seg: flow.segment,
        ourDirection: flow.flow_direction,
        gasDate: flow.best_available?.gas_date,
        cycle: flow.best_available?.nomination_cycle,
        scheduled: flow.best_available?.scheduled_volume,
        utilization: flow.best_available?.utilization,
        oac: flow.best_available?.oac_calc,
        type: flow.capacity_type,
        itFlow: flow.best_available?.interruptible_flow,
        locations: flow.points.length,
        notice: flow.best_available_notice,
        nodes: flow.nodes,
      }))
      .sort((a, b) => {
        let sortValue = 0;
        if (typeof a[sortKey] !== typeof b[sortKey]) sortValue = 0;
        if (typeof a[sortKey] === 'string') {
          sortValue = a[sortKey].localeCompare(b[sortKey]);
        } else if (
          typeof a[sortKey] === 'number' ||
          typeof a[sortKey] === 'boolean'
        ) {
          sortValue = a[sortKey] - b[sortKey];
        }

        return sortOrder === 'asc' ? sortValue : -sortValue;
      });
  }, [flows, sortKey, sortOrder, getFavorite]);

  const setSorting = (key) => {
    if (sortKey === key) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortKey(key);
      setSortOrder('asc');
    }
  };

  if (!data) {
    return null;
  }

  return (
    <>
      <div className="row justify-content-between m-3 position-sticky">
        <div className="col-6 col-sm-8 col-xs-11">
          <div className="input-group">
            <span className="input-group-text">
              <SearchIcon />
            </span>
            <DebouncedInput
              type="text"
              className="form-control"
              placeholder="Pipeline or description"
              value={searchText}
              onChange={(value) => {
                setSearchText(value);
                onSearch(value);
              }}
            />
          </div>
        </div>
        {/* <div className="col-1 d-flex flex-row-reverse align-items-center">
          <button className="btn btn-outline-secondary" type="button"><FilterIcon/></button>
        </div> */}
      </div>
      <div style={{ minHeight: 200 }}>
        <div>
          {data?.length === 0 && loading === false ? (
            <div className="alert alert-info m-3 text-center" role="alert">
              {searchText.length > 0
                ? `No results for ${searchText}, try a different search.`
                : 'No flows found. Try a different filter.'}
            </div>
          ) : (
            <>
              <table className="table">
                <thead>
                  <tr>
                    <th role="button" onClick={() => setSorting('isFavorite')}>
                      Favorite{' '}
                      {sortKey === 'isFavorite' &&
                        (sortOrder === 'asc' ? (
                          <ChevronUpIcon />
                        ) : (
                          <ChevronDownIcon />
                        ))}
                    </th>
                    <th>Go To</th>
                    <th>History</th>
                    <th role="button" onClick={() => setSorting('pipeline')}>
                      Pipeline
                      {sortKey === 'pipeline' &&
                        (sortOrder === 'asc' ? (
                          <ChevronUpIcon />
                        ) : (
                          <ChevronDownIcon />
                        ))}
                    </th>
                    <th>Description</th>
                    <th
                      role="button"
                      onClick={() => setSorting('noticePriority')}
                    >
                      Notice Priority
                      {sortKey === 'noticePriority' &&
                        (sortOrder === 'asc' ? (
                          <ChevronUpIcon />
                        ) : (
                          <ChevronDownIcon />
                        ))}
                    </th>
                    <th role="button" onClick={() => setSorting('noticeOAC')}>
                      Notice OAC
                      {sortKey === 'noticeOAC' &&
                        (sortOrder === 'asc' ? (
                          <ChevronUpIcon />
                        ) : (
                          <ChevronDownIcon />
                        ))}
                    </th>
                    <th
                      role="button"
                      onClick={() => setSorting('noticePercent')}
                    >
                      Notice %
                      {sortKey === 'noticePercent' &&
                        (sortOrder === 'asc' ? (
                          <ChevronUpIcon />
                        ) : (
                          <ChevronDownIcon />
                        ))}
                    </th>
                    <th>Seg</th>
                    <th
                      role="button"
                      onClick={() => setSorting('ourDirection')}
                    >
                      Our Direction
                      {sortKey === 'ourDirection' &&
                        (sortOrder === 'asc' ? (
                          <ChevronUpIcon />
                        ) : (
                          <ChevronDownIcon />
                        ))}
                    </th>
                    <th>Gas Date</th>
                    <th role="button" onClick={() => setSorting('cycle')}>
                      Cycle
                      {sortKey === 'cycle' &&
                        (sortOrder === 'asc' ? (
                          <ChevronUpIcon />
                        ) : (
                          <ChevronDownIcon />
                        ))}
                    </th>
                    <th role="button" onClick={() => setSorting('scheduled')}>
                      Scheduled
                      {sortKey === 'scheduled' &&
                        (sortOrder === 'asc' ? (
                          <ChevronUpIcon />
                        ) : (
                          <ChevronDownIcon />
                        ))}
                    </th>
                    <th role="button" onClick={() => setSorting('utilization')}>
                      Utilization
                      {sortKey === 'utilization' &&
                        (sortOrder === 'asc' ? (
                          <ChevronUpIcon />
                        ) : (
                          <ChevronDownIcon />
                        ))}
                    </th>
                    <th role="button" onClick={() => setSorting('oac')}>
                      OAC
                      {sortKey === 'oac' &&
                        (sortOrder === 'asc' ? (
                          <ChevronUpIcon />
                        ) : (
                          <ChevronDownIcon />
                        ))}
                    </th>
                    <th role="button" onClick={() => setSorting('type')}>
                      Type
                      {sortKey === 'type' &&
                        (sortOrder === 'asc' ? (
                          <ChevronUpIcon />
                        ) : (
                          <ChevronDownIcon />
                        ))}
                    </th>
                    <th role="button" onClick={() => setSorting('itFlow')}>
                      IT Flow?
                      {sortKey === 'itFlow' &&
                        (sortOrder === 'asc' ? (
                          <ChevronUpIcon />
                        ) : (
                          <ChevronDownIcon />
                        ))}
                    </th>
                    <th role="button" onClick={() => setSorting('locations')}>
                      Locations
                      {sortKey === 'locations' &&
                        (sortOrder === 'asc' ? (
                          <ChevronUpIcon />
                        ) : (
                          <ChevronDownIcon />
                        ))}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((row) => (
                    <tr key={row.id}>
                      <td>
                        <div
                          disabled={toggleFavoriteLoading}
                          className="btn btn-light w-100"
                          onClick={() => toggleFavorite(row.id)}
                        >
                          <StarIcon
                            color={getFavorite(row.id) ? 'yellow' : 'black'}
                          />
                        </div>
                      </td>
                      <td>
                        <button
                          className="btn btn-light w-100 b-0"
                          disabled={!allNodes[row.nodes[0]]}
                          onClick={() => {
                            panMap(allNodes[row.nodes[0]], 12);
                          }}
                        >
                          <ZoomInIcon
                            color={allNodes[row.nodes[0]] ? 'black' : 'grey'}
                          />
                        </button>
                      </td>
                      <td>
                        <button
                          className="btn btn-light w-100"
                          onClick={() => setComponentHistoryId(row.id)}
                        >
                          <ChartIcon />
                        </button>
                      </td>
                      <td>{row.pipeline}</td>
                      <td>{row.description}</td>
                      <td>
                        {row.noticePriority ? (
                          <>
                            <div id={`tooltip-priority-${row.id}`}>
                              {row.noticePriority}
                            </div>
                            <NoticeToolTip
                              notice={row.notice}
                              tooltipId={`tooltip-priority-${row.id}`}
                            />
                          </>
                        ) : (
                          <div style={{ textAlign: 'center' }}>-</div>
                        )}
                      </td>
                      <td>
                        {row.noticeOAC ? (
                          <>
                            <div id={`tooltip-amount-${row.id}`}>
                              {row.noticeOAC !== null
                                ? row.noticeOAC.toLocaleString('en-US')
                                : ''}
                            </div>
                            <NoticeToolTip
                              notice={row.notice}
                              tooltipId={`tooltip-amount-${row.id}`}
                            />
                          </>
                        ) : (
                          <div style={{ textAlign: 'center' }}>-</div>
                        )}
                      </td>
                      <td>
                        {row.noticePercent ? (
                          <>
                            <div id={`tooltip-percent-${row.id}`}>
                              {row.noticePercent * 100}%
                            </div>
                            <NoticeToolTip
                              notice={row.notice}
                              tooltipId={`tooltip-percent-${row.id}`}
                            />
                          </>
                        ) : (
                          <div style={{ textAlign: 'center' }}>-</div>
                        )}
                      </td>
                      <td>{row.segment}</td>
                      <td>{row.ourDirection ?? 'FORWARDHAUL'}</td>
                      <td>{row.gasDate || 'Unknown'}</td>
                      <td>{row.cycle || 'Unknown'}</td>
                      <td>
                        {row.scheduled ? (
                          numberFormatter(row.scheduled)
                        ) : (
                          <div style={{ textAlign: 'center' }}>-</div>
                        )}
                      </td>
                      <td>
                        {row.utilization ? (
                          `${Math.floor(row.utilization)}%`
                        ) : (
                          <div style={{ textAlign: 'center' }}>-</div>
                        )}
                      </td>
                      <td>
                        {row.oac ? (
                          numberFormatter(row.oac)
                        ) : (
                          <div style={{ textAlign: 'center' }}>-</div>
                        )}
                      </td>
                      <td>{row.type}</td>
                      <td>{row.itFlow}</td>
                      <td>
                        <button
                          className="btn btn-primary w-100 px-2"
                          onClick={() => setComponentId(row.id)}
                        >
                          {row.locations}
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <ReusableModal
                onClose={() => setComponentId(false)}
                isOpen={Boolean(componentId)}
                header={component?.description}
              >
                {componentId && (
                  <ComponentLocationManager
                    componentId={componentId}
                    setComponent={setComponent}
                  />
                )}
              </ReusableModal>

              <ReusableModal
                size="lg"
                onClose={() => setComponentHistoryId(false)}
                isOpen={Boolean(componentHistoryId)}
                header={'History'}
              >
                <CapacityComponentHistory componentId={componentHistoryId} />
              </ReusableModal>
            </>
          )}
        </div>
        {loading && <LoadingIndicator />}
        <InfiniteScrollObserver onScrollIn={onNextPage} />
      </div>
    </>
  );
};

const NoticeToolTip = ({ notice, tooltipId }) => {
  const tooltipData = [
    { key: 'identifier', header: 'id' },
    { key: 'identifier_type', header: 'id type' },
    { key: 'notice_id', header: 'notice id' },
    { key: 'effective_datetime', header: 'date' },
    { key: 'priority', header: 'priority' },
    { key: 'amount', header: 'amount' },
    { key: 'percent', header: 'percent' },
    { key: 'reason', header: 'reason' },
    { key: 'tsp_name', header: 'tsp' },
    { key: 'nomination_cycle', header: 'nomination cycle' },
  ];

  return (
    <UncontrolledTooltip
      style={{ maxWidth: '80%', left: '10%', position: 'relative' }}
      target={tooltipId}
    >
      <table>
        <tbody>
          <tr>
            {tooltipData.map((item) => (
              <th
                key={item.key}
                style={{
                  border: '1px solid white',
                  fontSize: 'bold',
                }}
              >
                {item.header}
              </th>
            ))}
          </tr>
          <tr>
            {tooltipData.map((item) => (
              <td key={item.key} style={{ border: '1px solid white' }}>
                {item.header === 'amount'
                  ? notice[item.key] !== null
                    ? notice[item.key].toLocaleString('en-US')
                    : ''
                  : item.header === 'percent'
                  ? `${notice[item.key] * 100}%`
                  : notice[item.key]}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </UncontrolledTooltip>
  );
};
