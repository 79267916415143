import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import toast from 'react-hot-toast';
import { plotterActions, createUpdateNode } from '../plotter/plotterSlice';
import { Edge, Node } from './Sidebar';
import {
  ItemSelector,
  SelectedNode,
} from '../selectedItems/SelectorComponents';
import * as selectedItemsActions from '../selectedItems/selectedItemsSlice';
import { EdgeBuilder } from '../selectedItems/Edge';
import { ReduxPointSearch } from '../search/ReduxPointSearch';
import * as plotterSelectors from '../plotter/plotterSelectors';

import { useCreateUpdatePointMutation } from '../services/edgeApi';
import { jsonBinApi } from '../services/jsonBinApi';

export function MapBuilder() {
  const dispatch = useDispatch();
  const showCountyPoints = useSelector(
    (state) => state.plotter.showCountyPoints,
  );
  const currentlySelecting = useSelector(
    (state) => state.selectedItems.currentlySelecting,
  );
  // might be worth adding these to selectors in selectedItemsSlice:
  const currentSelectionTypes = Object.keys(currentlySelecting).map(
    (type) => type,
  );
  const currentSelectionKeys = Object.keys(currentlySelecting).map(
    (type) => currentlySelecting[type].selectionKey,
  );
  // this isn't very clean but whatever:
  const selectedNodeId = useSelector(
    (state) => state.selectedItems.buildNodeId[0],
  );
  const selectedEdgeId = useSelector((state) => state.selectedItems.edge[0]);
  const {
    data: counties,
    error: countiesError,
    isLoading: countiesLoading,
  } = jsonBinApi.endpoints.getCounties.useQueryState();
  const selectedNodeData = useSelector(plotterSelectors.selectAllNodes)[
    selectedNodeId
  ];
  useEffect(() => {
    // should we set a mapBuilder flag somewhere? or handle that in the selectionKey? or a new flag for add / edit true? - because a trader might still need to edit something even if they aren't in the map builder
    dispatch(
      selectedItemsActions.currentlySelectingChanged({
        selectionKey: 'buildNodeId',
        itemType: 'node',
        multiSelect: false,
        isCreatable: true,
        isEditable: true,
        isDeletable: true,
      }),
    );
    // dispatch(selectedItemsActions.currentlySelectingChanged({selectionKey: 'edge', itemType:'edge', multiSelect: false, isCreatable: true, isEditable: true, isDeletable: true, preventToggle: true}))
  }, []);

  // keyboard shortcuts
  // useEffect(() => {
  //   const handleEsc = (event) => {
  //      if (event.keyCode === 27) {
  //        // dispatch(setStartNode(null))
  //        dispatch(plotterActions.setActiveNodeId(null))
  //        // is this the best way to handle new node removal?
  //        dispatch(plotterActions.removeNewNode())
  //        dispatch(plotterActions.setActiveEdge(null))
  //     }
  //   };
  //   window.addEventListener('keydown', handleEsc);

  //   return () => {
  //     window.removeEventListener('keydown', handleEsc);
  //   };
  // }, []);

  // clean up
  useEffect(
    () => () => {
      // what's the way to send one action that a bunch of slices respond to to clean up their state?
      dispatch(selectedItemsActions.resetState());
    },
    [],
  );

  return (
    <>
      <div className="card-group my-3">
        <ItemSelector
          selectionKey="buildNodeId"
          itemType="node"
          title="Add Points & Nodes"
          isEditable
          isCreatable
          isDeletable
          hideItems
          overwrite
          hideSearch
        />
        <ItemSelector
          selectionKey="edge"
          itemType="edge"
          title="Add Edges"
          preventToggle
          isEditable
          isCreatable
          isDeletable
          hideItems
          overwrite
          hideSearch
        />
      </div>
      {currentSelectionKeys.includes('buildNodeId') ? (
        <NodeBuilder
          key={`${selectedNodeId}_${selectedNodeData?.lat}`}
          nodeId={selectedNodeId}
        />
      ) : currentSelectionKeys.includes('edge') ? (
        <EdgeBuilder key={selectedEdgeId} />
      ) : (
        'Something went wrong'
      )}
      {/* <button
        className="btn w-100 btn-outline-secondary"
        onClick={() => dispatch(plotterActions.toggleShowCountyPoints())}
        disabled={countiesLoading}
      >
        {countiesLoading && (
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />
        )}
        {showCountyPoints && !countiesLoading
          ? 'Hide County Points'
          : 'Show County Points'}
      </button> */}
    </>
  );
}

function NodeBuilder(props) {
  const selectedNodeId = props.nodeId;
  const selectedNodeData = useSelector(plotterSelectors.selectAllNodes)[
    selectedNodeId
  ];
  const [createUpdatePoint] = useCreateUpdatePointMutation();
  const apiPoints = useSelector(plotterSelectors.selectApiPoints);
  // start point match search immediately if node id is new
  const [initSearch, setInitSearch] = useState(selectedNodeId === 'new');

  // start searching again when new node is moved.  This could also be accomplished by setting the key of NodeBuilder to change when location changes
  useEffect(() => {
    setInitSearch(true);
  }, [selectedNodeData]);

  const addPointToNode = async (searchProps) => {
    // pass this function into the search results to be run when a result is clicked
    const { id: pointId } = searchProps;
    // current server data of the point before editing it
    const pointCurrentData = apiPoints[pointId];
    const nodeData = selectedNodeData;
    const pointData = { id: pointId, node: selectedNodeId };
    // if the point is already part of a different node, alert the user
    if (pointCurrentData.node && pointCurrentData.node !== nodeData.id) {
      // confirm with user
      const confirmed = window.confirm(
        `This point is already associated with node ${pointCurrentData.node}.  Are you sure you want to change its node?`,
      );
      // stop if not confirmed
      if (!confirmed) return null;
    }

    try {
      await createUpdatePoint({
        point: pointData,
        node: nodeData,
      }).unwrap();
      toast.success('Point Added To Node');
    } catch (e) {
      toast.error(`ERROR: ${JSON.stringify(e)}`);
    }
  };

  if (!selectedNodeId) {
    return (
      <>
        <div>Select a node to edit or search by point info</div>
        <div>Click map to create a new node</div>
      </>
    );
  }

  return (
    <SelectedNode
      key={selectedNodeId}
      nodeId={selectedNodeId}
      selectionKey="buildNodeId"
      isEditable
      isDeletable
      pointIsEditable
    >
      <ReduxPointSearch
        isSearching={initSearch}
        handler={addPointToNode}
        buttonTitle="Add Existing Point to Node"
      />
      <>Add New Point</>
    </SelectedNode>
  );
}

export function MapBuilderSidebar() {
  const dispatch = useDispatch();

  const plotter = useSelector((state) => state.plotter);
  // const remainingPointCount = useSelector(plotterActions.selectRemainingPointCount)
  const remainingPointCount = 'fix';
  const noEdgeNodes = 'fix';
  const points = useSelector((state) => state.plotter.points);
  const appMode = useSelector((state) => state.plotter.appMode);

  const dumpRemainingPoints = () => {
    const remainingPoints = Object.keys(points)
      .filter(
        (key, index) => !points[key].node && points[key].inact_date === '',
      )
      .map((pointId) => points[pointId]);
    window.remainingPoints = remainingPoints;
  };

  const dumpData = () => {
    const { nodes } = plotter;
    const { edges } = plotter;
    const nodeDump = [];
    const edgeDump = [];

    Object.keys(nodes).map((key, index) => {
      const node = nodes[key];
      // just getting the first point associated with a node
      if (node.points.length > 0) {
        const point = points[node.points[0]];
        const nodePoint = {};
        nodePoint.id = node.id;
        nodePoint.lat = node.lat;
        nodePoint.lng = node.lng;
        nodePoint.loc_id = point.loc_id || '';
        nodePoint.loc_name = point.loc_name || '';
        nodePoint.dir_flo = point.dir_flo;
        nodePoint.seg_nbr = point.seg_nbr;
        nodePoint.loc_zone_del = point.loc_zone_del;
        nodePoint.loc_zone_rec = point.loc_zone_rec;
        nodeDump.push(nodePoint);
        // console.log(nodePoint)
      }
    });

    Object.keys(edges).map((key, index) => {
      const edge = edges[key];
      // just getting the first point associated with a node
      const cleanEdge = {};
      cleanEdge.id = edge.id;
      cleanEdge.start_node = edge.start;
      cleanEdge.end_node = edge.end;
      edgeDump.push(cleanEdge);
    });

    const data = {};

    data.nodes = nodeDump;
    data.edges = edgeDump;
    // nodes: 1: {lat, lng, firstpointinfo}
    // edges: 1: start, end
  };

  // this is almost definitely not the best way to do this.  Django should probably send related edges to each point in the JSON

  // const noEdgeNodes = useSelector((state) => {
  //   let nodes = state.plotter.nodes
  //   let edges = state.plotter.edges
  //   let nodes_with_edges = []
  //   Object.keys(edges).map((key, index) => {
  //     const edge = edges[key]
  //     nodes_with_edges.push(edge.start)
  //     nodes_with_edges.push(edge.end)
  //   })
  //   // get unique
  //   const unique = nodes_with_edges.filter((v, i, a) => a.indexOf(v) === i);
  //   const node_count = Object.keys(nodes).length
  //   // need to get the actual nodes to display on the map, but just doing the count for now
  //   return node_count - unique.length
  //   //Object.keys(nodes).filter((key, index) => unique.includes(key))
  // })

  const inputRef = useRef();

  const handleChange = (evt) => {
    const value =
      evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
    const { name } = evt.target;

    dispatch(plotterActions.updateActiveNode({ name, value }));
  };

  const checkMatch = (evt) => {
    handleChange(evt);
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    dispatch(createUpdateNode(plotter.activeNode));
  };

  const nodeMode = appMode === 'nodeBuilder';
  const edgeMode = appMode === 'edgeBuilder';

  return (
    <>
      <div className="p-3">
        <div className="row">
          <div className="col">
            <div className="btn-group d-flex">
              <Button
                className="w-100"
                color={nodeMode ? 'primary' : 'outline-primary'}
                disabled={nodeMode}
                onClick={() =>
                  dispatch(
                    plotterActions.appModeChanged({
                      appMode: 'nodeBuilder',
                    }),
                  )
                }
              >
                {nodeMode ? 'Adding' : 'Add'} Nodes
              </Button>
              <Button
                className="w-100"
                color={edgeMode ? 'primary' : 'outline-primary'}
                disabled={edgeMode}
                onClick={() =>
                  dispatch(
                    plotterActions.appModeChanged({
                      appMode: 'edgeBuilder',
                    }),
                  )
                }
              >
                {edgeMode ? 'Adding' : 'Add'} Edges
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="p-3 overflow-auto">
        {edgeMode ? (
          <EdgeBuilder />
        ) : (
          // defaulting to nodeMode even if it's not explicitly set.  Should we change that?
          <Node isEditable />
        )}
        <hr />
        <div className="btn-group-vertical w-100">
          <Button color="outline-primary" onClick={() => dumpData()}>
            Dump Data
          </Button>
          <Button color="outline-primary" onClick={() => dumpRemainingPoints()}>
            Dump Remaining Points
          </Button>
          <Button
            color="outline-primary"
            onClick={() => dispatch(plotterActions.toggleIsolated())}
          >
            Toggle Isolated Nodes
          </Button>
          <Button
            color="outline-primary"
            onClick={() => dispatch(plotterActions.toggleFiltered())}
          >
            Toggle Review Nodes
          </Button>
          <Button
            color="outline-primary"
            onClick={() => dispatch(plotterActions.setModal(true))}
          >
            Set Map Overlays
          </Button>
        </div>
      </div>
    </>
  );
}
