import { Navigate, Outlet } from 'react-router-dom';
import LogRocket from 'logrocket';
import { useGetPermissionsQuery } from '../services/edgeApi';
// import toast from "react-hot-toast";

export function Can(props) {
  const { data: permissions, error, isLoading } = useGetPermissionsQuery();
  const { type, can, children } = props;

  if (error) {
    console.error('PERMISSIONS ERROR', error);
    return null;
  }

  if (isLoading) {
    // console.log("PERMISSIONS LOADING")
    return null;
  }

  if (!permissions) {
    return null;
  }

  if (permissions.info) {
    // identify with logrocket - might not be the place to do it but should be good enough
    // This is an example script - don't forget to change it!
    const user = permissions.info;
    LogRocket.identify(user.id, {
      name: user.name,
      email: user.email,

      // Add your own custom user variables here, ie:
      role: user.role,
    });
  }

  if (permissions.rules?.includes(can)) {
    // console.log("HAS THE PERMISSIONS")
    return type === 'route' ? <Outlet /> : children;
  }

  if (!permissions.rules?.includes(can)) {
    // console.log("NOT PERMITTED")
    // console.log(JSON.stringify(permissions))
    return type === 'route' ? <Navigate to="/" /> : <></>;
  }

  return null;
}
