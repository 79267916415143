import React, { forwardRef } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Spreadsheet } from './Spreadsheet';

/**
 * @template {import('react-spreadsheet').CellBase} CellType
 * @param {import("./Spreadsheet").SpreadsheetProps<CellType> & {
 *  toggle: () => void,
 *  isOpen: boolean,
 *  children: React.ReactNode,
 *  title: React.ReactNode,
 *  subTitle: React.ReactNode,
 * }} props
 * @param {React.Ref<import("./Spreadsheet").SpreadsheetRef<CellType>>} ref
 * @returns
 */
const _SpreadsheetModal = (props, ref) => {
  const { toggle, isOpen, children, title, subTitle, ...spreadSheetProps } =
    props;
  return (
    <Modal size="xl" scrollable isOpen={isOpen}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        {subTitle}
        <Spreadsheet ref={ref} {...spreadSheetProps} />
      </ModalBody>
      <ModalFooter>{children}</ModalFooter>
    </Modal>
  );
};

export const SpreadsheetModal = forwardRef(_SpreadsheetModal);
