import { useSelector, useDispatch } from 'react-redux';
import { UncontrolledTooltip, Collapse } from 'reactstrap';
import {
  useParams,
  NavLink,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MarketData } from '../flows/Flows';
import { numberFormatter } from '../helpers/formatters';
import { Tag } from '../helpers/Tag';
import { SidebarPath } from '../paths/SidebarPaths';
import { AddToGroup } from './AddToGroup';
import { ChainDetail } from './ChainDetail';
import { ScrollIntoView } from '../helpers/ScrollIntoView';
import {
  useCreateUpdateScenarioMutation,
  useGetTSPsQuery,
  useLazyGetPointConstraintsQuery,
  useLazyGetPricesQuery,
  useLazyGetSegmentConstraintsQuery,
  useLockScenarioMutation,
} from '../services/edgeApi';
import { getUniqueTsps } from '../helpers/Scenario';
import { toast } from 'react-hot-toast';
import { toIsoDate } from '../utils/stringUtils';
import { scenarioActions, selectScenario } from './scenarioSlice';
import { store } from '../store';
import {
  CopyIcon,
  EyeIcon,
  EyeOffIcon,
  LockIcon,
  LockUnlockedIcon,
  UploadIcon,
} from '@iconicicons/react';
import { colors } from '../utils/colors';
import { useTspFilter } from '../helpers/hooks';
import { MultiSelectBox } from '../helpers/MultiSelect';

import * as plotterSelectors from '../plotter/plotterSelectors';
import * as pathsActions from '../paths/pathsSlice';
import { allPathsActions, selectAllPathsPickedChains } from './allPathsSlice';
import { OperationDetail } from './OperationDetail';
import { AllPathsChainDetail } from './AllPathsChainDetail';
import * as xlsx from 'xlsx';
import { scenarioToWorkbook } from '../utils/excelUtils';

const SECOND = 1000;

/**
 * @typedef { "NAME" | "PROFIT" | "IS_NEW" | "PROFIT_DTH" | "VOLUME" } SortParameter
 */

/**
 *
 * @param {import('./scenarioSlice').OperationChain[]} chains
 * @param {SortParameter} sortBy
 * @returns {import('./scenarioSlice').OperationChain[]}
 */
const sortChains = (chains, sortBy) => {
  const propertyToUse = {
    NAME: 'chain_info.name',
    PROFIT: 'profit',
    IS_NEW: 'is_new',
    VOLUME: 'delivered_dth',
  };
  return chains.sort((a, b) => {
    if (sortBy === 'PROFIT_DTH') {
      return b.profit / b.delivered_dth - a.profit / a.delivered_dth;
    }
    if (sortBy === 'NAME' && (!a.chain_info || !b.chain_info)) {
      return 0;
    }
    return b[propertyToUse[sortBy]] - a[propertyToUse[sortBy]];
  });
};

/**
 *
 * @param {import('./scenarioSlice').OperationChain[]} chains
 * @param {import('./scenarioSlice').ChainFilters} filters
 */
const filterChains = (scenario, chains, filters) => {
  const tspArray = Object.keys(filters.tsps).filter((tsp) => filters.tsps[tsp]);
  const ids = Object.keys(filters.ids).filter((id) => filters.ids[id]);
  const deliveryTspArray = Object.keys(filters.delivery).filter(
    (tsp) => filters.delivery[tsp],
  );
  const receiptTspArray = Object.keys(filters.receipt).filter(
    (tsp) => filters.receipt[tsp],
  );
  const tags = Object.keys(filters.tags).filter((tagId) => filters.tags[tagId]);
  return chains.filter((chain) => {
    const chainTsps = getUniqueTsps(scenario, chain);
    const receiptTsp = scenario.operations.find(
      (op) => op.id === chain.operations[0],
    )?.receipt_point?.tsp_name;
    const deliveryTsp = scenario.operations.find(
      (op) => op.id === chain.operations.slice(-1).pop(),
    )?.delivery_point?.tsp_name;

    const matchIds = ids.length === 0 || ids.includes(chain.id.toString());
    if (!matchIds) return false;

    if (
      tspArray.length == 0 &&
      deliveryTspArray.length == 0 &&
      receiptTspArray.length == 0 &&
      tags.length == 0
    ) {
      return true;
    }

    const includeTsps =
      tspArray.length === 0
        ? filters.conjunctive
        : filters.conjunctive
        ? tspArray.every((tsp) => chainTsps.includes(tsp))
        : tspArray.some((tsp) => chainTsps.includes(tsp));

    const matchDeliveryTsp =
      deliveryTspArray.length === 0
        ? filters.conjunctive
        : deliveryTspArray.some((tsp) => tsp === deliveryTsp);

    const matchReceiptTsp =
      receiptTspArray.length === 0
        ? filters.conjunctive
        : receiptTspArray.some((tsp) => tsp === receiptTsp);

    const includeTags =
      tags.length === 0
        ? filters.conjunctive
        : filters.conjunctive
        ? tags.every((tag) =>
            chain.chain_info.tags.some((t) => t.type.id.toString() === tag),
          )
        : tags.some((tag) =>
            chain.chain_info.tags.some((t) => t.type.id.toString() === tag),
          );

    const conditions = [
      includeTsps,
      matchDeliveryTsp,
      matchReceiptTsp,
      includeTags,
    ];

    const result = filters.conjunctive
      ? conditions.every((condition) => condition)
      : conditions.some((condition) => condition);

    return filters.negative ? !result : result;
  });
};

/**
 *
 * @param {{
 *  filter: import('./scenarioSlice').ChainFilters,
 *  setFilter: (type: keyof import('./scenarioSlice').ChainFilters, setterFunc: (currentFilters: import('./scenarioSlice').ChainFilters) => import('./scenarioSlice').ChainFilters) => void,
 *  tsps: Array<{id: number, name: string}>,
 *  tags: import('./scenarioSlice').TagType[],
 * }} props
 * @returns
 */
const ChainFilterForm = ({ filter, setFilter, tsps, tags }) => {
  return (
    <>
      <p
        className="btn btn-light w-100 dropdown-toggle my-0"
        id="filters-collapse-button"
        data-bs-auto-close="outside"
        data-bs-toggle="collapse"
        aria-expanded="false"
        href="#filters-collapse"
      >
        Filter Chains
      </p>
      <div id="filters-collapse" className="collapse w-100 px-2 mt-1">
        <div className="w-100">
          <MultiSelectBox
            options={tsps}
            title="Pipelines"
            keyExtractor={(tsp) => `through-tsp-${tsp.id}`}
            isMarked={(tsp) => Boolean(filter.tsps[tsp.name])}
            allowAll
            onToggleAll={(checked) => {
              setFilter('tsps', (prev) => ({
                ...prev,
                tsps: tsps.reduce(
                  (acc, tsp) => ({
                    ...acc,
                    [tsp.name]: checked,
                  }),
                  {},
                ),
              }));
            }}
            onToggle={(tsp, checked) => {
              setFilter('tsps', (prev) => ({
                ...prev,
                tsps: {
                  ...prev.tsps,
                  [tsp.name]: checked,
                },
              }));
            }}
            optionRenderer={(tsp) => tsp.name}
          />
        </div>
        <div className="mt-1 w-100">
          <MultiSelectBox
            options={tsps}
            title="Receipt from"
            keyExtractor={(tsp) => `receipt-tsp-${tsp.id}`}
            isMarked={(tsp) => Boolean(filter.receipt[tsp.name])}
            allowAll
            onToggleAll={(checked) => {
              setFilter('receipt', (prev) => ({
                ...prev,
                receipt: tsps.reduce(
                  (acc, tsp) => ({
                    ...acc,
                    [tsp.name]: checked,
                  }),
                  {},
                ),
              }));
            }}
            onToggle={(tsp, checked) => {
              setFilter('receipt', (prev) => ({
                ...prev,
                receipt: {
                  ...prev.receipt,
                  [tsp.name]: checked,
                },
              }));
            }}
            optionRenderer={(tsp) => tsp.name}
          />
        </div>
        <div className="mt-1 w-100">
          <MultiSelectBox
            options={tsps}
            title="Delivery at"
            keyExtractor={(tsp) => `delivery-tsp-${tsp.id}`}
            isMarked={(tsp) => Boolean(filter.delivery[tsp.name])}
            allowAll
            onToggleAll={(checked) => {
              setFilter('delivery', (prev) => ({
                ...prev,
                delivery: tsps.reduce(
                  (acc, tsp) => ({
                    ...acc,
                    [tsp.name]: checked,
                  }),
                  {},
                ),
              }));
            }}
            onToggle={(tsp, checked) => {
              setFilter('delivery', (prev) => ({
                ...prev,
                delivery: {
                  ...prev.delivery,
                  [tsp.name]: checked,
                },
              }));
            }}
            optionRenderer={(tsp) => tsp.name}
          />
        </div>
        <div className="mt-1 w-100">
          <MultiSelectBox
            options={tags}
            title="Tags"
            keyExtractor={(tag) => `tag-filter-${tag.id}`}
            isMarked={(tag) => Boolean(filter.tags[tag.id.toString()])}
            onToggle={(tag, checked) => {
              setFilter('tags', (prev) => ({
                ...prev,
                tags: {
                  ...prev.tags,
                  [tag.id.toString()]: checked,
                },
              }));
            }}
            optionRenderer={(tag) => <Tag name={tag.name} color={tag.color} />}
          />
        </div>
        <div className="mt-1 w-100 d-flex justify-content-center gap-3">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={filter.conjunctive}
              id="conjunctive-filter"
              onChange={(evt) =>
                setFilter('conjunctive', (prev) => ({
                  ...prev,
                  conjunctive: evt.target.checked,
                }))
              }
            />
            <label className="form-check-label" htmlFor="conjunctive-filter">
              Match All
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value={filter.conjunctive}
              id="negative-filter"
              onChange={(evt) =>
                setFilter('negative', (prev) => ({
                  ...prev,
                  negative: evt.target.checked,
                }))
              }
            />
            <label className="form-check-label" htmlFor="negative-filter">
              Negate Filters
            </label>
          </div>
        </div>
      </div>
      {Object.values(filter.ids).some((val) => val) && (
        <div className="d-flex justify-content-center">
          <span className="text-danger">
            Showing chains through comprehensive view filters
          </span>
        </div>
      )}
    </>
  );
};

/**
 *
 * @typedef {{
 *  scenarioGroup: ScenarioGroup
 *  onEditStart: () => void,
 *  onOptimize: (scenario: import('./scenarioSlice').Scenario) => void,
 *  usingAllPaths: boolean,
 * }} ScenarioDetailProps
 */

/**
 *
 * @param {ScenarioDetailProps} props
 * @returns {React.FC<ScenarioDetailProps>}
 */
export const ScenarioDetail = ({
  usingAllPaths,
  scenarioGroup,
  onEditStart,
  onOptimize,
}) => {
  const selectedPathId = useSelector((state) => state.paths.selectedPathId);
  const setTspFilter = useTspFilter();
  const [createUpdateScenario, { isLoading: isCreatingUpdating }] =
    useCreateUpdateScenarioMutation();
  const [lockScenarioTrigger, { isLoading: isLocking }] =
    useLockScenarioMutation();
  const [pricesTrigger, { isFetching: fetchingPrices }] =
    useLazyGetPricesQuery();
  const [
    segmentConstraintsTrigger,
    { isFetching: fetchingSegmentConstraints },
  ] = useLazyGetSegmentConstraintsQuery();
  const [pointConstraintsTrigger, { isFetching: fetchingPointConstraints }] =
    useLazyGetPointConstraintsQuery();
  const [showMarketData, setShowMarketData] = useState(false);
  const [shouldLiveUpdate, setShouldLiveUpdate] = useState(false);
  const { data: tspsResponse } = useGetTSPsQuery();
  const allNodes = useSelector(plotterSelectors.selectAllNodes);
  const allEdges = useSelector(plotterSelectors.selectAllEdges);
  const allPoints = useSelector(plotterSelectors.selectAllPoints);
  const [queryParams, _] = useSearchParams();
  const navigate = useNavigate();

  const tsps = useMemo(() => {
    return Object.values(tspsResponse ?? {}).sort((a, b) =>
      a.name.localeCompare(b.name),
    );
  }, [tspsResponse]);

  /** @type {import('../types/types').State<SortParameter>} */
  const [sortBy, setSortBy] = useState('PROFIT');

  const timerRef = useRef(null);

  const scenario = useSelector(selectScenario);

  const allPathsChains = useSelector(selectAllPathsPickedChains);
  // const allPathsOperations = useSelector(selectAllPathsOperations);

  /** @type {import('./scenarioSlice').ChainFilters} */
  const chainFilters = useSelector((state) => state.scenario.chainFilters);

  /**
   * @param {keyof import('./scenarioSlice').ChainFilters} type
   * @param {(currentFilters: import('./scenarioSlice').ChainFilters) => import('./scenarioSlice').ChainFilters} setterFunc
   */
  const setFilter = (type, setterFunc) => {
    const newFilters = setterFunc(chainFilters);
    dispatch(
      scenarioActions.setChainFilters({ type, value: newFilters[type] }),
    );
  };

  const filteredChains = useMemo(
    () =>
      sortChains(
        filterChains(scenario, scenario.operation_chains.slice(), chainFilters),
        sortBy,
      ),
    [sortBy, chainFilters, scenario.operation_chains],
  );

  useEffect(() => {
    if (scenario?.operations) {
      if (scenario.operation_chains.length > 0) {
        dispatch(
          pathsActions.pathSelected({
            selectedPathId: filteredChains[0]?.id,
          }),
        );
        // TODO: use context and map inject instead of redux to make mapping paths more composable and reusable
        dispatch(
          pathsActions.setPathsVisible(
            filteredChains.slice(0, 5).map((chain) => String(chain.id)),
          ),
        );
      } else {
        const maxId =
          scenario.operations.reduce((result, op) => {
            if (!result || op.delivered_dth > result?.delivered_dth) {
              return op;
            }
            return result;
          }, null)?.id ?? null;
        dispatch(pathsActions.pathSelected({ selectedPathId: maxId }));
        dispatch(
          pathsActions.setPathsVisible(
            scenario.operations.slice(0, 5).map((op) => String(op.id)),
          ),
        );
      }
    }
  }, [scenario.id, scenario.operation_chains.length, scenario.operations]);

  useEffect(() => {
    if (selectedPathId) return;
    if (
      !(Object.keys(allNodes)?.length > 0) ||
      !(Object.keys(allEdges)?.length > 0) ||
      !(Object.keys(allPoints)?.length > 0) ||
      !(tsps?.length > 0)
    )
      return;
    const selectedChain = Number(queryParams.get('selectedChain'));
    if (selectedChain) {
      const chain = scenario.operation_chains.find(
        ({ id }) => Number(id) === selectedChain,
      );
      if (chain) {
        dispatch(pathsActions.pathSelected({ selectedPathId: chain.id }));
        dispatch(pathsActions.hideAll());
        dispatch(pathsActions.zoomToPath(chain.id));
        const chainTsps = getUniqueTsps(scenario, chain);
        setTspFilter(chainTsps);
      }
    }
  }, [
    queryParams,
    scenario?.operation_chains,
    allNodes,
    allEdges,
    allPoints,
    tsps,
  ]);

  const dispatch = useDispatch();
  const params = useParams();
  const { scenarioGroupId } = params;
  const groupUrlPrepend = scenarioGroupId
    ? `/scenarios/groups/${scenarioGroupId}`
    : '';

  const totalProfit = usingAllPaths
    ? allPathsChains.reduce((profit, chain) => profit + chain.profit, 0)
    : filteredChains.reduce(
        (sum, chain) => Number(sum) + Number(chain.profit),
        0,
      );
  // filter out kexchanges so we just see the total delivered dth
  // TODO: this sorting and filtering should be done in backend
  const totalVolume = usingAllPaths
    ? allPathsChains.reduce(
        (profit, chain) => profit + chain.constrained_max_volume,
        0,
      )
    : filteredChains.reduce(
        (sum, chain) => Number(sum) + Number(chain.delivered_dth),
        0,
      );

  const optimizeScenario = async ({ update = false } = {}) => {
    try {
      if (update) await updateScenario();
      const result = await createUpdateScenario({
        ...store.getState().scenario.data,
        optimize: true,
      }).unwrap();
      onOptimize(result);
    } catch (e) {
      toast.error('Something went wrong, please try again');
      console.error(e);
    }

    /**
     * This could be replaced by a setInterval, but I wanted to make sure
     * that the next update doesn't start until the previous one is finished
     */
    if (shouldLiveUpdate) {
      clearTimeout(timerRef.current);
      timerRef.current = setTimeout(async () => {
        await optimizeScenario({ update: true });
      }, 30 * SECOND);
    }
  };

  /** @type {import('./scenarioSlice').TagType[]} */
  const chainTags = useMemo(() => {
    return scenario.operation_chains.reduce((tags, chain) => {
      chain.chain_info.tags.forEach((tag) => {
        if (tags.some((t) => t.id === tag.type.id)) return;
        tags.push(tag.type);
      });
      return tags;
    }, []);
  }, [scenario]);

  const updateScenario = async (flowDate = toIsoDate(new Date())) => {
    const promises = [
      updatePointConstraints(flowDate),
      updateSegmentConstraints(flowDate),
      updatePrices(flowDate),
    ];
    await Promise.all(promises);
    dispatch(
      scenarioActions.setFlowDate({
        flowDate,
      }),
    );
  };

  const updatePointConstraints = async (flowDate) => {
    try {
      const result = await pointConstraintsTrigger({
        source: 'PLATTS',
        date: flowDate,
      }).unwrap();
      await dispatch(
        scenarioActions.overwrite({
          componentType: 'point_constraints',
          components: result,
          applySettings: true,
        }),
      );
    } catch (e) {
      console.error(e);
    }
  };

  const updateSegmentConstraints = async (flowDate) => {
    try {
      const result = await segmentConstraintsTrigger({
        source: 'PLATTS',
        date: flowDate,
      }).unwrap();
      await dispatch(
        scenarioActions.overwrite({
          componentType: 'segment_constraints',
          components: result,
          applySettings: true,
        }),
      );
    } catch (e) {
      console.error(e);
    }
  };

  const updatePrices = async (flowDate) => {
    try {
      /** @type {import('../scenarios/scenarioSlice').Market[]} */
      const marketPrices = await pricesTrigger({
        date: flowDate,
        priceSource: scenario?.settings?.price_source || 'PLATTS_DAILY',
        bidAsk: scenario?.settings?.price_type === 'BID_ASK',
      }).unwrap();

      await dispatch(
        scenarioActions.overwrite({
          componentType: 'markets',
          components: marketPrices.filter((market) => market.price !== null),
          applySettings: true,
        }),
      );
    } catch (e) {
      console.error(e);
    }
  };

  const exportScenario = () => {
    const wb = scenarioToWorkbook(scenario, allEdges, allPoints, allNodes);
    xlsx.writeFile(wb, `${scenario.name}.xlsx`);
  };

  useEffect(() => {
    if (shouldLiveUpdate) {
      optimizeScenario({ update: true });
    }

    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, [shouldLiveUpdate]);

  const isUpdating =
    isCreatingUpdating ||
    fetchingPrices ||
    fetchingPointConstraints ||
    fetchingSegmentConstraints;

  const setLockState = useCallback(
    async (locked) => {
      const lockRes = await lockScenarioTrigger({
        scenarioId: scenario.id,
        lockState: locked,
      });
      if (lockRes.error) {
        toast.error('Something went wrong, please try again');
        return;
      }
      dispatch(scenarioActions.setLockState({ locked }));
    },
    [scenario],
  );

  const duplicateScenario = () => {
    navigate(`/scenarios/new`, {
      state: {
        scenario: {
          ...scenario,
          name: `${scenario.name} Copy`,
          previous_version: scenario.id,
          id: 'new',
        },
      },
    });
  };

  const hideAllChains = () => {
    if (usingAllPaths) {
      dispatch(allPathsActions.hideAllChains());
    } else {
      dispatch(pathsActions.hideAll());
    }
  };

  const showAllChains = () => {
    if (usingAllPaths) {
      dispatch(allPathsActions.showAllChains());
    } else {
      dispatch(pathsActions.showAll());
    }
  };

  const lockedLoading = scenario.id === 'new' || isLocking || isUpdating;

  const renderScenarioOperations = () =>
    scenario.operations
      .filter(
        (operation) => !operation.is_reciprocal_op && operation.contract_id,
      )
      .map((operation) => (
        <SidebarPath
          id={operation.id}
          path={operation}
          key={operation.id}
          title={`${operation.tsp_name} #${operation.id}`}
        >
          <OperationDetail
            scenario={scenario}
            operation={operation}
            fuelMakeupPrice={3}
          />
        </SidebarPath>
      ));

  const renderScenarioChains = () => {
    if (filteredChains.length > 0) {
      return filteredChains.map((operation_chain) => (
        <ChainDetail
          id={operation_chain.id}
          key={operation_chain.id.toString()}
          chain={operation_chain}
          scenario={scenario}
          selectedPathId={selectedPathId}
        />
      ));
    }
    if (scenario.operations.length > 0) {
      return renderScenarioOperations();
    }
    return (
      <div className="alert alert-warning text-center">
        No chains or operations yet
      </div>
    );
  };

  const renderAllPathsChains = () => {
    if (allPathsChains.length > 0) {
      return allPathsChains.map((chain) => (
        <AllPathsChainDetail
          id={chain.chain_number}
          key={chain.chain_number.toString()}
          selectedPathId={selectedPathId}
          chain={chain}
        />
      ));
    }
    return (
      <div className="alert alert-warning text-center">
        No chains or operations yet
      </div>
    );
  };

  return (
    <>
      {scenarioGroup && <h5>{scenarioGroup.name}</h5>}

      <ul className="pagination justify-content-center">
        <li className={`${scenario.prev_in_group ? '' : 'disabled'} page-item`}>
          <NavLink
            to={
              scenario.prev_in_group
                ? `${groupUrlPrepend}/scenarios/${scenario.prev_in_group}`
                : '#'
            }
            className="page-link"
          >
            {scenarioGroupId ? 'Prev In Group' : 'Prev Scenario'}
          </NavLink>
        </li>
        <li className={`${scenario.next_in_group ? '' : 'disabled'} page-item`}>
          <NavLink
            to={
              scenario.next_in_group
                ? `${groupUrlPrepend}/scenarios/${scenario.next_in_group}`
                : '#'
            }
            className="page-link"
          >
            {scenarioGroupId ? 'Next In Group' : 'Next Scenario'}
          </NavLink>
        </li>
      </ul>

      {/* <GoToScenario  currentId={scenario.id} /> */}
      <UnflowedGasWarnings scenario={scenario} />
      <h5 className="d-flex align-content-center justify-content-between w-100">
        {scenario.name || `Scenario #${scenario.id}`}{' '}
        <div className="float-end" style={{ flexShrink: '0' }}>
          <span id="export-scenario-icon">
            <UploadIcon
              role="button"
              onClick={exportScenario}
              color={colors.primaryBlue}
            />
          </span>
          <UncontrolledTooltip target="export-scenario-icon">
            Export Scenario
          </UncontrolledTooltip>
          <span id="lock-state-icon">
            {scenario.locked ? (
              <>
                <LockIcon
                  id="locked-icon"
                  role={lockedLoading ? 'note' : 'button'}
                  color={lockedLoading ? colors.lightGrey : colors.primaryBlue}
                  onClick={() => setLockState(false)}
                />
              </>
            ) : (
              <>
                <LockUnlockedIcon
                  id="unlocked-icon"
                  role={lockedLoading ? 'note' : 'button'}
                  color={lockedLoading ? colors.lightGrey : colors.primaryBlue}
                  onClick={() => setLockState(true)}
                />
              </>
            )}
          </span>
          <UncontrolledTooltip target="lock-state-icon">
            {scenario.locked ? 'Unlock scenario' : 'Lock scenario'}
          </UncontrolledTooltip>
          <span id="copy-scenario-icon">
            <CopyIcon
              data-testid="duplicate-button"
              role="button"
              color={colors.primaryBlue}
              onClick={duplicateScenario}
            />
            <UncontrolledTooltip target="copy-scenario-icon">
              Duplicate scenario
            </UncontrolledTooltip>
          </span>
          <AddToGroup scenarioId={scenario.id}>Add to group</AddToGroup>
        </div>
      </h5>
      <div className="clearfix" />
      <p style={{ whiteSpace: 'pre' }}>{scenario.description}</p>
      {/* <OptimizeScenario onSuccess={setScenario} scenario={scenario} >Re-Run Optimization</OptimizeScenario> */}
      <div>
        <strong>Flow Date:</strong>{' '}
        {new Date(scenario.flow_date).toLocaleDateString('en-US', {
          timeZone: 'UTC',
        })}
      </div>

      {/* <OptimizeScenario currentScenario={scenario} onSuccess={setOverrideScenarioData}/> */}
      <div>
        <strong>Total Profit:</strong> ${numberFormatter(totalProfit)}
        {filteredChains.length < scenario.operation_chains.length && <>*</>}
      </div>
      <div>
        <strong>Total Delivered Volume:</strong>{' '}
        {numberFormatter(Math.floor(totalVolume))} dth
        {filteredChains.length < scenario.operation_chains.length && <>*</>}
      </div>
      <div>
        <strong>Profit/Dth:</strong> $
        {totalVolume ? numberFormatter(totalProfit / totalVolume, 3) : '-'}
        {filteredChains.length < scenario.operation_chains.length && <>*</>}
      </div>
      {filteredChains.length < scenario.operation_chains.length && (
        <p className="text-muted">
          *Profit and volume are based on the partial selection of filtered
          chains
        </p>
      )}
      <hr />
      <div className="d-grid gap-2">
        <div id="optimize-button-group" className="d-grid gap-2">
          <OptimizeScenarioButton
            scenario={scenario}
            usingAllPaths={usingAllPaths}
            disabled={scenario.locked}
            isUpdating={isUpdating}
            shouldLiveUpdate={shouldLiveUpdate}
            togleLiveUpdate={(evt) => setShouldLiveUpdate(evt.target.checked)}
            onClcik={(update) => {
              if (timerRef.current) {
                clearTimeout(timerRef.current);
              }
              optimizeScenario({ update });
            }}
          />
          <button
            onClick={() => onEditStart()}
            className="btn btn-warning w-100"
            disabled={scenario.locked}
          >
            Edit Scenario
          </button>
        </div>
        {scenario.locked && (
          <UncontrolledTooltip placement="top" target="optimize-button-group">
            This scenario is currently locked <LockIcon />
          </UncontrolledTooltip>
        )}
        {scenario.previous_version && (
          <div className="btn-group" role="group">
            <NavLink
              className="btn btn-outline-primary w-100"
              to={`/scenarios/${scenario.previous_version}`}
            >
              Previous Version
            </NavLink>
          </div>
        )}
        <div className="btn-group" role="group">
          <button className="btn btn-outline-primary" onClick={hideAllChains}>
            Hide All Chains <EyeOffIcon />
          </button>
          <button
            type="button"
            className="btn btn-outline-primary"
            onClick={showAllChains}
          >
            Show All Chains <EyeIcon />
          </button>
        </div>
        {/* to see market data if we want - this can be set up much better where it loads on first click, but on hide it just removes the flows from the map - fine for now */}
        {/* also might need to get nom cycle - we don't save this to a scenario yet but we could */}
        <button
          className="btn btn-outline-primary w-100"
          onClick={() => setShowMarketData(!showMarketData)}
        >
          {showMarketData ? 'Hide Market Data' : 'Show Market Data'}
        </button>
        <Collapse isOpen={showMarketData}>
          {showMarketData && <MarketData gasDate={scenario.flow_date} />}
        </Collapse>
      </div>
      <div className="my-2">
        {scenario.operation_chains?.length > 0 && usingAllPaths === false && (
          <>
            <hr />
            <div className="input-group mb-1">
              <label
                htmlFor="scenario-chain-filter"
                className="input-group-text"
              >
                Sort By
              </label>
              <select
                id="scenario-chain-filter"
                className="form-select"
                value={sortBy}
                onChange={(evt) => setSortBy(evt.target.value)}
              >
                <option value="PROFIT">Profit</option>
                <option value="PROFIT_DTH">Profit/Dth</option>
                <option value="NAME">Name</option>
                <option value="IS_NEW">New chains first</option>
                <option value="VOLUME">Delivered volume</option>
              </select>
            </div>
            <ChainFilterForm
              filter={chainFilters}
              setFilter={setFilter}
              tsps={tsps}
              tags={chainTags}
            />
          </>
        )}
      </div>
      <hr />
      <ScrollIntoView selectedChildId={selectedPathId} delay={1000}>
        {usingAllPaths ? renderAllPathsChains() : renderScenarioChains()}
      </ScrollIntoView>
    </>
  );
};

/**
 *
 * @param {{ scenario: import('./scenarioSlice').Scenario }} props
 * @returns
 */
const UnflowedGasWarnings = ({ scenario }) => {
  // reciprocal operations are the ones where unflowed gas is flowed to so we can prevent optimizer errors and give details about the issue
  // if there are reciprocal operations, other options of where to flow gas need to be added to the scenario in locations where the gas can flow to, like other market prices, trades, storage, contracts, park and loans, balancing agreements, or a best efforts trade to reduce the amount required to flow
  const reciprocalOps = scenario.operations?.filter(
    (op) => op.is_reciprocal_op,
  );

  if (!reciprocalOps) {
    return null;
  }

  // right now it's telling where the unflowed gas is based on the trade description
  // could also use the point name if there's no description

  return (
    <ul className="list-group">
      {reciprocalOps.map((op) => (
        <li key={op.id} className="list-group-item list-group-item-danger">
          {`
              ${Math.ceil(op.delivered_dth)}
              dth of 
              ${
                scenario.trades.find(
                  (trade) => trade.id == op.receipt_asset_object_id,
                ).buy_sell == 'BUY'
                  ? 'unflowed'
                  : 'undelivered'
              }
              gas at 
              ${
                scenario.trades.find(
                  (trade) => trade.id == op.receipt_asset_object_id,
                ).description
              } 
            `}
        </li>
      ))}
    </ul>
  );
};

/**
 * @typedef {{
 *  scenario: import('./scenarioSlice').Scenario,
 *  usingAllPaths: boolean,
 *  isUpdating: boolean,
 *  shouldLiveUpdate: boolean,
 *  disabled: boolean
 *  togleLiveUpdate: React.ChangeEventHandler<HTMLInputElement>,
 *  onClcik: (update: boolean) => void,
 * }} OptimizeScenarioButtonProps
 * @param {OptimizeScenarioButtonProps} props
 * @returns {React.FC<OptimizeScenarioButtonProps>}
 */
const OptimizeScenarioButton = ({
  scenario,
  usingAllPaths,
  isUpdating,
  shouldLiveUpdate,
  disabled,
  togleLiveUpdate,
  onClcik,
}) => {
  const navigate = useNavigate();
  return (
    <div className="btn-group w-100">
      <button
        className="btn btn-primary form-control"
        disabled={isUpdating || disabled}
        onClick={() => onClcik(false)}
        id="optimize-scenario"
      >
        {isUpdating ? (
          <>
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            Optimizing...
          </>
        ) : (
          <>Optimize</>
        )}
      </button>
      {!usingAllPaths && (
        <button
          className="btn btn-primary form-control"
          disabled={isUpdating || disabled}
          onClick={() => navigate(`/scenarios/${scenario.id}/all-paths`)}
        >
          Pick Paths
        </button>
      )}
      <button
        className="btn btn-primary form-control"
        disabled={isUpdating || disabled}
        onClick={() => onClcik(true)}
        id="refresh-and-optimize-scenario"
      >
        {isUpdating ? (
          <>
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            Optimizing...
          </>
        ) : (
          <>Refresh and Optimize</>
        )}
      </button>
      <div className="btn btn-default bg-light text-primary border border-primary align-items-center">
        Live Updates
        <div className="form-switch">
          <input
            disabled={isUpdating || disabled}
            onChange={togleLiveUpdate}
            className="form-check-input"
            type="checkbox"
            id="live-update-toggle"
            checked={shouldLiveUpdate}
          />
        </div>
      </div>
    </div>
  );
};
