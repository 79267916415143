import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
// import { loginUser, userSelector, clearState } from './userSlice';
import toast from 'react-hot-toast';
import { useLoginUserMutation } from '../services/edgeApi';

export function Login() {
  const [loginUser, { isLoading }] = useLoginUserMutation();
  const [user, setUser] = useState({ username: '', password: '' });
  const { state } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    try {
      const loginResult = await loginUser(user).unwrap();
      // toast.success('Logging in')
      localStorage.setItem('token', loginResult.token);
      navigate(state?.from.pathname || '/');
    } catch (e) {
      toast.error('Something Went Wrong.  Please Try Again');
    }
  };

  const handleChange = (evt) => {
    const { value } = evt.target;
    const { name } = evt.target;
    setUser({ ...user, [name]: value });
  };

  return (
    <div className="col col-sm-6 offset-sm-3 text-center mt-5 mb-5">
      <h2>Sign In</h2>

      <form className="" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="username" className="">
            Email address
          </label>
          <input
            id="username"
            type="username"
            name="username"
            autoComplete="email"
            required
            onChange={handleChange}
            value={user.username}
            className="form-control"
          />
        </div>

        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            id="password"
            type="password"
            name="password"
            onChange={handleChange}
            value={user.password}
            autoComplete="current-password"
            required
            className="form-control"
          />
        </div>

        <div className="form-group">
          <button
            type="submit"
            className="btn btn-primary w-100 form-control mt-3"
            disabled={isLoading}
          >
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {/* {isError && <span>ERROR</span>} */}
            <span>Sign in</span>
          </button>
        </div>
      </form>
    </div>
  );
}

// export const Login = ({}) => {
//   console.log("IN LOGIN")
//   const { state } = useLocation();
//   const dispatch = useDispatch();
//   let navigate = useNavigate();
//   const { register, errors, handleSubmit } = useForm();
//   const { isFetching, isSuccess, isError, errorMessage } = useSelector(
//     userSelector
//   );
//   const onSubmit = (data) => {
//     dispatch(loginUser(data));
//   };

//   useEffect(() => {
//     return () => {
//       dispatch(clearState());
//     };
//   }, []);

//   useEffect(() => {
//     if (isError) {
//       toast.error(errorMessage);
//       dispatch(clearState());
//     }

//     if (isSuccess) {
//       dispatch(clearState());
//       navigate(state?.from.pathname || '/');
//     }
//   }, [isError, isSuccess]);

//   return(
//     <>
//       <div className="col col-sm-6 offset-sm-3 text-center mt-5 mb-5">
//         <h2>Sign In</h2>

//         <form
//           className=""
//           onSubmit={handleSubmit(onSubmit)}
//         >
//           <div className="form-group">
//             <label
//               htmlFor="username"
//               className=""
//             >
//               Email address
//             </label>
//             <input
//               id="username"
//               {...register('username', {
//                 pattern: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/i,
//               })}
//               type="username"
//               autoComplete="email"
//               required
//               className="form-control" />
//           </div>

//           <div className="form-group">
//             <label
//               htmlFor="password"
//             >
//               Password
//             </label>
//             <input
//               id="password"
//               {...register('password', { required: true })}
//               type="password"
//               autoComplete="current-password"
//               required
//               className="form-control" />
//           </div>

//           <div className="form-group">
//             <button
//               type="submit"
//               className="btn btn-primary w-100 form-control mt-3"
//               disabled={isFetching}
//             >
//               {isFetching && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>}
//               {/* {isError && <span>ERROR</span>} */}
//               <span>Sign in</span>
//             </button>
//           </div>
//         </form>
//       </div>
//     </>
//   );
// };
