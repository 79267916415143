import { NavLink } from 'react-router-dom';
import { useGetScenarioGroupsQuery } from '../services/edgeApi';

export function ScenarioGroups() {
  const {
    data: scenarioGroups,
    error,
    isLoading,
  } = useGetScenarioGroupsQuery();
  if (isLoading) {
    return <>Loading Scenario Groups</>;
  }

  if (error) {
    return (
      <div className="danger">
        Error Loading Scenario Groups, Please Refresh
      </div>
    );
  }

  return (
    <ul className="list-group">
      {/* <li className="list-group-item">
				<NavLink className="btn btn-success w-100" to={`/scenarios/new`}>New Scenario</NavLink>
			</li> */}
      {scenarioGroups.map((scenarioGroup) => (
        <li key={scenarioGroup.id} className="list-group-item">
          <NavLink className="btn" to={`/scenarios/groups/${scenarioGroup.id}`}>
            {scenarioGroup.name}
          </NavLink>
        </li>
      ))}
      <li key="new" className="list-group-item text-center">
        <NavLink className="btn text-primary" to="/scenarios/groups/new">
          New Scenario Group
        </NavLink>
      </li>
    </ul>
  );
}
