import React, { useState } from 'react';
import { toast } from 'react-hot-toast';
import { ReusableModal } from '../modals/ReusableModal';
import {
  useGetScenarioGroupsQuery,
  useCreateScenarioGroupScenarioMutation,
} from '../services/edgeApi';
import { FolderPlusIcon } from '@iconicicons/react';
import { UncontrolledTooltip } from 'reactstrap';
import { colors } from '../utils/colors';

/**
 *
 * @param {{
 *  children: React.ReactNode | React.ReactNode[],
 *  scenarioId: number,
 *  className: string,
 *  iconColor: string
 * }} props
 * @returns
 */
export function AddToGroup({ children, scenarioId, className, iconColor }) {
  const [isAdding, setIsAdding] = useState(false);

  const { data: scenarioGroups } = useGetScenarioGroupsQuery();
  // TODO: just return the scenario groups that are eligible to be added
  // TODO: need search and pagination for when we end up with a lot of scenario groups

  // const openModal = () => {
  //   ModalService.open(ReusableModal);
  // };

  const [createScenarioGroupScenario, _] =
    useCreateScenarioGroupScenarioMutation();

  const addToGroup = async (evt, groupId) => {
    evt.preventDefault();
    try {
      const deets = { scenario_group: groupId, scenario: scenarioId };
      await createScenarioGroupScenario(deets).unwrap();
      toast.success('Added!');
    } catch (e) {
      if (e.data?.non_field_errors) {
        toast.error(e.data.non_field_errors);
      } else {
        toast.error('Something went wrong, please try again');
      }
    }
  };

  if (!scenarioGroups) {
    return null;
  }

  // modal only opens once at the moment - whatever the fix is, add it into the reusable part of the modal - callback fn?
  // don't really need to load scenario groups until modal is opened - also should load with an attribute to know which ones are already added

  return (
    <span id={`add-to-group-icon-${scenarioId}`}>
      <FolderPlusIcon
        className={className}
        role="button"
        color={iconColor ?? colors.primaryBlue}
        onClick={() => {
          setIsAdding(true);
        }}
      />
      <UncontrolledTooltip target={`add-to-group-icon-${scenarioId}`}>
        {children}
      </UncontrolledTooltip>
      {isAdding ? (
        <ReusableModal
          isOpen={isAdding}
          onClose={() => setIsAdding(false)}
          header="Add To Group"
        >
          <ul className="list-group">
            {scenarioGroups.map((group) => (
              <button
                key={group.id}
                className="list-group-item list-group-item-action"
                onClick={(evt) => addToGroup(evt, group.id)}
              >
                {group.name}
              </button>
            ))}
          </ul>
        </ReusableModal>
      ) : null}
    </span>
  );
}
