import { useDispatch, useSelector } from 'react-redux';
import { EyeIcon, EyeOffIcon } from '@iconicicons/react';
import { plotterActions } from '../plotter/plotterSlice';

export function ToggleContracts({ className }) {
  const dispatch = useDispatch();
  const showContracts = useSelector((state) => state.plotter.showContracts);

  return (
    <button
      onClick={() => dispatch(plotterActions.toggleContractsVisibility())}
      className={`btn btn-primary-outline m-0 p-0 ${className}`}
    >
      {showContracts ? <EyeIcon /> : <EyeOffIcon />}
    </button>
  );
}
