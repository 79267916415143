import { NavLink, useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { EditIcon } from '@iconicicons/react';
import { EditScenarioGroup } from './EditScenarioGroup';
import { useGetScenarioGroupQuery } from '../services/edgeApi';

export function ScenarioGroup(props) {
  // TODO: this and Contract.js could use a hook or HOC to reuse this type of logic for future CRUD components
  const { isEditable } = props;
  const params = useParams();
  const navigate = useNavigate();
  const scenarioGroupId = props.id || params.id;
  const [isEditing, setIsEditing] = useState(
    props.isEditing || scenarioGroupId === 'new',
  );

  // if there's data passed in from props, start with that
  const [scenarioGroup, setScenarioGroup] = useState(props.scenarioGroup);
  // if data is already here, skip fetching it
  const skip = scenarioGroup || scenarioGroupId === 'new';
  // or if we pass in data from a list of contracts, we don't have to run a query for each individual contract
  const {
    data: fetchedScenarioGroup,
    error,
    isLoading,
  } = useGetScenarioGroupQuery(String(scenarioGroupId), { skip });

  useEffect(() => {
    if (fetchedScenarioGroup) {
      setScenarioGroup(fetchedScenarioGroup);
    }
  }, [fetchedScenarioGroup]);

  const onEditSuccess = (result) => {
    // should we invalidate and refetch this scenariogroup, or just handle the response directly?
    setScenarioGroup(result);
    setIsEditing(false);
    // navigate to new contract URL, but might want to do this conditionally
    // right now it does navigate to the new URL but it doesn't re-render this component, probably because there's no key set on the ScenarioGroup component in the routes.  I don't know if we really care because it seems to do what we want
    navigate(`/scenarios/groups/${result.id}`);
  };

  const onCancel = () => {
    setIsEditing(false);
    // depending on where we are, we probably want to do different things when canceliing
    // like on contracts/new, we probably want to go back to /contracts
    // but if we make a contract modal, cancel edit should just hide the modal
    if (scenarioGroupId === 'new') {
      // or "back" to wherever we were previously
      navigate('/scenarios/groups');
    }
  };

  if (isLoading) {
    return <div>Loading ScenarioGroup #{scenarioGroupId}</div>;
  }

  if (error) {
    toast.error(`Problem loading scenario group #${scenarioGroupId}`);
    return null;
  }

  if (isEditing) {
    return (
      <EditScenarioGroup
        scenarioGroup={scenarioGroup}
        onSuccess={onEditSuccess}
        cancelEdit={onCancel}
      />
    );
  }

  // this prevents errors in the first render
  if (!scenarioGroup) {
    return null;
  }

  // console.log(JSON.stringify(scenarioGroup))
  return (
    <>
      <div className="d-flex">
        <h5 className="float-start">{scenarioGroup.name}</h5>
        <button
          className="btn btn-primary-outline btn-sm float-end"
          onClick={() => setIsEditing(true)}
        >
          <EditIcon color="orange" />
        </button>
      </div>
      <p>{scenarioGroup.description}</p>

      {/* TODO: Create new scenario directly in group */}
      <ul className="list-group">
        {scenarioGroup.scenarios.map((scenario) => (
          <li key={scenario.id} className="list-group-item">
            <NavLink
              className="btn w-100"
              to={`/scenarios/groups/${scenarioGroup.id}/scenarios/${scenario.id}`}
            >
              <span className="float-start">
                {scenario.name || scenario.id}
              </span>
              <span className="float-end">{scenario.flow_date}</span>
            </NavLink>
          </li>
        ))}
      </ul>
    </>
  );
}
