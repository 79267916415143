import { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { scenarioActions } from '../scenarios/scenarioSlice';
import { numberFormatter } from '../helpers/formatters';
// import { segmentConstraintsActions } from './segmentConstraintsSlice';

const defaultConstraintValues = {
  description: '',
  flow_direction: 'placeholder',
  constraint_factor: 0,
  max_volume: 1000000,
  cuts_at_priority: 'placeholder',
};

/**
 *
 * @param {{
 *  edgeId?: string,
 * }} props
 */
export const PopupSegmentConstraints = ({ edgeId }) => {
  const componentType = 'segment_constraints';
  const dispatch = useDispatch();
  const [isAdding, setIsAdding] = useState(false);

  /**
   * @type {import('../scenarios/scenarioSlice').ScenarioState}
   */
  const {
    data: { segment_constraints: allSegmentConstraints },
    isEditing,
  } = useSelector((state) => state.scenario);
  const segmentConstraints = allSegmentConstraints.filter(
    (constraint) => constraint.edge == edgeId,
  );

  const [newConstraint, setNewConstraint] = useState({
    ...defaultConstraintValues,
    edge: edgeId,
  });

  const resetNewConstraint = () => {
    setNewConstraint({ ...defaultConstraintValues, edge: edgeId });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewConstraint({ ...newConstraint, [name]: value });
  };

  const handleSubmit = (e) => {
    e.view.L.DomEvent.stopPropagation(e);
    dispatch(
      scenarioActions.updateComponent({
        componentType,
        component: {
          ...newConstraint,
        },
      }),
    );
    resetNewConstraint();
    setIsAdding(false);
  };

  const handleCancel = (e) => {
    e.view.L.DomEvent.stopPropagation(e);
    resetNewConstraint();
    setIsAdding(false);
  };

  const isValid = useMemo(() => {
    if (newConstraint.flow_direction === 'placeholder') {
      return false;
    }
    if (newConstraint.cuts_at_priority === 'placeholder') {
      return false;
    }
    return true;
  }, [newConstraint.flow_direction, newConstraint.cuts_at_priority]);

  return (
    <div className="mt-2" style={{ minWidth: 300 }}>
      {isAdding ? (
        <>
          <h5>New Constraint</h5>
          <div className="form-group my-2">
            <label
              className="form-label mt-2"
              htmlFor={`${edgeId}-description`}
            >
              Description
            </label>
            <input
              className="form-control"
              id={`${edgeId}-description`}
              name="description"
              type="text"
              value={newConstraint.description}
              onChange={handleChange}
            />

            <label
              className="form-label mt-2"
              htmlFor={`${edgeId}-flow-direction`}
            >
              Flow Direction
            </label>
            <select
              className="form-control"
              id={`${edgeId}-flow-direction`}
              name="flow_direction"
              value={newConstraint.flow_direction}
              onChange={handleChange}
            >
              <option value="placeholder" disabled>
                Select Direction
              </option>
              <option value="FORWARDHAUL">Forwardhaul</option>
              <option value="BACKHAUL">Backhaul</option>
            </select>

            <label
              className="form-label mt-2"
              htmlFor={`${edgeId}-constraint-factor`}
            >
              Constraint Factor (0 to 1)
            </label>
            <input
              className="form-control"
              id={`${edgeId}-constraint-factor`}
              name="constraint_factor"
              min="0"
              max="1"
              type="number"
              value={newConstraint.constraint_factor}
              onChange={handleChange}
            />

            <label className="form-label mt-2" htmlFor={`${edgeId}-max-volume`}>
              Max Volume
            </label>
            <input
              className="form-control"
              id={`${edgeId}-max-volume`}
              name="max_volume"
              type="number"
              value={newConstraint.max_volume}
              onChange={handleChange}
            />

            <label className="form-label mt-2" htmlFor={`${edgeId}-priority`}>
              Cuts at Priority
            </label>
            <select
              className="form-control"
              id={`${edgeId}-priority`}
              name="cuts_at_priority"
              value={newConstraint.cuts_at_priority}
              onChange={handleChange}
            >
              <option value="placeholder" disabled>
                Select Priority
              </option>
              <option value="PIP">PIP</option>
              <option value="SIP_PS">SIP_PS</option>
              <option value="SIP_SP">SIP_SP</option>
              <option value="SIP_SS">SIP_SS</option>
              <option value="SOP">SOP</option>
              <option value="IT">IT</option>
            </select>
          </div>
          <div className="d-flex">
            <button className="btn btn-danger me-1 mb-1" onClick={handleCancel}>
              Cancel
            </button>
            <button
              className="btn btn-success mb-1"
              onClick={handleSubmit}
              disabled={isValid === false}
            >
              Save
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="d-flex justify-content-between">
            {isEditing && (
              <button
                className="btn btn-sm btn-success mb-1"
                onClick={(e) => {
                  e.view.L.DomEvent.stopPropagation(e);
                  setIsAdding(true);
                }}
              >
                Add Constraint
              </button>
            )}
          </div>
          <table className="table table-bordered table-sm">
            <thead>
              <tr>
                <th colSpan="4" className="text-center">
                  Constraints
                </th>
              </tr>
              <tr>
                <th>Direction</th>
                <th>Constraint Factor</th>
                <th>OAC</th>
                <th>Priority</th>
              </tr>
            </thead>
            <tbody>
              {segmentConstraints?.length > 0 ? (
                segmentConstraints.map((constraint) => (
                  <tr key={constraint.id}>
                    <td>{constraint.flow_direction}</td>
                    <td>{constraint.constraint_factor * 100}%</td>
                    <td>{numberFormatter(constraint.max_volume)}</td>
                    <td>{constraint.cuts_at_priority}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">
                    No Constraints
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};
