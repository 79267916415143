import jht from 'json-html-table';

/**
 * @template {Object} AT
 * @template {Object} BT
 *
 * @param {AT} a
 * @param {BT} b
 * @returns {Partial<AT>} Object containing only the keys of a that are different from b
 */
export const getDiff = (a, b) =>
  Object.keys(a).reduce((diff, key) => {
    if (a[key] !== b[key]) {
      diff[key] = a[key];
    }
    return diff;
  }, {});

/**
 *
 * @param {any} a
 * @param {any} b
 * @returns {boolean} true if a and b are deeply equal
 */
export const deepEqual = (a, b) => {
  if (Array.isArray(a)) {
    if (Array.isArray(b)) {
      return (
        a.length === b.length &&
        a.every((item, index) => deepEqual(item, b[index]))
      );
    }
    return false;
  }

  if (typeof a === 'object') {
    if (typeof b !== 'object') {
      return false;
    }

    if (a === b) {
      return true;
    }

    if (Object.keys(a).length !== Object.keys(b).length) {
      return false;
    }

    return Object.keys(a).every((key) => deepEqual(a[key], b[key]));
  }

  return a === b;
};

/**
 * Converts an array of objects to it's HTML table representation
 * @param {Array<Object>} data
 * @param {string?} title
 * @returns {string} promise that resolves to csv representation of data
 */
export const toHtmlTable = (data, title = null) => {
  if (data.length === 0) {
    return '';
  }
  const headers = Object.keys(data[0]);
  const table = jht(data, headers);
  if (title !== null) {
    const columns = headers.length;
    return table.replace(
      '<table>',
      `<table><tr><th colspan="${columns}">${title}</th></tr>`,
    );
  }
  return table;
};

/**
 *
 * @param {import('leaflet').LatLngBounds} bounds
 * @param {{lat: number, lng: number}} point
 */
export const boundsContains = (bounds, point) => {
  const { lat, lng } = point;
  if (lat < bounds.getSouth() || lat > bounds.getNorth()) {
    return false;
  }
  if (lng < bounds.getWest() || lng > bounds.getEast()) {
    return false;
  }
  return true;
};
