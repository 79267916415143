import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';
import 'ol/ol.css';
import { Toaster } from 'react-hot-toast';
// import { MapContainer, Pane, TileLayer } from 'react-leaflet';
import { useDispatch } from 'react-redux';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { Button, Col, UncontrolledTooltip } from 'reactstrap';
import { useState, useEffect, useRef } from 'react';
import { MapOverlayPicker } from '../overlays/OverlayPicker';
// import { MapPaths } from '../paths/MapPaths';
// import { AllNodes, Plotter } from '../plotter/Plotter';
import * as overlaysActions from '../overlays/overlaysSlice';
import { Can } from '../user/Can';
// import { MapSegmentConstraints } from '../segmentConstraints/MapSegmentConstraints';
// import { MapPointConstraints } from '../pointConstraints/MapPointConstraints';
// import { MapMarkets } from '../markets/MapMarkets';
// import { ZoomPath } from '../paths/ZoomPath';
import {
  useGetMapEdgesQuery,
  useGetMapNodesQuery,
  useGetMapPointsQuery,
} from '../services/edgeApi';
// import { MapEdgeContracts } from '../contracts/MapContracts';
import mapContext from '../plotter/leafletMapContext';
import {
  // MapInjectDisplay,
  MapInjectProvider,
} from '../plotter/mapInjectContext';
import { BelowMapDisplay, BelowMapProvider } from './BelowMapContext';
import { ModalRoot } from '../modals/ModalRoot';
import { ExploreMap } from '../modes/ExploreMap';
// import ResizeListener from '../helpers/ResizeListener';
// import { MapNotices } from '../flows/MapNotices';
// import { PlotterSettings } from '../plotter/PlotterSettings';
import { ArrowUpIcon } from '@iconicicons/react';
import { MapSettings } from '../plotter/MapSettings';
import { EdgeMap } from '../map/EdgeMap';
import { RiseTransportTable } from '../paths/RisePaths';
import { useLocation } from 'react-router-dom';
import { LeafletMap } from '../map/LeafletMap';

export function FlexLayout({ heightCss, navContent }) {
  const navigate = useNavigate();

  const logOut = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };
  // https://stackoverflow.com/questions/33062830/using-react-router-with-a-layout-page-or-multiple-components-per-page
  return (
    <>
      <Toaster />
      <ModalRoot />
      {/* <div id="dark-mode"></div> */}
      <div className={`d-flex flex-column ${heightCss}`}>
        <nav className="navbar navbar-light bg-light d-flex">
          <a
            className="navbar-brand"
            href="/"
            target="_blank"
            rel="noreferrer noopener"
          >
            <img
              style={{
                mixBlendMode: 'multiply',
                paddingLeft: '10px',
              }}
              src="/logo.png"
              height="50"
              alt=""
            />
          </a>

          {localStorage.getItem('token') && (
            <>
              {navContent}
              <Button color="outline-primary" className="mx-2" onClick={logOut}>
                Log Out
              </Button>
            </>
          )}
        </nav>
        <div className="main-content d-flex flex-fill flex-row overflow-hidden">
          <Outlet />
        </div>
      </div>
    </>
  );
}

// TODO: resizeable sidebar width
// https://codesandbox.io/s/react-resizable-sidebar-kz9de

export function SidebarMapLayout(props) {
  const dispatch = useDispatch();

  const [bottomOffset, setBottomOffset] = useState(0);

  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const sidebarRef = useRef();

  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const backToTopRef = useRef();

  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const mapSettingsRef = useRef();

  const { isLoading: pointsLoading } = useGetMapPointsQuery();
  const { isLoading: nodesLoading } = useGetMapNodesQuery();
  const { isLoading: edgesLoading } = useGetMapEdgesQuery();

  const isMapLoading = pointsLoading || nodesLoading || edgesLoading;
  const location = useLocation();

  const isMapBuilderPage = location.pathname.startsWith('/map-builder');

  useEffect(() => {
    props.setHeightCss('vh-100');
    props.setNavContent(
      <div
        className="d-flex justify-content-between mx-3"
        style={{ flexGrow: 1, flexShrink: 0 }}
      >
        <div className="navbar navbar-expand-lg navbar-light bg-light gap-4">
          {buttons
            // .filter(button => permissions?.rules?.includes(button.permissions))
            .map((button) => (
              <Can key={button.url} can={button.permissions}>
                <NavLink
                  key={button.url}
                  to={button.url}
                  className={({ isActive }) =>
                    `nav-item nav-link ${isActive ? 'active text-primary' : ''}`
                  }
                >
                  {button.name}
                </NavLink>
              </Can>
            ))}
        </div>
        <div className="d-flex gap-2">
          {/* <ExploreMap /> */}
          <Button
            color="outline-primary"
            className="mx-2"
            onClick={() => dispatch(overlaysActions.setModal(true))}
          >
            Set Map Overlays
          </Button>
        </div>
      </div>,
    );
  }, []);

  const [map, setMap] = useState(false);

  const buttons = [
    // just using point right now as the defacto permission model
    { url: '/', name: 'Map', permissions: 'view_point' },
    { url: '/scenarios', name: 'Create Scenarios', permissions: 'view_point' },
    // {
    //   url: '/scenario-groups',
    //   name: 'Scenario Groups',
    //   permissions: 'view_point',
    // },
    { url: '/contracts', name: 'Manage Contracts', permissions: 'view_point' },
    {
      url: '/market-conditions',
      name: 'Market Conditions',
      permissions: 'view_point',
    },
    { url: '/day-over-day', name: 'Day Over Day', permissions: 'view_point' },
    // {url: "/all-paths", name:"All Paths"},
    // {url: "/all-receipts", name:"All Receipts to Delivery Point"},
    // {url: "/segmentation", name:"Max Segmentation"},
    // {url: "/visualize-data", name:"Visualize Data"},
    // {url: "/optimize", name:"Optimize Assets"},
    { url: '/map-builder', name: 'Map Builder', permissions: 'add_point' },
  ];

  return (
    <mapContext.Provider value={{ map, setMap }}>
      <MapInjectProvider>
        <BelowMapProvider>
          <Col
            md={3}
            className="d-flex flex-column overflow-auto"
            style={{ position: 'relative' }}
          >
            <div className="p-3">
              <ExploreMap />
            </div>
            {isMapLoading && (
              <div className="d-flex align-content-center align-items-center justify-content-center gap-3 my-3">
                <span className="spinner-border" />
                <span>Map Loading</span>
              </div>
            )}
            <div
              ref={sidebarRef}
              onScroll={(e) => {
                if (!backToTopRef.current) return;
                const display = backToTopRef.current.style.display;
                if (e.target.scrollTop > 1000 && display === 'none') {
                  backToTopRef.current.style.display = 'flex';
                } else if (e.target.scrollTop < 1000 && display === 'flex') {
                  backToTopRef.current.style.display = 'none';
                }
              }}
              className="flex-grow-1 p-3 overflow-auto"
            >
              {/* <hr /> */}
              {/* {JSON.stringify(permissions)} */}
              <Outlet />
              <hr />
              {/* switching to include sidebar paths as children when needed instead of always waiting to be displayed */}
              {/* <SidebarPaths /> */}
            </div>
            <div ref={mapSettingsRef} className="p-3 border-top">
              <MapSettings
                onChangeState={() =>
                  setBottomOffset(
                    mapSettingsRef.current?.getBoundingClientRect().height,
                  )
                }
              />
            </div>
            {/* floatting "back to top" button */}
            <div
              id="back-to-top-button"
              ref={backToTopRef}
              style={{
                zIndex: 1000,
                position: 'absolute',
                right: 32,
                bottom: bottomOffset + 15,
                width: 64,
                height: 48,
                backgroundColor: 'white',
                border: '1px solid lightgrey',
                borderRadius: 8,
                display: 'none',
              }}
              role="button"
              className="flex-column justify-content-center align-items-center"
              onClick={() => {
                if (sidebarRef.current) {
                  sidebarRef.current.scrollTop = 0;
                }
              }}
            >
              <ArrowUpIcon />
            </div>
            <UncontrolledTooltip target="back-to-top-button">
              Back to top
            </UncontrolledTooltip>
          </Col>
          <div className="d-flex flex-column flex-grow-1 col-md-9">
            {isMapBuilderPage ? (
              /**
               * Map builder still needs to use leaflet for now.
               * Eventually we should make it work with openlayers
               */
              <LeafletMap />
            ) : (
              <EdgeMap />
            )}
            <RiseTransportTable key="rise-flows-table" />
            <BelowMapDisplay />
          </div>
          <MapOverlayPicker />
        </BelowMapProvider>
      </MapInjectProvider>
    </mapContext.Provider>
  );
}
