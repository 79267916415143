import { createSlice, createSelector } from '@reduxjs/toolkit';

// ================================ Types ================================ //

/**
 * @typedef {{
 *  id: number,
 *  tsp_name: string,
 *  flow_direction: "string",
 *  oac: number,
 *  notice_file_path: string,
 *  created_at: string,
 *  updated_at: string,
 *  start_gas_date: string,
 *  end_gas_date: string,
 *  effective_datetime: string,
 *  nomination_cycle: string,
 *  nomination_cycle_rank: number,
 *  raw_flow_direction: string,
 *  additional_information: any,
 *  needs_to_be_checked: boolean,
 *  what_to_check: string,
 *  identifier: string,
 *  identifier_type: string,
 *  priority: string,
 *  oac_unit: string,
 *  percentage: number,
 *  reason: string,
 *  full_text: string,
 *  metadata: string,
 *  tsp: number,
 *  extracted_notice: number,
 *  notice_component: number,
 *  max_record_id: number,
 *  nodes: Array<Array<number>>,
 *  has_notice_records: boolean
 * }} NoticeRecord
 */

/**
 * @typedef {{
 *  noticeRecords: NoticeRecord[]
 * }} NoticesState
 */

// ================================ Initial State ================================ //

/**
 * @type {NoticesState}
 */
const initialState = {
  noticeRecords: [],
};

// ============================= Public Methods ============================= //

/**
 *
 * @param {NoticeRecord} notice
 * @returns
 */
export const getRawNoticeUrl = (notice) =>
  notice
    ? `https://pathpoint-space.nyc3.digitaloceanspaces.com/${notice.notice_file_path}`
    : '';

// ================================ Reducers ================================ //

/**
 * @param {NoticesState} initialState
 */
const resetState = (initialState) => initialState;

/**
 *
 * @param {NoticesState} state
 * @param {{
 *  payload: {
 *    noticeRecords: NoticeRecord[]
 *  }
 * }} action
 * @returns {void}
 */
const setNoticeRecords = (state, action) => {
  state.noticeRecords = action.payload.noticeRecords;
};

/**
 *
 * @param {NoticesState} initialState
 */
const _noticeReducers = (initialState) => ({
  resetState: () => resetState(initialState),
  setNoticeRecords,
});

const reducers = _noticeReducers(initialState);

/** @type {import('@reduxjs/toolkit').Slice<NoticesState, typeof reducers, 'notices'>} */
export const noticeSlice = createSlice({
  name: 'notices',
  initialState,
  reducers,
});

export const selectNoticeRecords = createSelector(
  /**
   *
   * @param {{
   *  notices: NoticesState
   * }} state
   * @returns
   */
  (state) => state.notices.noticeRecords,
  (noticeRecords) => noticeRecords ?? [],
);

export const noticeActions = noticeSlice.actions;

export default noticeSlice.reducer;
