import { useSelector } from 'react-redux';
import {
  selectPointConstraints,
  selectSegmentConstraints,
} from '../scenarios/scenarioSlice';
import {
  selectAllNodes,
  selectAllPoints,
  selectFilteredEdgeNodes,
  selectFilteredEdgePoints,
  selectFilteredEdges,
  selectInterconnects,
  selectInterconnectsAtPoints,
} from '../plotter/plotterSelectors';
import { Vector as VectorSource } from 'ol/source.js';
import GeoJSON from 'ol/format/GeoJSON.js';
import { useContext, useEffect, useMemo, useState } from 'react';
import { edgeToGeoJson } from '../utils/geoJsonUtils';
import { WebGLLayer } from './webglLayer';
import { mapContext } from './EdgeMap';
import { fromLonLat } from 'ol/proj';

const getEdgeStyle = () => [
  {
    filter: ['==', ['get', 'head'], 0],
    'stroke-width': 6,
    'stroke-color': ['get', 'color'],
    'stroke-line-cap': 'round',
    'stroke-line-join': 'round',
  },
  {
    filter: ['==', ['get', 'head'], 1],
    'shape-points': 3,
    'shape-radius': 10,
    'shape-fill-color': ['get', 'color'],
    'shape-stroke-color': ['get', 'color'],
    'shape-stroke-width': 4,
    'shape-rotate-with-view': false,
    'shape-displacement': [0, 0],
    'shape-opacity': 1,
    'shape-angle': ['get', 'angle'],
  },
];

const getPointStyle = () => {
  return [
    {
      filter: ['==', ['get', 'interconnect'], 0],
      'circle-radius': 4,
      'circle-fill-color': ['get', 'fill'],
      'circle-stroke-color': ['get', 'stroke'],
      'circle-stroke-width': 4,
    },
    {
      filter: [
        'all',
        ['==', ['get', 'interconnect'], 1],
        ['==', ['get', 'head'], 1],
      ],
      'shape-points': 3,
      'shape-radius': 10,
      'shape-fill-color': ['get', 'color'],
      'shape-stroke-color': ['get', 'color'],
      'shape-stroke-width': 4,
      'shape-rotate-with-view': false,
      'shape-displacement': [0, 0],
      'shape-opacity': 1,
      'shape-angle': ['get', 'angle'],
    },
    {
      filter: [
        'all',
        ['==', ['get', 'interconnect'], 1],
        ['==', ['get', 'head'], 0],
      ],
      'stroke-width': 6,
      'stroke-color': ['get', 'color'],
      'stroke-line-cap': 'round',
      'stroke-line-join': 'round',
    },
  ];
};

/**
 *
 * @param {import('../services/edgeApi').Node} node
 * @param {import('../scenarios/scenarioSlice').PointConstraint} constraint
 * @returns {import('ol/Feature').FeatureLike}
 */
const nodeToGEOJson = (node, constraint) => {
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: fromLonLat([node.lng, node.lat]),
    },
    properties: {
      id: node.id,
      key: `node-${node.id}`,
      stroke: constraint.constraint_factor < 1 ? '#4277d8A0' : '#ff0000A0',
      fill: constraint.constraint_factor < 1 ? '#4277d820' : '#ff000020',
      interconnect: 0,
      head: 0,
      angle: 0,
    },
  };
};

/**
 *
 * @param {{positions: [number, number][], edge: import('../services/edgeApi').Edge, constraint: import('../scenarios/scenarioSlice').SegmentConstraint}} edge
 * @param {boolean} arrowHead
 * @returns {Object}
 */
const extractConstraintGeoJsonProps = ({ constraint }, _arrowHead) => {
  return {
    key: `edge-${constraint.edge}`,
    id: constraint.edge,
    color: constraint.constraint_factor >= 1 ? '#ff0000A0' : '#4277d8A0',
  };
};

/**
 *
 * @param {{positions: [number, number][], interconnect: import('../services/edgeApi').Interconnect, constraint: import('../scenarios/scenarioSlice').SegmentConstraint}} edge
 * @param {boolean} arrowHead
 * @returns {Object}
 */
const extractInterconnectConstraintGeoJsonProps = (
  { constraint, interconnect },
  _arrowHead,
) => {
  return {
    key: `interconnect-${interconnect.id}`,
    id: interconnect.id,
    color: constraint.constraint_factor >= 1 ? '#ff0000A0' : '#4277d8A0',
    interconnect: 1,
  };
};

export const MapMajorSegmentConstraints = ({ zIndex }) => {
  const { map } = useContext(mapContext);
  const segmentConstraints = useSelector(selectSegmentConstraints);
  const edges = useSelector(selectFilteredEdges);
  const showConstraints = useSelector((state) =>
    Boolean(state.plotter.showConstraints),
  );

  const constraintsLayer = useMemo(() => {
    const source = new VectorSource({
      features: new GeoJSON().readFeatures({
        type: 'FeatureCollection',
        features: showConstraints
          ? Object.values(segmentConstraints)
              .filter((constraint) => constraint.constraint_factor >= 1)
              .flatMap((constraint) => {
                const edge = edges[constraint.edge];
                const positions =
                  constraint.flow_direction === 'FORWARDHAUL'
                    ? edge?.positions
                    : edge?.positions?.slice().reverse();
                if (!positions) return [];
                return edgeToGeoJson(
                  { positions, edge, constraint },
                  extractConstraintGeoJsonProps,
                  true,
                );
              })
          : [],
      }),
      format: new GeoJSON(),
    });

    return new WebGLLayer({
      source,
      style: getEdgeStyle(),
      zIndex,
    });
  }, [segmentConstraints, edges, showConstraints]);

  useEffect(() => {
    if (!map || !constraintsLayer) return;
    map.addLayer(constraintsLayer);
    return () => {
      map.removeLayer(constraintsLayer);
      constraintsLayer.dispose();
    };
  }, [map, constraintsLayer]);

  return null;
};

export const MapMinorSegmentConstraints = ({ zIndex }) => {
  const { map } = useContext(mapContext);
  const segmentConstraints = useSelector(selectSegmentConstraints);
  const edges = useSelector(selectFilteredEdges);
  const showConstraints = useSelector((state) =>
    Boolean(state.plotter.showConstraints),
  );

  const [showDetailedConstraints, setShowDetailedConstraints] = useState(
    map?.getView().getZoom() ?? 0 > 8,
  );

  const constraintsLayer = useMemo(() => {
    const source = new VectorSource({
      features: new GeoJSON().readFeatures({
        type: 'FeatureCollection',
        features:
          showDetailedConstraints && showConstraints
            ? Object.values(segmentConstraints)
                .filter((constraint) => constraint.constraint_factor < 1)
                .flatMap((constraint) => {
                  const edge = edges[constraint.edge];
                  const positions =
                    constraint.flow_direction === 'FORWARDHAUL'
                      ? edge?.positions
                      : edge?.positions?.slice().reverse();
                  if (!positions) return [];
                  return edgeToGeoJson(
                    { positions, edge, constraint },
                    extractConstraintGeoJsonProps,
                    true,
                  );
                })
            : [],
      }),
      format: new GeoJSON(),
    });

    return new WebGLLayer({
      source,
      style: getEdgeStyle(),
      zIndex,
    });
  }, [segmentConstraints, edges, showConstraints, showDetailedConstraints]);

  useEffect(() => {
    if (!map || !constraintsLayer) return;
    map.addLayer(constraintsLayer);
    const onChangeZoom = () =>
      setShowDetailedConstraints(map.getView().getZoom() > 8);
    map.getView().on('change:resolution', onChangeZoom);
    return () => {
      map.removeLayer(constraintsLayer);
      constraintsLayer.dispose();
      map.un('change:resolution', onChangeZoom);
    };
  }, [map, constraintsLayer]);

  return null;
};

export const MapMajorPointConstraints = ({ zIndex }) => {
  const { map } = useContext(mapContext);
  const pointConstraints = useSelector(selectPointConstraints);
  const nodeSize = useSelector((state) => state.plotter.nodeSize);
  const nodes = useSelector(selectFilteredEdgeNodes);
  const allNodes = useSelector(selectAllNodes);
  const points = useSelector(selectFilteredEdgePoints);
  const allPoints = useSelector(selectAllPoints);
  const interconnectsAtPoints = useSelector(selectInterconnectsAtPoints);
  const showConstraints = useSelector((state) =>
    Boolean(state.plotter.showConstraints),
  );

  const constraintsLayer = useMemo(() => {
    const source = new VectorSource({
      features: new GeoJSON().readFeatures({
        type: 'FeatureCollection',
        features: showConstraints
          ? Object.values(pointConstraints)
              .filter((constraint) => constraint.constraint_factor >= 1)
              .flatMap((constraint) => {
                const point = points[constraint.point];
                const node = nodes[point?.node];
                if (!node || !point) return [];
                const interconnectsAtPoint =
                  interconnectsAtPoints[point.id] ?? [];
                if (interconnectsAtPoint.length > 0) {
                  return interconnectsAtPoint.flatMap((ic) => {
                    const startPoint = allPoints[ic.start_point];
                    const startNode = allNodes[startPoint?.node];
                    const endPoint = allPoints[ic.end_point];
                    const endNode = allNodes[endPoint?.node];

                    if (!startNode || !endNode) return [];
                    const positions =
                      (constraint.flow_direction === 'DELIVERY' &&
                        ic.start_point === point.id) ||
                      (constraint.flow_direction === 'RECEIPT' &&
                        ic.end_point === point.id)
                        ? [
                            [startNode.lat, startNode.lng],
                            [endNode.lat, endNode.lng],
                          ]
                        : [
                            [endNode.lat, endNode.lng],
                            [startNode.lat, startNode.lng],
                          ];

                    return edgeToGeoJson(
                      { positions, interconnect: ic, constraint },
                      extractInterconnectConstraintGeoJsonProps,
                      true,
                    );
                  });
                } else {
                  return [nodeToGEOJson(node, constraint)];
                }
              })
          : [],
      }),
      format: new GeoJSON(),
    });

    return new WebGLLayer({
      source,
      style: getPointStyle(),
      zIndex,
    });
  }, [pointConstraints, nodes, showConstraints, nodeSize]);

  useEffect(() => {
    if (!map || !constraintsLayer) return;
    map.addLayer(constraintsLayer);
    return () => {
      map.removeLayer(constraintsLayer);
      constraintsLayer.dispose();
    };
  }, [map, constraintsLayer]);

  return null;
};

export const MapMinorPointConstraints = ({ zIndex }) => {
  const { map } = useContext(mapContext);
  const pointConstraints = useSelector(selectPointConstraints);
  const nodeSize = useSelector((state) => state.plotter.nodeSize);
  const nodes = useSelector(selectFilteredEdgeNodes);
  const allNodes = useSelector(selectAllNodes);
  const points = useSelector(selectFilteredEdgePoints);
  const allPoints = useSelector(selectAllPoints);
  const interconnects = useSelector(selectInterconnects);
  const showConstraints = useSelector((state) =>
    Boolean(state.plotter.showConstraints),
  );

  const [showDetailedConstraints, setShowDetailedConstraints] = useState(
    map?.getView().getZoom() ?? 0 > 8,
  );

  const constraintsLayer = useMemo(() => {
    const source = new VectorSource({
      features: new GeoJSON().readFeatures({
        type: 'FeatureCollection',
        features:
          showConstraints && showDetailedConstraints
            ? Object.values(pointConstraints)
                .filter((constraint) => constraint.constraint_factor < 1)
                .flatMap((constraint) => {
                  const point = points[constraint.point];
                  const node = nodes[point?.node];
                  if (!node || !point) return [];
                  const interconnectsAtPoint = Object.values(
                    interconnects,
                  ).filter((ic) => {
                    return (
                      Number(ic.end_point) === Number(point.id) ||
                      Number(ic.start_point) === Number(point.id)
                    );
                  });
                  if (interconnectsAtPoint.length > 0) {
                    return interconnectsAtPoint.flatMap((ic) => {
                      const startPoint = allPoints[ic.start_point];
                      const startNode = allNodes[startPoint?.node];
                      const endPoint = allPoints[ic.end_point];
                      const endNode = allNodes[endPoint?.node];

                      if (!startNode || !endNode) return [];
                      const positions =
                        (constraint.flow_direction === 'DELIVERY' &&
                          ic.start_point === point.id) ||
                        (constraint.flow_direction === 'RECEIPT' &&
                          ic.end_point === point.id)
                          ? [
                              [startNode.lat, startNode.lng],
                              [endNode.lat, endNode.lng],
                            ]
                          : [
                              [endNode.lat, endNode.lng],
                              [startNode.lat, startNode.lng],
                            ];

                      return edgeToGeoJson(
                        { positions, interconnect: ic, constraint },
                        extractInterconnectConstraintGeoJsonProps,
                        true,
                      );
                    });
                  } else {
                    return [nodeToGEOJson(node, constraint)];
                  }
                })
            : [],
      }),
      format: new GeoJSON(),
    });

    return new WebGLLayer({
      source,
      style: getPointStyle(),
      zIndex,
    });
  }, [
    pointConstraints,
    nodes,
    showConstraints,
    showDetailedConstraints,
    nodeSize,
  ]);

  useEffect(() => {
    if (!map || !constraintsLayer) return;
    map.addLayer(constraintsLayer);
    const onChangeZoom = () => {
      const val = map.getView().getZoom() > 8;
      if (val !== showDetailedConstraints) {
        setShowDetailedConstraints(val);
      }
    };
    map.getView().on('change:resolution', onChangeZoom);
    return () => {
      map.removeLayer(constraintsLayer);
      constraintsLayer.dispose();
      map.un('change:resolution', onChangeZoom);
    };
  }, [map, constraintsLayer]);

  return null;
};
